import React from 'react';
import styled from 'styled-components';
import { FieldArray, Field, getIn } from 'formik';
import { Table, Button, Icon } from 'rbx';
import uuid from 'uuid';
import {
  Input,
  Table as CustomTable,
  CheckBox,
} from '../../../components/elements';

const Container = styled.fieldset`
  .table td,
  .table th {
    border: 0;
  }
`;

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="has-text-danger	is-size-5">{error}</div>
      ) : null;
    }}
  />
);

const EditDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <div className="is-flex">
    {!hideDeleteButton && (
      <Button onClick={remove}>
        <Icon size="small">
          <i className="fas fa-trash has-text-grey" />
        </Icon>
      </Button>
    )}
    {showAddButton && (
      <Button onClick={add}>
        <Icon size="small">
          <i className="fas fa-plus has-text-grey" />
        </Icon>
      </Button>
    )}
  </div>
);

const PlaceMangedFee = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
}) => {
  const addNewFee = helpers => {
    helpers.push({
      id: uuid(),
      name: `custom-${uuid()}`,
      applies_to: '',
      can_override: true,
      isOverride: true,
      label: '',
      place_managed: true,
      sales_method: '',
      service_type: '',
      value: 0,
      fee_value_type: '',
    });
  };
  const removeFee = id => {
    setValues({
      ...values,
      placeManagedFee: values.placeManagedFee.filter(item => item.id !== id),
    });
  };

  return (
    <Container className="box">
      <legend className="label">Place Manged Fees</legend>
      <CustomTable>
        <Table.Head>
          <Table.Row>
            {/* <Table.Heading>Fee Name</Table.Heading> */}
            <Table.Heading>Fee Display Label</Table.Heading>
            <Table.Heading>Sale Method</Table.Heading>
            {/* <Table.Heading>Override</Table.Heading> */}
            <Table.Heading>Fee Type</Table.Heading>
            <Table.Heading>Fee</Table.Heading>
            <Table.Heading>Applies To</Table.Heading>
            {/* <Table.Heading>Place Managed</Table.Heading> */}
            <Table.Heading>Actions</Table.Heading>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <FieldArray
            name="placeManagedFee"
            render={helpers => (
              <React.Fragment>
                {values.placeManagedFee.length === 0 ? (
                  <Table.Row>
                    {/* <Table.Cell>
                      <Input disabled />
                    </Table.Cell> */}
                    <Table.Cell>
                      <Input disabled />
                    </Table.Cell>
                    <Table.Cell>
                      <Input disabled />
                    </Table.Cell>
                    <Table.Cell>
                      <CheckBox label="" disabled />
                    </Table.Cell>
                    <Table.Cell>
                      <Input disabled />
                    </Table.Cell>
                    {/* <Table.Cell>
                      <Input disabled />
                    </Table.Cell> */}
                    {/* <Table.Cell>
                      <Input disabled />
                    </Table.Cell> */}
                    <Table.Cell>
                      <CheckBox label="" disabled={false} />
                    </Table.Cell>
                    <Table.Cell>
                      <EditDeleteButton
                        add={() => addNewFee(helpers)}
                        remove={() => {}}
                        showAddButton
                        hideDeleteButton
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  <React.Fragment>
                    {values.placeManagedFee.map((item, index) => (
                      <Table.Row>
                        {/* <Table.Cell>
                          <Input value={item.name} disabled />
                        </Table.Cell> */}
                        <Table.Cell>
                          <Input
                            value={item.label}
                            name={`placeManagedFee.${index}.label`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item.isOverride}
                          />
                          <ErrorMessage
                            name={`placeManagedFee.${index}.label`}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            value={item.sales_method}
                            name={`placeManagedFee.${index}.sales_method`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item.isOverride}
                          />
                          <ErrorMessage
                            name={`placeManagedFee.${index}.sales_method`}
                          />
                        </Table.Cell>
                        {/* <Table.Cell>
                          {!item.can_override ? (
                            <div className="is-size-1 has-text-danger">
                              <i className="fas fa-times-circle" />
                            </div>
                          ) : (
                            <CheckBox
                              label=""
                              value={item.isOverride}
                              onChange={value =>
                                setFieldValue(
                                  `placeManagedFee.${index}.isOverride`,
                                  value,
                                )
                              }
                            />
                          )}
                        </Table.Cell> */}
                        <Table.Cell>
                          <Input
                            value={item.fee_value_type}
                            name={`placeManagedFee.${index}.fee_value_type`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item.isOverride}
                          />
                          <ErrorMessage
                            name={`placeManagedFee.${index}.fee_value_type`}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            value={item.value}
                            type="number"
                            name={`placeManagedFee.${index}.value`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item.isOverride}
                          />
                          <ErrorMessage
                            name={`placeManagedFee.${index}.value`}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            value={item.applies_to}
                            name={`placeManagedFee.${index}.applies_to`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!item.isOverride}
                          />
                          <ErrorMessage
                            name={`placeManagedFee.${index}.applies_to`}
                          />
                        </Table.Cell>
                        {/* <Table.Cell>
                          <CheckBox
                            label=""
                            value={item.place_managed}
                            disabled
                          />
                        </Table.Cell> */}
                        <Table.Cell>
                          {item.can_override && (
                            <EditDeleteButton
                              add={() => addNewFee(helpers)}
                              remove={() => removeFee(item.id)}
                              showAddButton={
                                index === values.placeManagedFee.length - 1
                              }
                              hideDeleteButton={false}
                            />
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          />
        </Table.Body>
      </CustomTable>
    </Container>
  );
};

export default PlaceMangedFee;
