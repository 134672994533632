/* eslint-disable max-len */
// defaultVariables is for local development
const defaultVariables = {
  authEnvironmentPrefix: 'test',
  apiPrefix: 'test.',
  userPoolId: 'us-east-1_JvKtjTImD',
  clientId: '32gr3s5n85rfbi10top0dtv9fc',
  identityPoolId: 'us-east-1:a3176c3e-c65d-447a-83fd-fe9a94accb85',
  awsAppSyncApiKey: 'da2-cceejclsjjbavb526roab337nu',
  analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
  redirectUri: `http://localhost:3000`,
  authUrl: `https://testauth.kravein.com.au/login?response_type=token`,
  graphqlApiPublic: 'https://test.publicapi.kravein.com.au/graphql',
  graphqlApiPrivate: 'https://test.graphql.kravein.com.au/graphql',
};

const variables = {
  DEV1: {
    authEnvironmentPrefix: 'dev1',
    apiPrefix: 'dev1.',
    userPoolId: 'us-east-1_9Xkdly93x',
    clientId: '3p8hpefpgqd2vri5jpkg7pceu1',
    identityPoolId: 'us-east-1:c8290d25-a498-49cf-a5a7-b08100615c9b',
    awsAppSyncApiKey: 'da2-qnkheg5hnve4honticilenbt7a',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirectUri: `https://dev1.admin.portal.kravein.com.au/`,
    authUrl: `https://dev1auth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic: 'https://dev1.publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://dev1.graphql.kravein.com.au/graphql',
  },
  TEST: {
    authEnvironmentPrefix: 'test',
    apiPrefix: 'test.',
    userPoolId: 'us-east-1_JvKtjTImD',
    clientId: '32gr3s5n85rfbi10top0dtv9fc',
    identityPoolId: 'us-east-1:a3176c3e-c65d-447a-83fd-fe9a94accb85',
    awsAppSyncApiKey: 'da2-cceejclsjjbavb526roab337nu',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirectUri: `https://test.admin.portal.kravein.com.au/`,
    authUrl: `https://testauth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic: 'https://test.publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://test.graphql.kravein.com.au/graphql',
  },
  PROD: {
    authEnvironmentPrefix: '',
    apiPrefix: '',
    userPoolId: 'us-east-1_PyOI52FsO',
    clientId: '9je5lt1t5iud1op64u2tjhl31',
    identityPoolId: 'us-east-1:a6029dca-788f-4893-b398-1234cd454a78',
    awsAppSyncApiKey: 'da2-notr5x5trjh4xcvv7de37lmgbu',
    analyticsKey: 'HTjBdAzlMKtWou728rDVZULxUIidOB2S',
    redirectUri: `https://admin.portal.kravein.com.au/`,
    authUrl: `https://auth.kravein.com.au/login?response_type=token`,
    graphqlApiPublic: 'https://publicapi.kravein.com.au/graphql',
    graphqlApiPrivate: 'https://graphql.kravein.com.au/graphql',
  },
};

// defaultVariables is for local development
const config = process.env.REACT_APP_ENV
  ? variables[process.env.REACT_APP_ENV]
  : defaultVariables;

export default {
  public: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPublic,
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: config.awsAppSyncApiKey,
  },
  secured: {
    aws_appsync_graphqlEndpoint: config.graphqlApiPrivate,
    aws_appsync_region: 'us-east-1',
  },

  userPoolConfig: {
    UserPoolId: config.userPoolId,
    ClientId: config.clientId,
    IdentityPoolId: config.identityPoolId,
  },

  analyticsKey: config.analyticsKey,
  redirectUri: config.redirectUri,
  apiPrefix: config.apiPrefix,
  authUrl: `${config.authUrl}&client_id=${config.clientId}&redirect_uri=${config.redirectUri}`,
  emojiRegex:
    '/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/',
};
