import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import {
  Radio,
  Input,
  CheckBox,
  TagInputs,
} from '../../../components/elements';

const RadioWrapper = styled.div`
  padding: 0rem 0.5rem;
`;
const Wrapper = styled.div`
  padding-top: 0.8rem;
`;

const DiscountDetails = ({
  values,
  // handleChange,
  // handleBlur,
  setFieldValue,
}) => (
  <Column.Group vcentered multiline gapSize={8}>
    <Column size={5}>
      <TagInputs label="Menu" disabled />
    </Column>
    <Column size={5}>
      <TagInputs label="Discount Level" disabled />
    </Column>
    <Column size={10}>
      <TagInputs label="Menu Section" disabled />
    </Column>
    <Column size={5}>
      <div className="field">
        <div className="label">Deal / Voucher Type</div>
        <div className="control">
          <div className="is-flex">
            <RadioWrapper>
              <Radio
                label="Amount"
                value={values.deal_type === 'Deal Listing Only'}
                onChange={value => {
                  if (value) {
                    setFieldValue('deal_type', 'Deal Listing Only');
                  }
                }}
                disabled
              />
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                label="Percentage"
                value={values.deal_type === 'Automatic Promotion'}
                onChange={value => {
                  if (value) {
                    setFieldValue('deal_type', 'Automatic Promotion');
                  }
                }}
                disabled
              />
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                label="Free Delivery"
                value={values.deal_type === 'Requires Voucher Code'}
                onChange={value => {
                  if (value) {
                    setFieldValue('deal_type', 'Requires Voucher Code');
                  }
                }}
                disabled
              />
            </RadioWrapper>
          </div>
        </div>
      </div>
    </Column>
    <Column size={5}>
      <Input
        label="Deal / Voucher Min Purchase Amount"
        value={values.minimum_purchase_amount}
        disabled
      />
    </Column>
    <Column size={5}>
      <Input
        label="Discount Amount / Percentage"
        value={values.percentage}
        disabled
      />
    </Column>
    <Column size={5}>
      <Input
        label="Maximum No of Discount per User"
        values={values.maximum_discount_amount}
        disabled
      />
    </Column>
    <Column size={5}>
      <div className="field">
        <div className="label">Discount Limitation</div>
        <div className="control">
          <RadioWrapper>
            <Radio
              label="Unlimited Uses"
              value={values.deal_type === 'Requires Voucher Code'}
              onChange={value => {
                if (value) {
                  setFieldValue('deal_type', 'Requires Voucher Code');
                }
              }}
              disabled
            />
          </RadioWrapper>
          <div className="is-flex">
            <RadioWrapper>
              <Wrapper>
                <Radio
                  label="Maximum No of Discount"
                  value={values.deal_type === 'Requires Voucher Code'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('deal_type', 'Requires Voucher Code');
                    }
                  }}
                  disabled
                />
              </Wrapper>
            </RadioWrapper>
            <Input disabled />
          </div>
        </div>
      </div>
    </Column>
    <Column size={5}>
      <div className="field">
        <div className="label">Maximum No Of Discount Per User</div>
        <div className="control">
          <RadioWrapper>
            <Radio
              label="Unlimited"
              value={values.deal_type === 'Requires Voucher Code'}
              onChange={value => {
                if (value) {
                  setFieldValue('deal_type', 'Requires Voucher Code');
                }
              }}
              disabled
            />
          </RadioWrapper>
          <div className="is-flex">
            <RadioWrapper>
              <Wrapper>
                <Radio
                  label="Enter Limit"
                  value={values.deal_type === 'Requires Voucher Code'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('deal_type', 'Requires Voucher Code');
                    }
                  }}
                  disabled
                />
              </Wrapper>
            </RadioWrapper>
            <Input disabled />
          </div>
        </div>
      </div>
    </Column>
    <Column size={12}>
      <CheckBox label="Can be clubbed with other discount" secondary disabled />
    </Column>
    <Column size={12}>
      <CheckBox
        label="Published Voucher / Deal"
        value={values.is_published}
        onChange={value => setFieldValue('is_published', value)}
        secondary
      />
    </Column>
  </Column.Group>
);

export default DiscountDetails;
