import React, { useState } from 'react';
//import { capitalize, forEach } from 'lodash';
import styled from 'styled-components';

import Selection from './Selection';

import { Column } from 'rbx';
import {
  Select,
  Input,
 
} from '../../../components/elements';

const CheckBoxWrapper = styled.div``;

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];
const customSelect = [
  { value: 'CUSTOM SELECT', label: 'CUSTOM SELECT' },
  { value: 'CUSTOM SELECT2', label: 'CUSTOM SELECT2' },
];

const ModifierDetails = ({
  values,
  touched,
  
}) => {
 // const [isManual, setManual] = useState(false);
  const [statusFilter, setStatusFilter] = useState('REQUIRED');
  return (
    <div>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input
            label="Modifier Display Name"
            placeholder="Choose a side"
            optionalText="(Max 50 Characters)"
            required
          />
        </Column>
        <Column size={4}>
          <Input
            label="Modifier Internal Name"
            placeholder="Sides for Pizzas and Pastas"
            optionalText="(Max 50 Characters)"
          />
        </Column>
        <Column size={4}>
          <Select label="Modifier Status" options={status} />
        </Column>
      </Column.Group>

      <Selection
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />

      <Column size={5}>
      <Select label="Selection Quantity" optionalText="(Select Custom Select to enter the total number of modifiers customers can choose (i.e, choose a side)
       or 'Select a Range' to allow the customer to choose a minimum or maximum number of modifiers
        (i.e, choose upto 2 sides))" 
        options={customSelect}
        />
      </Column>
    </div>
  );
};

export default ModifierDetails;
