import React from 'react';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { replace, capitalize } from 'lodash';
import { Button, Icon, Column } from 'rbx';
import uuid from 'uuid';

import Input from './elements/Input';
import { MobileInput, Select, CheckBox } from './elements';

const types = [
  {
    value: 'email',
    label: 'Email',
  },
  {
    value: 'mobile',
    label: 'Mobile',
  },
  {
    value: 'fax',
    label: 'Fax',
  },
  {
    value: 'website',
    label: 'Website',
  },
  {
    value: 'social',
    label: 'Social',
  },
];

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const Wrapper = styled.div`
  padding-top: 2rem;
`;
const CheckBoxWrapper = styled.div`
  position: relative;
  left: 9.5rem;
  top: 20px;
  @media (min-width: 1024px) and (max-width: 1240px) {
    top: 15px;
  }
`;

const AddDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <Button.Group hasAddons>
    {!hideDeleteButton && (
      <Button onClick={remove}>
        <Icon size="small">
          <i className="fas fa-trash has-text-grey" />
        </Icon>
      </Button>
    )}
    {showAddButton && (
      <Button onClick={add}>
        <Icon size="small">
          <i className="fas fa-plus has-text-grey" />
        </Icon>
      </Button>
    )}
  </Button.Group>
);

const ContactRow = ({
  index,
  item,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  addContact,
  removeContact,
  helpers,
}) => (
  <div className="columns is-vcentered" key={index}>
    <div className="column is-3">
      <Select
        label="Contact Types"
        options={types}
        value={[{ value: item.type, label: capitalize(item.type) }]}
        onChange={value => setFieldValue(`contact.${index}.type`, value.value)}
      />
    </div>
    <div className="column is-4">
      {(item.type === 'email' ||
        item.type === 'fax' ||
        item.type === 'website' ||
        item.type === 'social') && (
        <Input
          label={capitalize(item.type)}
          options={types}
          name={`contact.${index}.value`}
          value={item.value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      )}

      {item.type === 'mobile' && (
        <MobileInput
          label=" Mobile"
          value={values.contact[index].value}
          onChange={value =>
            setFieldValue(`contact.${index}.value`, replace(value, ' ', ''))
          }
        />
      )}
    </div>
    <div className="column is-2">
      <Wrapper>
        <CheckBox
          label="Display"
          value={values.contact[index].display}
          onChange={value => setFieldValue(`contact.${index}.display`, value)}
        />
      </Wrapper>
    </div>
    <div className="column is-2">
      <Wrapper>
        <AddDeleteButton
          add={() => addContact(helpers, 'email')}
          remove={() => removeContact(item.id)}
          showAddButton={index === values.contact.length - 1}
          hideDeleteButton={false}
        />
      </Wrapper>
    </div>
  </div>
);

const ContactRowDefault = ({ addContact, helpers }) => (
  <div className="columns is-vcentered">
    <div className="column is-3">
      <Select
        label="Contact Types"
        options={types}
        onChange={value => addContact(helpers, value.value)}
      />
    </div>
    <div className="column is-4">
      <Input label="Value" />
    </div>
    <div className="column is-2">
      <Wrapper>
        <CheckBox label="Display" />
      </Wrapper>
    </div>
    <div className="column is-2">
      <Wrapper>
        <AddDeleteButton
          add={() => addContact(helpers, 'email')}
          remove={() => {}}
          showAddButton
          hideDeleteButton
        />
      </Wrapper>
    </div>
  </div>
);

const Contacts = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
  onlyAdditionalContact,
}) => {
  // const isPrimaryEmailField = () => {
  //   const data = values.contact.filter(
  //     item => item.type === 'email' && item.is_primary === true,
  //   );
  //   const isPrimary = data.length === 0;
  //   return isPrimary;
  // };

  const addContact = (helpers, value) => {
    const order = values.contact.length + 3;
    helpers.push({
      id: uuid(),
      type: value,
      value: '',
      display: false,
      is_primary: false,
      display_order: order.toString(8),
    });
  };

  const removeContact = id => {
    setValues({
      ...values,
      contact: values.contact.filter(item => item.id !== id),
    });
  };

  // const deleteContact = helpers => {
  //   helpers.pop();
  // };

  return (
    <Container>
      <FieldArray
        name="contact"
        render={helpers => (
          <React.Fragment>
            {!onlyAdditionalContact && (
              <Column.Group vcentered multiline gapSize={8}>
                <Column size={6}>
                  <div className="is-flex">
                    <CheckBoxWrapper>
                      <CheckBox
                        label="Display Email"
                        value={values.displayEmail}
                        onChange={() =>
                          setFieldValue(`displayEmail`, !values.displayEmail)
                        }
                      />
                    </CheckBoxWrapper>
                    &nbsp;&nbsp;
                    <CheckBoxWrapper>
                      <CheckBox
                        label="Enable user to contact organizer directly"
                        value={values.contact_event_organizer}
                        onChange={() =>
                          setFieldValue(
                            `contact_event_organizer`,
                            !values.contact_event_organizer,
                          )
                        }
                      />
                    </CheckBoxWrapper>
                  </div>
                  <Input
                    label="Primary Contact Email"
                    name="primaryEmail"
                    value={values.primaryEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Column>
                <Column size={6}>
                  <CheckBoxWrapper>
                    <CheckBox
                      label="Display Phone"
                      value={values.displayMobile}
                      onChange={() =>
                        setFieldValue('displayMobile', !values.displayMobile)
                      }
                    />
                  </CheckBoxWrapper>
                  <MobileInput
                    label=" Primary Contact Phone"
                    value={values.primaryMobile}
                    onChange={value =>
                      setFieldValue('primaryMobile', replace(value, ' ', ''))
                    }
                  />
                </Column>
                <Column size={6}>
                  <CheckBoxWrapper>
                    <CheckBox
                      label="Display Website"
                      value={values.displayBusiness}
                      onChange={() =>
                        setFieldValue(
                          'displayBusiness',
                          !values.displayBusiness,
                        )
                      }
                    />
                  </CheckBoxWrapper>
                  <Input
                    label="Business Website"
                    name="businessSite"
                    value={values.businessSite}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Column>
              </Column.Group>
            )}
            <fieldset className="box">
              <legend className="label">Additional Contact Details</legend>
              {values.contact.length !== 0 && (
                <React.Fragment>
                  {values.contact.map((item, index) => (
                    <ContactRow
                      index={index}
                      item={item}
                      values={values}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      addContact={addContact}
                      removeContact={removeContact}
                      helpers={helpers}
                    />
                  ))}
                </React.Fragment>
              )}
              {values.contact.length === 0 && (
                <React.Fragment>
                  <ContactRowDefault
                    handleChange={handleChange}
                    addContact={addContact}
                    helpers={helpers}
                  />
                </React.Fragment>
              )}
            </fieldset>
          </React.Fragment>
        )}
      />
    </Container>
  );
};

export default Contacts;
