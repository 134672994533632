import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';

const CheckInList = ({ flag, users }) => {
  const userArray = users.filter(
    item => item.user_id === flag.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <>
      <tr>
        <td>
          <Link to={`/flagged-review/${flag.place.place_id}/${flag.flag_id}`}>
            {flag.place.name}
          </Link>
        </td>

        <td> {flag.object_type} </td>
        <td>{flag.flag_type}</td>
        {/* <td> {flag.object_type} </td> */}
        <td>
          {user ? (
            <React.Fragment>
              <p data-tip={user.full_name}>{user.full_name}</p>
              <ReactTooltip place="top" type="dark" effect="float">
                <span>
                  <p>email:{user.email} </p>
                  <p>objectId: {user.user_id}</p>
                </span>
              </ReactTooltip>
            </React.Fragment>
          ) : (
            flag.audit.updated_by
          )}
        </td>

        <td>{moment(flag.audit.updated_at).format('lll')}</td>
        {/* <td>{flag.status}</td> */}
      </tr>
    </>
  );
};

export default CheckInList;
