import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import { Title, Table } from 'rbx';
import { sortBy, reverse, first } from 'lodash';
import {
  Table as CustomTable,
  Box,
  Pagination,
} from '../../../components/elements';
import BookingByTicketList from './BookingByTicketList';

const Heading = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  margin-bottom: 1rem;
`;

// const normalizeFee = values => {
//   const onlinePassFee = values
//     ? values.filter(item => item.name === 'online_passon_fee')
//     : [];
//   const offlinePassFee = values
//     ? values.filter(item => item.name === 'offline_passon_fee')
//     : [];
//   const onlineAbsorbFee = values
//     ? values.filter(item => item.name === 'online_absorb_fee')
//     : [];
//   const offlineAbsorbFee = values
//     ? values.filter(item => item.name === 'offline_absorb_fee')
//     : [];
//   return {
//     onlinePassFee: onlinePassFee.length !== 0 ? first(onlinePassFee).value : 0,
//     offlinePassFee:
//       offlinePassFee.length !== 0 ? first(offlinePassFee).value : 0,
//     onlineAbsorbFee:
//       onlineAbsorbFee.length !== 0 ? first(onlineAbsorbFee).value : 0,
//     offlineAbsorbFee:
//       offlineAbsorbFee.length !== 0 ? first(offlineAbsorbFee).value : 0,
//   };
// };

const BookingByTicket = ({ tickets, setSort }) => {
  const [ticketData, setTicketData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [ticketCategorySort, setTicketCategorySort] = useState('TICKET_AES');
  const [quantitySort, setQuantitySort] = useState('AES');
  const [priceSort, setPriceSort] = useState('AES');
  const [oLPassOnFee, setOLPassOnFee] = useState('OLPassOnFee_AES');
  const [oLAbsorbFee, setOLAbsorbFee] = useState('OLAbsorbFee_AES');
  const [agentPassOnFee, setAgentPassOnFee] = useState('AgentPassOnFee_AES');
  const [agentAbsorbFee, setAgentAbsorbFee] = useState('AgentAbsorbFee_AES');
  const [ticketType, setTicketType] = useState('TicketType_AES');

  return (
    <Box>
      {totalElements > 0 && (
        <Pagination
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <Heading>
        <Title size={3}>Booking By Ticket Category</Title>
      </Heading>
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        ticketCategorySort === 'TICKET_AES'
                          ? sortBy(ticketData, 'ticket_name')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setTicketCategorySort(
                        ticketCategorySort === 'TICKET_AES'
                          ? 'TICKET_DES'
                          : 'TICKET_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketCategorySort === 'TICKET_AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Category
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      const ticket =
                        ticketCategorySort === 'QUANTITY_ASC'
                          ? sortBy(ticketData, 'QUANTITY')
                          : reverse(ticketData);
                      setTicketData(ticket);
                      setTicketCategorySort(
                        ticketCategorySort === 'QUANTITY_ASC'
                          ? 'QUANTITY_DESC'
                          : 'QUANTITY_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketCategorySort === 'QUANTITY_ASC'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Quantity
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setPriceSort(priceSort === 'AES' ? 'DES' : 'AES');
                      setSort(priceSort === 'AES' ? 'PRICE_DESC' : 'PRICE_ASC');
                    }}
                  >
                    <i
                      className={
                        priceSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Price
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setOLPassOnFee(oLPassOnFee === 'AES' ? 'DES' : 'AES');
                      setSort(
                        oLPassOnFee === 'AES'
                          ? 'OLPassOnFee_DESC'
                          : 'OLPassOnFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        oLPassOnFee === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  OL Pass On Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setOLAbsorbFee(oLAbsorbFee === 'AES' ? 'DES' : 'AES');
                      setSort(
                        oLAbsorbFee === 'AES'
                          ? 'OLAbsorbFee_DESC'
                          : 'OLAbsorbFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        oLAbsorbFee === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  OL Absorb Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setAgentPassOnFee(
                        agentPassOnFee === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        agentPassOnFee === 'AES'
                          ? 'AgentPassOnFee_DESC'
                          : 'AgentPassOnFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        agentPassOnFee === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Agent Pass On Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setAgentAbsorbFee(
                        agentAbsorbFee === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        agentAbsorbFee === 'AES'
                          ? 'AgentAbsorbFee_DESC'
                          : 'AgentAbsorbFee_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        agentAbsorbFee === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Agent Absorb Fee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setTicketType(ticketType === 'AES' ? 'DES' : 'AES');
                      setSort(
                        ticketType === 'AES'
                          ? 'TicketType_DESC'
                          : 'TicketType_AES',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketType === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Type
                </span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>

        <View
          ticket={tickets}
          // fee={normalizeFee(tickets.fee)}
          ticketData={ticketData}
          setTicketData={setTicketData}
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
        />
      </CustomTable>
    </Box>
  );
};
export default BookingByTicket;
const View = ({
  ticket,
  // fee,
  setPageCount,
  setTotalElements,
  setTicketData,
  ticketData,
}) => {
  useEffect(() => {
    setPageCount(ticket.total_pages);
    setTotalElements(ticket.total_size);
    if (ticket.ticket_record_listing) {
      setTicketData(ticket.ticket_record_listing);
    }
  }, [ticket.total_pages]);

  return (
    <tbody>
      {ticketData.map(tickets => (
        <tr key={tickets.ticket_number}>
          <BookingByTicketList
            tickets={tickets}
            // fee={fee}
          />
        </tr>
      ))}
    </tbody>
  );
};
