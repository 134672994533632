import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Button, Title, Level, Table } from 'rbx';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';
import gql from 'graphql-tag';
import Footer from './Footer';

import {
  Input,
  TextArea,
  CheckBox,
  Loading,
  Select,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_claim(input: $input) {
      place_id
      claimant_id
      name
      city
      status
      claim_status
    }
  }
`;

const Container = styled.div``;

const ItemInformation = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  props,
}) => {
  const [statusFilter, setStatusFilter] = useState('AVAILABLE');
  return (
    <React.Fragment>
      <Column.Group>
        <Column size={4}>
          <Input
            label="Combo Deal Display Name"
            placeholder="Hawaiian Pizza"
            optionalText="(Max 50 Characters)"
            required
          />
        </Column>
        <Column size={4}>
          <Input
            label="Combo Deal Internal Name"
            placeholder="Hawaiian Pizza"
            optionalText="(Visible to you only-Max 50 Chars)"
            required
          />
        </Column>

        <Column size={4}>
          <Select label="Deal Status" required />
        </Column>
      </Column.Group>

      <Column.Group multilined vcentered>
        <Column size={2}>
          <Select label="Combo Option Type" required />
        </Column>
        <Column size={2}>
          <Input
            label="Combo Discount"
            placeholder="$99.95"
            optionalText="(Max 50 Characters)"
            required
          />
        </Column>

        <Column size={2}>
          <Select label="Item Tax" required />
        </Column>

        <Column>
          <CheckBox label=" Popular" />
        </Column>

        <Column size={2}>
          <Select label="Approval Status" required />
        </Column>
      </Column.Group>
      <TextArea
        label="Combo Deal Description"
        optionalText="Max 250 Characters"
        rows={2}
      />

      {/* <Query
        query={placesQuery}
        variables={{ input: { filter: { place_filter: {} } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const { search_place_claim } = data;
          if (isNull(search_place_claim)) {
            return 'no data found';
          }

          return (
            <tbody>
              {search_place_claim.map(claim => (
                <ListItem claim={claim} />
              ))}
            </tbody>
          );
        }}
      </Query> 
   */}
    </React.Fragment>
  );
};

export default ItemInformation;
