import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  action,
  loading,
  createForm,
  handleClaimApproveAndSubmit,
  handleClaimSubmit,
  handleClaimApprove,
  handleClaimReject,
  handleClaimUpdate,
  hideRejectButton,
  hideApproveButton,
  history,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        {createForm ? (
          <React.Fragment>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'submit-approve-claim' &&
                  'is-loading'}`}
                onClick={handleClaimApproveAndSubmit}
              >
                <span>Submit And Approve</span>
              </button>
            </Level.Item>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'submit-claim' &&
                  'is-loading'}`}
                onClick={handleClaimSubmit}
              >
                <span>Submit</span>
              </button>
            </Level.Item>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Level.Item>
              {!hideRejectButton && (
                <button
                  className={`button is-danger ${loading &&
                    action === 'reject-claim' &&
                    'is-loading'}`}
                  onClick={handleClaimReject}
                >
                  <span>Reject</span>
                </button>
              )}
            </Level.Item>
            <Level.Item>
              {!hideApproveButton && (
                <button
                  className={`button is-primary ${loading &&
                    action === 'approve-claim' &&
                    'is-loading'}`}
                  onClick={handleClaimApprove}
                >
                  <span>Approve</span>
                </button>
              )}
            </Level.Item>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'update-claim' &&
                  'is-loading'}`}
                onClick={handleClaimUpdate}
                disabled
              >
                <span>Update</span>
              </button>
            </Level.Item>
          </React.Fragment>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
