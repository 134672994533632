import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import useFetchUser from '../../../utils/useFetchUser';

const CheckInList = ({ menu }) => {
  const userId = menu.audit.created_by;
  const { user } = useFetchUser({
    userId,
    caption: true,
  });

  return (
    <React.Fragment>
      <td>
        <Link to="/update-menu">{menu.name}</Link>
      </td>
      <td>{menu.place_name}</td>
      <td>{menu.place_city}</td>
      <td>-</td>
      <td>-</td>
      <td>-</td>
      <td>
        {user ? (
          <React.Fragment>
            <p>{`${user.caption1} ${user.caption2}`}</p>
          </React.Fragment>
        ) : (
          userId
        )}
      </td>
      <td> {moment(menu.audit.created_at).format('lll')} </td>
      <td>{menu.status}</td>
      <td> </td>
    </React.Fragment>
  );
};

export default CheckInList;
