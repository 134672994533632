import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import qs from 'querystringify';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  CustomInput,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_feed(input: $input) {
      total_pages
      total_size
      place_feed_listing {
        place_id
        feed_id
        name
        city
        title
        content
        # status
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchQuery($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
      }
    }
  }
`;

const PlacePostings = props => {
  const routeState = qs.parse(props.location.search);
  const { status } = routeState;

  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [titleSort, setTitleSort] = useState('AES');
  const [postedBySort, setPostedBySort] = useState('AES');
  const [postedOnSort, setPostedOnSort] = useState('AES');
  const [StatusSort, setStatusSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState('SUBMITTED');
  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');

  const input = {};
  const place_feed_filter = {};
  Object.assign(
    place_feed_filter,
    vendorNameSearchInput !== '' && { vendor_name: vendorNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    status !== '' && { status },
  );

  Object.assign(
    input,
    { sort },
    location !== '' && { location },
    { from: pageFrom, size: listSize },
    {
      filter: {
        place_feed_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setTitleSort(titleSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          titleSort === 'AES' ? 'TITLE_DESC' : 'TITLE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          titleSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Title
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              {/* <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(StatusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          StatusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          StatusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  <br />
                <CustomInput />
                </div>
              </th> */}
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_place_feed } = data;

              if (isNull(search_place_feed)) {
                return <tbody>No Data Found</tbody>;
              }

              return (
                // <React.Fragment>
                //   <tbody>
                //     {search_place_feed.place_feed_listing &&
                //       search_place_feed.place_feed_listing.map(post => (
                //         <tr key={post.place_id}>
                //           <ListItem
                //             totalPages={search_place_feed.total_pages}
                //             post={post}
                //             loading={loading}
                //             error={error}
                //             totalElements={search_place_feed.total_size}
                //             setPageCount={() =>
                //               setPageCount(search_place_feed.total_pages)
                //             }
                //             setTotalElements={() =>
                //               setTotalElements(search_place_feed.total_size)
                //             }
                //           />
                //         </tr>
                //       ))}
                //   </tbody>
                // </React.Fragment>
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_place_feed={search_place_feed}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_place_feed,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_place_feed.total_pages);
    setTotalElements(search_place_feed.total_size);

    if (search_place_feed.place_feed_listing) {
      setPlaceData(search_place_feed.place_feed_listing);
      const userFilter = search_place_feed.place_feed_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_feed.total_pages]);

  return (
    <tbody>
      {placeData.map(place => (
        <tr key={place.place_id}>
          <ListItem place={place} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default PlacePostings;
