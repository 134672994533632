import React from 'react';
import moment from 'moment';
import { first } from 'lodash';

const PhotoLikeList = ({ users, social }) => {
  const userArray = users.filter(
    item => item.user_id === social.audit.created_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <tr>
      <td>
        {user ? (
          <React.Fragment>
            <p>{user.full_name}</p>
          </React.Fragment>
        ) : (
          social.audit.created_by
        )}
      </td>
      <td>{user && user.email}</td>
      <td>{moment(social.audit.created_at).format('lll')}</td>
    </tr>
  );
};

export default PhotoLikeList;
