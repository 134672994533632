import React from 'react';
import { Column, Level } from 'rbx';
import styled from 'styled-components';
import moment from 'moment';
import TimeDatePicker from '../../../components/global/TimeDatePicker';
import {
  Select,
  TextArea,
  Input,
  CheckBox,
  ReactDateTimePicker,
  CreatableSelectInputs,
} from '../../../components/elements';
const Wrapper = styled.div`
  padding: 2.5rem;
`;

const HolidayHours = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <Column.Group multiline gapSize={8}>
      <Column size={4}>
        <Input
          label="Holiday Name"
          name="name"
          // value={values.name}
          //onChange={handleChange}
          required
        />
      </Column>
      <Column size={4}>
        <Select
          label="Holiday Hours Status"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          // value={[{ value: values.status, label: values.status }]}
          required
        />
      </Column>
      <Column size={4}>
        <Select
          label="Approval Status"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          //  value={[{ value: values.status, label: values.status }]}
          required
        />
      </Column>
      <Column size={4}>
        <ReactDateTimePicker label="Start Date" value={null} />
      </Column>
      <Column size={4}>
        <ReactDateTimePicker label="End Date" value={null} />
      </Column>
      <Wrapper>
        <CheckBox label="Closed" />
      </Wrapper>
    </Column.Group>
  );
};
export default HolidayHours;
