import React from 'react';
import { Column } from 'rbx';
import styled from 'styled-components';
import { Input, TextArea } from '../../../components/elements';
import ItemReviewedUserDetails from './ItemReviewedUserDetails';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;
const ItemDetails = ({ item }) => (
  <Container>
    <Column>
      <fieldset className="box">
        <legend className="label">Item Details</legend>
        <Column>
          <Column.Group vcentered multiline gapSize={8}>
            <Column size="half">
              <Input label="Item Name" value={item.item_name} disabled />
            </Column>
            <Column size="half">
              <Input label="Status" value={item.status} disabled />
            </Column>
          </Column.Group>
        </Column>

        <Column size="full">
          <TextArea label="Review Content" value={item.content} disabled />
        </Column>

        <ItemReviewedUserDetails
          createdBy={item.audit.created_by}
          createdTime={item.audit.created_at}
          item={item}
        />
      </fieldset>
    </Column>
  </Container>
);
export default ItemDetails;
