import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  heading,
  placeName,
  loading,
  action,
  createForm,
  handleClaimApproveAndSubmit,
  handleClaimSubmit,
  handleClaimApprove,

  handleClaimReject,
  handleClaimUpdate,
  hideRejectButton,
  hideApproveButton,
  history,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            Cancel
          </a>
        </Level.Item>
        {createForm ? (
          <React.Fragment>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'submit-approve-claim' &&
                  'is-loading'}`}
                onClick={handleClaimApproveAndSubmit}
              >
                <span>Submit And Approve </span>
              </button>
            </Level.Item>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'submit-claim' &&
                  'is-loading'}`}
                onClick={handleClaimSubmit}
              >
                <span>Submit</span>
              </button>
            </Level.Item>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Level.Item>
              {!hideRejectButton && (
                <button
                  className={`button is-danger ${loading &&
                    action === 'reject-claim' &&
                    'is-loading'}`}
                  onClick={handleClaimReject}
                >
                  <span>Reject</span>
                </button>
              )}
            </Level.Item>

            <Level.Item>
              {!hideApproveButton && (
                <button
                  className={`button is-primary ${loading &&
                    action === 'approve-claim' &&
                    'is-loading'}`}
                  onClick={handleClaimApprove}
                >
                  <span>Approve</span>
                </button>
              )}
            </Level.Item>
            <Level.Item>
              <button
                className={`button is-primary ${loading &&
                  action === 'update-claim' &&
                  'is-loading'}`}
                onClick={handleClaimUpdate}
              >
                <span>Update</span>
              </button>
            </Level.Item>
          </React.Fragment>
        )}
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{placeName}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
