import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  activeTab,
  handleUpdate,
  handleApprove,
  approvalLoading,
  placeUpdateLoading,
  heading,
  placeName,
  approvalStatus,
  next,
  prev,
  history,
  dirty,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title className="is-size-2">{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}

        <Level.Item>
          <button
            className={`button is-primary ${approvalLoading && 'is-loading'}`}
            onClick={handleApprove}
            disabled={approvalStatus === 'APPROVED'}
          >
            <span>Approve</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button
            className={`button is-primary ${placeUpdateLoading &&
              'is-loading'}`}
            onClick={handleUpdate}
            disabled={!dirty}
          >
            <span>Update</span>
          </button>
        </Level.Item>

        {activeTab !== 1 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{placeName}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
