import React, { useState } from 'react';
import { capitalize, forEach } from 'lodash';
import styled from 'styled-components';

import { Column } from 'rbx';
import {
  Select,
  Input,
  TextArea,
  CheckBox,
  TagInputs,
} from '../../../components/elements';

const CheckBoxWrapper = styled.div``;

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const DefineMenus = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [isManual, setManual] = useState(false);
  return (
    <div>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input
            label="Menu Section Name"
            optionalText="(Max 35 Characters )"
            placeholder="Dessert and Coffee"
            required
          />
        </Column>
        <Column size={4}>
          <Select
            label="Menu Section Status"
            options={status}
            value={[{ value: values.status, label: values.status }]}
            onChange={({ value }) =>
              setFieldValue('status', value.toUpperCase())
            }
            errors={errors.name}
            touched={touched.name}
            required
          />
        </Column>
        <Column size={8}>
          <TagInputs label="Menu Section Keywords/ Tags" />
        </Column>
      </Column.Group>
    </div>
  );
};

export default DefineMenus;
