import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';
import { Message } from '../../../components/elements';

const placesQuery = gql`
  query searchPlaceClaim($input: SearchInput) {
    search_place_claimant(input: $input) {
      total_pages
      total_size
      place_claim_listing {
        place_id
        claimant_id
        name
        city
        status
        claim_status
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const PlaceClaims = props => {
  const routeState = qs.parse(props.location.search);
  const { status } = routeState;
  const [placeData, setPlaceData] = useState([]);

  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [claimStatusSort, setClaimStatusSort] = useState('AES');

  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [placeNameFilter, setPlaceNameFilter] = useState('');
  const [cityInput, setCityInput] = useState('');
  // const [placeLocationFilter, setPlaceLocationFilter] = useState('');
  const [location, setLocation] = useState('');
  const input = {};
  const place_filter = {};

  Object.assign(
    place_filter,
    {
      status,
    },
    cityInput !== '' && { city: cityInput },
    placeNameFilter !== '' && { place_name: placeNameFilter },
  );

  Object.assign(
    input,
    // location !== '' && { location },
    { from: pageFrom, size: listSize },
    { sort },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          location={location}
          setLocation={setLocation}
          status={status}
          setPlaceNameFilter={setPlaceNameFilter}
          cityInput={cityInput}
          setCityInput={setCityInput}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Status
                  </span>
                   <br />
                  <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'SUBMITTED', label: 'SUBMITTED' },
                      { value: 'APPROVED', label: 'APPROVED' },
                      { value: 'REJECTED', label: 'REJECTED' },
                    ]}
                  /> 
                </div>
              </th> */}
              <th>
                <span>
                  <a
                    onClick={() => {
                      setClaimStatusSort(
                        claimStatusSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        claimStatusSort === 'AES'
                          ? 'CLAIM_STATUS_DESC'
                          : 'CLAIM_STATUS_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        claimStatusSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Claim Status
                </span>
                {/* <br />
                <CustomSelect
                  options={[
                    { value: '', label: 'Select..' },
                    { value: 'CLAIMED', label: 'CLAIMED' },
                    { value: 'UNCLAIMED', label: 'UNCLAIMED' },
                  ]}
                /> */}
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.description}</Message>;
              }

              const { search_place_claimant } = data;

              return (
                <View
                  placeData={placeData}
                  setPlaceData={setPlaceData}
                  search_place_claim={search_place_claimant}
                  setTotalElements={setTotalElements}
                  setPageCount={setPageCount}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default PlaceClaims;

const View = ({
  search_place_claim,
  setPageCount,
  setTotalElements,
  placeData,
  setPlaceData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_place_claim.total_pages);
    setTotalElements(search_place_claim.total_size);
    if (search_place_claim.place_claim_listing) {
      setPlaceData(search_place_claim.place_claim_listing);

      const userFilter = search_place_claim.place_claim_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          // console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_claim.total_pages]);
  return (
    <tbody>
      {placeData.map(claim => (
        <ListItem
          claim={claim}
          totalPages={search_place_claim.total_pages}
          totalElements={search_place_claim.total_size}
          setPageCount={() => setPageCount(search_place_claim.total_pages)}
          setTotalElements={() =>
            setTotalElements(search_place_claim.total_size)
          }
          users={users}
        />
      ))}
    </tbody>
  );
};
