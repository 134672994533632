import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  flag,
  placeName,
  action,
  loading,
  heading,
  subHeading,
  handleApproveAction,
  handleRejectAction,
  history,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
        <Level.Item>
          <button
            className={`button is-danger ${action === 'REJECTED' &&
              loading &&
              'is-loading'}`}
            onClick={handleRejectAction}
            disabled={flag.status === 'REJECTED'}
          >
            <span>Reject</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button
            className={`button is-primary ${action === 'APPROVED' &&
              loading &&
              'is-loading'}`}
            onClick={() => handleApproveAction()}
            disabled={flag.status === 'APPROVED'}
          >
            <span>Unpublish and Inactivate</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{placeName}</SubHeading>
    <SubHeading className="is-size-2">{subHeading}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
