import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import {
  first,
  omit,
  forEach,
  concat,
  uniqBy,
  isNull,
  isEqual,
  size,
} from 'lodash';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import AddPlaceForm from '../AddPlace/AddPlaceForm';
import { getEncodedImage } from '../../../utils/s3';
import { Loading, Message, Blank } from '../../../components/elements';

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      error {
        code
        description
      }
    }
  }
`;

const placeQuery = gql`
  query fetch_place($placeId: String!, $imageFilter: ImageFilter) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      # vendor_id
      name
      slug
      trading_name
      tax_id {
        key
        value
      }
      tagline
      keywords
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      item_review_place
      default_image_url
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
      service_type {
        name
        description
        display_order
      }
      service_category {
        name
        description
        display_order
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      feature {
        name
        description
        logo
        display_order
      }
      dining_style {
        name
        description
        display_order
      }
      dietary_information {
        name
        description
        display_order
      }
      parking_detail {
        name
        description
        display_order
      }
      public_transport {
        name
        description
        display_order
      }
      noise_level {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      business_type {
        name
        description
        display_order
      }
      ambience {
        name
        description
        display_order
      }
      good_for {
        name
        description
        display_order
      }
      service_type {
        name
        description
        display_order
      }
      tag
      listing_status
      claim_status
      price_range
      slug
      featured
      event_business
      item_review_place
      business_hour {
        day
        is_active
        option {
          type
          start
          end
          start_in_sec
          end_in_sec
        }
      }
      note {
        type
        value
        date
      }
      verification_mode
      status
      approval_status
      display_order
      url {
        uri
        display_order
      }
      voucher_count
      voucher_list {
        code
        description
        tagline
        display_order
      }

      tax {
        type
        value
      }
      default_url
      location
      #  stars
      review_count
      like_percentage
      claimant_id
      listing_audit {
        listing_approved_by
        listing_approved_at
        listing_contact_user_id
        listing_contact_name
        listing_contact_phone
        listing_contact_email
        listing_contact_role
        listing_verification_note {
          type
          value
          date
        }
      }
    }
    search_images(input: { filter: { image_filter: $imageFilter } }) {
      image_id
      url
      link
      tooltip
      tags
    }
  }
`;

const claimQuery = gql`
  query fetch_place_claimant($claimantId: String) {
    fetch_place_claimant(input: { claimant_id: $claimantId }) {
      claimant_id
      object_id
      object_type
      contact_user_id
      contact_full_name
      contact_phone
      contact_email
      contact_role
      contact_business_url
      contact_linked_in_url
      contact_facebook_url
      service_type {
        name
        description
        display_order
      }
      agree_terms_and_conditions
      verification_mode
      verification_note {
        type
        value
        date
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      place_claimant_audit {
        claim_approved_by
        claim_approved_at
        claim_rejected_by
        claim_rejected_at
      }
      error {
        code
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const removeBusinessTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    const hours = omit(item, ['option', '__typename']);
    const option = [];
    forEach(item.option, g => {
      const customOption = omit(g, ['__typename']);
      option.push({ ...customOption });
    });

    final.push({ ...hours, option });
  });
  return final;
};

const parseSearchImages = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        id: item.image_id,
        url: item.url,
        preview: getEncodedImage(item.url, 300, 300),
        tooltip: item.tooltip,
        link: item.link,
        tag: item.tags,
        uploadingStatus: 'uploaded',
      });
    }
  });
  return imageValues;
};

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};

const UpdatePlace = ({ history, match }) => {
  const [images, setImages] = useState([]);
  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      <div className="container">
        <Query
          query={placeQuery}
          variables={{
            placeId: match.params.placeId,
            imageFilter: {
              object_id: match.params.placeId,
              object_type: ['PLACE'],
              parent_id: match.params.placeId,
              parent_type: ['PLACE'],
              links_to_id: [match.params.placeId],
              links_to_type: ['PLACE'],
            },
          }}
          fetchPolicy="network-only"
        >
          {({ data: data1, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>error</Message>;
            }

            if (error) {
              return <Message>error</Message>;
            }

            const place = data1.fetch_place;

            // console.log('place', place);
            if (isNull(place)) {
              return <Blank message="This Place Not Found" />;
            }

            const uploadedImages = parseSearchImages(data1.search_images);

            return (
              <Query
                client={client.clientPrivate}
                query={claimQuery}
                variables={{
                  claimantId: !isNull(place.claimant_id)
                    ? place.claimant_id
                    : '',
                }}
                fetchPolicy="network-only"
              >
                {({ data: data2, loading: loading2 }) => {
                  if (loading2) {
                    return <Loading />;
                  }
                  // if (error2) {
                  //   return <Message>error</Message>;
                  // }

                  return (
                    <Mutation
                      client={client.clientPrivate}
                      mutation={createImageMutation}
                      onCompleted={() => {
                        swal(
                          'Nice!',
                          'Place has been updated succesfully!',
                          'success',
                        ).then(() => {
                          history.push('/places');
                        });
                      }}
                    >
                      {(
                        create_image,
                        { loading: imageLoading, error: imageError },
                      ) => (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={updatePlaceMutation}
                          onCompleted={({ update_place }) => {
                            if (!isNull(update_place.error)) {
                              update_place.error.map(item =>
                                toast.error(item.description),
                              );
                            } else if (images.length !== 1) {
                              create_image({
                                variables: {
                                  input: {
                                    user_id: userId,
                                    object_id: place.place_id,
                                    object_type: 'PLACE',
                                    upload_type: 'ADMIN',
                                    parent_id: place.place_id,
                                    parent_type: 'PLACE',
                                    // links_to: [
                                    //   {
                                    //     id: place.place_id,
                                    //     type: 'PLACE',
                                    //   },
                                    // ],
                                    images: parseImage(images),
                                  },
                                },
                              });
                            } else {
                              swal(
                                'Nice!',
                                'Place has been updated succesfully!',
                                'success',
                              ).then(() => {
                                history.push('/places');
                              });
                            }
                          }}
                        >
                          {(
                            update_place,
                            { loading: loading3, error: error3 },
                          ) => (
                            <AddPlaceForm
                              isClaimed
                              userId={userId}
                              claim={data2 && first(data2.fetch_place_claimant)}
                              place={place}
                              uploadedImages={uploadedImages}
                              loading={loading3 || imageLoading}
                              error={error3 || imageError}
                              onSubmit={data => {
                                setImages(data.image);
                                const inputs = omit(data, [
                                  'business_hour',
                                  'primaryEmail',
                                  'displayEmail',
                                  'primaryMobile',
                                  'displayMobile',
                                  'businessSite',
                                  'displayBusiness',
                                  'contact',
                                  'claim',
                                  'uploadedImages',
                                  'image',
                                ]);

                                const primaryContacts = [
                                  {
                                    type: 'email_primary',
                                    value: data.primaryEmail,
                                    display: data.displayEmail,
                                    is_primary: true,
                                    display_order: '1',
                                  },
                                  {
                                    type: 'phone_primary',
                                    value: data.primaryMobile,
                                    display: data.displayMobile,
                                    is_primary: true,
                                    display_order: '1',
                                  },
                                  {
                                    type: 'website',
                                    value: data.businessSite,
                                    display: data.displayBusiness,
                                    is_primary: true,
                                    display_order: '1',
                                  },
                                ];

                                const formContact = () => {
                                  const final = [];
                                  forEach(data.contact, item => {
                                    const custom = omit(item, [
                                      'id',
                                      '__typename',
                                    ]);
                                    final.push(custom);
                                  });
                                  return final;
                                };

                                const contact = concat(
                                  primaryContacts,
                                  formContact(),
                                );

                                const businessHour = data.business_hour.map(
                                  item => {
                                    const customItem = omit(item, [
                                      'open24Hours',
                                      'closed',
                                    ]);
                                    return customItem;
                                  },
                                );

                                const input = {
                                  ...inputs,
                                  business_hour: businessHour,
                                  contact,
                                };
                                // console.log(
                                //   'contact',
                                //   input.contact,
                                //   place.contact,
                                // );
                                const final = {};
                                Object.assign(
                                  final,
                                  { user_id: userId },
                                  { place_id: input.place_id },
                                  !isEqual(place.name, input.name) && {
                                    name: input.name,
                                  },
                                  !isEqual(place.slug, input.slug) && {
                                    slug: input.slug,
                                  },
                                  !isEqual(place.status, input.status) && {
                                    status: input.status,
                                  },
                                  !isEqual(
                                    place.default_image_url,
                                    input.default_image_url,
                                  ) && {
                                    default_image_url: input.default_image_url,
                                  },

                                  !isEqual(
                                    place.listing_status,
                                    input.listing_status,
                                  ) && {
                                    listing_status: input.listing_status,
                                  },
                                  !isEqual(place.featured, input.featured) && {
                                    featured: input.featured,
                                  },
                                  !isEqual(
                                    place.event_business,
                                    input.event_business,
                                  ) && {
                                    event_business: input.event_business,
                                  },
                                  !isEqual(
                                    place.item_review_place,
                                    input.item_review_place,
                                  ) && {
                                    item_review_place: input.item_review_place,
                                  },
                                  !isEqual(
                                    place.address_line_1,
                                    input.address_line_1,
                                  ) && {
                                    address_line_1: input.address_line_1,
                                  },
                                  !isEqual(
                                    place.address_line_2,
                                    input.address_line_2,
                                  ) && {
                                    address_line_2: input.address_line_2,
                                  },
                                  !isEqual(place.city, input.city) && {
                                    city: input.city,
                                  },
                                  !isEqual(place.state, input.state) && {
                                    state: input.state,
                                  },
                                  !isEqual(place.country, input.country) && {
                                    country: input.country,
                                  },
                                  !isEqual(
                                    place.post_code,
                                    input.post_code,
                                  ) && {
                                    post_code: input.post_code,
                                  },
                                  !isEqual(place.latitude, input.latitude) && {
                                    latitude: input.latitude,
                                  },
                                  !isEqual(
                                    place.longitude,
                                    input.longitude,
                                  ) && {
                                    longitude: input.longitude,
                                  },
                                  !isEqual(place.location, input.location) && {
                                    location: input.location,
                                  },
                                  !isEqual(place.timezone, input.timezone) && {
                                    timezone: input.timezone,
                                  },
                                  !isEqual(place.tagline, input.tagline) &&
                                    input.tagline !== '' && {
                                      tagline: input.tagline,
                                    },
                                  !isEqual(
                                    place.description,
                                    input.description,
                                  ) &&
                                    input.description !== '' && {
                                      description: input.description,
                                    },

                                  !isEqual(
                                    removeTypename(place.contact),
                                    input.contact,
                                  ) && {
                                    contact: input.contact,
                                  },

                                  !isEqual(
                                    removeTypename(place.social),
                                    input.social,
                                  ) && {
                                    social: input.social,
                                  },

                                  !isEqual(
                                    place.price_range,
                                    input.price_range,
                                  ) &&
                                    input.price_range !== '' && {
                                      price_range: input.price_range,
                                    },

                                  !isEqual(
                                    removeTypename(place.business_type),
                                    input.business_type,
                                  ) && {
                                    business_type: input.business_type,
                                  },

                                  !isEqual(
                                    removeTypename(place.service_type),
                                    input.service_type,
                                  ) && {
                                    service_type: input.service_type,
                                  },

                                  !isEqual(
                                    removeTypename(place.service_category),
                                    input.service_category,
                                  ) && {
                                    service_category: input.service_category,
                                  },

                                  !isEqual(
                                    removeTypename(place.cuisine_style),
                                    input.cuisine_style,
                                  ) && {
                                    cuisine_style: input.cuisine_style,
                                  },

                                  !isEqual(
                                    removeTypename(place.item_type),
                                    input.item_type,
                                  ) && {
                                    item_type: input.item_type,
                                  },

                                  !isEqual(
                                    removeTypename(place.feature),
                                    input.feature,
                                  ) && {
                                    feature: input.feature,
                                  },

                                  !isEqual(
                                    removeTypename(place.dining_style),
                                    input.dining_style,
                                  ) && {
                                    dining_style: input.dining_style,
                                  },

                                  !isEqual(
                                    removeTypename(place.parking_detail),
                                    input.parking_detail,
                                  ) && {
                                    parking_detail: input.parking_detail,
                                  },

                                  !isEqual(
                                    removeTypename(place.public_transport),
                                    input.public_transport,
                                  ) && {
                                    public_transport: input.public_transport,
                                  },

                                  !isEqual(
                                    removeTypename(place.noise_level),
                                    input.noise_level,
                                  ) && {
                                    noise_level: input.noise_level,
                                  },

                                  !isEqual(
                                    removeTypename(place.dress_code),
                                    input.dress_code,
                                  ) && {
                                    dress_code: input.dress_code,
                                  },

                                  !isEqual(
                                    removeTypename(place.ambience),
                                    input.ambience,
                                  ) && {
                                    ambience: input.ambience,
                                  },

                                  !isEqual(
                                    removeTypename(place.good_for),
                                    input.good_for,
                                  ) && {
                                    good_for: input.good_for,
                                  },

                                  !isEqual(place.keywords, input.keywords) && {
                                    keywords: input.keywords,
                                  },
                                  !isEqual(place.tag, input.tag) && {
                                    tag: input.tag,
                                  },
                                  !isEqual(
                                    removeTypename(place.dietary_information),
                                    input.dietary_information,
                                  ) && {
                                    dietary_information:
                                      input.dietary_information,
                                  },

                                  !isEqual(
                                    removeBusinessTypename(place.business_hour),
                                    uniqBy(input.business_hour, 'day'),
                                  ) && {
                                    business_hour: uniqBy(
                                      input.business_hour,
                                      'day',
                                    ),
                                  },
                                );

                                update_place({ variables: { input: final } });
                              }}
                            />
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

export default UpdatePlace;
