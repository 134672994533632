import React, { useEffect, useState } from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { filter, first, isUndefined } from 'lodash';
import client from '../../../utils/apolloClient';
import { Input, Loading } from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const searchFlags = gql`
  query searchFlags($input: SearchInput) {
    search_flags(input: $input) {
      total_size
    }
  }
`;

const SocialCounts = ({ values, review }) => {
  const socialFilter = {
    object_id: review.image_review_id,
    object_type: 'IMAGE_REVIEW',
  };

  const objectId = review.image_review_id;
  const flagFilter = {
    object_id: objectId,
  };

  return (
    <Query
      query={searchSocial}
      variables={{ input: { filter: { social_filter: socialFilter } } }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (error) {
          return (
            <React.Fragment>
              <Column.Group vcentered multiline>
                <Column size={3}>
                  <Input label="Like Count" disabled />
                </Column>
                <Column size={3}>
                  <Input
                    label="Share count"
                    // value={search_comments.total_size}
                    disabled
                  />
                </Column>
                <Column size={3}>
                  <Input
                    label="Flag count"
                    // value={search_flags.total_size}
                    disabled
                  />
                </Column>
              </Column.Group>
            </React.Fragment>
          );
        }

        const { search_social } = data;
        if (isUndefined(search_social)) {
          return '';
        }
        const { social_aggregation } = search_social;

        const likeCountArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'LIKE',
              )
            : [];

        const likeCount =
          likeCountArray.length !== 0 ? first(likeCountArray).count : 0;
        console.log('like', likeCount);
        return (
          <Query
            client={client.clientPrivate}
            query={searchFlags}
            variables={{
              input: { filter: { flag_filter: flagFilter } },
            }}
            fetchPolicy="network-only"
          >
            {({ data: flag, loading, error: flagError }) => {
              if (flagError) {
                return (
                  <React.Fragment>
                    <Column.Group vcentered multiline>
                      <Column size={3}>
                        <Input label="Like Count" value={likeCount} disabled />
                      </Column>
                      <Column size={3}>
                        <Input
                          label="Share count"
                          // value={search_comments.total_size}
                          disabled
                        />
                      </Column>
                      <Column size={3}>
                        <Input
                          label="Flag count"
                          // value={search_flags.total_size}
                          disabled
                        />
                      </Column>
                    </Column.Group>
                  </React.Fragment>
                );
              }
              const { search_flags } = flag;
              return (
                <React.Fragment>
                  <Column.Group vcentered multiline>
                    <Column size={3}>
                      <Input label="Like Count" value={likeCount} disabled />
                    </Column>

                    <Column size={3}>
                      <Input label="Share count" placeholder="0" disabled />
                    </Column>

                    {isUndefined(search_flags) ? (
                      <Column size={3}>
                        <Input label="Flag count" placeholder="0" disabled />
                      </Column>
                    ) : (
                      <Column size={3}>
                        <Input
                          label="Flag count"
                          value={search_flags.total_size}
                          disabled
                        />
                      </Column>
                    )}
                  </Column.Group>
                </React.Fragment>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default SocialCounts;
