import React from 'react';
import { Column, Title } from 'rbx';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { isNull } from 'lodash';
import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';

import { Input, Select } from '../../../components/elements';

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
`;

const checkItemStatusForDictionary = values => {
  const activeItems = values.filter(item => item.status === 'ACTIVE');
  const dictionaryStatus = activeItems.length === 0;
  return dictionaryStatus;
};

const updateItemMutation = gql`
  mutation updateItem($input: ItemInput) {
    update_item(input: $input) {
      item_id
      item_dictionary_id
      name
      status
      error {
        description
      }
    }
  }
`;

const ItemDictionaryDetails = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  loading,
  handleSubmit,
}) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <React.Fragment>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input
            label="Item Dictionary Name"
            value={values.name}
            optionalText="(Max 50 Characters)"
            required
            name="name"
            onChange={handleChange}
            onBlur={() => handleSubmit()}
            errors={errors.name}
            touched={touched.name}
            disabled={values.items !== 0}
          />
        </Column>

        <Column size={4}>
          <Input
            label="Item Dictionary Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={() => handleSubmit()}
            optionalText="(Max 50 Characters)"
            errors={errors.description}
            touched={touched.description}
          />
        </Column>

        <Column size={3}>
          <Select
            label="Item Status"
            options={status}
            name="status"
            value={[{ value: values.status, label: values.status }]}
            onChange={async ({ value }) => {
              await setFieldValue('status', value);
              handleSubmit();
            }}
            errors={errors.status}
            touched={touched.status}
            required
            disabled={!checkItemStatusForDictionary(values.items)}
            isLoading={loading}
          />
        </Column>
      </Column.Group>

      <hr />
      <Title>Item Name to Item Dictionary assignment</Title>
      <Container>
        <FieldArray
          name="items"
          render={() => (
            <React.Fragment>
              {values.items.map((item, index) => (
                <fieldset className="box">
                  <Column.Group vcentered multiline gapSize={8}>
                    <Column size={4}>
                      <Input
                        label="Item Name"
                        name="item_name"
                        value={item.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                      />
                    </Column>
                  </Column.Group>
                  <Column.Group vcentered multiline gapSize={8}>
                    <Column size={4}>
                      <Input
                        label="Place Name"
                        value={item.place_name}
                        disabled
                      />
                    </Column>

                    <Column size={4}>
                      <Input label="City / Suburb" value={item.city} disabled />
                    </Column>

                    <Column size={3}>
                      <Mutation
                        client={client.clientPrivate}
                        mutation={updateItemMutation}
                        onCompleted={({ update_item }) => {
                          if (!isNull(update_item.error)) {
                            update_item.error.map(edge =>
                              toast.error(edge.description),
                            );
                          } else {
                            toast.success('Item status updated successfully!');
                          }
                        }}
                      >
                        {(update_item, { loading: isLoading }) => (
                          <Select
                            label="Item Status"
                            name="item_status"
                            options={status}
                            value={[{ value: item.status, label: item.status }]}
                            onChange={({ value }) => {
                              setFieldValue(`items.${index}.status`, value);
                              update_item({
                                variables: {
                                  input: {
                                    user_id: userId,
                                    item_id: item.item_id,
                                    item_dictionary_id: item.item_dictionary_id,
                                    status: value,
                                  },
                                },
                              });
                            }}
                            disabled={values.status === 'INACTIVE'}
                            isLoading={isLoading}
                          />
                        )}
                      </Mutation>
                    </Column>
                  </Column.Group>
                  <br />
                  <br />
                </fieldset>
              ))}
            </React.Fragment>
          )}
        />
      </Container>
    </React.Fragment>
  );
};

export default ItemDictionaryDetails;
