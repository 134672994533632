import React from 'react';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import moment from 'moment';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
  border-bottom: 2px solid #e0e0e0;
  margin-bottom: 1rem;
`;

const Header = ({ event, noOfCheckingAttendees, totalAttendeesCount }) => (
  <React.Fragment>
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title size={2}>{event.name} (Public event)</Title>
        </Level.Item>

        <Level.Item align="right">
          <Level.Item>
            <span className="is-flex">
              <p className="is-size-1 has-text-weight-bold">
                Total Check In :{' '}
              </p>
              &nbsp;
              <p className="is-size-1 has-text-weight-semibold">
                {noOfCheckingAttendees}/{totalAttendeesCount}{' '}
              </p>
            </span>
          </Level.Item>
        </Level.Item>
      </Level>
      <SubHeading className="is-size-3">
        {event.place_name} | {event.state}
      </SubHeading>
      <SubHeading className="is-size-3">
        {event.address_line_1} | {event.city} | {event.post_code} |{' '}
        {event.country}
      </SubHeading>
      <SubHeading className="is-size-4 has-text-weight-semibold">
        {moment(event.start).format('LLLL')}
      </SubHeading>
    </Wrapper>
  </React.Fragment>
);

export default Header;
