import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';

import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { makeRootValidatingTransform } from 'rbx/base/helpers';
import { DEFAULTS } from 'rbx/base/helpers/variables';
import { ThemeContext } from 'rbx/base/theme';

import Routes from './pages/Routes';
import bulmaTheme, { GlobalStyle } from './theme';
import client from './utils/apolloClient';
import store from './store';

const COLORS = [...DEFAULTS.colors, 'kr'];

const themeValue = {
  transform: makeRootValidatingTransform({ colors: COLORS }),
};

const persistor = persistStore(store);

const App = () => (
  <ApolloProvider client={client.clientPublic}>
    <PersistGate loading={<div>Loading</div>} persistor={persistor}>
      <StoreProvider store={store}>
        <ThemeProvider theme={bulmaTheme}>
          <React.Fragment>
            <ThemeContext.Provider value={themeValue}>
              <Routes />
            </ThemeContext.Provider>
            <GlobalStyle />
          </React.Fragment>
        </ThemeProvider>
      </StoreProvider>
    </PersistGate>
  </ApolloProvider>
);

export default App;
