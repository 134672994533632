/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'rbx';
import moment from 'moment';
import { first, capitalize, isNull } from 'lodash';

const BookingSummaryList = ({ booking, bookings, event }) => {
  const paymentArray =
    bookings.payment && !isNull(bookings.payment)
      ? bookings.payment.filter(
          item =>
            item.type === 'credit-card' ||
            item.type === 'debit-card' ||
            item.type === 'paypal',
        )
      : [];

  const payment = paymentArray.length !== 0 ? first(paymentArray).type : 'N.A';

  return (
    <React.Fragment>
      <Table.Cell>
        <Link
          to={`/ticket/booking-details/${event.event_id}/${booking.booking_id}`}
        >
          {booking.booking_number}{' '}
        </Link>
      </Table.Cell>

      <Table.Cell>{booking.quantity}</Table.Cell>
      <Table.Cell>
        ${' '}
        {isNull(booking.total_absorb_fee)
          ? 0.0
          : parseFloat(booking.total_absorb_fee).toFixed(2)}
      </Table.Cell>
      <Table.Cell>$ {parseFloat(booking.total_fee).toFixed(2)}</Table.Cell>
      <Table.Cell>$ {parseFloat(booking.total_price).toFixed(2)}</Table.Cell>
      <Table.Cell>{capitalize(booking.sales_method)}</Table.Cell>
      <Table.Cell>{booking.booking_status}</Table.Cell>
      <Table.Cell>
        {payment === 'credit-card'
          ? 'CREDIT CARD'
          : payment === 'debit-card'
          ? 'DEBIT CARD'
          : payment === 'paypal'
          ? 'PAYPAL'
          : 'N.A'}
      </Table.Cell>
      <Table.Cell>
        {booking.purchaser.first_name === '' ||
        (booking.purchaser.first_name === null &&
          booking.purchaser.last_name === '') ||
        booking.purchaser.last_name === null
          ? `${booking.booking.booked_by}`
          : `${booking.purchaser.first_name} ${booking.purchaser.last_name}`}
      </Table.Cell>
      <Table.Cell>{moment(booking.timestamp).format('lll')}</Table.Cell>
    </React.Fragment>
  );
};

export default BookingSummaryList;
