import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { first, differenceBy, isNull, forEach, omit, isEqual } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import client from '../../../utils/apolloClient';
// import qs from 'querystringify';

import Layout from '../../../components/global/Layout';
import ItemReviewForm from './ItemReviewForm';
import { Loading, Message } from '../../../components/elements';

// import PlaceSelection from '../../../components/PlaceSelection';

const itemReviewsQuery = gql`
  query fetchItemReview($itemReviewId: String, $itemId: String) {
    fetch_item_review(
      input: { item_id: $itemId, item_review_id: $itemReviewId }
    ) {
      place_id
      item_id
      item_review_id
      item_name
      status
      rating
      note {
        type
        value
        date
      }
      cuisine_style {
        name
        description
        display_order
      }
      item_type {
        name
        description
        display_order
      }
      content

      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      item_review_audit {
        item_review_approved_by
        item_review_approved_at
        item_review_rejected_by
        item_review_rejected_at
      }
      error {
        description
      }
    }
  }
`;

const placeImageQuery = gql`
  query fetchItemReview(
    $itemReviewId: String
    $placeId: String
    $imageStatus: String
  ) {
    search_image_review(
      input: {
        filter: {
          image_review_filter: {
            object_id: $itemReviewId
            parent_id: $placeId
            object_type: "ITEM_REVIEW"
            parent_type: "PLACE"
            approval_status: $imageStatus
          }
        }
      }
    ) {
      image_review_listing {
        image_id
        image_review_id
        url
        tags
        approval_status
        status
      }
    }
  }
`;

const placeQuery = gql`
  query placeReview($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      status
      claim_status
      listing_status
      post_code
      country
      item_review_place
    }
  }
`;

const actionItemReviewMutation = gql`
  mutation actionItemReview($input: ActionItemReviewInput) {
    action_item_review(input: $input) {
      place_id
      item_id
      item_review_id
      error {
        description
      }
    }
  }
`;

const approvePhotoMutation = gql`
  mutation approvePhotosAction(
    $user_id: String
    $image_review_id: String
    $image_review_status: String
  ) {
    update_image_review(
      input: {
        user_id: $user_id
        image_review_id: $image_review_id
        approval_status: $image_review_status
      }
    ) {
      status
      image_id
      url
      error {
        description
      }
    }
  }
`;

const updateItemReviewMutation = gql`
  mutation update_item_review($input: ItemReviewInput) {
    update_item_review(input: $input) {
      item_id
      item_review_id
      error {
        description
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const PlaceReview = ({ match, history }) => {
  const { itemId, reviewId } = match.params;
  const [approvedImages, setApprovedImages] = useState([]);
  const [action, setAction] = useState('');

  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Query
        client={client.clientPublic}
        query={itemReviewsQuery}
        variables={{
          itemId,
          itemReviewId: reviewId,
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }

          const review = first(data.fetch_item_review);
          return (
            <Query
              client={client.clientPrivate}
              query={placeImageQuery}
              variables={{
                placeId: review.place_id,
                itemId,
                itemReviewId: reviewId,
                imageStatus: review.status,
              }}
              fetchPolicy="network-only"
            >
              {({ data: data1, loading: loading1, error: error1 }) => {
                if (loading1) {
                  return <Loading />;
                }
                if (error1) {
                  return <Message>{JSON.stringify(error)}</Message>;
                }

                const images = data1.search_image_review.image_review_listing;
                return (
                  <Query
                    query={placeQuery}
                    variables={{
                      placeId: review.place_id,
                    }}
                    fetchPolicy="network-only"
                  >
                    {({ data: data2, loading: loading2, error: error2 }) => {
                      if (loading2) {
                        return <Loading />;
                      }
                      if (error2) {
                        return <Message>{JSON.stringify(error2)}</Message>;
                      }
                      const place = data2.fetch_place;
                      return (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={approvePhotoMutation}
                        >
                          {(
                            update_image_review,
                            { loading: approvalLoading },
                          ) => (
                            <Mutation
                              client={client.clientPrivate}
                              mutation={actionItemReviewMutation}
                              onCompleted={async ({ action_item_review }) => {
                                if (!isNull(action_item_review.error)) {
                                  action_item_review.error.map(item =>
                                    toast.error(item.description),
                                  );
                                } else if (approvedImages.length !== 0) {
                                  await approvedImages.map(item =>
                                    update_image_review({
                                      variables: {
                                        user_id: userId,
                                        image_review_id: item.image_review_id,
                                        image_review_status:
                                          action === 'APPROVED'
                                            ? 'APPROVED'
                                            : 'REJECTED',
                                      },
                                    }),
                                  );
                                  swal(
                                    'Hurray!',
                                    `Item Review ${
                                      action === 'APPROVED'
                                        ? 'APPROVED'
                                        : 'REJECTED'
                                    } successfully!`,
                                    'success',
                                  ).then(() => {
                                    history.push('/item-reviews');
                                  });
                                } else {
                                  swal(
                                    'Hurray!',
                                    `Item Review ${
                                      action === 'APPROVED'
                                        ? 'APPROVED'
                                        : 'REJECTED'
                                    } successfully!`,
                                    'success',
                                  ).then(() => {
                                    history.push('/item-reviews');
                                  });
                                }
                              }}
                            >
                              {(
                                action_item_review,
                                { loading: updateLoading },
                              ) => (
                                <Mutation
                                  client={client.clientPrivate}
                                  mutation={updateItemReviewMutation}
                                  onCompleted={() => {
                                    swal(
                                      'Hurray!',
                                      `Item Review updated successfully!`,
                                      'success',
                                    );
                                  }}
                                >
                                  {(
                                    update_item_review,
                                    {
                                      loading: updateItemReviewLoading,
                                      error: updateItemReviewError,
                                    },
                                  ) => (
                                    <ItemReviewForm
                                      place={place}
                                      loading={updateLoading || approvalLoading}
                                      images={images}
                                      action={action}
                                      handleAction={setAction}
                                      review={review}
                                      updateItemReviewLoading={
                                        updateItemReviewLoading
                                      }
                                      updateItemReviewError={
                                        updateItemReviewError
                                      }
                                      onSubmit={values => {
                                        if (action === 'ITEM_UPDATE') {
                                          update_item_review({
                                            variables: {
                                              input: {
                                                user_id: userId,
                                                item_review_id:
                                                  review.item_review_id,
                                                content: values.content,
                                                item_type: removeTypename(
                                                  values.item_type,
                                                ),
                                                cuisine_style: removeTypename(
                                                  values.cuisine_style,
                                                ),
                                              },
                                            },
                                          });
                                        } else {
                                          const allApprovalPhotos = differenceBy(
                                            values.allImages,
                                            values.rejectImages,
                                            'image_id',
                                          );

                                          if (action === 'APPROVED') {
                                            setApprovedImages(
                                              allApprovalPhotos,
                                            );
                                          }
                                          if (action === 'REJECTED') {
                                            setApprovedImages(values.allImages);
                                          }

                                          action_item_review({
                                            variables: {
                                              input: {
                                                user_id: userId,
                                                place_id: review.place_id,
                                                item_review_id:
                                                  review.item_review_id,
                                                status: action,
                                                note: [
                                                  {
                                                    type: action,
                                                    value: values.note,
                                                    date: moment().toISOString(),
                                                  },
                                                ],
                                              },
                                            },
                                          });
                                        }
                                      }}
                                    />
                                  )}
                                </Mutation>
                              )}
                            </Mutation>
                          )}
                        </Mutation>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default PlaceReview;
