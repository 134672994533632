import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Column, Title, Level } from 'rbx';
import { replace, isUndefined } from 'lodash';
// import uuidv4 from 'uuid/v4';
// import moment from 'moment';

import {
  Box,
  Input,
  TextArea,
  MobileInput,
} from '../../../components/elements';

const Container = styled.div`
  padding: 1rem;
`;

const Form = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    setSubmitting,
  } = props;
  return (
    <React.Fragment>
      <Box>
        <Container>
          <Title size={1}>Booking Details</Title>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size="half">
              <Input
                label="Customer First Name"
                placeholder="Customer First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.firstName}
                touched={touched.firstName}
              />
            </Column>
            <Column size="half">
              <Input
                label="Customer Last Name"
                placeholder="Customer Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.lastName}
                touched={touched.lastName}
              />
            </Column>
            <Column size="half">
              <Input
                label="Customer Email"
                placeholder="Customer Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.email}
                touched={touched.email}
                required
              />
            </Column>
            <Column size="half">
              <MobileInput
                label=" Mobile"
                value={values.mobile}
                onChange={value =>
                  setFieldValue('mobile', replace(value, ' ', ''))
                }
                errors={errors.mobile}
                touched={touched.mobile}
                required
              />
            </Column>

            <Column size="full">
              <TextArea
                label="Comments"
                placeholder="Comments..."
                name="comments"
                value={values.comments}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.comments}
                touched={touched.comments}
              />
            </Column>
          </Column.Group>
          <hr />
          <Level>
            <Level.Item align="left" />
            <Level.Item align="right">
              <Level.Item>
                <button
                  className={`button is-primary ${!setSubmitting &&
                    'is-loading'}`}
                  onClick={handleSubmit}
                >
                  <span>Continue Booking</span>
                </button>
              </Level.Item>
            </Level.Item>
          </Level>
        </Container>
      </Box>
    </React.Fragment>
  );
};

const BookingDetailsForm = withFormik({
  mapPropsToValues: () => ({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    comments: '',
  }),

  validate: values => {
    const errors = {};
    if (values.firstName.length !== 0 && values.lastName.length === 0) {
      errors.lastName = 'Last name is required!';
    }
    if (values.firstName.length !== 0 && values.mobile.length === 0) {
      errors.mobile = 'Mobile is required!';
    }

    if (values.firstName.length !== 0 && values.email.length === 0) {
      errors.email = 'Email is required!';
    }

    if (values.lastName.length !== 0 && values.firstName.length === 0) {
      errors.firstName = 'First name is required!';
    }
    if (values.lastName.length !== 0 && values.mobile.length === 0) {
      errors.mobile = 'Mobile is required!';
    }

    if (values.lastName.length !== 0 && values.email.length === 0) {
      errors.email = 'Email is required!';
    }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'BookingDetailsForm',
})(Form);

export default BookingDetailsForm;
