import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const Search = ({
  vendorNameSearchInput,
  setVendorNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  setStatus,
  status,
  history,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="ACTIVE"
                name="Type"
                value={status === 'ACTIVE'}
                onChange={() =>
                  history.push(
                    `/place-postings/${qs.stringify(
                      {
                        status: 'ACTIVE',
                      },
                      true,
                    )}`,
                  )
                }
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="INACTIVE"
                name="Type"
                value={status === 'INACTIVE'}
                onChange={() =>
                  history.push(
                    `/place-postings/${qs.stringify(
                      {
                        status: 'INACTIVE',
                      },
                      true,
                    )}`,
                  )
                }
              />
            </div>
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              value={vendorNameSearchInput}
              onChange={event => setVendorNameSearchInput(event.target.value)}
              placeholder="Start entering company name..."
            />
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
