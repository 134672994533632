import React from 'react';
import { Link } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  loading,
  handleSubmit,
  goToStepOne,
  goToStepTwo,
  step,
  onSubmit,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Link to="/manage-events" className="button is-light">
            <span>Back to Search</span>
          </Link>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Footer;
