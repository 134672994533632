import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, sortBy, reverse, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const imageReviewsQuery = gql`
  query searchImageReviews($input: SearchInput) {
    search_image_review(input: $input) {
      total_pages
      total_size
      image_review_listing {
        image_review_id
        image_id
        object_id
        object_type
        parent_name
        parent_id
        parent_type
        upload_type
        approval_status
        status
        url
        link
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        item {
          name
        }
        place {
          name
          city
        }
        note {
          type
          date
          value
        }
        approval_status
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;
const ItemPhotoApprovals = props => {
  const routeState = qs.parse(props.location.search);
  const { approval_status } = routeState;

  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('CREATED_DESC');
  const [itemNameSort, setItemNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  // const [objectTypeSort, setObjectTypeSort] = useState('AES');
  const [uploadedAtSort, setUploadedAtSort] = useState('AES');
  const [uploaderSort, setUploaderSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [reviewOn, setReviewOn] = useState(null);

  const [itemNameSearchInput, setItemNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const input = {};
  const image_review_filter = {};
  Object.assign(
    image_review_filter,
    itemNameSearchInput !== '' && { item_name: itemNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    { approval_status, object_type: 'ITEM' },
    reviewOn !== null && {
      date_range: {
        start_date: moment(reviewOn).format('YYYY-MM-DD'),
        end_date: moment(reviewOn)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        image_review_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          approval_status={approval_status}
          itemNameSearchInput={itemNameSearchInput}
          setItemNameSearchInput={setItemNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
          reviewOn={reviewOn}
          setReviewOn={setReviewOn}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          itemNameSort === 'AES'
                            ? 'ITEM_NAME_DESC'
                            : 'ITEM_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Item Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          itemNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        const place =
                          uploaderSort === 'AES'
                            ? sortBy(placeData, 'audit.created_by')
                            : reverse(placeData);
                        setPlaceData(place);
                        setUploaderSort(uploaderSort === 'AES' ? 'DES' : 'AES');
                      }}
                    >
                      <i
                        className={
                          uploaderSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Uploaded By
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUploadedAtSort(
                          uploadedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          uploadedAtSort === 'AES'
                            ? 'CREATED_DESC'
                            : 'CREATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          uploadedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Uploaded At
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  {/* <br />
                  <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'Active ', label: 'Active' },
                      { value: 'Inactive', label: 'Inactive' },
                    ]}
                  /> */}
                </div>
              </th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={imageReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_image_review } = data;
              if (isNull(search_image_review)) {
                return 'no data found';
              }
              if (search_image_review.length === 0) {
                return 'no data found';
              }

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_image_review={search_image_review}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default ItemPhotoApprovals;

const View = ({
  search_image_review,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_image_review.total_pages);
    setTotalElements(search_image_review.total_size);
    if (search_image_review.image_review_listing) {
      setPlaceData(search_image_review.image_review_listing);

      const userFilter = search_image_review.image_review_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_image_review.total_pages]);
  return (
    <tbody>
      {placeData.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};
