import React from 'react';
// import gql from 'graphql-tag';
// import { Mutation } from 'react-apollo';
// import swal from 'sweetalert';
import ModifierActivity from './ModifierActivity';

import Layout from '../../../components/global/Layout';

// import PlaceSelection from '../../../components/PlaceSelection';

const Menu = () => (
  <Layout>
    <ModifierActivity />
  </Layout>
);

export default Menu;
