import React from 'react';
import styled from 'styled-components';
import { Column, Title, Level, Table } from 'rbx';
//import { Query } from 'react-apollo';
//import { isNull } from 'lodash';
import gql from 'graphql-tag';
import Footer from './Footer';

import {
  Input,
  TextArea,
  TagInputs,
  CheckBox,
  Table as CustomTable,
  CustomInput,
  CustomSelect,
  Loading,
  Pagination,
  Select,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_claim(input: $input) {
      place_id
      claimant_id
      name
      city
      status
      claim_status
    }
  }
`;

const Container = styled.div``;

const Summary = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
  props,
}) => (
  <React.Fragment>
    <Column size={4}>
      <Input placeholder="Delivery Menu-Dinner" disabled />
    </Column>
    {/* <Query
        query={placesQuery}
        variables={{ input: { filter: { place_filter: {} } } }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }

          const { search_place_claim } = data;
          if (isNull(search_place_claim)) {
            return 'no data found';
          }

          return (
            <tbody>
              {search_place_claim.map(claim => (
                <ListItem claim={claim} />
              ))}
            </tbody>
          );
        }}
      </Query> 
   */}
  </React.Fragment>
);

export default Summary;
