import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { first } from 'lodash';
import { SubHeading, Message } from '../../../components/elements';

const fetchItem = gql`
  query fetchPlace($input: NavigateItemInput) {
    fetch_item(input: $input) {
      item_id
      name
      status
      approval_status
    }
  }
`;

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const ItemHeader = ({
  flag,
  placeName,
  action,
  loading,
  heading,
  subHeading,
  handleApproveAction,
  handleRejectAction,
  history,
  items,
}) => (
  <Query
    query={fetchItem}
    variables={{ input: { item_id: items.item_id } }}
    fetchPolicy="network-only"
  >
    {({ data, loading: itemLoading, error }) => {
      if (itemLoading) {
        return '';
      }
      if (error) {
        return <Message>{error.message}</Message>;
      }
      const { fetch_item } = data;
      const item = first(fetch_item);
      console.log('itemName', fetch_item);
      return (
        <Wrapper>
          <Level>
            <Level.Item align="left">
              <Title size={2}>{heading}</Title>
            </Level.Item>
            <Level.Item align="right">
              <Level.Item>
                <a onClick={() => history.goBack()} className="button is-light">
                  <span>Cancel</span>
                </a>
              </Level.Item>
              <Level.Item>
                <button
                  className={`button is-danger ${action === 'REJECTED' &&
                    loading &&
                    'is-loading'}`}
                  onClick={handleRejectAction}
                  disabled={flag.status === 'REJECTED'}
                >
                  <span>Reject</span>
                </button>
              </Level.Item>
              <Level.Item>
                <button
                  className={`button is-primary ${action === 'APPROVED' &&
                    loading &&
                    'is-loading'}`}
                  onClick={() => handleApproveAction()}
                  disabled={flag.status === 'APPROVED'}
                >
                  <span>Unpublish and Inactivate</span>
                </button>
              </Level.Item>
            </Level.Item>
          </Level>
          <SubHeading className="is-size-2">{`${item.name} (${item.approval_status})`}</SubHeading>
          <SubHeading className="is-size-2">{placeName}</SubHeading>
          <SubHeading className="is-size-2">{subHeading}</SubHeading>
        </Wrapper>
      );
    }}
  </Query>
);

export default withRouter(ItemHeader);
