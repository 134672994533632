import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { omit } from 'lodash';
import uuid from 'uuid';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import Form from './Form';
import { cognitoSignUp } from '../../../utils/auth';

const createUserMutation = gql`
  mutation createUser($input: UserInput) {
    update_user(input: $input) {
      user_id
      error {
        description
      }
    }
  }
`;

const UserAdd = ({ history }) => (
  <Layout>
    <Mutation
      client={client.clientPrivate}
      mutation={createUserMutation}
      onCompleted={() => {
        swal('Great!', 'User has been created successfully!', 'success').then(
          () => {
            history.push('/users');
          },
        );
      }}
    >
      {(update_user, { loading, error }) => (
        <Form
          error={error}
          loading={loading}
          createForm
          onSubmit={async value => {
            const input = omit(value, ['primaryContact', 'contact', 'address']);
            const primaryContact = {
              id: '',
              type: 'mobile',
              is_primary: true,
              value: value.primaryContact,
              display: true,
              display_order: '1',
            };
            const address = value.address.map(item => {
              const custom = omit(item, ['id']);
              return custom;
            });

            const addPrimaryContact = value.contact.concat(primaryContact);

            const contact = addPrimaryContact.map(item => {
              const custom = omit(item, ['id']);
              return custom;
            });
            const inputs = {
              ...input,
              contact,
              address,
            };
            const finalInput = omit(inputs, ['user_id', 'social', 'address']);
            try {
              
              const userData = await cognitoSignUp(
                inputs.first_name,
                inputs.last_name,
                inputs.display_name,
                inputs.user_id,
                inputs.email,
                uuid(),
              );
              console.log('userData', userData);
              update_user({
                variables: {
                  input: { user_id: userData.username, ...finalInput },
                },
              });
            } catch (err) {
              console.log(error);
            }
          }}
        />
      )}
    </Mutation>
  </Layout>
);

export default UserAdd;
