import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MenuSectionList = ({ menuSection, setPageCount, totalPages }) => {
  useEffect(() => {
    setPageCount();
  }, [totalPages]);
  return (
    <tr>
      <td>
        <Link to="/manage-menu-section">Chicken Wings</Link>
      </td>
      <td>Las Tapas Diner</td>
      <td>St Kilda</td>
      <td>Delivery Menu - All Day Menu</td>
      <td>8</td>
      <td>Super Admin</td>
      <td>24/06/2019</td>
      <td>--</td>
    </tr>
  );
};

export default MenuSectionList;
