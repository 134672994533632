import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Input, CheckBox, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  vendorNameSearchInput,
  setVendorNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  listingApproved,
  setListingApproved,
  eventBusiness,
  setEventBusiness,
  itemReviewPlace,
  setItemReviewPlace,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        {/* <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Listing Type</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="APPROVED"
                name="Type"
                value={listingApproved === 'APPROVED'}
                onChange={() => setListingApproved('APPROVED')}
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="UNLISTED"
                name="Type"
                value={listingApproved === 'UNLISTED'}
                onChange={() => setListingApproved('UNLISTED')}
              />
            </div>
          </Level.Item>
        </Level.Item> */}
        {/* <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Additional Criteria</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="Event organizer only"
              name="event_business"
              value={eventBusiness}
              onChange={value => {
                setEventBusiness(value);
                setItemReviewPlace('');
              }}
            />
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="User added place via item review"
              value={itemReviewPlace}
              name="item_review_place"
              onChange={value => {
                setItemReviewPlace(value);
                setEventBusiness('');
              }}
            />
          </Level.Item>
        </Level.Item> */}
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              value={vendorNameSearchInput}
              onChange={place => setVendorNameSearchInput(place.target.value)}
              placeholder="Start entering company name..."
            />
          </div>

          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={place => setPlaceNameSearchInput(place.target.value)}
              placeholder="Start entering place name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default PlaceSearch;
