/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
// import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const Container = styled.nav`
  padding: 0rem 1rem;
  .containerClassName {
    border: 1px solid #eeeeee;
    border-radius: 4px;
    font-size: 16px;
  }
  .pageClassName {
    font-weight: 600;
    padding: 13px 1px;
  }
  .nextClassName {
    font-weight: 600;
  }
  .previousClassName {
    font-weight: 600;
  }
  .previousLinkClassName {
    padding: 14px 20px;
    color: #505050;
    font-weight: 600;
    font-size: 20px;
  }
  .nextLinkClassName {
    padding: 10px 20px;
    color: #505050;
    font-weight: 600;
    font-size: 20px;
  }
  .pageLinkClassName {
    padding: 10px 20px;
    color: #505050;
    :hover {
      background: #f6f6f6;
    }
    .activeClassName {
      background: #f6f6f6;
    }
  }
`;

// eslint-disable-next-line no-return-assign
const Pagination = ({
  pageCount,
  handlePageClick,
  pageFrom,
  listSize,
  currentPage,
  totalElements,
}) => (
  <Container className="level">
    <div className="level-left">
      <div className="level-item">
        <p className="subtitle is-4">
          <strong>
            {pageFrom + 1} to{' '}
            {listSize * currentPage - 1 === totalElements
              ? totalElements
              : pageFrom + 1 === totalElements
              ? totalElements
              : listSize * currentPage > totalElements
              ? totalElements
              : listSize * currentPage}{' '}
            of {totalElements} row(s) (&nbsp;
            {totalElements < listSize
              ? totalElements
              : pageFrom + 1 === totalElements
              ? 1
              : listSize * currentPage - pageFrom + 1 === 1
              ? 2
              : listSize * currentPage - totalElements === 1
              ? 2
              : pageFrom + 1 === listSize * currentPage - 1 ||
                pageFrom + 1 === totalElements ||
                pageFrom + 1 === listSize * currentPage
              ? 1
              : totalElements - pageFrom - 1 === 2
              ? 3
              : totalElements - pageFrom - 1 === 5
              ? 6
              : totalElements - pageFrom - 1 === 4
              ? 5
              : totalElements - pageFrom - 1 === 3
              ? 4
              : totalElements - pageFrom - 1 === 1
              ? 2
              : listSize}{' '}
            rows per page)
          </strong>
        </p>
      </div>
    </div>

    <div className="level-right">
      <ReactPaginate
        previousLabel="Previous"
        nextLabel="Next page"
        breakLabel="..."
        breakClassName="pagination-ellipsis"
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        containerClassName="pagination"
        // subContainerClassName="pages pagination"
        activeClassName="is-current"
        activeLinkClassName="is-current"
        // pageClassName="pagination-link"
        pageLinkClassName="pagination-link"
        previousLinkClassName="pagination-previous"
        nextLinkClassName="pagination-next"
      />
      &nbsp; {pageCount} Page(s)
    </div>
  </Container>
);

export default Pagination;
