import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  CustomInput,
  CustomSelect,
} from '../../../components/elements';

const searchMenuType = gql`
  query searchMenuType($input: SearchInput) {
    search_menu_type(input: $input) {
      total_size
      total_pages
      menu_type_listing {
        place_id
        place_name
        place_city
        menu_type_id
        name
        slug
        status
        private_menu
        service_type
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const GlobalMenu = () => {
  const [menuData, setMenuData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [sort, setSort] = useState('CREATED_DESC');
  const [menuTypeSort, setMenuTypeSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  const [listingTypeSort, setListingTypeSort] = useState('AES');
  const [startDateSort, setStartDateSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [eventBusiness, setEventBusiness] = useState(false);
  const [statusFilter, setStatusFilter] = useState('SUBMITTED');

  const input = {};
  const menu_type_filter = { status: 'ACTIVE' };
  Object.assign(
    menu_type_filter,
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
  );

  Object.assign(
    input,
    location !== '' && { location },
    {
      from: pageFrom,
      size: listSize,
    },
    { sort },
    menu_type_filter !== {} && {
      filter: {
        menu_type_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        {/* <Search
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
          // setPlaceNameFilter={setPlaceNameFilter}
          //  setPlaceLocationFilter={setPlaceLocationFilter}
        /> */}
        <br />

        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setMenuTypeSort(menuTypeSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          menuTypeSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          menuTypeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Menu Type
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Action
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchMenuType}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_menu_type } = data;
              console.log('search_menu_type', search_menu_type);

              if (isNull(search_menu_type)) {
                return 'no data found';
              }

              return (
                <React.Fragment>
                  <View
                    menuData={menuData}
                    setMenuData={setMenuData}
                    search_menu_type={search_menu_type}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_menu_type,
  setPageCount,
  setTotalElements,
  setMenuData,
  menuData,
}) => {
  useEffect(() => {
    setPageCount(search_menu_type.total_pages);
    setTotalElements(search_menu_type.total_size);
    if (search_menu_type.menu_type_listing) {
      setMenuData(search_menu_type.menu_type_listing);
    }
  }, [search_menu_type.total_pages]);
  return (
    <tbody>
      {menuData.map(menu => (
        <tr key={menu.menu_type_id}>
          <ListItem menu={menu} />
        </tr>
      ))}
    </tbody>
  );
};

export default GlobalMenu;
