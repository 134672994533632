import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { omit, forEach, concat, isNull } from 'lodash';
import swal from 'sweetalert';
import { toast } from 'react-toastify';

import Layout from '../../../components/global/Layout';
import AddPlaceForm from './AddPlaceForm';
import client from '../../../utils/apolloClient';

const createPlaceMutation = gql`
  mutation createPlace($input: PlaceInput) {
    create_place(input: $input) {
      place_id
      name
      error {
        description
      }
    }
  }
`;

const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};

const AddPlace = ({ history }) => {
  const [images, setImages] = useState([]);
  const [placeId, setPlaceId] = useState('');

  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Mutation
        client={client.clientPrivate}
        mutation={createImageMutation}
        onCompleted={() => {
          swal(
            'Great!',
            'Place has been created successfully!',
            'success',
          ).then(() => {
            history.push('/places');
          });
        }}
      >
        {(create_image, { loading: imageLoading, error: imageError }) => (
          <Mutation
            client={client.clientPrivate}
            mutation={createPlaceMutation}
            onCompleted={({ create_place }) => {
              if (create_place.error !== null) {
                create_place.error.map(item => toast.error(item.description));
              }
              if (!isNull(create_place.error)) {
                create_place.error.map(item => toast.error(item.description));
              } else if (images.length !== 1) {
                create_image({
                  variables: {
                    input: {
                      user_id: userId,
                      object_id: placeId,
                      object_type: 'PLACE',
                      parent_id: placeId,
                      upload_type: 'ADMIN',
                      parent_type: 'PLACE',
                      // links_to: [
                      //   {
                      //     id: placeId,
                      //     type: 'PLACE',
                      //   },
                      // ],
                      images: parseImage(images),
                    },
                  },
                });
              } else {
                swal(
                  'Great!',
                  'Place had been created successfully!',
                  'success',
                ).then(() => {
                  history.push('/places');
                });
              }
            }}
          >
            {(create_place, { loading, error }) => (
              <AddPlaceForm
                isClaimed={false}
                userId={userId}
                loading={loading || imageLoading}
                error={error || imageError}
                onSubmit={async data => {
                  setImages(data.image);
                  setPlaceId(data.place_id);
                  const inputs = await omit(data, [
                    'business_hour',
                    'primaryEmail',
                    'displayEmail',
                    'primaryMobile',
                    'displayMobile',
                    'businessSite',
                    'displayBusiness',
                    'contact',
                    'claim',
                    'uploadedImages',
                    'image',
                  ]);

                  const primaryContacts = await [
                    {
                      type: 'email_primary',
                      value: data.primaryEmail,
                      display: data.displayEmail,
                      is_primary: true,
                      display_order: '1',
                    },
                    {
                      type: 'phone_primary',
                      value: data.primaryMobile,
                      display: data.displayMobile,
                      is_primary: true,
                      display_order: '1',
                    },
                    {
                      type: 'website',
                      value: data.businessSite,
                      display: data.displayBusiness,
                      is_primary: true,
                      display_order: '1',
                    },
                  ];

                  const formContact = () => {
                    const final = [];
                    forEach(data.contact, item => {
                      const custom = omit(item, ['id']);
                      final.push(custom);
                    });
                    return final;
                  };

                  const contact = await concat(primaryContacts, formContact());

                  const businessHour = await data.business_hour.map(item => {
                    const customItem = omit(item, ['open24Hours', 'closed']);
                    return customItem;
                  });

                  const input = await {
                    ...inputs,
                    business_hour: businessHour,
                    contact,
                  };

                  if (input) {
                    const final = {};

                    Object.assign(
                      final,
                      { user_id: userId },
                      { place_id: input.place_id },
                      { name: input.name },
                      { slug: input.slug },
                      { status: input.status },
                      { approval_status: input.approval_status },
                      { listing_status: input.listing_status },
                      { featured: input.featured },
                      { event_business: input.event_business },
                      { item_review_place: input.item_review_place },
                      { description: input.description },
                      { address_line_1: input.address_line_1 },
                      { address_line_2: input.address_line_2 },
                      { city: input.city },
                      { state: input.state },
                      { country: input.country },
                      { post_code: input.post_code },
                      { latitude: input.latitude },
                      { longitude: input.longitude },
                      { location: input.location },
                      { timezone: input.timezone },
                      input.tagline !== '' && { tagline: input.tagline },
                      input.price_range !== '' && {
                        price_range: input.price_range,
                      },
                      { contact: input.contact },
                      { social: input.social },
                      input.price_range !== '' && {
                        price_range: input.price_range,
                      },
                      {
                        business_type: input.business_type,
                      },
                      {
                        service_type: input.service_type,
                      },
                      {
                        service_category: input.service_category,
                      },
                      {
                        cuisine_style: input.cuisine_style,
                      },
                      {
                        dietary_information: input.dietary_information,
                      },
                      {
                        item_type: input.item_type,
                      },
                      { feature: input.feature },
                      {
                        dining_style: input.dining_style,
                      },
                      {
                        parking_detail: input.parking_detail,
                      },
                      {
                        public_transport: input.public_transport,
                      },
                      {
                        noise_level: input.noise_level,
                      },
                      {
                        dress_code: input.dress_code,
                      },
                      {
                        ambience: input.ambience,
                      },
                      {
                        good_for: input.good_for,
                      },
                      {
                        keywords: input.keywords,
                      },
                      { tag: input.tag },
                      {
                        business_hour: input.business_hour,
                      },
                      input.listing_status === 'APPROVED' && {
                        listing_role: 'Admin',
                      },
                    );
                    create_place({ variables: { input: final } });
                  }
                }}
              />
            )}
          </Mutation>
        )}
      </Mutation>
    </Layout>
  );
};

export default AddPlace;
