import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { CreatableSelectInputs, Select } from './elements';

const options = [
  { label: 'ACTIVE', value: 'ACTIVE' },
  { label: 'INACTIVE', value: 'INACTIVE' },
];

const Icon = styled.i`
  position: relative;
  z-index: 20;
  color: white;
  float: right;
  bottom: 120px;
  right: 9px;
  background: red;
  padding: 8px;
  border-radius: 13px;
  :hover {
    background: #d60707f2;
  }
`;

// const placeTagValues = tagsValue => {
//   const tags = [];
//   if (!isUndefined(tagsValue)) {
//     tagsValue.map(item => {
//       tags.push({ value: item, label: item });
//       return null;
//     });
//   }
//   return tags;
// };

// const placeTagInput = tagsValue => {
//   const tags = [];
//   tagsValue.map(item => {
//     tags.push(item.value);
//     return null;
//   });
//   return tags;
// };

const ImageItem = ({
  imageSrc,
  imageName,
  status,
  tags,
  handleRejection,
  isRejected,
}) => (
  <div>
    <Select
      label="Status"
      options={options}
      value={{ label: status, value: status }}
      required
      disabled
    />
    <figure className="image is-square">
      <img src={imageSrc} alt={imageName} />
    </figure>
    <CreatableSelectInputs
      value={tags}
      suggestions={[]}
      isClearable={false}
      components={{ DropdownIndicator: null }}
      disabled
    />
    <a
      onClick={handleRejection}
      className="button is-danger is-fullwidth"
      disabled={isRejected}
    >
      {isRejected ? 'Rejected' : 'Reject'}
    </a>
  </div>
);

ImageItem.defaultProps = {
  imageSrc: 'https://bulma.io/images/placeholders/128x128.png',
  imageName: 'image',
  status: 'ACTIVE',
  tags: [{ label: '#image', value: 'image' }],
};

ImageItem.propTypes = {
  imageSrc: PropTypes.string,
  imageName: PropTypes.string,
  status: PropTypes.string,
  tags: PropTypes.array,
};

export default ImageItem;
