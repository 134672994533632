import React from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { omit } from 'lodash';

import { Loading, Message } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import Form from '../UserAdd/Form';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      date_of_birth
      gender
      description
      status
      user_handle
      external_payment_id
      visibility
      admin
      rating
      contact {
        type
        value
        display
        display_order
        is_primary
      }

      profile_image {
        image_id
        is_default
      }
      address {
        type
        is_default
        contact_name
        contact_phone
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
      }
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const updateMutation = gql`
  mutation updateUser($input: UserInput) {
    update_user(input: $input) {
      user_id
    }
  }
`;

const UserUpdate = ({ history, match }) => (
  <Layout>
    <Query
      client={client.clientPrivate}
      query={userQuery}
      variables={{
        userId: match.params.userId,
        user_handle: '',
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_user } = data;

        return (
          <Mutation
            client={client.clientPrivate}
            mutation={updateMutation}
            onCompleted={() => {
              swal(
                'Great!',
                'User has been updated successfully!',
                'success',
              ).then(() => {
                history.push('/users');
              });
            }}
          >
            {(update_user, { loading: updateLoading, error: updateUser }) => (
              <Form
                error={updateUser}
                loading={updateLoading}
                user={fetch_user}
                onSubmit={value => {
                  const input = omit(value, [
                    'primaryContact',
                    'contact',
                    'address',
                    'profile_image',
                  ]);
                  const primaryContact = {
                    id: '',
                    type: 'mobile',
                    is_primary: true,
                    value: value.primaryContact,
                    display: true,
                    display_order: '1',
                  };
                  const address = value.address.map(item => {
                    const custom = omit(item, ['id', '__typename']);
                    return custom;
                  });

                  const addPrimaryContact = value.contact.concat(
                    primaryContact,
                  );

                  const contact = addPrimaryContact.map(item => {
                    const custom = omit(item, ['id', '__typename']);
                    return custom;
                  });
                  const inputs = {
                    ...input,
                    contact,
                  };
                  update_user({ variables: { input: inputs } });
                }}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  </Layout>
);

export default UserUpdate;
