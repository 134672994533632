import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CheckInList = ({ review, setPageCount, totalPages }) => {
  useEffect(() => {
    setPageCount();
  }, [totalPages]);
  return (
    <tr>
      <td>
        <Link to="/create-menu">Slow Cooked Lamb Shanks</Link>
      </td>
      <td>Rinesh Dining</td>
      <td>St Kilda</td>
      <td>Unclaimed</td>
      <td>not found</td>
      <td>not found</td>
      <td>not found</td>
      <td>not found</td>
      <td>not found</td>
      <td>not found</td>
    </tr>
  );
};

export default CheckInList;
