import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

const deletePlaceMutation = gql`
  mutation delete_place($input: NavigatePlaceInput) {
    delete_place(input: $input) {
      place_id
    }
  }
`;

const DeletePlaceMutation = ({ placeId }) => {
  const { userId } = useStoreState(state => state.auth);
  return (
    <Mutation
      mutation={deletePlaceMutation}
      variables={{
        input: {
          user_id: userId,
          place_id: placeId,
        },
      }}
      onCompleted={() => {
        swal('Poof! The place has been deleted!', {
          icon: 'success',
        });
      }}
    >
      {delete_place => (
        <a
          onClick={() => {
            swal('Are you sure you want to do this?', {
              buttons: ['Cancel', true],
            }).then(willDelete => {
              if (willDelete) {
                delete_place();
              }
            });
          }}
        >
          <span>
            <i className="fas fa-trash has-text-grey" />
          </span>
        </a>
      )}
    </Mutation>
  );
};

export default DeletePlaceMutation;
