import React, { useState } from 'react';
import { Column, Level } from 'rbx';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import PhotoUpload from './PhotoUpload';
import client from '../../../utils/apolloClient';
import { Select } from '../../../components/elements';

const ImageContainer = styled.div`
  padding: 1rem;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  background-color: ${({ defaultButtonDisabled }) =>
    defaultButtonDisabled ? '#e2e2e2' : '#ffffff'};
`;

const ButtonWrapper = styled.div`
  padding-top: 0.5rem;
`;

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      error {
        description
      }
    }
  }
`;

const UserUploadedPlaceImg = ({ values, place, images, setFieldValue }) => {
  const [statusFilter, setStatusFilter] = useState('ADD A REVIEW');
  const { userId } = useStoreState(state => state.auth);
  console.log('images', images);
  return (
    <React.Fragment>
      <Column.Group>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">User Display Name: </div>
              </Level.Item>
              <Level.Item>David</Level.Item>
            </Level.Item>
          </Level>
        </Column>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">Photo Upload: </div>
              </Level.Item>
              <PhotoUpload
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
              />
            </Level.Item>
          </Level>
        </Column>
      </Column.Group>

      <Column.Group>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">User Login: </div>
              </Level.Item>
              <Level.Item>raj_mallampati@hotmail.com</Level.Item>
            </Level.Item>
          </Level>
        </Column>
        <Column size={6}>
          <Level>
            <Level.Item align="left">
              <Level.Item>
                <div className="label">Updated At: </div>
              </Level.Item>
              <Level.Item>05/06/2018 12:29:21 AM</Level.Item>
            </Level.Item>
          </Level>
        </Column>
      </Column.Group>
      <br />
      <br />

      <Column.Group vcentered multiline gapSize={8}>
        <React.Fragment>
          {images.map(image => (
            <>
              <Mutation
                client={client.clientPrivate}
                mutation={updatePlaceMutation}
                onCompleted={() => {
                  toast.success('This image set as Place default image');
                  setFieldValue('default_image_url', image.url);
                }}
              >
                {(update_item, { loading }) => (
                  <Column size={3} key={image.image_id}>
                    <ImageContainer
                      defaultButtonDisabled={
                        image.url === values.default_image_url
                      }
                    >
                      <Select
                        label="Status"
                        value={{ label: image.status, value: image.status }}
                        required
                        disabled
                      />
                      <figure className="image is-square">
                        <img src={image.url} alt={place.name} />
                      </figure>

                      <ButtonWrapper>
                        <button
                          className={`button is-light is-fullwidth ${loading &&
                            'is-loading'}`}
                          disabled={image.url === values.default_image_url}
                          onClick={() => {
                            update_item({
                              variables: {
                                input: {
                                  user_id: userId,
                                  place_id: place.place_id,
                                  default_image_url: image.url,
                                },
                              },
                            });
                          }}
                        >
                          Set default
                        </button>
                      </ButtonWrapper>
                    </ImageContainer>
                  </Column>
                )}
              </Mutation>
            </>
          ))}
        </React.Fragment>
      </Column.Group>
    </React.Fragment>
  );
};

export default UserUploadedPlaceImg;
