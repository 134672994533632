import React from 'react';
import styled from 'styled-components';
import { Column, Button, Icon } from 'rbx';
import { FieldArray } from 'formik';
import uuidv4 from 'uuid/v4';
import { omit } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import {
  Radio,
  Input,
  Select,
  MobileInput,
  Loading,
  Message,
} from '../../../components/elements';

const Wrapper = styled.div`
  padding-top: 2rem;
  float: right;
`;
// const fetchAddress = gql`
//   query fetchAddress($input: NavigateUserInput) {
//     fetch_user(input: $input) {
//       user_id
//       object_id
//       first_name
//       last_name
//       display_name
//       preferred_username
//       color_code
//       user_handle
//       email
//       contact {
//         type
//         value
//         display
//         is_primary
//       }
//       address {
//         address_id
//         type
//         is_default
//         contact_name
//         contact_phone
//         address_line_1
//         address_line_2
//         city
//         state
//         country
//         post_code
//         latitude
//         location
//         location
//         timezone
//         status
//         error {
//           description
//         }
//       }
//     }
//   }
// `;

const AddressDefault = ({ addAddress, helpers, values }) => (
  // const { userId } = match.params;
  // console.log('id', userId);
  <div className="box">
    <Column.Group vcentered multiline gapSize={8}>
      <Column size="half">
        <Input label="Name" required onChange={addAddress} />
      </Column>
      <Column size="half">
        <Input label="Address Type" required />
      </Column>
      <Column size="half">
        <Input label="Location Address" required />
      </Column>
      <Column size="half">
        <Radio label="Default Address" />
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={8}>
            <Select label="Suburb" required />
          </Column>
          <Column size={4}>
            <Input label="Pin" required />
          </Column>
        </Column.Group>
      </Column>
      <Column size="half">
        <Select label="State" required />
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={8}>
            <Select label="Country" required />
          </Column>
          <Column size={4}>
            <Input label="Timezone" required />
          </Column>
        </Column.Group>
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={9}>
            <Input label="Contact Phone" />
          </Column>
          <Column size={3}>
            <AddDeleteButton
              add={() => addAddress(helpers)}
              showAddButton
              hideDeleteButton
            />
          </Column>
        </Column.Group>
      </Column>
    </Column.Group>
  </div>
);
const AddDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <Wrapper>
    <Button.Group hasAddons>
      {!hideDeleteButton && (
        <Button onClick={remove}>
          <Icon size="small">
            <i className="fas fa-trash has-text-grey" />
          </Icon>
        </Button>
      )}

      {showAddButton && (
        <Button onClick={add}>
          <Icon size="small">
            <i className="fas fa-plus has-text-grey" />
          </Icon>
        </Button>
      )}
    </Button.Group>
  </Wrapper>
);

const UserAddress = ({
  values,
  handleChange,
  handleBlur,
  // setFieldValue,
  setValues,
}) => {
  const addAddress = helpers => {
    helpers.push({
      id: uuidv4(),
      type: 'Home',
      is_default: false,
      contact_name: 'Rinesh',
      address_line_1: 'JP Nagar, 5th Phase',
      address_line_2: 'Arekere junction',
      city: 'Brisbane',
      state: 'Queensland',
      country: 'Australia',
      post_code: '4101',
      timezone: 'AEST',
      latitude: '-39.145',
      longitude: '70.258',
    });
  };
  const removeAddress = addressId => {
    setValues({
      ...values,
      address: values.address.filter(item => item.id !== addressId),
    });
  };
  const setDefault = address => {
    const allAddress = values.address.filter(item => item.id !== address.id);
    const setToNotDefault = allAddress.map(item => {
      const customItem = omit(item, 'is_default');
      return { ...customItem, is_default: false };
    });

    const omittedAddress = omit(address, 'is_default');
    const newAddress = {
      ...omittedAddress,
      is_default: true,
    };
    const customAddress = setToNotDefault.concat(newAddress);

    setValues({
      ...values,
      address: customAddress,
    });
  };
  return (
    <FieldArray
      name="address"
      render={helpers => (
        <React.Fragment>
          {values.address.length === 0 ? (
            <AddressDefault addAddress={addAddress} helpers={helpers} />
          ) : (
            <React.Fragment>
              {values.address.map((item, index) => (
                <div>
                  <div className="box">
                    <Column.Group vcentered multiline gapSize={8}>
                      <Column size="half">
                        <Input
                          label="Name"
                          name={`address.${index}.contact_name`}
                          value={item.contact_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Column>
                      <Column size="half">
                        <Input
                          label="Address Type"
                          name={`address.${index}.type`}
                          value={item.type}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Column>
                      <Column size="half">
                        <Input
                          label="Location Address"
                          name={`address.${index}.address_line_1`}
                          value={item.address_line_1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                      </Column>
                      <Column size="half">
                        <Radio
                          label="Default Address"
                          name="is_default"
                          value={item.is_default}
                          onChange={() => setDefault(item)}
                        />
                      </Column>
                      <Column size="half">
                        <Column.Group vcentered multiline gapSize={8}>
                          <Column size={8}>
                            <Select
                              label="Suburb"
                              value={[{ value: item.city, label: item.city }]}
                              required
                            />
                          </Column>
                          <Column size={4}>
                            <Input
                              label="Pin"
                              name={`address.${index}.post_code`}
                              value={item.post_code}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                          </Column>
                        </Column.Group>
                      </Column>
                      <Column size="half">
                        <Select
                          label="State"
                          value={[{ value: item.state, label: item.state }]}
                          required
                        />
                      </Column>
                      <Column size="half">
                        <Column.Group vcentered multiline gapSize={8}>
                          <Column size={8}>
                            <Select
                              label="Country"
                              value={[{ value: item.state, label: item.state }]}
                              required
                            />
                          </Column>
                          <Column size={4}>
                            <Select
                              label="Timezone"
                              value={[
                                { value: item.timezone, label: item.timezone },
                              ]}
                              required
                            />
                          </Column>
                        </Column.Group>
                      </Column>
                      <Column size="half">
                        <Column.Group>
                          <Column size={8}>
                            <MobileInput label="Contact Phone" />
                          </Column>
                          <Column size={4}>
                            <AddDeleteButton
                              add={() => addAddress(helpers)}
                              remove={() => removeAddress(item.id)}
                              showAddButton={
                                index === values.address.length - 1
                              }
                              hideDeleteButton={values.address.length === 1}
                            />
                          </Column>
                        </Column.Group>
                      </Column>
                    </Column.Group>
                  </div>
                  <br />
                </div>
              ))}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    />
  );
};

export default UserAddress;
