import React from 'react';
// import gql from 'graphql-tag';
// import { Mutation } from 'react-apollo';
// import swal from 'sweetalert';
import DeliveryZoneActivity from './DeliveryZoneActivity';

import Layout from '../../../components/global/Layout';

// import PlaceSelection from '../../../components/PlaceSelection';

const Menu = () => (
  <Layout>
    <DeliveryZoneActivity />
  </Layout>
);

export default Menu;
