import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull } from 'lodash';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import MenuSectionSearch from './MenuSectionSearch';
import MenuSectionList from './MenuSectionList';
import {
  Loading,
  Pagination,
  Table,
  Box,
  CustomInput,
  CustomSelect,
} from '../../../components/elements';

const itemReviewsQuery = gql`
  query searchItemReviews($input: SearchInput) {
    search_item_review(input: $input) {
      total_pages
      item_review_listing {
        place_id
        item_id
        item_review_id
        item_name
        content
        status
        cuisine_style {
          name
          description
          display_order
        }
      }
    }
  }
`;

const ManageMenuSections = () => {
  const [eventBusiness, setEventBusiness] = useState(false);
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [statusFilter, setStatusFilter] = useState('SUBMITTED');
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(3);
  const input = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    eventBusiness && { event_business: eventBusiness },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { from: pageFrom, size: listSize },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <MenuSectionSearch
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          // setPlaceNameFilter={setPlaceNameFilter}
          //  setPlaceLocationFilter={setPlaceLocationFilter}
        />
        <br />
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down" /> Menu Section
                  </span>
                  <br />
                  <CustomInput />
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down" /> Place Name
                  </span>
                  <br />
                  <CustomInput />
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Suburb
                  </span>
                  <br />
                  <CustomInput />
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Menu Name
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Items
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Updated By
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Updated At
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Action
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={itemReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_item_review } = data;
              console.log('search_item_review', search_item_review);

              if (isNull(search_item_review)) {
                return 'no data found';
              }

              return (
                <tbody>
                  {search_item_review.item_review_listing.map(menuSection => (
                    <MenuSectionList
                      totalPages={search_item_review.total_pages}
                      menuSection={menuSection}
                      setPageCount={() =>
                        setPageCount(search_item_review.total_pages)
                      }
                    />
                  ))}
                </tbody>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default ManageMenuSections;
