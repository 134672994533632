import React, { useState, useEffect } from 'react';
import { withFormik, FieldArray } from 'formik';
import styled from 'styled-components';
import uuid from 'uuid';
import { omit, forEach, first, isNull } from 'lodash';
import * as yup from 'yup';
import gql from 'graphql-tag';
import moment from 'moment';
import { Tab } from 'rbx';

import Header from './Header';
import Footer from './Footer';
import ListingTypeForm from './ListingTypeForm';
import PrimaryDetailsForm from './PrimaryDetailsForm';
import LocationForm from '../../../components/LocationForm';
import Contacts from '../../../components/Contacts';
import Socials from '../../../components/Socials';
import EventDetailForm from './EventDetailForm';
import EventImages from './EventImages';
import Tickets from './Tickets';
import { Box, Tabs } from '../../../components/elements';
import ListingTypeView from './ListingTypeView';

import { eventListingVisibilityParserFromApi } from '../../../utils/eventHelpers';
import client from '../../../utils/apolloClient';
import { getGeneralFee } from '../../../utils/fetchStatic';
import { placeFeeManagementHandler } from '../../../utils/helpers';

const Wrapper = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 'event-information',
    name: 'Information',
    index: 0,
  },
  {
    key: 'event-details',
    name: 'Details',
    index: 1,
  },
  {
    key: 'image',
    name: 'Image',
    index: 2,
  },
];

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const removeTicketsTypename = (parseValue, user_id) => {
  const final = [];
  forEach(parseValue, item => {
    const attributes = [];
    const fee = [];
    forEach(item.attribute, dta => {
      attributes.push(omit(dta, ['__typename']));
    });
    forEach(item.fee, dta => {
      fee.push(omit(dta, ['__typename']));
    });
    const omitted = omit(item, ['__typename', 'attribute', 'fee', 'sold']);
    final.push({ ...omitted, attribute: attributes, user_id, fee });
  });
  return final;
};

const placeQuery = gql`
  query place($placeId: String) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      status
      fee {
        name
        label
        fee_value_type
        value
        service_type
        applies_to
        sales_method
        place_managed
        can_override
        exempt_fee
      }
    }
  }
`;

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const [generalFee, setGeneralFee] = useState([]);
  const [placeFee, setPlaceFee] = useState([]);
  const { handleSubmit, values, loading, place, setFieldValue, errors } = props;
  console.log('errors', errors);
  const submitDraft = async () => {
    await setFieldValue('draft', true);
    handleSubmit();
  };

  const submitEventPost = async () => {
    await setFieldValue('draft', false);
    handleSubmit();
  };

  const next = () => {
    if (
      first(values.listing_type) === 'private_tickets' ||
      first(values.listing_type) === 'public_tickets'
    ) {
      return activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);
    }
    return activeTab === 2 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  };
  const prev = () => {
    if (
      first(values.listing_type) === 'private_tickets' ||
      first(values.listing_type) === 'public_tickets'
    ) {
      return activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);
    }
    return activeTab === 0 ? setActiveTab(2) : setActiveTab(activeTab - 1);
  };
  const disableSubmitButton = () => {
    let disable = false;
    if (
      (first(values.listing_type) === 'private_tickets' ||
        first(values.listing_type) === 'public_tickets') &&
      values.ticket.length === 0
    ) {
      disable = true;
    }
    return disable;
  };

  useEffect(() => {
    const getData = async () => {
      const fees = await getGeneralFee();
      const customize = fees.map(item => {
        const removeId = omit(item, 'id');
        return { ...removeId, exempt_fee: false };
      });
      setGeneralFee(customize);
    };

    client.clientPublic
      .query({
        query: placeQuery,
        variables: { placeId: place.place_id },
        fetchPolicy: 'network-only',
      })
      .then(({ data: { fetch_place } }) => {
        setPlaceFee(fetch_place.fee);
      });
    getData();
  }, []);

  const fee =
    generalFee.length !== 0 && placeFeeManagementHandler(generalFee, placeFee);
  const removeIdExemptFee =
    generalFee.length !== 0
      ? fee.filter(item => item.exempt_fee !== true)
      : values.fee;

  const filterEventFee = removeIdExemptFee.filter(
    item => item.service_type === 'event',
  );

  return (
    <React.Fragment>
      <Header
        activeTab={activeTab}
        loading={!!(!values.draft && loading)}
        handleSubmit={submitEventPost}
        submitDraft={submitDraft}
        eventName={values.name}
        placeName={`${values.place_name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status})`}
        next={next}
        prev={prev}
        disableSubmitButton={disableSubmitButton()}
        draft={values.draft}
        isDraftLoading={!!(values.draft && loading)}
      />

      <Box>
        <Tabs>
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.index}
              key={tab.key}
              onClick={() => setActiveTab(tab.index)}
            >
              {tab.name}
            </Tab>
          ))}

          {(first(values.listing_type) === 'private_tickets' ||
            first(values.listing_type) === 'public_tickets' ||
            first(values.listing_type) === 'private_registration' ||
            first(values.listing_type) === 'public_registration') && (
            <Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>
              Tickets
            </Tab>
          )}
        </Tabs>
        <Wrapper>
          {activeTab === 0 && (
            <div>
              <ListingTypeForm activeTab={activeTab} {...props} />
              <br />
              <PrimaryDetailsForm {...props} />
              <br />
              <LocationForm {...props} isEventComponent />
              <br />
              <Contacts {...props} />
              <br />
              <Socials {...props} />
            </div>
          )}

          {activeTab === 1 && (
            <React.Fragment>
              <ListingTypeView {...props} />
              <EventDetailForm {...props} />
            </React.Fragment>
          )}
          {activeTab === 2 && (
            <React.Fragment>
              <ListingTypeView {...props} />
              <EventImages {...props} />
            </React.Fragment>
          )}

          {(first(values.listing_type) === 'private_tickets' ||
            first(values.listing_type) === 'public_tickets' ||
            first(values.listing_type) === 'private_registration' ||
            first(values.listing_type) === 'public_registration') && (
            <React.Fragment>
              {activeTab === 3 && (
                <React.Fragment>
                  <ListingTypeView {...props} />
                  <FieldArray
                    name="ticket"
                    render={helpers => (
                      <Tickets
                        loading={loading}
                        placeId={place.place_id}
                        eventId={values.event_id}
                        helpers={helpers}
                        eventStartDate={values.start}
                        eventEndDate={values.endDate}
                        eventStartTime={values.startTime}
                        eventEndTime={values.endTime}
                        latitude={values.latitude}
                        longitude={values.longitude}
                        totalTicketCapacity={values.total_capacity}
                        fees={filterEventFee}
                        {...props}
                      />
                    )}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Wrapper>
      </Box>
      <hr />

      <Footer
        activeTab={activeTab}
        loading={loading}
        handleSubmit={submitEventPost}
        prev={prev}
        next={next}
        disableSubmitButton={disableSubmitButton()}
      />
    </React.Fragment>
  );
};

const AddEventForm = withFormik({
  mapPropsToValues: ({
    userId,
    place,
    event,
    eventOccurrence,
    tickets,
    uploadedImages,
  }) => {
    console.log('place', place);
    const contactPlaceDisplayFalse = place
      ? place.contact.map(item => ({
          ...item,
          display: false,
        }))
      : null;

    const contactPlace =
      place && !isNull(contactPlaceDisplayFalse)
        ? contactPlaceDisplayFalse.filter(item => item.is_primary === false)
        : [];

    const primaryEmailPlace =
      place && !isNull(contactPlaceDisplayFalse)
        ? contactPlaceDisplayFalse.filter(
            item => item.is_primary === true && item.type === 'email_primary',
          )
        : [];
    const primaryMobilePlace =
      place && !isNull(contactPlaceDisplayFalse)
        ? contactPlaceDisplayFalse.filter(
            item => item.is_primary === true && item.type === 'phone_primary',
          )
        : [];

    const contact =
      event && !isNull(event.contact)
        ? event.contact.filter(item => item.is_primary === false)
        : contactPlace;

    const primaryEmail =
      event && !isNull(event.contact)
        ? event.contact.filter(
            item => item.is_primary === true && item.type === 'email_primary',
          )
        : primaryEmailPlace;
    const primaryMobile =
      event && !isNull(event.contact)
        ? event.contact.filter(
            item => item.is_primary === true && item.type === 'phone_primary',
          )
        : primaryMobilePlace;
    const businessSite =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];

    const startDate =
      event && !isNull(event.start) ? moment(event.start) : null;
    const endDate = event && !isNull(event.end) ? moment(event.end) : null;

    return {
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      displayEmail:
        primaryEmail.length !== 0 ? first(primaryEmail).display : false,
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      displayMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).display : false,
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      displayBusiness:
        businessSite.length !== 0 ? first(businessSite).display : false,
      listing: event
        ? eventListingVisibilityParserFromApi(first(event.listing_type)).listing
        : 'event-listing',
      visibility: event
        ? eventListingVisibilityParserFromApi(first(event.listing_type))
            .visibility
        : 'public',

      // original submission data
      user_id: userId,
      event_id: event ? event.event_id : uuid(),
      event_occurrence_id: eventOccurrence
        ? eventOccurrence.event_occurrence_id
        : uuid(),
      place_id: place.place_id,
      venue_name: event ? event.venue_name : '',
      name: event ? event.name : '',
      place_name: place.name,
      status: event ? event.status : 'ACTIVE',
      // display_order: event ? event.display_order : '',
      featured: event ? event.featured : false,
      // default_url: event ? event.default_url : '',
      // start: event ? event.start : null,
      // end: event ? event.end : null,
      startDate: event ? moment(startDate).toISOString() : null,
      startTime: event ? moment(startDate).toISOString() : null,
      endDate: event ? moment(endDate).toISOString() : null,
      endTime: event ? moment(endDate).toISOString() : null,
      // minimum_price: event ? event.minimum_price : parseInt('0', 10),
      // maximum_price: event ? event.maximum_price : parseInt('0', 10),
      approval_status: event ? event.approval_status : 'APPROVED',
      // invitation: event ? event.invitation : '',
      // password: event ? event.password : '',
      // password_value: event ? event.password_value : '',
      draft: event ? event.draft : false,
      description: event ? event.description : '',
      tagline: event ? event.tagline : '',
      tag: event ? event.tag : [],
      slug: event ? event.slug : '',
      contact_event_organizer: event ? event.contact_event_organizer : false,
      // voucher_count: event ? event.voucher_count : 0,
      // url: event ? event.url : [],
      payment: event ? event.payment : [],
      image: [
        {
          url: '',
          preview: null,
          tooltip: '',
          link: '',
          tag: [],
          uploadingStatus: 'pending',
        },
      ],
      uploadedImages: uploadedImages || [],
      default_image_url: event ? event.default_image_url : '',
      listing_type: event ? event.listing_type : ['public_listing'],
      dress_code: event ? removeTypename(event.dress_code) : [],
      event_type: event ? removeTypename(event.event_type) : [],
      event_category: event ? removeTypename(event.event_category) : [],
      feature: event ? removeTypename(event.feature) : [],
      restriction: event ? removeTypename(event.restriction) : [],
      address_line_1: event ? event.address_line_1 : place.address_line_1,
      address_line_2: event ? event.address_line_2 : place.address_line_2,
      city: event ? event.city : place.city,
      state: event ? event.state : place.state,
      country: event ? event.country : place.country,
      post_code: event ? event.post_code : place.post_code,
      latitude: event ? event.latitude : place.latitude,
      longitude: event ? event.longitude : place.longitude,
      // timezone: event ? event.timezone : place.timezone,
      social: event
        ? removeTypename(event.social)
        : removeTypename(place.social),
      contact: removeTypename(contact),
      ticket:
        removeTicketsTypename(
          tickets,
          'ff70ec5c-337a-4c28-97c8-9adc6bb25ced',
        ) || [],
      ticket_holding_time_limit: eventOccurrence
        ? eventOccurrence.ticket_holding_time_limit
        : 1,
      total_capacity: eventOccurrence ? eventOccurrence.total_capacity : 0,
      display_remaining_ticket: eventOccurrence
        ? eventOccurrence.display_remaining_ticket
        : false,
      total_tickets:
        eventOccurrence && !isNull(eventOccurrence.total_tickets)
          ? eventOccurrence.total_tickets
          : 0,
      fee: event && !isNull(event.fee) ? event.fee : [],
      eventFeeForUpdate: event && !isNull(event.fee) ? event.fee : [],
    };
  },
  // validate: values => {
  //   const errors = {};
  //   const customStartDate = moment(values.startDate).format('L');
  //   const customStartTime = !isNull(values.startTime)
  //     ? moment(values.startTime).format('LT')
  //     : '12:00 AM';
  //   const customEndDate = moment(values.endDate).format('L');
  //   const customEndTime = !isNull(values.endTime)
  //     ? moment(values.endTime).format('LT')
  //     : '11:59 PM';
  //   const startDate = moment(
  //     `${customStartDate} ${customStartTime}`,
  //   ).toISOString();
  //   const endDate = moment(`${customEndDate} ${customEndTime}`).toISOString();
  //   const difference = moment(endDate).diff(startDate);
  //   // if (moment(endDate).isBefore(startDate)) {
  //   //   errors.endDate = 'At least 10 min difference between start and end date';
  //   // }
  //   return errors;
  // },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(10, 'Please enter at least 10 characters')
      .max(75, 'Please enter less than 75 characters')
      .required('Event Name is required!'),
    venue_name: yup
      .string()
      .min(10, 'Minimum 10 and Maximum 50 Character required!')
      .max(50, 'Minimum 10 and Maximum 50 Character required!'),
    tagline: yup
      .string()
      .min(10, 'Please enter at least 10 characters')
      .max(50, 'Please enter less than 50 characters')
      .required('Tagline is required!'),
    event_type: yup
      .array()
      .min(1, 'Please select at least one Event type')
      .required('Event Type is required!'),
    description: yup
      .string()
      .min(100, 'Please enter at least 100 characters')
      .max(1800, 'Please enter less than 1800 characters'),
    startDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('Start date is required'),
    endDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('End date is required'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('City is required'),
    post_code: yup.string().required('Postcode is required'),
    // timezone: yup.string().required('Timezone is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    latitude: yup.string().required('Latitude is required'),
    longitude: yup.string().required('Longitude is required'),
    ticket_holding_time_limit: yup.number('Only Accept Integer value'),
    total_capacity: yup
      .number()
      .lessThan(0, 'Negative number is not accepted')
      .max(99999, 'Only accept 5 digit number'),
    // .required('Ticket holding time capacity is required'),
  }),

  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'AddPlaceForm',
})(Form);

export default AddEventForm;
