import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import {
  Input,
  Radio,
  ReactDateTimePicker,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  approval_status,
  itemNameSearchInput,
  setItemNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  reviewOn,
  setReviewOn,
  history,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label"> Approval Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Submitted"
              name="Type"
              value={approval_status === 'SUBMITTED'}
              onChange={() => {
                history.push(
                  `/item-photo-approvals${qs.stringify(
                    { approval_status: 'SUBMITTED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Approved"
              name="Type"
              value={approval_status === 'APPROVED'}
              onChange={() => {
                history.push(
                  `/item-photo-approvals${qs.stringify(
                    { approval_status: 'APPROVED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Rejected"
              name="Type"
              value={approval_status === 'REJECTED'}
              onChange={() => {
                history.push(
                  `/item-photo-approvals${qs.stringify(
                    { approval_status: 'REJECTED' },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Item Name"
              type="text"
              value={itemNameSearchInput}
              onChange={item => setItemNameSearchInput(item.target.value)}
              placeholder="Start entering item name..."
            />
          </div>
          <div className="column is-3">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>

          <div className="column is-3">
            <div className="field">
              <label className="label"> City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.name);
                    setCity(data.name);
                    console.log('data');
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="column is-3">
            <ReactDateTimePicker
              label="Review On"
              value={reviewOn}
              onChange={value => {
                setReviewOn(value);
              }}
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
