import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import { omit } from 'lodash';
import qs from 'querystringify';
import { Input, CheckBox, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  vendorNameSearchInput,
  setVendorNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  listing_status,
  event_business,
  item_review_place,
  place_name,
  page_no,
  routeState,
  history,
}) => {
  const [city, setCity] = useState('');

  const handlePush = (args, key) => {
    const newRoutState = omit(routeState, key);
    history.push(
      `/places${qs.stringify(
        {
          ...newRoutState,
          ...args,
        },
        true,
      )}`,
    );
  };

  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Listing Type</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="APPROVED"
                name="Type"
                value={listing_status === 'APPROVED'}
                onChange={() =>
                  handlePush({ listing_status: 'APPROVED' }, 'listing_status')
                }
              />
            </div>
          </Level.Item>
          <Level.Item>
            <div>
              <Radio
                label="UNLISTED"
                name="Type"
                value={listing_status === 'UNLISTED'}
                onChange={() => {
                  history.push(
                    `/places${qs.stringify(
                      {
                        listing_status: 'UNLISTED',
                        item_review_place,
                        event_business,
                        place_name,
                        page_no,
                      },
                      true,
                    )}`,
                  );
                }}
              />
            </div>
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Additional Criteria</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="Event organizer only"
              name="event_business"
              value={event_business === 'true'}
              onChange={value => {
                history.push(
                  `/places${qs.stringify(
                    {
                      listing_status,
                      item_review_place: false,
                      event_business: value,
                      place_name,
                      page_no,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <CheckBox
              label="User added place via item review"
              value={item_review_place === 'true'}
              name="item_review_place"
              onChange={value => {
                history.push(
                  `/places${qs.stringify(
                    {
                      listing_status,
                      item_review_place: value,
                      event_business: false,
                      place_name,
                      page_no,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={place_name}
              onChange={place => {
                handlePush({ place_name: place.target.value }, 'place_name');
              }}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              value={vendorNameSearchInput}
              onChange={place => setVendorNameSearchInput(place.target.value)}
              placeholder="Start entering company name..."
            />
          </div>

          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
