import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
// import uuidv4 from 'uuid/v4';
import ItemDashboard from './ItemDashboard';
import ItemReviewers from './ItemReviewers';
import ItemSocial from './ItemSocial';
import UserUploadedItemImg from './UserUploadedItemImg';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Item Dashboard',
  },
  {
    key: 1,
    name: 'Item Reviewers',
  },
  {
    key: 2,
    name: 'Item Social',
  },
  {
    key: 3,
    name: 'User Uploaded Item Images',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { item } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  return (
    <React.Fragment>
      <Header
        activeTab={activeTab}
        next={next}
        prev={prev}
        itemName={item.name}
        subHeading={`${item.city}, ${item.post_code} | ${item.country} ( ${item.status}  / ${item.approval_status} ) `}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {activeTab === 0 && <ItemDashboard {...props} />}
          {activeTab === 1 && <ItemReviewers />}
          {activeTab === 2 && <ItemSocial {...props} />}
          {activeTab === 3 && <UserUploadedItemImg {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer prev={prev} next={next} activeTab={activeTab} />
    </React.Fragment>
  );
};

const PlaceActivity = withFormik({
  mapPropsToValues: ({ item }) => ({
    default_image_url: item.default_image_url,
  }),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PlaceActivity',
})(Form);

export default PlaceActivity;
