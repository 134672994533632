import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';

const Header = ({
  activeTab,
  loading,
  handleSubmit,
  location,
  placeName,
  status,
  claimStatus,
  next,
  prev,
  history,
  dirty,
}) => (
  <PageHeader
    title={location.pathname === '/add-place' ? 'Add a Place' : 'Edit a Place'}
    otherText={
      location.pathname === '/add-place'
        ? ''
        : `${placeName} (${status} / ${claimStatus})`
    }
  >
    <Level.Item align="right">
      <Level.Item>
        <a onClick={() => history.goBack()} className="button is-light">
          <span>Cancel</span>
        </a>
      </Level.Item>
      {activeTab !== 0 && (
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      )}

      <Level.Item>
        <button
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
          disabled={!dirty}
        >
          <span>Submit</span>
        </button>
      </Level.Item>
      {activeTab !== 4 && (
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next</span>
          </button>
        </Level.Item>
      )}
    </Level.Item>
  </PageHeader>
);

export default withRouter(Header);
