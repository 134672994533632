import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, first, uniq } from 'lodash';
import moment from 'moment';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const searchItemDictionaryQuery = gql`
  query searchItemDictionary($input: SearchInput) {
    search_item_dictionary(input: $input) {
      total_pages
      total_size
      item_dictionary_listing {
        item_dictionary_id
        name
        description
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const ItemReviews = () => {
  const [dictionaryData, setDictionaryData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [itemNameSort, setItemNameSort] = useState('AES');

  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [statusFilter, setStatusFilter] = useState('SUBMITTED');
  const [itemNameSearchInput, setItemNameSearchInput] = useState('');
  const [statusInput, setStatusInput] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const item_dictionary_filter = {};
  // const item_filter = {};
  const input = {};
  Object.assign(
    item_dictionary_filter,
    itemNameSearchInput !== '' && { name: itemNameSearchInput },
    statusInput !== '' && { status: statusInput },
  );
  //Object.assign(item_filter, { status: 'ACTIVE' });
  Object.assign(
    input,
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        item_dictionary_filter,
        // item_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          itemNameSearchInput={itemNameSearchInput}
          setItemNameSearchInput={setItemNameSearchInput}
          statusInput={statusInput}
          setStatusInput={setStatusInput}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          itemNameSort === 'AES' ? 'NAME_DESC' : 'NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Item Dictionary Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedBySort(
                          updatedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedBySort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setUpdatedAtSort(
                          updatedAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          updatedAtSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          updatedAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_ASC' : 'STATUS_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  {/* <br />
                  <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'Active ', label: 'Active' },
                      { value: 'Inactive', label: 'Inactive' },
                    ]}
                  /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>Action</span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            query={searchItemDictionaryQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }
              const { search_item_dictionary } = data;

              return (
                <View
                  search_item_dictionary={search_item_dictionary}
                  setDictionaryData={setDictionaryData}
                  dictionaryData={dictionaryData}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_item_dictionary,
  setPageCount,
  setTotalElements,
  setDictionaryData,
  dictionaryData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_item_dictionary.total_pages);
    setTotalElements(search_item_dictionary.total_size);

    if (search_item_dictionary.item_dictionary_listing) {
      setDictionaryData(search_item_dictionary.item_dictionary_listing);
      const userFilter = search_item_dictionary.item_dictionary_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_item_dictionary.total_pages]);

  return (
    <tbody>
      {dictionaryData.map(dictionary => (
        <ListItem dictionary={dictionary} users={users} />
      ))}
    </tbody>
  );
};

export default ItemReviews;
