import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  &&& {
    a {
      width: fit-content;
    }
    .disabled a span {
      color: #a8a8a8 !important;
      cursor: not-allowed;
    }
  }
`;

const CheckBox = ({ label, value, onChange, disabled, secondary }) => {
  const [enable, setEnable] = useState(value);
  useEffect(() => {
    setEnable(value);
  }, [value]);
  return (
    <Wrapper className="field">
      <div className={classNames('control', { disabled })}>
        <input type="checkbox" className="is-hidden" onChange={onChange} />
        <a
          onClick={
            disabled
              ? null
              : () => {
                  setEnable(!enable);
                  onChange(!enable);
                }
          }
          className="has-text-weight-semibold label is-flex"
        >
          <span className="has-text-grey">
            <i
              className={
                enable ? 'fas fa-check-square has-text-info' : 'far fa-square'
              }
            />
          </span>
          &nbsp; &nbsp;
          <span
            className={
              secondary
                ? 'has-text-grey has-text-weight-bold'
                : 'has-text-grey has-text-weight-normal'
            }
          >
            {label}
          </span>
        </a>
      </div>
    </Wrapper>
  );
};

CheckBox.defaultProps = {
  label: '',
  value: false,
  onChange: () => {},
};

CheckBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckBox;
