import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  setLocation,
  status,
  setStatus,
  loginSearchInput,
  setLoginSearchInput,
  firstNameSearchInput,
  setFirstNameSearchInput,
  lastNameSearchInput,
  setLastNameSearchInput,
  displayNameSearchInput,
  setDisplayNameSearchInput,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Active"
              value={status === 'active'}
              onChange={() => {
                setStatus('active');
              }}
            />
          </Level.Item>

          <Level.Item>
            <Radio
              label="Locked"
              value={status === 'Locked'}
              onChange={() => {
                setStatus('Locked');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Inactive"
              value={status === 'Inactive'}
              onChange={() => {
                setStatus('Inactive');
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Login"
              type="text"
              value={loginSearchInput}
              onChange={user => setLoginSearchInput(user.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-3">
            <Input
              label="First Name"
              type="text"
              value={firstNameSearchInput}
              onChange={user => setFirstNameSearchInput(user.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-3">
            <Input
              label="Display Name"
              type="text"
              value={displayNameSearchInput}
              onChange={user => setDisplayNameSearchInput(user.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
