import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { omit, lowerCase, capitalize } from 'lodash';
import gql from 'graphql-tag';
import styled from 'styled-components';

import Input from '../elements/Input';
import client from '../../utils/apolloClient';

const searchLocationQuery = gql`
  query searchLocation($search_criteria: String!, $type: [String]) {
    search_location(
      input: {
        search_criteria: $search_criteria
        filter: { location_filter: { type: $type } }
      }
    ) {
      location_listing {
        code
        country_id
        country_name
        currency
        description
        language
        locale
        latitude
        longitude
        location
        name
        phone_code
        population
        post_code
        state_id
        state_name
        timezone
        tld
        type
      }
    }
  }
`;

const SuggestionsContainer = styled.div`
  max-height: 20rem;
  overflow-y: scroll;
`;
const renderInputComponent = inputProps => {
  const props = omit(inputProps, ['className', 'ref']);

  return <Input {...props} />;
};

const renderSuggestion = suggestion => (
  <p className="is-size-4 has-text-weight-medium is-capitalized">
    {capitalize(suggestion.name)}, {suggestion.state_name},{' '}
    {suggestion.post_code}
  </p>
);

const renderSuggestionsContainer = ({ containerProps, children }) =>
  children && (
    <SuggestionsContainer {...containerProps}>{children}</SuggestionsContainer>
  );

const LocationSuggestions = ({
  label,
  initialValue,
  onSelect,
  onHandleClear,
}) => {
  const [value, setValue] = React.useState(`${initialValue}`);
  const [suggestions, setSuggestions] = React.useState([]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    onHandleClear(newValue.length);
  };

  const getSuggestionValue = suggestion => {
    onSelect(suggestion);
    return suggestion.name;
  };

  const onSuggestionsFetchRequested = inputValue => {
    console.log('data', inputValue);
    client.clientPublic
      .query({
        query: searchLocationQuery,
        variables: {
          search_criteria: lowerCase(inputValue.value),
          type: ['city'],
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setSuggestions(data.search_location.location_listing);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Search location',
    value,
    onChange,
  };

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className="control">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          renderInputComponent={renderInputComponent}
          renderSuggestionsContainer={renderSuggestionsContainer}
          inputProps={inputProps}
          focusInputOnSuggestionClick={false}
        />
      </div>
    </div>
  );
};

LocationSuggestions.defaultProps = {
  label: '',
  initialValue: '',
  onSelect: () => {},
  onHandleClear: () => {},
};

LocationSuggestions.propTypes = {
  label: PropTypes.string,
  initialValue: PropTypes.string,
  onSelect: PropTypes.func,
  onHandleClear: PropTypes.func,
};

export default LocationSuggestions;
