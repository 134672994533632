import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const GroupList = ({ review, setPageCount, totalPages }) => {
  useEffect(() => {
    setPageCount();
  }, [totalPages]);
  return (
    <tr>
      <td>
        <Link to="/manage-modifier-option">Toppings</Link>
      </td>
      <td>Las Tapas Diner</td>
      <td>St Kilda</td>
      <td>Ham, Pepperoni, sausages, Olives</td>
      <td>O</td>
      <td>4</td>
      <td>Super Admin </td>
      <td>24/06/2019</td>
      <td>Y</td>
      <td />
    </tr>
  );
};

export default GroupList;
