import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';

const PlaceList = ({ claim, users }) => {
  const userArray = users.filter(
    item => item.user_id === claim.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <tr>
      <td>
        <Link to={`/update-claim/${claim.place_id}/${claim.claimant_id}`}>
          {claim.name}
        </Link>
      </td>
      <td>{claim.city}</td>
      {/* <td>{claim.status}</td> */}
      <td> {`${claim.claim_status}`}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email}</p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          claim.audit.updated_by
        )}
      </td>
      <td>{moment(claim.audit.updated_at).format('lll')}</td>
      <td>
        <Link>
          <i className="fas fa-trash has-text-grey" />
        </Link>
      </td>
    </tr>
  );
};

export default PlaceList;
