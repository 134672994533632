import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Title, Table, Level } from 'rbx';
import Footer from './Footer';
import EditAttendeeInfo from './EditAttendeeInfo';
import BookingByAttendeeList from './BookByAttendeeList';

import {
  Table as CustomTable,
  Box,
  Pagination,
} from '../../../components/elements';

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const Heading = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f6f6f6;
  margin-bottom: 1rem;
`;

const BookingByAttendee = ({ tickets, booking, setSort }) => {
  const [ticketData, setTicketData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [attendeeNameSort, setAttendeeNameSort] = useState('AES');
  const [emailSort, setEmailSort] = useState('AES');
  const [mobileSort, setMobileSort] = useState('AES');
  const [ageSort, setAgeSort] = useState('AES');
  const [ticketNameSort, setTicketNameSort] = useState('AES');
  const [ticketNumberSort, setTicketNumberSort] = useState('AES');

  const [step, setStep] = useState(1);
  const [selectedAttendee, setSelectedAttendee] = useState();

  return (
    <Box>
      {totalElements > 0 && (
        <Pagination
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          totalElements={totalElements}
          currentPage={currentPage}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <Heading>
        <Title size={3}>Booking By Attendee</Title>
      </Heading>

      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setAttendeeNameSort(
                        attendeeNameSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        attendeeNameSort === 'AES'
                          ? 'ATTENDEE_NAME_DESC'
                          : 'ATTENDEE_NAME_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        attendeeNameSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Attendee
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setEmailSort(emailSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        emailSort === 'AES'
                          ? 'ATTENDEE_EMAIL_DESC'
                          : 'ATTENDEE_EMAIL_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        emailSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Email
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setMobileSort(mobileSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        mobileSort === 'AES'
                          ? 'ATTENDEE_EMAIL_DESC'
                          : 'ATTENDEE_EMAIL_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        mobileSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Mobile
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setAgeSort(ageSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        ageSort === 'AES'
                          ? 'ATTENDEE_EMAIL_DESC'
                          : 'ATTENDEE_EMAIL_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        ageSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Age
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setTicketNameSort(
                        ticketNameSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        ticketNameSort === 'AES'
                          ? 'TICKET_NAME_DESC'
                          : 'TICKET_NAME_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketNameSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket Category
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <a
                    onClick={() => {
                      setTicketNumberSort(
                        ticketNumberSort === 'AES' ? 'DES' : 'AES',
                      );
                      setSort(
                        ticketNumberSort === 'AES'
                          ? 'TICKET_NUMBER_DESC'
                          : 'TICKET_NUMBER_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        ticketNumberSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up'
                      }
                    />
                  </a>{' '}
                  Ticket No
                </span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>Status</span>
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>Action</span>
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <View
          ticket={tickets}
          step={step}
          setStep={setStep}
          ticketData={ticketData}
          setTicketData={setTicketData}
          setPageCount={setPageCount}
          setTotalElements={setTotalElements}
          setSelectedAttendee={setSelectedAttendee}
          booking={booking}
        />
      </CustomTable>

      <Wrapper>
        <Footer />
      </Wrapper>
    </Box>
  );
};
export default BookingByAttendee;

const View = ({
  ticket,
  booking,
  setPageCount,
  setTotalElements,
  setTicketData,
  ticketData,
  setStep,
  step,
  setSelectedAttendee,
}) => {
  useEffect(() => {
    setPageCount(ticket.total_pages);
    setTotalElements(ticket.total_size);
    if (ticket.ticket_record_listing) {
      setTicketData(ticket.ticket_record_listing);
    }
  }, [ticket.total_pages]);

  return (
    <tbody>
      {ticketData.map(tickets => (
        <tr key={tickets.ticket_number}>
          <BookingByAttendeeList
            goToStepTwo={() => {
              setStep(2);
              setSelectedAttendee(ticket);
            }}
            tickets={tickets}
            booking={booking}
          />
        </tr>
      ))}
    </tbody>
  );
};
