import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { uniq } from 'lodash';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import CompanyList from './CompanyList';
import client from '../../../utils/apolloClient';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const searchCompanyQuery = gql`
  query searchCompany($input: SearchInput) {
    search_company(input: $input) {
      company_listing {
        company_id
        name
        place
        places {
          name
        }
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        status
        registration_id
        tax_id
        tax {
          type
          value
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
      total_size
      total_pages
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const Places = () => {
  const [companyData, setCompanyData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');
  const [updatedBySort, setUpdatedBySort] = useState('AES');
  const [updatedAtSort, setUpdatedAtSort] = useState('AES');
  // for pagination
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);
  // for pagination
  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [listingApproved, setListingApproved] = useState('APPROVED');

  const [eventBusiness, setEventBusiness] = useState('');
  const [itemReviewPlace, setItemReviewPlace] = useState('');

  const input = {};
  const company_filter = {};
  Object.assign(company_filter);

  Object.assign(
    input,
    // location !== '' && { location },
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        company_filter: {},
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
          listingApproved={listingApproved}
          setListingApproved={setListingApproved}
          eventBusiness={eventBusiness}
          setEventBusiness={setEventBusiness}
          itemReviewPlace={itemReviewPlace}
          setItemReviewPlace={setItemReviewPlace}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'COMPANY_NAME_DESC'
                            : 'COMPANY_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Company Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES' ? 'PLACE_DESC' : 'PLACE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Places
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES' ? 'COUNT_DESC' : 'COUNT_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Counts
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  <br />
                  {/* <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'ACTIVE', label: 'ACTIVE' },
                      { value: 'INACTIVE', label: 'INACTIVE' },
                    ]}
                    onChange={value => setStatus(value.value)}
                  /> */}
                </div>
              </th>

              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedBySort(updatedBySort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedBySort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedBySort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated By
                </span>
              </th>
              <th>
                <span>
                  <a
                    onClick={() => {
                      setUpdatedAtSort(updatedAtSort === 'AES' ? 'DES' : 'AES');
                      setSort(
                        updatedAtSort === 'AES'
                          ? 'UPDATED_DESC'
                          : 'UPDATED_ASC',
                      );
                    }}
                  >
                    <i
                      className={
                        updatedAtSort === 'AES'
                          ? 'fas fa-sort-amount-down'
                          : 'fas fa-sort-amount-up '
                      }
                    />
                  </a>{' '}
                  Updated At
                </span>
              </th>
            </tr>
          </thead>
          <Query
            query={searchCompanyQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_company } = data;
              console.log('search_company', search_company);

              return (
                <React.Fragment>
                  <View
                    companyData={companyData}
                    setCompanyData={setCompanyData}
                    search_company={search_company}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_company,
  setPageCount,
  setTotalElements,
  setCompanyData,
  companyData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_company.total_pages);
    setTotalElements(search_company.total_size);

    if (search_company.company_listing) {
      setCompanyData(search_company.company_listing);
      const userFilter = search_company.company_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_company.total_pages]);

  return (
    <tbody>
      {companyData.map(company => (
        <tr key={company.company_id}>
          <CompanyList company={company} users={users} />
        </tr>
      ))}
    </tbody>
  );
};

export default Places;
