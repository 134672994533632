import React from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';

// import gql from 'graphql-tag';

import { ACLSProvider } from '../utils/aclsContext';

import {
  Home,
  NotFound,
  Login,
  Places,
  Events,
  AddPlace,
  UpdatePlace,
  AddEvent,
  UpdateEvent,
  EventDeals,
  EventDealAdd,
  EventDealUpdate,
  EventActivities,
  EventInvitations,
  EmailInvitation,
  EventActivity,
  SellTickets,
  SellTicketsEvents,
  CheckingAttendeeEvents,
  CheckingAttendees,
  EditAttendee,
  // CheckInAttendeeDetails,
  Vendors,
  AddVendor,
  PlaceClaims,
  PlaceClaimAdd,
  PlaceClaimUpdate,
  PlaceDeals,
  PlaceDealAdd,
  PlaceDealUpdate,
  PlacePostings,
  PlaceActivities,
  PlaceActivity,
  ItemActivities,
  ItemActivity,
  AddCompany,
  Companies,
  UpdateCompany,
  PlacePostingAdd,
  PlacePostingUpdate,
  PlaceApproval,
  PlaceApprovalUpdate,
  PlaceEventFeeOverride,
  PlaceReviews,
  PlaceReview,
  ItemDictionaries,
  ItemDictionary,
  ItemReviews,
  ItemReview,
  FlaggedReviews,
  FlaggedReview,
  PlacePhotoApprovals,
  PlacePhotoApproval,
  ItemPhotoApprovals,
  ItemPhotoApproval,
  Users,
  UserAdd,
  UserActivities,
  UserActivity,
  UserUpdate,
  AdminUsers,
  AdminUser,
  Test,
  EditAttendeeInfo,
  ManageEvents,
  ManageEvent,
  AddMenu,
  UpdateMenu,
  AddGlobalMenus,
  AddGlobalMenu,
  ManageMenus,
  ManageMenuSections,
  ManageMenuSection,
  ManageMenuItems,
  ManageMenuItem,
  ModifierGroupOptions,
  ModifierGroupOption,
  ManageComboDeals,
  ManageComboDeal,
  ManageMenuDeals,
  ManageMenuDeal,
  HolidayHours,
  HolidayHour,
  DeliveryZones,
  DeliveryZone,
  BookingDetails,
} from './index';

import CreateMenu from './MenuManagement/CreateMenu';

// const userQuery = gql`
//   query user($userId: String!) {
//     fetch_user(input: { user_id: $userId }) {
//       user_id
//       first_name
//       last_name
//       email
//       role
//     }
//   }
// `;

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { setUserDetails } = useStoreActions(state => state.auth);
  // const tokens = Object.values(qs.parse(rest.location.hash));
  // if (tokens.length !== 0) {
  //   window.localStorage.setItem('token', first(tokens));
  //   setUserDetails(tokens[1]);
  //   window.localStorage.setItem('expiryDate', tokens[2]);
  //   const time = parseInt(tokens[2], 10) + moment().unix();
  //   window.localStorage.setItem('logOutTime', time);
  //   return <Redirect to="/" />;
  // }
  // const logOutTime = toString(window.localStorage.getItem('logOutTime'));
  // if (parseInt(logOutTime, 10) <= moment().unix()) {
  //   window.localStorage.clear();
  // }

  const isLoggedIn = window.localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const AppRouter = () => (
  // const [aclActions, setAclActions] = useState();
  // const { userId } = useStoreState(state => state.auth);
  // const isLoggedIn = window.localStorage.getItem('token');

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     client.clientPrivate
  //       .query({ query: userQuery, variables: { userId } })
  //       .then(({ data: { fetch_user } }) => {
  //         console.log('fetch_user', fetch_user);
  //         setAclActions({
  //           role: fetch_user.role ? fetch_user.role : [],
  //           apiActions: [],
  //           pageActions: [],
  //         });
  //       })

  //       .catch(error => {
  //         console.log('error', error);
  //       });
  //   }
  // }, []);

  <ACLSProvider value={{}}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/vendors" exact component={Vendors} />
        <PrivateRoute path="/vendors/add-vendor" exact component={AddVendor} />
        <PrivateRoute path="/places" component={Places} />
        <PrivateRoute path="/events" component={Events} />
        <PrivateRoute
          path="/update-event/:placeId/:eventId"
          component={UpdateEvent}
        />
        <PrivateRoute path="/add-place" component={AddPlace} />
        <PrivateRoute path="/update-place/:placeId" component={UpdatePlace} />

        <PrivateRoute path="/companies" component={Companies} />
        <PrivateRoute path="/add-company" component={AddCompany} />
        <PrivateRoute
          path="/update-company/:companyId"
          component={UpdateCompany}
        />

        <PrivateRoute path="/add-event" component={AddEvent} />
        <PrivateRoute path="/event-activities" component={EventActivities} />
        <PrivateRoute
          path="/event-activity/:eventId"
          component={EventActivity}
        />
        <PrivateRoute path="/email-invitations" component={EventInvitations} />
        <PrivateRoute
          path="/email-invitation/:eventId"
          component={EmailInvitation}
        />
        <PrivateRoute path="/event-deals" component={EventDeals} />
        <PrivateRoute path="/add-event-deal" component={EventDealAdd} />
        <PrivateRoute
          path="/update-event-deal/:placeId/:dealId"
          component={EventDealUpdate}
        />

        <PrivateRoute path="/global-menus" component={AddGlobalMenus} />
        <PrivateRoute path="/update-global-menu" component={AddGlobalMenu} />

        <PrivateRoute path="/menus" component={AddMenu} />
        <PrivateRoute path="/update-menu" component={UpdateMenu} />

        <PrivateRoute path="/manage-menus" component={ManageMenus} />
        <PrivateRoute path="/create-menu" component={CreateMenu} />
        <PrivateRoute
          path="/manage-menu-sections"
          component={ManageMenuSections}
        />
        <PrivateRoute
          path="/manage-menu-section"
          component={ManageMenuSection}
        />

        <PrivateRoute path="/manage-menu-items" component={ManageMenuItems} />
        <PrivateRoute path="/manage-menu-item" component={ManageMenuItem} />

        <PrivateRoute path="/manage-menu-items" component={ManageMenuItems} />

        <PrivateRoute
          path="/manage-modifier-groups"
          component={ModifierGroupOptions}
        />
        <PrivateRoute
          path="/manage-modifier-option"
          component={ModifierGroupOption}
        />

        <PrivateRoute path="/manage-combo-deals" component={ManageComboDeals} />
        <PrivateRoute path="/manage-combo-deal" component={ManageComboDeal} />

        <PrivateRoute path="/manage-menu-deals" component={ManageMenuDeals} />
        <PrivateRoute path="/manage-menu-deal" component={ManageMenuDeal} />

        <PrivateRoute path="/holiday-hours" component={HolidayHours} />
        <PrivateRoute path="/holiday-hour" component={HolidayHour} />

        <PrivateRoute path="/delivery-zones" component={DeliveryZones} />
        <PrivateRoute path="/delivery-zone" component={DeliveryZone} />

        <PrivateRoute path="/manage-events" component={ManageEvents} />
        <PrivateRoute path="/manage-event/:eventId/" component={ManageEvent} />
        <PrivateRoute
          path="/ticket/booking-details/:eventId/:bookingId"
          component={BookingDetails}
        />
        <PrivateRoute
          path="/ticket/booking-detail/edit-attendee-info/:ticketId"
          component={EditAttendeeInfo}
        />

        <PrivateRoute
          path="/select-events-for-sell-tickets"
          component={SellTicketsEvents}
        />
        <PrivateRoute path="/sell-tickets/:eventId" component={SellTickets} />
        <PrivateRoute
          path="/check-in-attendee-events"
          component={CheckingAttendeeEvents}
        />
        <PrivateRoute
          path="/check-in-attendees/:eventId"
          component={CheckingAttendees}
        />
        <PrivateRoute
          path="/edit-attendee-info/:ticketId"
          component={EditAttendee}
        />
        {/* <PrivateRoute
          path="/check-in-attendee-details"
          component={CheckInAttendeeDetails}
        /> */}
        <PrivateRoute path="/place-reviews" component={PlaceReviews} />
        <PrivateRoute
          path="/place-review/:placeId/:reviewId"
          component={PlaceReview}
        />

        <PrivateRoute path="/flagged-reviews" component={FlaggedReviews} />
        <PrivateRoute
          path="/flagged-review/:placeId/:flagId"
          component={FlaggedReview}
        />

        <PrivateRoute path="/item-reviews" component={ItemReviews} />
        <PrivateRoute
          path="/item-review/:itemId/:reviewId"
          component={ItemReview}
        />

        <PrivateRoute path="/item-dictionaries" component={ItemDictionaries} />
        <PrivateRoute
          path="/update-item-dictionary/:itemDictionaryId"
          component={ItemDictionary}
        />

        <PrivateRoute
          path="/place-photo-approvals"
          component={PlacePhotoApprovals}
        />
        <PrivateRoute
          path="/place-photo-approval/:imageReviewId"
          component={PlacePhotoApproval}
        />

        <PrivateRoute
          path="/item-photo-approvals"
          component={ItemPhotoApprovals}
        />
        <PrivateRoute
          path="/item-photo-approval/:imageReviewId"
          component={ItemPhotoApproval}
        />

        <PrivateRoute path="/place-claims" component={PlaceClaims} />
        <PrivateRoute path="/add-claim" component={PlaceClaimAdd} />
        <PrivateRoute
          path="/update-claim/:placeId/:claimantId"
          component={PlaceClaimUpdate}
        />
        <PrivateRoute path="/place-deals" component={PlaceDeals} />
        <PrivateRoute path="/add-place-deal" component={PlaceDealAdd} />

        <PrivateRoute
          path="/update-place-deal/:placeId/:dealId"
          component={PlaceDealUpdate}
        />
        <PrivateRoute path="/place-postings" component={PlacePostings} />

        <PrivateRoute path="/place-activities" component={PlaceActivities} />
        <PrivateRoute
          path="/place-activity/:placeId"
          component={PlaceActivity}
        />

        <PrivateRoute path="/item-activities" component={ItemActivities} />
        <PrivateRoute
          path="/item-activity/:placeId/:itemId"
          component={ItemActivity}
        />

        <PrivateRoute path="/add-place-posting" component={PlacePostingAdd} />
        <PrivateRoute
          path="/update-place-posting/:placeId/:feedId"
          component={PlacePostingUpdate}
        />
        <PrivateRoute path="/place-approval" component={PlaceApproval} />
        <PrivateRoute
          path="/place-approval-update/:placeId"
          component={PlaceApprovalUpdate}
        />
        <PrivateRoute
          path="/event-fee-override"
          component={PlaceEventFeeOverride}
        />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/user-activities" component={UserActivities} />
        <PrivateRoute path="/user-activity" component={UserActivity} />
        <PrivateRoute path="/add-user" component={UserAdd} />
        <PrivateRoute path="/user/:userId" component={UserUpdate} />

        <PrivateRoute path="/admin-users" component={AdminUsers} />
        <PrivateRoute path="/admin-user-activity" component={AdminUser} />
        <PrivateRoute path="/test" component={Test} />
        <PrivateRoute component={NotFound} />
      </Switch>
    </BrowserRouter>
  </ACLSProvider>
);
export default AppRouter;
