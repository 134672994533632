import React, { useState } from 'react';
import { capitalize, first } from 'lodash';
import { FieldArray } from 'formik';
import { Button, Icon } from 'rbx';

import { Radio, Table, Select } from '../../../components/elements';

const attributes = [
  { value: 'name', label: 'Name' },
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'email', label: 'Email' },
];

const attributesEmpty = [
  { value: 'select', label: 'Select....' },
  { value: 'name', label: 'Name' },
  { value: 'age', label: 'Age' },
  { value: 'gender', label: 'Gender' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'email', label: 'Email' },
];

const AddDeleteButton = ({ add, remove, showAddButton, hideDeleteButton }) => (
  <Button.Group hasAddons>
    {!hideDeleteButton && (
      <Button onClick={remove}>
        <Icon size="small">
          <i className="fas fa-trash has-text-grey" />
        </Icon>
      </Button>
    )}
    {showAddButton && (
      <Button onClick={add}>
        <Icon size="small">
          <i className="fas fa-plus has-text-grey" />
        </Icon>
      </Button>
    )}
  </Button.Group>
);

const TicketAttendeeForm = ({ values, setValues, setFieldValue }) => {
  const [options, setOptions] = useState(attributes);

  const addAAttendee = async (helpers, item1) => {
    const order = values.attribute.length + 1;
    await helpers.push({
      name: item1.value,
      label: capitalize(item1.label),
      required: false,
      values: '',
      validation: '',
      display_order: order,
    });

    const newOptions = await options.filter(
      item => item.value !== first(options).value,
    );
    setOptions(newOptions);
  };

  const removeAttendee = name => {
    setValues({
      ...values,
      attribute: values.attribute.filter(item => item.name !== name),
    });
    const newOptions = attributes.filter(item => item.value === name);
    setOptions(options.concat(newOptions));
  };

  return (
    <FieldArray
      name="attribute"
      render={helpers => (
        <div>
          <div className="label">Attendee Info to be collected</div>
          <Table>
            <thead>
              <tr>
                <th>Attendee Info</th>
                <th>Optional</th>
                <th>Required</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {values.attribute.length === 0 && (
                <tr>
                  <td>
                    <Select
                      options={options}
                      onChange={value =>
                        addAAttendee(
                          helpers,
                          first(
                            options.filter(item => item.value === value.value),
                          ),
                        )
                      }
                      disabled
                    />
                  </td>
                  <td>
                    <Radio label="" />
                  </td>
                  <td>
                    <Radio label="" />
                  </td>
                  <td>
                    <AddDeleteButton
                      add={() => addAAttendee(helpers, first(options))}
                      showAddButton
                      hideDeleteButton
                    />
                  </td>
                </tr>
              )}
              {values.attribute.map((item, index) => (
                <tr>
                  <td>
                    <Select
                      value={{ label: item.label, value: item.name }}
                      options={options}
                      onChange={value => {}}
                      // disabled
                    />
                  </td>
                  <td>
                    <Radio
                      label=""
                      value={!item.required}
                      onChange={() =>
                        setFieldValue(`attribute.${index}.required`, false)
                      }
                    />
                  </td>
                  <td>
                    <Radio
                      label=""
                      value={item.required}
                      onChange={() =>
                        setFieldValue(`attribute.${index}.required`, true)
                      }
                    />
                  </td>
                  <td>
                    <AddDeleteButton
                      add={() => addAAttendee(helpers, first(options))}
                      remove={() => removeAttendee(item.name)}
                      showAddButton={
                        index === values.attribute.length - 1 &&
                        values.attribute.length < attributes.length
                      }
                      hideDeleteButton={false}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    />
  );
};

export default TicketAttendeeForm;
