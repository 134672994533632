import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
// import { Query } from 'react-apollo';
// import { isNull } from 'lodash';
// import gql from 'graphql-tag';
import { withFormik } from 'formik';
import * as yup from 'yup';

import {
  Input,
  TextArea,
  CustomSelect,
  AsyncTagInputs,
} from '../components/elements';
import Layout from '../components/global/Layout';
import {getAmbience} from '../utils/fetchStatic'
// const placesQuery = gql`
//   query searchPlaces($input: SearchInput) {
//     search_place_claim(input: $input) {
//       place_id
//       claimant_id
//       name
//       city
//       status
//       claim_status
//     }
//   }
// `;

const Container = styled.div`
  padding: 1rem;
`;

const AttendeeInfo = props => {
  const { values, handleChange, handleBlur } = props;
  return (
    <Layout>
      <Container>
        <Column.Group vcentered multiline gapSize={8}>
          <Column size={4}>
            <AsyncTagInputs promiseFunction={getAmbience} />
          </Column>

          <Column size={4}>
            <Input label="Ticket No" placeholder="N0FZQRNG" disabled />
          </Column>
        </Column.Group>

        <hr />

        <Column.Group vcentered multiline gapSize={8}>
          <Column size={4}>
            <Input
              label="Booked By"
              name="bookedBy"
              value={values.bookedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Email Id"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Date And Time"
              name="dateAndTime"
              value={values.dateAndTime}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Purchased By"
              name="purchasedBy"
              value={values.purchasedBy}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Ticket Type"
              name="ticketType"
              value={values.ticketType}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>

          <Column size={4}>
            <Input
              label="Ticket Sales Method"
              name="ticketSalesMethod"
              value={values.ticketSalesMethod}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>
        </Column.Group>
        <br />

        <Column.Group vcentered multiline gapSize={8}>
          <Column size={6}>
            <Input
              label="Attendee Name"
              name="name"
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={6}>
            <Input
              label="Attendee Mobile"
              type="number"
              name="mobile"
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={6}>
            <Input
              label="Attendee Email"
              type="email"
              name="attendeeEmail"
              value={values.attendeeEmail}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={3}>
            <Input
              label="Attendee Age"
              name="age"
              type="number"
              value={values.age}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>

          <Column size={3}>
            <CustomSelect
              options={[
                { value: '', label: 'Gender' },
                { value: 'MALE ', label: 'MALE' },
                { value: 'FEMALE', label: 'FEMALE' },
                { value: 'OTHER', label: 'OTHER' },
              ]}
            />
          </Column>

          <Column size={8} rows={4}>
            <TextArea
              label="Attendee Notes"
              name="notes"
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Column>
        </Column.Group>

        <br />
      </Container>
    </Layout>
  );
};

const Info = withFormik({
  mapPropsToValues: () => ({
    bookedBy: '',
    email: '',
    dateAndTime: '',
    purchasedBy: '',
    ticketType: '',
    ticketSalesMethod: '',
    name: '',
    mobile: '',
    attendeeEmail: '',
    age: '',
    notes: '',
  }),
  validationSchema: yup.object().shape({
    mobile: yup.number(),
    // email: yup.string().email(),
    // age: yup.string().number(),
  }),
  handleSubmit: (values, { setSubmitting }) => {
    console.log('values', values);
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  },
  displayName: 'AttendeeInfo',
})(AttendeeInfo);

export default Info;
