import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Note = styled.div`
  padding: 0.5rem;
  margin-left: 0.5rem;
`;

const Header = ({
  heading,
  loading,
  action,
  next,
  status,
  handleApprovedAction,
  handleRejectAction,
  subHeading,
  itemName,
  updateItemReviewLoading,
  handleUpdateReview,
  history,
  dirty,
  placeListingStatus,
}) => (
  <>
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Title size={2}>{heading}</Title>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-light">
              <span>Cancel</span>
            </a>
          </Level.Item>
          <Level.Item>
            <button
              className={`button is-danger ${action === 'REJECTED' &&
                loading &&
                'is-loading'}`}
              onClick={handleRejectAction}
              disabled={status === 'REJECTED'}
            >
              <span>Reject</span>
            </button>
          </Level.Item>

          {placeListingStatus !== 'APPROVED' && (
            <Level.Item>
              <button
                className={`button is-primary ${action === 'APPROVED' &&
                  loading &&
                  'is-loading'}`}
                onClick={handleApprovedAction}
                disabled
              >
                <span>Approve and Publish</span>
              </button>
            </Level.Item>
          )}

          {status !== 'APPROVED' && placeListingStatus === 'APPROVED' && (
            <Level.Item>
              <button
                className={`button is-primary ${action === 'APPROVED' &&
                  loading &&
                  'is-loading'}`}
                onClick={handleApprovedAction}
                disabled={status === 'APPROVED'}
              >
                <span>Approve and Publish</span>
              </button>
            </Level.Item>
          )}
          <Level.Item>
            <button
              className={`button is-primary ${updateItemReviewLoading &&
                'is-loading'}`}
              onClick={handleUpdateReview}
              disabled={status === 'APPROVED' || !dirty}
            >
              <span>Update Review</span>
            </button>
          </Level.Item>
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        </Level.Item>
      </Level>

      <SubHeading className="is-size-2">{itemName}</SubHeading>
      <SubHeading className="is-size-2">{subHeading}</SubHeading>
    </Wrapper>

    <Note>
      {placeListingStatus !== 'APPROVED' && (
        <p className="has-text-weight-semibold" style={{ color: '#FFC841' }}>
          Place listing is not approved. Place listing must be reviewed and
          approved before, then the item listing can be approved
        </p>
      )}
    </Note>
  </>
);

export default withRouter(Header);
