import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import {
  Input,
  Radio,
  ReactDateTimePicker,
  Select,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const menu = [
  { value: 'Delivery Menu-All Day Menu', label: 'Delivery Menu-All Day Menu' },
  { value: 'Lunch Menu', label: 'Lunch Menu' },
  { value: 'Dinner Menu', label: 'Dinner Menu' },
];

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const GroupSearch = ({ statusFilter, setStatusFilter }) => (
  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-4">
          <Input label="Place Name" type="text" />
        </div>
        <div className="column is-4">
          <Input label="Suburb Name" type="text" />
        </div>

        <div className="column is-4">
          <Select label="Menu Name" options={menu} />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default GroupSearch;
