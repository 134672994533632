import React from 'react';
import styled from 'styled-components';
import { Column, Divider } from 'rbx';
import { first } from 'lodash';
import ListingAudit from './ListingAudit';

import {
  Input,
  TextArea,
  Select,
  MobileInput,
} from '../../../components/elements';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;
const TIME_ZONE_OPTIONS = [
  { value: '+10', label: 'AEST' },
  { value: '+8', label: 'AWST' },
  { value: '+9.5', label: 'ACST' },
  { value: '+5.5', label: 'IST' },
];

const Details = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const timeZoneLabel = first(
    TIME_ZONE_OPTIONS.filter(item => item.value === values.timezone),
  );

  console.log('primaryMobile', errors);
  return (
    <Container>
      <div className="label">Place Information</div>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            label="Place Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.name}
            touched={touched.name}
          />
        </Column>

        <Column size={4}>
          <Input
            label="Listing Status"
            value={values.listing_status}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Street Address"
            name="address_line_1"
            value={values.address_line_1}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.address_line_1}
            touched={touched.address_line_1}
            required
          />
        </Column>
        <Column size={4}>
          <Input label="Claim Status" value={values.claim_status} disabled />
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={8}>
              <Input
                label="City / Suburb"
                name="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.city}
                touched={touched.city}
                required
              />
            </Column>
            <Column size={4}>
              <Input
                label="Postcode / Pin"
                name="post_code"
                value={values.post_code}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.post_code}
                touched={touched.post_code}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="State"
            name="state"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.state}
            touched={touched.state}
          />
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={8}>
              <Input
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.country}
                touched={touched.country}
                required
              />
            </Column>
            <Column size={4}>
              <Select
                label="TimeZone"
                options={TIME_ZONE_OPTIONS}
                value={[
                  {
                    value: values.timezone,
                    label:
                      timeZoneLabel && timeZoneLabel.length !== 0
                        ? timeZoneLabel.label
                        : 'AEST',
                  },
                ]}
                onChange={({ value }) => setFieldValue('timezone', value)}
                required
                errors={errors.timezone}
                touched={touched.timezone}
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Column.Group vcentered gapSize={2}>
            <Column size={6}>
              <Input
                label="Latitude"
                name="latitude"
                value={values.latitude}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.latitude}
                touched={touched.latitude}
                required
              />
            </Column>
            <Column size={6}>
              <Input
                label="Longitude"
                name="longitude"
                value={values.longitude}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.longitude}
                touched={touched.longitude}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="Additional Address Information"
            name="address_line_2"
            value={values.address_line_2}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        <Column size="half">
          <Input
            label="Relationship to the place"
            name="relationShip"
            value={values.relationShip}
            onChange={handleChange}
            onBlur={handleBlur}
            required
          />
        </Column>
        <Column size="half">
          <Input
            label="Primary Contact Email"
            name="primaryEmail"
            value={values.primaryEmail}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Primary Contact Phone"
            name="primaryMobile"
            value={values.primaryMobile}
            onChange={value => setFieldValue('primaryMobile', value)}
            onBlur={handleBlur}
          />
        </Column>
        <Column size="half">
          <Input
            label="Business Website"
            name="businessSite"
            value={values.businessSite}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.businessSite}
            touched={touched.businessSite}
          />
        </Column>
        <Column size="half">
          <Input
            label="Business Facebook Page"
            name="facebookPage"
            value={values.facebookPage}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Column>
        <Column size="full">
          <TextArea
            label="Place Description"
            placeholder="Business description"
            name="description"
            optionalText="(Min 10 and Max 8000 Characters)"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.description}
            touched={touched.description}
          />
          {values.description.length > 8000 || values.description.length < 10
            ? ''
            : `Characters left ${8000 - values.description.length}`}
        </Column>
      </Column.Group>
      <Divider />

      <ListingAudit
        values={values}
        approvedBy={values.listing_audit.listing_approved_by}
        approvedAt={values.listing_audit.listing_approved_at}
      />

      {/* <div className="label">List By Details</div>
    <Column.Group multiline vcentered gapSize={8}>
      <Column size="half">
        <Input label="List By Name" disabled />
      </Column>
      <Column size="half">
        <Input label="List By Contact Phone" disabled />
      </Column>
      <Column size="half">
        <Input label="List By Contact Email" disabled />
      </Column>
      <Column size="half">
        <Input label="List By User Role" disabled />
      </Column>
    </Column.Group>
    <fieldset className="box">
      <legend className="label">List By User Login Details</legend>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input label="User Email" disabled />
        </Column>
        <Column size="half">
          <Input label="User Name" disabled />
        </Column>
        <Column size="half">
          <Input label="Listed Date & Time" disabled />
        </Column>
        <Column size="half">
          <Input label="User Phone" disabled />
        </Column>
      </Column.Group>
    </fieldset> */}
      {/* <Column.Group multiline vcentered gapSize={8}>
      <Column size="half">
        <Input label="Listing Approval By User Login (Email)" disabled />
      </Column>
      <Column size="half">
        <Input label="Listing Approval Date And Time" disabled />
      </Column>
    </Column.Group> */}
    </Container>
  );
};

export default Details;
