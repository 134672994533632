import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { forEach } from 'lodash';
import { TagInputs } from '../../../components/elements';
import PlaceHours from '../AddPlace/PlaceHours';

import {
  getGoodFor,
  getCuisines,
  getDiningStyle,
  getFeatures,
  getServiceCategories,
  getPlaceTypes,
} from '../../../utils/fetchStatic';

import {
  parseTagInputsData,
  parseTagInputsInput,
} from '../../../utils/helpers';

const Container = styled.div``;

const Details = ({ values, touched, errors, setFieldValue }) => {
  const [data, setData] = useState([
    {
      businessTypes: [],
      serviceCategories: [],
      features: [],
      diningStyle: [],
      cuisines: [],
      goodFor: [],
    },
  ]);

  const parseData = parseValue => {
    const final = [];
    forEach(parseValue, item => {
      final.push({ value: item.name, label: item.description });
    });
    return final;
  };

  const parseInput = parseValue => {
    const final = [];
    forEach(parseValue, (item, index) => {
      final.push({
        name: item.value,
        description: item.label,
        display_order: (index + 1).toString(),
      });
    });
    return final;
  };

  useEffect(() => {
    const getData = async () => {
      // const serviceCategories = await getServiceCategories();
      const businessTypes = await getPlaceTypes();
      const serviceCategories = await getServiceCategories();

      const features = await getFeatures();
      const diningStyle = await getDiningStyle();
      const cuisines = await getCuisines();
      const goodFor = await getGoodFor();

      setData({
        serviceCategories,
        businessTypes,
        features,
        diningStyle,
        cuisines,
        goodFor,
      });
    };
    getData();
  }, []);

  return (
    <Container>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <TagInputs
            label="Business Type"
            optionalText="(Upto 2 Business Types)"
            value={parseData(values.business_type)}
            suggestions={
              values.business_type.length === 2
                ? []
                : parseData(data.businessTypes)
            }
            onChange={value =>
              setFieldValue('business_type', parseInput(value))
            }
            errors={errors.business_type}
            touched={touched.business_type}
            required
          />
        </Column>
        <Column size="half">
          <TagInputs
            label="Service Category"
            suggestions={parseData(data.serviceCategories)}
            value={parseData(values.service_category)}
            onChange={value =>
              setFieldValue('service_category', parseInput(value))
            }
            errors={errors.service_category}
            touched={touched.service_category}
            required
          />
        </Column>
        <Column size="half">
          <TagInputs
            label="Place Features"
            suggestions={parseTagInputsData(data.features)}
            value={parseTagInputsData(values.feature)}
            onChange={value =>
              setFieldValue('feature', parseTagInputsInput(value))
            }
          />
        </Column>
        <Column size="half">
          <TagInputs
            label="Cuisine Style"
            suggestions={parseTagInputsData(data.cuisines)}
            value={parseTagInputsData(values.cuisine_style)}
            onChange={value =>
              setFieldValue('cuisine_style', parseTagInputsInput(value))
            }
          />
        </Column>
        <Column size="half">
          <TagInputs
            label="Good For"
            suggestions={parseTagInputsData(data.goodFor)}
            value={parseTagInputsData(values.good_for)}
            onChange={value =>
              setFieldValue('good_for', parseTagInputsInput(value))
            }
          />
        </Column>
        <Column size="half">
          <TagInputs
            label="Dinning Style"
            suggestions={parseTagInputsData(data.diningStyle)}
            value={parseTagInputsData(values.dining_style)}
            onChange={value =>
              setFieldValue('dining_style', parseTagInputsInput(value))
            }
          />
        </Column>
      </Column.Group>
      <div className="is-size-2">Place Hours</div>
      <PlaceHours
        values={values}
        touched={touched}
        errors={errors}
        setFieldValue={setFieldValue}
      />
    </Container>
  );
};

export default Details;
