import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isNull } from 'lodash';

import Details from './Details';
import ReviewPhotos from './ReviewPhotos';
import ReviewFlags from './ReviewFlags';
import ReviewComments from './ReviewComments';
import ReviewLikes from './ItemReviewLikes';
import SocialCounts from './SocialCounts';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;
const tabs = [
  {
    key: 0,
    name: 'Item Review Detail',
  },
  {
    key: 1,
    name: 'Review Photos',
  },
  {
    key: 2,
    name: 'Review Likes',
  },
  {
    key: 3,
    name: 'Review Comments',
  },
  {
    key: 4,
    name: 'Review Flags',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    handleSubmit,
    loading,
    values,
    action,
    handleAction,
    place,
    review,
    images,
    dirty,
    updateItemReviewLoading,
  } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 4 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  const handleApprovedAction = async () => {
    await handleAction('APPROVED');
    handleSubmit();
  };
  const handleRejectAction = async () => {
    await handleAction('REJECTED');
    handleSubmit();
  };
  const handleUpdateReview = async () => {
    await handleAction('ITEM_UPDATE');
    handleSubmit();
  };

  console.log('listingStatus', place.listing_status);

  return (
    <React.Fragment>
      <Header
        heading="Item Review Approval"
        prev={prev}
        next={next}
        dirty={dirty}
        loading={loading}
        action={action}
        status={values.status}
        itemName={values.item_name}
        updateItemReviewLoading={updateItemReviewLoading}
        handleUpdateReview={handleUpdateReview}
        handleApprovedAction={handleApprovedAction}
        handleRejectAction={handleRejectAction}
        placeListingStatus={place.listing_status}
        subHeading={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${place.claim_status} / ${review.status})`}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {review.status !== 'SUBMITTED' && activeTab !== 0 && (
            <SocialCounts {...props} />
          )}

          {activeTab === 0 && <Details {...props} images={images} />}
          {activeTab === 1 && <ReviewPhotos {...props} />}

          {activeTab === 2 && <ReviewLikes {...props} />}
          {activeTab === 3 && <ReviewComments {...props} reivew={review} />}
          {activeTab === 4 && <ReviewFlags {...props} />}
        </Container>
      </Box>
      <hr />

      <Footer
        prev={prev}
        next={next}
        dirty={dirty}
        action={action}
        loading={loading}
        placeListingStatus={place.listing_status}
        status={values.status}
        handleApprovedAction={handleApprovedAction}
        handleRejectAction={handleRejectAction}
      />
    </React.Fragment>
  );
};

const ItemReviewForm = withFormik({
  mapPropsToValues: ({ review, images }) => ({
    item_name: review && !isNull(review.item_name) ? review.item_name : '',
    status: review && !isNull(review.status) ? review.status : '',
    cuisine_style:
      review && !isNull(review.cuisine_style) ? review.cuisine_style : '',
    item_type: review && !isNull(review.item_type) ? review.item_type : [],
    rating: review && !isNull(review.rating) ? review.rating : 0,
    content: review && !isNull(review.content) ? review.content : '',
    user_info: review && !isNull(review.user_info) ? review.user_info : {},
    image: review && !isNull(review.image) ? review.image : [],
    note: '',
    allImages: images.length !== 0 ? images : [],
    rejectImages: [],
  }),

  validationSchema: yup.object().shape({
    note: yup.string().required('Note is Required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'ItemReviewForm',
})(Form);

export default ItemReviewForm;
