import React, { useState } from 'react';
import { Column } from 'rbx';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import { filter, first } from 'lodash';
import { Input } from '../../../components/elements';

const searchSocial = gql`
  query searchSocial($input: SearchInput) {
    search_social(input: $input) {
      social_aggregation {
        total_social_count {
          type
          count
        }
      }
    }
  }
`;

const EventSocial = ({ match }) => {
  

  const socialFilter = {
    object_id: match.params.eventId,
    object_type: 'EVENT',
  };

  return (
    <Query
      query={searchSocial}
      variables={{
        input: {
          filter: {
            social_filter: socialFilter,
          },
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return (
            <fieldset className="box">
              <legend className="label">Social</legend>
              <Column.Group vcentered multiline>
                <Column size={3}>
                  <Input label="Event Likes" placeholder="6" disabled />
                </Column>
                <Column size={3}>
                  <Input label="Event Shares" placeholder="60" disabled />
                </Column>
                <Column size={3}>
                  <Input label="Event Bookmarks" placeholder="2" disabled />
                </Column>
                <Column size={3}>
                  <Input label="Page Views" placeholder="60" disabled />
                </Column>
              </Column.Group>
            </fieldset>
          );
        }
        if (error) {
          return 'error';
        }

        const { search_social } = data;
        const { social_aggregation } = search_social;

        const likeArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'LIKE',
              )
            : [];

        const shareArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'SHARE',
              )
            : [];

        const bookmarkArray =
          social_aggregation.total_social_count &&
          social_aggregation.total_social_count.length !== 0
            ? filter(
                social_aggregation.total_social_count,
                item => item.type === 'BOOKMARK',
              )
            : [];

        const bookmarkCount =
          bookmarkArray.length !== 0 ? first(bookmarkArray).count : 0;

        const shareCount =
          shareArray.length !== 0 ? first(shareArray).count : 0;

        const likeCount = likeArray.length !== 0 ? first(likeArray).count : 0;

        return (
          <fieldset className="box">
            <legend className="label">Social</legend>
            <Column.Group vcentered multiline>
              <Column size={3}>
                <Input label="Event Likes" value={likeCount} disabled />
              </Column>
              <Column size={3}>
                <Input label="Event Shares" value={shareCount} disabled />
              </Column>
              <Column size={3}>
                <Input label="Event Bookmarks" value={bookmarkCount} disabled />
              </Column>
              <Column size={3}>
                <Input label="Page Views" placeholder="60" disabled />
              </Column>
            </Column.Group>
          </fieldset>
        );
      }}
    </Query>
  );
};
export default withRouter(EventSocial);
