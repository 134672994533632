import React, { useState, useEffect } from 'react';
import { Table } from 'rbx';

import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import {
  Table as CustomTable,
  Pagination,
  Loading,
  Message,
} from '../../../components/elements';
import FollowingList from './FollowingList';

const searchFollow = gql`
  query searchFollows($input: SearchInput) {
    search_follows(input: $input) {
      total_size
      total_pages
      follow_listing {
        user_id
        object_type
        type
        user {
          user_id
          first_name
          last_name
          display_name
          email
          audit {
            created_at
          }
        }
        audit {
          created_by
          created_at
          updated_at
          updated_by
        }
      }
    }
  }
`;

const Following = ({ match }) => {
  const [userData, setUserData] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();
  const { userId } = match.params;
  const followFilter = {
    user_id: userId,
    object_type: 'USER',
    type: 'FOLLOW',
  };
  return (
    <React.Fragment>
      {totalElements > 0 && (
        <Pagination
          pageFrom={pageFrom}
          pageCount={pageCount}
          listSize={listSize}
          currentPage={currentPage}
          totalElements={totalElements}
          handlePageClick={value => {
            setPageFrom(value.selected * listSize);
            setCurrentPage(value.selected + 1);
          }}
        />
      )}
      <CustomTable>
        <Table.Head>
          <Table.Row>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Display Name
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> User Login
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
            <Table.Heading>
              <div>
                <span>
                  <i className="fas fa-sort-amount-down" /> Followed On
                </span>
                {/* <br />
              <CustomInput /> */}
              </div>
            </Table.Heading>
          </Table.Row>
        </Table.Head>
        <Query
          query={searchFollow}
          variables={{ input: { filter: { follow_filter: followFilter } } }}
          fetchPolicy="network-only"
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>{error.message}</Message>;
            }
            const { search_follows } = data;
            return (
              <React.Fragment>
                <View
                  userData={userData}
                  setUserData={setUserData}
                  search_follows={search_follows}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              </React.Fragment>
            );
          }}
        </Query>
      </CustomTable>
    </React.Fragment>
  );
};
export default withRouter(Following);

const View = ({
  search_follows,
  setPageCount,
  setTotalElements,
  setUserData,
  userData,
}) => {
  useEffect(() => {
    setPageCount(search_follows.total_pages);
    setTotalElements(search_follows.total_size);
    if (search_follows.follow_listing) {
      setUserData(search_follows.follow_listing);
    }
  }, [search_follows.follow_listing]);
  return (
    <tbody>
      {userData.map(social => (
        <FollowingList social={social} />
      ))}
    </tbody>
  );
};
