import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Input, CheckBox, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  status,
  setStatus,
  loginSearchInput,
  setLoginSearchInput,
  firstNameSearchInput,
  setFirstNameSearchInput,
  lastNameSearchInput,
  setLastNameSearchInput,
  displayNameSearchInput,
  setDisplayNameSearchInput,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <TitleWrapper>
            <Title className="label">Status</Title>
          </TitleWrapper>
        </Level.Item>
        <Level.Item>
          <div>
            <Radio
              label="Active"
              value={status === 'active'}
              onChange={() => {
                setStatus('active');
              }}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Radio
              label="Registered"
              value={status === 'Registered'}
              onChange={() => {
                setStatus('Registered');
              }}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Radio
              label="Locked"
              value={status === 'Locked'}
              onChange={() => {
                setStatus('Locked');
              }}
            />
          </div>
        </Level.Item>
        <Level.Item>
          <div>
            <Radio
              label="Inactive"
              value={status === 'Inactive'}
              onChange={() => {
                setStatus('Inactive');
              }}
            />
          </div>
        </Level.Item>
      </Level.Item>
    </Level>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-3">
          <Input
            label="Login"
            type="text"
            value={loginSearchInput}
            onChange={user => setLoginSearchInput(user.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
        <div className="column is-3">
          <Input
            label="First Name"
            type="text"
            value={firstNameSearchInput}
            onChange={user => setFirstNameSearchInput(user.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
        <div className="column is-3">
          <Input
            label="Last Name"
            type="text"
            value={lastNameSearchInput}
            onChange={user => setLastNameSearchInput(user.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
        <div className="column is-3">
          <Input
            label="Display Name"
            type="text"
            value={displayNameSearchInput}
            onChange={user => setDisplayNameSearchInput(user.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default PlaceSearch;
