import React from 'react';
import styled from 'styled-components';
import { Title, Level } from 'rbx';
import { Input, Radio } from '../../../components/elements';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;
const BoxWrapper = styled.div`
  &&& {
    background: #f6f6f6;
  }
`;

const BookingSummarySearch = ({
  purchasedBySearchInput,
  setPurchasedBySearchInput,
  status,
  setStatus,
  bookingNumberSearchQuery,
  setBookingNumberSearchQuery,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <TitleWrapper>
            <Title className="label">Status</Title>
          </TitleWrapper>
        </Level.Item>
        <Level.Item>
          <Radio
            label="Active"
            value={status === 'ACTIVE'}
            onChange={() => {
              setStatus('ACTIVE');
            }}
          />
        </Level.Item>
        <Level.Item>
          <Radio
            label="Inactive"
            value={status === 'INACTIVE'}
            onChange={() => {
              setStatus('INACTIVE');
            }}
          />
        </Level.Item>
      </Level.Item>
    </Level>

    <BoxWrapper className="box">
      <div className="columns">
        <div className="column column is-5">
          <Input
            label="Booking Number"
            name="searchQuery"
            value={bookingNumberSearchQuery}
            onChange={booking =>
              setBookingNumberSearchQuery(booking.target.value)
            }
            placeholder="Enter Booking no...."
          />
        </div>
        <div className="column column is-5">
          <Input
            label="Purchased By"
            type="text"
            value={purchasedBySearchInput}
            onChange={purchased =>
              setPurchasedBySearchInput(purchased.target.value)
            }
            placeholder="Start entering the Purchaser name..."
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default BookingSummarySearch;
