import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull } from 'lodash';
import moment from 'moment';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import VoucherDetails from './VoucherDetails';
import DiscountDetails from './DiscountDetails';
import VoucherImages from './VoucherImages';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Voucher Details',
  },
  {
    key: 1,
    name: 'Discount Details',
  },
  {
    key: 2,
    name: 'Voucher Images',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, place, values, loading, userId } = props;
  console.log('lskdjfks', userId);
  const prev = () =>
    activeTab === 0 ? setActiveTab(2) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 2 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  return (
    <React.Fragment>
      <Header
        heading="Add a place deal"
        placeName={`${place.name} | ${place.city}, ${place.post_code} | ${place.country}`}
        dealName={values.name}
        handleSubmit={handleSubmit}
        loading={loading}
        prev={prev}
        next={next}
      />

      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          {activeTab === 0 && <VoucherDetails {...props} />}
          {activeTab === 1 && <DiscountDetails {...props} />}
          {activeTab === 2 && <VoucherImages {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer
        loading={false}
        handleSubmit={handleSubmit}
        prev={prev}
        next={next}
      />
    </React.Fragment>
  );
};

const ClaimPlace = withFormik({
  mapPropsToValues: ({ place, deal }) => {
    const startDate = deal && !isNull(deal.start) ? moment(deal.start) : null;
    const endDate = deal && !isNull(deal.end) ? moment(deal.end) : null;
    return {
      deal_id: deal ? deal.deal_id : uuidv4(),
      menu_id: deal ? deal.menu_id : '',
      place_id: deal ? deal.place_id : place.place_id,
      deal_type: deal ? deal.deal_type : 'Deal Listing Only',
      name: deal ? deal.name : '',
      voucher_code: deal ? deal.voucher_code : '',
      status: deal ? deal.status : 'ACTIVE',
      approval_status: deal ? deal.approval_status : 'APPROVED',
      tagline: deal ? deal.tagline : '',
      valid_for_new_user: deal ? deal.valid_for_new_user : false,
      description: deal ? deal.description : '',
      startDate: deal ? moment(startDate).toISOString() : null,
      startTime: deal ? moment(startDate).toISOString() : null,
      endDate: deal ? moment(endDate).toISOString() : null,
      endTime: deal ? moment(endDate).toISOString() : null,
      menu_item: deal ? deal.menu_item : [],
      minimum_purchase_amount: deal ? deal.minimum_purchase_amount : 0,
      maximum_discount_amount: deal ? deal.maximum_discount_amount : 0,
      maximum_use_per_user: deal ? deal.maximum_use_per_user : 0,
      maximum_usage: deal ? deal.maximum_usage : 0,
      // price: deal ? deal.price : 0,
      // percentage: deal ? deal.percentage : 0,
      can_be_clubbed: deal ? deal.can_be_clubbed : false,
      is_published: deal ? deal.is_published : true,
      free_delivery: deal ? deal.free_delivery : true,
      // image: deal ? deal.image : [],
      service_type: deal ? deal.service_type : [],
      applicable_city: deal ? deal.applicable_city : [],
      applies_to: deal ? deal.applies_to : [],
    };
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(4, 'At least 4 Characters Required!')
      .max(50, 'Not greater than 50 Characters!')
      .required('Deal Name is required!'),
    voucher_code: yup
      .string()
      .min(5, 'At least 5 Characters Required!')
      .max(10, 'Not greater than 10 Characters!'),
    tagline: yup
      .string()
      .min(10, 'At least 10 Characters Required!')
      .max(50, 'Not greater than 50 Characters!'),
    description: yup
      .string()
      .min(100, 'At least 100 Characters Required!')
      .max(500, 'Not greater than 500 Characters!'),
    startDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('Start date is required'),
    endDate: yup
      .string()
      .transform(v => (v === null ? '' : v))
      .required('End date is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'BasicForm',
})(Form);

export default ClaimPlace;
