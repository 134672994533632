import React from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import { Input } from '../../components/elements';

const SubHeadingWrapper = styled.div`
  padding-bottom: 2rem;
  padding-top: 0.5rem;
`;
const SubHeading = styled.h2`
  text-align: start;
  font-size: 1.5rem;
`;
const P = styled.p`
  text-align: start;
  font-size: 0.8rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 1rem;
`;

const Icon = styled.span`
  right: 6px;
  position: relative;
  top: 30px;
  z-index: 100;
`;

const Form = ({
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  errors,
  touched,
  handleSubmit,
  isError,
  isLoading,
}) => (
  <form
    onSubmit={event => {
      event.preventDefault();
      handleSubmit();
    }}
  >
    <SubHeadingWrapper>
      <SubHeading className=" has-text-weight-semibold">
        {values.step === 2 ? 'Welcome back' : 'Log In'}
      </SubHeading>
      {values.step === 2 && <P>Please enter your password to login</P>}
    </SubHeadingWrapper>
    {isError && <P className="help is-danger">{isError.message}</P>}
    <Input
      label="Email Address"
      name="email"
      value={values.email}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={values.step === 2}
    >
      {values.step === 2 && (
        <a
          onClick={() => {
            setFieldValue('step', 1);
            setFieldValue('password', '');
          }}
        >
          <Icon className="icon is-small is-right">
            <i className="fas fa-pencil-alt" />
          </Icon>
        </a>
      )}
    </Input>
    {errors.email && touched.email && (
      <P className="help is-danger">{errors.email}</P>
    )}
    {values.step === 2 && (
      <Input
        label="Password"
        name="password"
        type="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    )}
    <ButtonWrapper>
      <button
        className={`button is-primary is-fullwidth ${isLoading &&
          'is-loading'}`}
        type="submit"
      >
        Next
      </button>
    </ButtonWrapper>
  </form>
);

const LoginForm = withFormik({
  mapPropsToValues: () => ({ email: '', password: '', step: 1 }),

  validate: values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Please enter valid email!';
    }
    if (values.step === 2 && !values.password) {
      errors.name = 'Email is required';
    }

    return errors;
  },

  handleSubmit: (values, { setFieldValue, props }) => {
    console.log('props', props);
    if (values.password === '') {
      setFieldValue('step', 2);
    } else {
      props.onSubmit(values);
    }
  },

  displayName: 'BasicForm',
})(Form);

export default LoginForm;
