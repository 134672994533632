import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { first, isNull } from 'lodash';

// import moment from 'moment';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import UserDetails from './UserDetails';
import UserAddress from './UserAddress';
import Following from './Following';
import Followers from './Followers';
import PlaceFollowed from './PlaceFollowed';
import Images from './Images';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'User',
  },
  {
    key: 1,
    name: 'User Address',
  },
  {
    key: 2,
    name: 'Following',
  },
  {
    key: 3,
    name: 'Followers',
  },
  {
    key: 4,
    name: 'Place Followed',
  },
  {
    key: 5,
    name: 'User Image',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, values, loading } = props;
  console.log('value', values);
  return (
    <React.Fragment>
      <Header
        activeTab={activeTab}
        heading="User"
        handleSubmit={handleSubmit}
        loading={loading}
        userName={`${values.first_name} (${values.email})`}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          {activeTab === 0 && <UserDetails {...props} />}
          {activeTab === 1 && <UserAddress {...props} />}
          {activeTab === 2 && <Following {...props} />}
          {activeTab === 3 && <Followers {...props} />}
          {activeTab === 4 && <PlaceFollowed {...props} values={values} />}
          {activeTab === 5 && <Images />}
        </Container>
      </Box>
      <hr />
      <Footer loading={false} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
};

const UserForm = withFormik({
  mapPropsToValues: ({ user }) => {
    const filterPrimaryContact =
      user && !isNull(user.contact)
        ? user.contact.filter(item => item.is_primary === true)
        : [];
    const primaryContact =
      filterPrimaryContact.length === 0
        ? ''
        : first(filterPrimaryContact).value;
    const removePrimaryContact =
      user && !isNull(user.contact)
        ? user.contact.filter(item => item.is_primary !== true)
        : [];
    const address =
      user && !isNull(user.address) && user.address.length !== 0
        ? user.address.map(item => {
            const custom = {
              id: uuidv4(),
              ...item,
            };
            return custom;
          })
        : [];
    const contact =
      user &&
      removePrimaryContact.map(item => {
        const custom = {
          id: uuidv4(),
          ...item,
        };
        return custom;
      });

    return {
      primaryContact,
      user_id: user && !isNull(user.user_id) ? user.user_id : uuidv4(),
      object_id: user && !isNull(user.object_id) ? user.object_id : uuidv4(),
      first_name: user && !isNull(user.first_name) ? user.first_name : '',
      last_name: user && !isNull(user.last_name) ? user.last_name : '',
      display_name: user && !isNull(user.display_name) ? user.display_name : '',
      preferred_username:
        user && !isNull(user.preferred_username) ? user.preferred_username : '',
      email: user && !isNull(user.email) ? user.email : '',
      date_of_birth:
        user && !isNull(user.date_of_birth) ? user.date_of_birth : null,
      gender: user && !isNull(user.gender) ? user.gender : 'MALE',
      description: user && !isNull(user.description) ? user.description : '',
      status: user && !isNull(user.status) ? user.status : 'ACTIVE',
      visibility: user && !isNull(user.visibility) ? user.visibility : '',
      admin: user && !isNull(user.admin) ? user.admin : false,
      rating: user && !isNull(user.rating) ? user.rating : '',
      contact: user && !isNull(user.date_of_birth) ? contact : [],
      social: user && !isNull(user.social) ? user.social : [],
      user_handle: user && !isNull(user.user_handle) ? user.user_handle : '',
      profile_image:
        user && !isNull(user.profile_image) ? user.profile_image : [],
      address: user
        ? address
        : [
            {
              id: uuidv4(),
              type: '',
              is_default: false,
              contact_name: '',
              address_line_1: '',
              address_line_2: '',
              city: '',
              state: '',
              country: '',
              post_code: '',
              timezone: 'AEST',
              latitude: '',
              longitude: '',
            },
          ],
    };
  },

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'UserForm',
})(Form);

export default UserForm;
