import React from 'react';
import styled from 'styled-components';
import qs from 'querystringify';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import 'react-accessible-accordion/dist/fancy-example.css';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

const Wrapper = styled.div`
  .right-span {
    padding-left: 0.6rem;
  }
  .left-span {
    padding-right: 1.1rem;
  }
  .accordion__panel {
    padding: 0rem;
  }
  .sidebar {
    :focus {
      outline: none;
    }
  }
  .accordion {
    border: 0;
  }
  .sidebar-button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #e0e0e0;
    padding: 0.9rem 1rem;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    color: #505050;
    @media screen and (max-width: 1023px) {
      font-size: 0.7rem;
    }
    @media screen and (min-width: 1024px) {
      font-size: 0.6rem;
    }
    @media screen and (min-width: 1216px) {
      font-size: 0.7rem;
    }
    @media screen and (min-width: 1408px) {
      font-size: 0.8rem;
    }
    white-space: nowrap;
    :hover {
      background: #d0d0d0;
    }
    :focus {
      outline: none;
    }
  }
`;

const SideNavItemStyle = styled.div`
  && {
    /* background: #e0e0e0; */
    border: 0px;
    padding: 0.6rem 1rem;
    font-weight: 600;
    white-space: nowrap;
    a {
      color: #505050;
      @media screen and (max-width: 1023px) {
        font-size: 0.6rem;
      }
      @media screen and (min-width: 1024px) {
        font-size: 0.5rem;
      }
      @media screen and (min-width: 1216px) {
        font-size: 0.6rem;
      }
      @media screen and (min-width: 1408px) {
        font-size: 0.7rem;
      }
    }
    :hover {
      background: #e8e8e8;
    }
  }
`;

const SideNavItem = ({ title, active, onClick }) => (
  <SideNavItemStyle onClick={onClick}>
    <a className={active ? 'has-text-primary	' : ''}>
      <span className="icon is-small left-span">
        <i className="far fa-dot-circle" />
      </span>
      {title}
    </a>
  </SideNavItemStyle>
);

const SideNav = ({ history }) => {
  const route = useStoreState(state => state.global.currentRoute);
  const updateCurrentRoute = useStoreActions(
    state => state.global.updateCurrentRoute,
  );
  const preExpanded = route.main;
  const preSubExpanded = route.sub;
  return (
    <Wrapper>
      <Accordion preExpanded={[preExpanded]}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton className="sidebar">
              <div
                className="sidebar-button"
                onClick={() => {
                  history.push('/');
                  updateCurrentRoute({ main: 'dashboard', sub: '' });
                }}
              >
                <div>
                  <span className="icon is-small left-span">
                    <i className="fa fa-desktop" />
                  </span>
                  Dashboard
                </div>
                <span className="icon is-small right-span ">
                  <i className="fa fa-angle-right" />
                </span>
              </div>
            </AccordionItemButton>
          </AccordionItemHeading>
        </AccordionItem>
        <AccordionItem uuid="place">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'place', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-map-marker" />
                </span>
                Place Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'place'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Add/ Edit Place"
              active={preSubExpanded === 'addEditPlace'}
              onClick={() => {
                history.push(
                  `/places/${qs.stringify(
                    {
                      listing_status: 'APPROVED',
                      item_review_place: false,
                      event_business: false,
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'addEditPlace' });
              }}
            />
            <SideNavItem
              title="List your place approval"
              active={preSubExpanded === 'listPlaceApproval'}
              onClick={() => {
                history.push(
                  `/place-approval/${qs.stringify(
                    {
                      listing_status: 'UNLISTED',
                      item_review_place: false,
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'listPlaceApproval' });
              }}
            />
            <SideNavItem
              title="Claim your Place Approvals"
              active={preSubExpanded === 'placeClaim'}
              onClick={() => {
                history.push(
                  `/place-claims/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'placeClaim' });
              }}
            />
            <SideNavItem
              title="Place Posting"
              active={preSubExpanded === 'placePosting'}
              onClick={() => {
                history.push(
                  `/place-postings${qs.stringify(
                    {
                      status: 'ACTIVE',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({ main: 'place', sub: 'placePosting' });
              }}
            />

            <SideNavItem
              title="Place Activity"
              active={preSubExpanded === 'placeActivities'}
              onClick={() => {
                history.push('/place-activities');
                updateCurrentRoute({ main: 'place', sub: 'placeActivities' });
              }}
            />

            <SideNavItem
              title="Item Activity"
              active={preSubExpanded === 'itemActivities'}
              onClick={() => {
                history.push('/item-activities');
                updateCurrentRoute({ main: 'place', sub: 'itemActivities' });
              }}
            />

            <SideNavItem
              title="Company Details"
              active={preSubExpanded === 'companyDetails'}
              onClick={() => {
                history.push('/companies');
                updateCurrentRoute({ main: 'place', sub: 'companyDetails' });
              }}
            />

            {/* <SideNavItem
              title=" Place and Menu Deals"
              active={preSubExpanded === 'placeDeals'}
              onClick={() => {
                history.push('/place-deals');
                updateCurrentRoute({ main: 'place', sub: 'placeDeals' });
              }}
            /> */}
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="menu">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'menu', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-utensils" />
                </span>
                Menu Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'event'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Add / Edit Global Menu Type"
              active={preSubExpanded === 'defineMenu'}
              onClick={() => {
                history.push('/global-menus');
                updateCurrentRoute({ main: 'menu', sub: 'defineMenu' });
              }}
            />
            <SideNavItem
              title="Add / Edit Menu's"
              active={preSubExpanded === 'addMenu'}
              onClick={() => {
                history.push('/menus');
                updateCurrentRoute({ main: 'menu', sub: 'addMenu' });
              }}
            />
            <SideNavItem
              title="Manage menu's"
              active={preSubExpanded === 'manageMenu'}
              onClick={() => {
                history.push('/manage-menus');
                updateCurrentRoute({ main: 'menu', sub: 'manageMenu' });
              }}
            />
            <SideNavItem
              title="Manage Menu Sections "
              active={preSubExpanded === 'manageMenuSections'}
              onClick={() => {
                history.push('/manage-menu-sections');
                updateCurrentRoute({ main: 'menu', sub: 'manageMenuSections' });
              }}
            />
            <SideNavItem
              title="Manage Menu Items "
              active={preSubExpanded === 'manageMenuItems'}
              onClick={() => {
                history.push('/manage-menu-Items');
                updateCurrentRoute({ main: 'menu', sub: 'manageMenuItems' });
              }}
            />

            <SideNavItem
              title="Manage Modifier Group"
              active={preSubExpanded === 'manageModifierGroup'}
              onClick={() => {
                history.push('/manage-modifier-groups');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'manageModifierGroup',
                });
              }}
            />

            <SideNavItem
              title="Manage Combo Deals"
              active={preSubExpanded === 'manageComboDeal'}
              onClick={() => {
                history.push('/manage-combo-deals');
                updateCurrentRoute({
                  main: 'menu',
                  sub: 'manageComboDeal',
                });
              }}
            />

            <SideNavItem
              title="Place and Menu Deals"
              active={preSubExpanded === 'manageMenuDeals'}
              onClick={() => {
                history.push('/place-deals');
                updateCurrentRoute({ main: 'menu', sub: 'manageMenuDeals' });
              }}
            />

            <SideNavItem
              title="Holiday Hours"
              active={preSubExpanded === 'holidayHours'}
              onClick={() => {
                history.push('/holiday-hours');
                updateCurrentRoute({ main: 'menu', sub: 'holidayHours' });
              }}
            />
            <SideNavItem
              title="Delivery Zones"
              active={preSubExpanded === 'deliveryZones'}
              onClick={() => {
                history.push('/delivery-zones');
                updateCurrentRoute({ main: 'menu', sub: 'deliveryZones' });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="event">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'event', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-calendar" />
                </span>
                Event Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'event'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Add/ Edit Events"
              active={preSubExpanded === 'addEditEvent'}
              onClick={() => {
                history.push('/events');
                updateCurrentRoute({ main: 'event', sub: 'addEditEvent' });
              }}
            />
            <SideNavItem
              title="Manage Events "
              active={preSubExpanded === 'manageEvents'}
              onClick={() => {
                history.push('/manage-events');
                updateCurrentRoute({ main: 'event', sub: 'manageEvents' });
              }}
            />
            <SideNavItem
              title="Event Activity"
              active={preSubExpanded === 'eventActivities'}
              onClick={() => {
                history.push('/event-activities');
                updateCurrentRoute({ main: 'event', sub: 'eventActivities' });
              }}
            />
            <SideNavItem
              title="Manage Event Deals"
              active={preSubExpanded === 'eventDeals'}
              onClick={() => {
                history.push('/event-deals');
                updateCurrentRoute({ main: 'event', sub: 'eventDeals' });
              }}
            />
            <SideNavItem
              title="Email Invitation"
              active={preSubExpanded === 'emailInvitations'}
              onClick={() => {
                history.push('/email-invitations');
                updateCurrentRoute({ main: 'event', sub: 'emailInvitations' });
              }}
            />
            <SideNavItem
              title="Sell Tickets"
              active={preSubExpanded === 'sellTickets'}
              onClick={() => {
                history.push('/select-events-for-sell-tickets');
                updateCurrentRoute({ main: 'event', sub: 'sellTickets' });
              }}
            />
            <SideNavItem
              title="Check In Attendees"
              active={preSubExpanded === 'checkInAttendeeEvents'}
              onClick={() => {
                history.push('/check-in-attendee-events');
                updateCurrentRoute({
                  main: 'event',
                  sub: 'checkInAttendeeEvents',
                });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="review">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'review', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-external-link-alt" />
                </span>
                Review Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'review'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Place Reviews"
              active={preSubExpanded === 'placeReviews'}
              onClick={() => {
                history.push(
                  `/place-reviews/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'placeReviews',
                });
              }}
            />

            <SideNavItem
              title="Item Dictionary"
              active={preSubExpanded === 'itemDictionaries'}
              onClick={() => {
                history.push('/item-dictionaries');
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemDictionaries',
                });
              }}
            />

            <SideNavItem
              title="Item Reviews"
              active={preSubExpanded === 'itemReviews'}
              onClick={() => {
                history.push(
                  `/item-reviews${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemReviews',
                });
              }}
            />

            <SideNavItem
              title="Flagged Reviews"
              active={preSubExpanded === 'flaggedReviews'}
              onClick={() => {
                history.push(
                  `/flagged-reviews/${qs.stringify(
                    {
                      status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'flaggedReviews',
                });
              }}
            />

            <SideNavItem
              title="Place Photo Approvals"
              active={preSubExpanded === 'photoApprovals'}
              onClick={() => {
                history.push(
                  `/place-photo-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'photoApprovals',
                });
              }}
            />

            <SideNavItem
              title="Item Photo Approvals"
              active={preSubExpanded === 'itemPhotoApprovals'}
              onClick={() => {
                history.push(
                  `/item-photo-approvals${qs.stringify(
                    {
                      approval_status: 'SUBMITTED',
                    },
                    true,
                  )}`,
                );
                updateCurrentRoute({
                  main: 'review',
                  sub: 'itemPhotoApprovals',
                });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="fee">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'fee', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fas fa-hand-holding-usd" />
                </span>
                Fee Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'event'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Place Event Override Fee"
              active={preSubExpanded === 'placeEventOvrdFee'}
              onClick={() => {
                history.push('/event-fee-override');
                updateCurrentRoute({ main: 'fee', sub: 'placeEventOvrdFee' });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem uuid="users">
          <AccordionItemHeading
            onClick={() => {
              updateCurrentRoute({ main: 'users', sub: '' });
            }}
          >
            <AccordionItemButton className="sidebar-button">
              <div>
                <span className="icon is-small left-span">
                  <i className="fa fa-user" />
                </span>
                User Management
              </div>
              <span className="icon is-small right-span ">
                <i
                  className={
                    preExpanded === 'users'
                      ? 'fa fa-angle-down'
                      : 'fa fa-angle-right'
                  }
                />
              </span>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <SideNavItem
              title="Users"
              active={preSubExpanded === 'allUsers'}
              onClick={() => {
                history.push('/users');
                updateCurrentRoute({ main: 'users', sub: 'allUsers' });
              }}
            />
            <SideNavItem
              title="User Activity"
              active={preSubExpanded === 'userActivity'}
              onClick={() => {
                history.push('/user-activities');
                updateCurrentRoute({ main: 'users', sub: 'userActivity' });
              }}
            />
            <SideNavItem
              title="Admin Users"
              active={preSubExpanded === 'adminUsers'}
              onClick={() => {
                history.push('/admin-users');
                updateCurrentRoute({ main: 'users', sub: 'adminUsers' });
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </Wrapper>
  );
};

export default withRouter(SideNav);
