import React, { useEffect, useState } from 'react';

import { Input, TagInputs, Select } from '../../../components/elements';
import { getUserRoles } from '../../../utils/fetchStatic';

const PlaceDetails = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const roles = await getUserRoles();
      setUserRoles(roles);
      console.log(roles);
    };
    getData();
  }, []);

  return (
    <div>
      <div className="columns is-multiline">
        <div className="column is-half">
          <Input label="Login" required disabled />
        </div>
        <div className="column is-half">
          <Input label="Status" required placeholder="ACTIVE" disabled />
        </div>
        <div className="column is-half">
          <Input label="Name" required placeholder="David Jones" disabled />
        </div>
        <div className="column is-half">
          <Input label="Contact Email" placeholder="Email" />
        </div>
        <div className="column is-half">
          <Input label="Contact Phone" />
        </div>

        <div className="column is-half">
          <Input
            label="Display Name"
            required
            placeholder="David Jones"
            disabled
          />
        </div>
        <div className="column is-half">
          <Select label="Role" required />
        </div>
        <div className="column is-half">
          <TagInputs label="Place Names" />
        </div>
        <div className="column is-half">
          <TagInputs label="Company Names" />
        </div>
        <div className="column is-half">
          <TagInputs label="Event Names" />
        </div>

        <div className="column is-half">
          <TagInputs label="Countries" />
        </div>
        <div className="column is-half">
          <TagInputs label="States" />
        </div>
        <div className="column is-half">
          <Input label="Created/Modified By" disabled />
        </div>
        <div className="column is-half">
          <Input label="Created/Modified At" disabled />
        </div>
      </div>
    </div>
  );
};

export default PlaceDetails;
