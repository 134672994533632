import React, { useState } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { isNaN } from 'lodash';

import {
  Radio,
  Input,
  CheckBox,
  InputErrorMessage,
} from '../../../components/elements';

const RadioWrapper = styled.div`
  padding: 0rem 0.5rem;
`;
const Wrapper = styled.div`
  padding-top: 0.8rem;
`;

const DiscountDetails = ({
  values,
  touched,
  errors,
  handleBlur,
  setFieldValue,
}) => {
  const [discountLimit, setDiscountLimit] = useState(
    values.maximum_usage === 999999 ? 'unlimited' : 'limited',
  );
  const [discountLimitPerUser, setDiscountLimitPerUser] = useState(
    values.maximum_use_per_user === 999999 ? 'unlimited' : 'limited',
  );

  return (
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={5}>
        <div className="field">
          <div className="label">Discount Type</div>
          <div className="control">
            <div className="is-flex">
              <RadioWrapper>
                <Radio
                  label="Amount"
                  value={values.discount_type === 'Amount'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('discount_type', 'Amount');
                    }
                  }}
                />
              </RadioWrapper>
              <RadioWrapper>
                <Radio
                  label="Percentage"
                  value={values.discount_type === 'Percentage'}
                  onChange={value => {
                    if (value) {
                      setFieldValue('discount_type', 'Percentage');
                    }
                  }}
                />
              </RadioWrapper>
            </div>
          </div>
        </div>
      </Column>
      <Column size={5}>
        <Input
          label="Discount Voucher Min Purchase Amount"
          type="number"
          name="minimum_purchase_amount"
          value={values.minimum_purchase_amount}
          onChange={e => {
            if (e.target.value === '') {
              setFieldValue('minimum_purchase_amount', e.target.value);
            } else if (!isNaN(parseFloat(e.target.value))) {
              setFieldValue(
                'minimum_purchase_amount',
                parseFloat(e.target.value),
              );
            }
          }}
          onBlur={handleBlur}
          min="0.00"
        />
        <InputErrorMessage
          errors={errors.minimum_purchase_amount}
          touched={touched.minimum_purchase_amount}
        />
      </Column>
      <Column size={5}>
        <Input
          label="Discount Amount / Percentage"
          type="number"
          name="value"
          value={values.value}
          onChange={e => {
            if (e.target.value === '') {
              setFieldValue('value', e.target.value);
            } else if (!isNaN(parseFloat(e.target.value))) {
              setFieldValue('value', parseFloat(e.target.value));
            }
          }}
          onBlur={handleBlur}
          min="0.00"
        />
        <InputErrorMessage errors={errors.value} touched={touched.value} />
      </Column>
      <Column size={5}>
        <Input
          label="Maximum Discount Amount"
          type="number"
          name="maximum_discount_amount"
          value={values.maximum_discount_amount}
          onChange={e => {
            if (e.target.value === '') {
              setFieldValue('maximum_discount_amount', e.target.value);
            } else if (!isNaN(parseFloat(e.target.value))) {
              setFieldValue(
                'maximum_discount_amount',
                parseFloat(e.target.value),
              );
            }
          }}
          onBlur={handleBlur}
          min="0.00"
        />
        <InputErrorMessage
          errors={errors.maximum_discount_amount}
          touched={touched.maximum_discount_amount}
        />
      </Column>
      <Column size={5}>
        <div className="field">
          <div className="label">Discount Limitation</div>
          <div className="control">
            <RadioWrapper>
              <Radio
                label="Unlimited Uses"
                value={discountLimit === 'unlimited'}
                onChange={value => {
                  if (value) {
                    setDiscountLimit('unlimited');
                    setFieldValue('maximum_usage', 999999);
                  }
                }}
              />
            </RadioWrapper>
            <div className="is-flex">
              <RadioWrapper>
                <Wrapper>
                  <Radio
                    label="Maximum No of Discount"
                    value={discountLimit === 'limited'}
                    onChange={value => {
                      if (value) {
                        setDiscountLimit('limited');
                        setFieldValue('maximum_usage', 0);
                      }
                    }}
                  />
                </Wrapper>
              </RadioWrapper>
              {discountLimit === 'limited' && (
                <Input
                  type="number"
                  name="maximum_usage"
                  value={values.maximum_usage}
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('maximum_usage', e.target.value);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'maximum_usage',
                        parseFloat(e.target.value),
                      );
                    }
                  }}
                  errors={errors.maximum_usage}
                  touched={touched.maximum_usage}
                  onBlur={handleBlur}
                  min="0.00"
                />
              )}
            </div>
          </div>
        </div>
      </Column>
      <Column size={5}>
        <div className="field">
          <div className="label">Maximum No Of Discount Per User</div>
          <div className="control">
            <RadioWrapper>
              <Radio
                label="Unlimited"
                value={discountLimitPerUser === 'unlimited'}
                onChange={value => {
                  if (value) {
                    setDiscountLimitPerUser('unlimited');
                    setFieldValue('maximum_use_per_user', 999999);
                  }
                }}
              />
            </RadioWrapper>
            <div className="is-flex">
              <RadioWrapper>
                <Wrapper>
                  <Radio
                    label="Enter Limit"
                    value={discountLimitPerUser === 'limited'}
                    onChange={value => {
                      if (value) {
                        setDiscountLimitPerUser('limited');
                        setFieldValue('maximum_use_per_user', 0);
                      }
                    }}
                  />
                </Wrapper>
              </RadioWrapper>
              {discountLimitPerUser === 'limited' && (
                <Input
                  type="number"
                  name="maximum_use_per_user"
                  value={values.maximum_use_per_user}
                  onChange={e => {
                    if (e.target.value === '') {
                      setFieldValue('maximum_use_per_user', e.target.value);
                    } else if (!isNaN(parseFloat(e.target.value))) {
                      setFieldValue(
                        'maximum_use_per_user',
                        parseFloat(e.target.value),
                      );
                    }
                  }}
                  errors={errors.maximum_use_per_user}
                  touched={touched.maximum_use_per_user}
                  onBlur={handleBlur}
                  min="0.00"
                />
              )}
            </div>
          </div>
        </div>
      </Column>
      <Column size={12}>
        <CheckBox
          label="Can be clubbed with other discount"
          value={values.can_be_clubbed}
          onChange={value => setFieldValue('can_be_clubbed', value)}
          secondary
        />
      </Column>
      <Column size={12}>
        <CheckBox
          label="Published Voucher / Deal"
          value={values.is_published}
          onChange={value => setFieldValue('is_published', value)}
          secondary
        />
      </Column>
    </Column.Group>
  );
};

export default DiscountDetails;
