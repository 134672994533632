import React from 'react';
import { Table } from 'rbx';
import {
  Table as CustomTable,
  CustomInput,
  Pagination,
} from '../../../components/elements';

const PhotoShares = () => (
  <React.Fragment>
    {/* <Pagination /> */}
    <CustomTable>
      <Table.Head>
        <Table.Row>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> User Display Name
              </span>
              <br />
              <CustomInput />
            </div>
          </Table.Heading>
          <Table.Heading>
            <div>
              <span>
                <i className="fas fa-sort-amount-down" /> User Login
              </span>
              <br />
              <CustomInput />
            </div>
          </Table.Heading>
          <Table.Heading>Updated At</Table.Heading>
          <Table.Heading>Shared On</Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
          <Table.Cell />
        </Table.Row>
      </Table.Body>
    </CustomTable>
  </React.Fragment>
);

export default PhotoShares;
