import React from 'react';
import styled, { css } from 'styled-components';
import { useStoreState, useStoreActions } from 'easy-peasy';

import Sidebar from './Sidebar';
import Header from './Header';

const width = css`
  @media screen and (max-width: 1023px) {
    width: 205px;
  }
  @media screen and (min-width: 1024px) {
    width: 160px;
  }
  @media screen and (min-width: 1216px) {
    width: 205px;
  }
  @media screen and (min-width: 1408px) {
    width: 230px;
  }
`;

const padding = css`
  @media screen and (max-width: 1023px) {
    padding-left: 205px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 160px;
  }
  @media screen and (min-width: 1216px) {
    padding-left: 205px;
  }
  @media screen and (min-width: 1408px) {
    padding-left: 230px;
  }
`;

const SidebarWrapper = styled.div`
  background: #f6f6f6;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 52px;
  height: 100%;
  width: 34px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  box-shadow: 5px 0 5px -5px #f6f6f6;
  &:hover,
  &.active,
  &.hovered {
    ${width}
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

const Content = styled.div`
  position: relative;
  top: 50px;
  left: 0;
  padding-left: 32px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  &.active {
    ${padding}
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
`;

const Layout = ({ children }) => {
  const switchSideBar = useStoreState(state => state.global.switchSideBar);
  const setSwitchSideBar = useStoreActions(state => state.global.updateSidebar);
  return (
    <React.Fragment>
      <Header onClickMenu={() => setSwitchSideBar(!switchSideBar)} />
      <div className="is-hidden-touch">
        <SidebarWrapper className={switchSideBar ? 'active' : ''}>
          <Sidebar />
        </SidebarWrapper>
        <Content className={switchSideBar ? 'active' : ''}>{children}</Content>
      </div>
      <div className="is-hidden-desktop">
        <SidebarWrapper>
          <Sidebar />
        </SidebarWrapper>
        <Content>{children}</Content>
      </div>
    </React.Fragment>
  );
};

export default Layout;

// {switchSideBar ? (
//   <SecondarySidebarWrapper className="column is-2">
//     <SecondarySidebar onClick={() => setSwitchSideBar(false)} />
//   </SecondarySidebarWrapper>
// ) : (
//   <SidebarWrapper className="column is-2">
//     <Sidebar />
//   </SidebarWrapper>
// )}
