import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import {
  Input,
  Radio,
  ReactDateTimePicker,
  Select,
} from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const status = [
  { value: '', label: 'All' },
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const Search = ({
  itemNameSearchInput,
  setItemNameSearchInput,
  statusInput,
  setStatusInput,
}) => (
  <Wrapper>
    <Level />
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-half">
          <Input
            label="Item Dictionary Name"
            type="text"
            value={itemNameSearchInput}
            onChange={item => setItemNameSearchInput(item.target.value)}
            placeholder="Start entering item dictionary name..."
          />
        </div>

        <div className="column is-half">
          <Select
            label="Status"
            options={status}
            value={[{ value: status.value, label: status.value }]}
            onChange={({ value }) => setStatusInput(value)}
            placeholder="Start entering status..."
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default Search;
