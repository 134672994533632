import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { omit, first, concat, forEach, isNull, isEqual } from 'lodash';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import tzLookup from 'tz-lookup';
import { toast } from 'react-toastify';

import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import AddEventForm from '../AddEvent/AddEventForm';
import { getEncodedImage } from '../../../utils/s3';
import { Message, Loading } from '../../../components/elements';

const allEventQuery = gql`
  query allEventQuery(
    $eventId: String
    $placeId: String
    $imageFilter: ImageFilter
  ) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      listing_type
      default_image_url
      # contact_event_organizer
      social {
        type
        logo
        description
        value
        display
      }
      event_category {
        name
        description
        display_order
      }
      dress_code {
        name
        description
        display_order
      }
      display_order
      featured
      default_url
      start
      end
      approval_status
      event_type {
        name
        description
        display_order
      }
      payment {
        name
        description
        display_order
        ticket_sales_method
        fee
      }
      invitation
      password
      password_value
      draft
      place_name
      venue_name
      name
      description
      tagline
      tag
      slug
      voucher_count
      minimum_price
      maximum_price
      url {
        uri
        display_order
      }
      restriction {
        type
        name
        description
        display_order
      }

      fee {
        name
        label
        fee_value_type
        value
        service_type
        applies_to
        sales_method
        place_managed
        can_override
      }
      feature {
        name
        description
        logo
        display_order
      }
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      latitude
      longitude
      location
      timezone
      contact {
        type
        value
        display
        display_order
        is_primary
      }
    }

    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
      start
      end
      status
      display_remaining_ticket
      minimum_price
      maximum_price
      total_capacity
      total_tickets
    }
    search_images(input: { filter: { image_filter: $imageFilter } }) {
      image_id
      url
      link
      tooltip
      tags
    }

    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      status
      tagline
      keywords
      description
      address_line_1
      address_line_2
      city
      state
      country
      post_code
      timezone
      contact {
        type
        value
        display
        display_order
        is_primary
      }
      social {
        type
        logo
        description
        value
        display
      }
      latitude
      longitude
    }
  }
`;

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String) {
    fetch_event_occurrence_ticket(
      input: { event_occurrence_id: $eventOccurrenceId }
    ) {
      event_occurrence_id
      ticket_id
      status
      sales_method
      name
      type
      description
      quantity
      sold
      sale_start
      sale_end
      minimum
      maximum
      special_price
      price
      fee {
        name
        label
        fee_value_type
        value
        service_type
        applies_to
        sales_method
        place_managed
        can_override
      }
      attribute {
        name
        label
        required
        values
        validation
        display_order
      }
    }
  }
`;

const updateEventMutation = gql`
  mutation updateEvent($input: EventInput) {
    update_event(input: $input) {
      place_id
      event_id
      listing_type
      error {
        description
      }
    }
  }
`;

const updateEventOccurrenceMutation = gql`
  mutation updateEventOccurrence($input: EventOccurrenceInput) {
    update_event_occurrence(input: $input) {
      event_id
      event_occurrence_id
      error {
        description
      }
    }
  }
`;

const updateEventOccurrenceTicketsMutation = gql`
  mutation updateEventOccurrenceTickets($input: [TicketInput]) {
    update_event_occurrence_ticket(input: $input) {
      event_occurrence_id
      ticket_id
    }
  }
`;
const createImageMutation = gql`
  mutation createImage($input: ImageInput) {
    create_image(input: $input) {
      image_id
      url
      error {
        description
      }
    }
  }
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const parseSearchImages = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        id: item.image_id,
        url: item.url,
        preview: getEncodedImage(item.url, 300, 300),
        tooltip: item.tooltip,
        link: item.link,
        tag: item.tags,
        uploadingStatus: 'uploaded',
      });
    }
  });
  return imageValues;
};

const parseImage = images => {
  const imageValues = [];
  forEach(images, item => {
    if (item.url !== '') {
      imageValues.push({
        image_id: item.id,
        url: item.url,
        link: item.link,
        tags: item.tags,
        tooltip: item.tooltip,
      });
    }
  });
  return imageValues;
};
const UpdateEvent = ({ history, match }) => {
  const { params } = match;

  return (
    <Layout>
      <div className="container">
        <Query
          query={allEventQuery}
          variables={{
            eventId: params.eventId,
            placeId: params.placeId,
            imageFilter: {
              object_id: params.eventId,
              object_type: ['EVENT'],
              parent_id: params.placeId,
              parent_type: ['PLACE'],
            },
          }}
          fetchPolicy="network-only"
        >
          {({
            data: allEventData,
            loading: queryLoading,
            error: queryError,
          }) => {
            if (queryError) {
              return <Message>{queryError.message}</Message>;
            }
            if (queryLoading) {
              return <Loading />;
            }
            const {
              fetch_event,
              fetch_place,
              search_images,
              fetch_event_occurrence,
            } = allEventData;

            const uploadedImages = parseSearchImages(search_images);

            return (
              <Query
                query={eventTicketsQuery}
                variables={{
                  eventOccurrenceId:
                    fetch_event_occurrence.length !== 0
                      ? first(fetch_event_occurrence).event_occurrence_id
                      : '',
                }}
                fetchPolicy="network-only"
              >
                {({
                  data: ticketsData,
                  loading: ticketsLoading,
                  error: ticketsQueryError,
                }) => {
                  if (ticketsQueryError) {
                    return <Message>{ticketsQueryError.message}</Message>;
                  }
                  if (ticketsLoading) {
                    return <Loading />;
                  }

                  const { fetch_event_occurrence_ticket } = ticketsData;

                  return (
                    <View
                      uploadedImages={uploadedImages}
                      fetch_event={fetch_event}
                      fetch_place={fetch_place}
                      fetch_event_occurrence={fetch_event_occurrence}
                      fetch_event_occurrence_ticket={
                        fetch_event_occurrence_ticket
                      }
                      history={history}
                    />
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </div>
    </Layout>
  );
};

const View = ({
  uploadedImages,
  fetch_place,
  fetch_event_occurrence_ticket,
  fetch_event_occurrence,
  fetch_event,
  history,
}) => {
  const [occurrenceInput, setOccurrenceInput] = useState({});
  const [ticketsInput, setTicketsInput] = useState([]);
  const [images, setImages] = useState([]);
  const [isOccurrenceTrigger, setIsOccurrenceTrigger] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  const { userId } = useStoreState(state => state.auth);

  useEffect(() => {
    const lat = parseFloat(fetch_event.latitude).toFixed(5);
    const long = parseFloat(fetch_event.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={updateEventOccurrenceTicketsMutation}
      variables={{ input: [...ticketsInput] }}
      onCompleted={() => {
        swal('Hurray!', 'Event had been updated succesfully!', 'success').then(
          () => {
            history.push('/events');
          },
        );
      }}
    >
      {(
        update_event_occurrence_ticket,
        { loading: loadingTickets, error: ticketsError },
      ) => (
        <Mutation
          client={client.clientPrivate}
          mutation={updateEventOccurrenceMutation}
          variables={{ input: { ...occurrenceInput } }}
          onCompleted={() => {
            update_event_occurrence_ticket();
          }}
        >
          {(
            update_event_occurrence,
            { loading: occurrenceLoading, error: occurrenceError },
          ) => (
            <Mutation
              client={client.clientPrivate}
              mutation={createImageMutation}
              onCompleted={() => {
                if (isOccurrenceTrigger) {
                  update_event_occurrence();
                } else {
                  swal(
                    'Hurray!',
                    'Event had been updated succesfully!',
                    'success',
                  ).then(() => {
                    history.push('/events');
                  });
                }
              }}
            >
              {(create_image, { loading: imageLoading, error: imageError }) => (
                <Mutation
                  client={client.clientPrivate}
                  mutation={updateEventMutation}
                  onCompleted={({ update_event }) => {
                    if (!isNull(update_event.error)) {
                      update_event.error.map(item =>
                        toast.error(item.description),
                      );
                    } else if (images.length === 1) {
                      if (isOccurrenceTrigger) {
                        update_event_occurrence();
                      } else {
                        swal(
                          'Hurray!',
                          'Event had been updated succesfully!',
                          'success',
                        ).then(() => {
                          history.push('/events');
                        });
                      }
                    } else {
                      create_image({
                        variables: {
                          input: {
                            user_id: userId,
                            object_id: fetch_event.event_id,
                            object_type: 'EVENT',
                            parent_id: fetch_event.place_id,
                            upload_type: 'ADMIN',
                            parent_type: 'PLACE',
                            links_to: [
                              {
                                id: fetch_event.event_id,
                                type: 'EVENT',
                              },
                            ],
                            images: parseImage(images),
                          },
                        },
                      });
                    }
                  }}
                >
                  {(update_event, { loading, error }) => (
                    <React.Fragment>
                      {(error || occurrenceError || ticketsError) && (
                        <Message type="error">{`${(error && error.message) ||
                          (occurrenceError && occurrenceError.message) ||
                          (ticketsError && ticketsError.message)}`}</Message>
                      )}

                      <AddEventForm
                        uploadedImages={uploadedImages}
                        place={{ ...fetch_place }}
                        userId={userId}
                        event={fetch_event}
                        whichPage="updateEventPage"
                        eventOccurrence={
                          fetch_event_occurrence.length !== 0
                            ? first(fetch_event_occurrence)
                            : {}
                        }
                        tickets={fetch_event_occurrence_ticket}
                        loading={
                          loading ||
                          occurrenceLoading ||
                          loadingTickets ||
                          imageLoading
                        }
                        error={error || imageError}
                        onSubmit={async data => {
                          setImages(data.image);
                          if (
                            first(data.listing_type) === 'private_tickets' ||
                            first(data.listing_type) === 'public_tickets'
                          ) {
                            setIsOccurrenceTrigger(true);
                          }

                          const customStartDate = moment(data.startDate).format(
                            'YYYY-MM-DD',
                          );
                          const customStartTime = !isNull(data.startTime)
                            ? moment(data.startTime).format('HH:mm:ss')
                            : moment().format('HH:mm:ss');
                          const customEndDate = moment(data.endDate).format(
                            'YYYY-MM-DD',
                          );
                          const customEndTime = !isNull(data.endTime)
                            ? moment(data.endTime).format('HH:mm:ss')
                            : '23:59:00';

                          const startDate = moment
                            .tz(
                              `${customStartDate} ${customStartTime}`,
                              currentTimeZone,
                            )
                            .toISOString();

                          const endDate = moment
                            .tz(
                              `${customEndDate} ${customEndTime}`,
                              currentTimeZone,
                            )
                            .toISOString();

                          const inputs = omit(data, [
                            'startDate',
                            'endDate',
                            'startTime',
                            'endTime',
                            'ticket',
                            'event_occurrence_id',
                            'business_hour',
                            'primaryEmail',
                            'displayEmail',
                            'primaryMobile',
                            'displayMobile',
                            'businessSite',
                            'displayBusiness',
                            'listing',
                            'visibility',
                            'ticket_holding_time_limit',
                            'total_capacity',
                            'display_remaining_ticket',
                            'total_tickets',
                            'fee',
                            'eventFeeForUpdate',
                            'image',
                            'uploadedImages',
                          ]);

                          setOccurrenceInput({
                            user_id: data.user_id,
                            event_id: data.event_id,
                            event_occurrence_id: data.event_occurrence_id,
                            start: startDate,
                            end: endDate,
                            status: data.status,
                            display_remaining_ticket:
                              data.display_remaining_ticket,
                            total_capacity: data.total_capacity,
                            total_tickets: data.total_tickets,
                          });

                          setTicketsInput(data.ticket);
                          const primaryContacts = [
                            {
                              type: 'email_primary',
                              value: data.primaryEmail,
                              display: data.displayEmail,
                              is_primary: true,
                              display_order: '1',
                            },
                            {
                              type: 'phone_primary',
                              value: data.primaryMobile,
                              display: data.displayMobile,
                              is_primary: true,
                              display_order: '1',
                            },
                            {
                              type: 'website',
                              value: data.businessSite,
                              display: data.displayBusiness,
                              is_primary: true,
                              display_order: '1',
                            },
                          ];

                          const formContact = () => {
                            const final = [];
                            forEach(data.contact, item => {
                              const custom = omit(item, ['id']);
                              final.push(custom);
                            });
                            return final;
                          };
                          const feeInputs = () => {
                            const final = [];
                            forEach(data.eventFeeForUpdate, item => {
                              const custom = omit(item, ['__typename']);
                              final.push(custom);
                            });
                            return final;
                          };

                          const contact = concat(
                            primaryContacts,
                            formContact(),
                          );

                          const input = {
                            ...inputs,
                            start: startDate,
                            end: endDate,
                            contact,
                            fee: feeInputs(),
                          };

                          const final = {};
                          Object.assign(
                            final,
                            {
                              user_id: userId,
                            },
                            { event_id: fetch_event.event_id },
                            !isEqual(fetch_event.name, input.name) && {
                              name: input.name,
                            },
                            !isEqual(
                              fetch_event.default_image_url,
                              input.default_image_url,
                            ) && {
                              default_image_url: input.default_image_url,
                            },
                            !isEqual(
                              fetch_event.total_capacity,
                              input.total_capacity,
                            ) && {
                              total_capacity: input.total_capacity,
                            },
                            !isEqual(fetch_event.status, input.status) && {
                              status: input.status,
                            },
                            !isEqual(
                              fetch_event.venue_name,
                              input.venue_name,
                            ) && {
                              venue_name: input.venue_name,
                            },
                            !isEqual(fetch_event.slug, input.slug) && {
                              slug: input.slug,
                            },
                            !moment(fetch_event.start).isSame(input.start) && {
                              start: input.start,
                            },
                            !moment(fetch_event.end).isSame(input.end) && {
                              end: input.end,
                            },
                            !isEqual(fetch_event.key, input.key) &&
                              input.key.length !== 0 && {
                                key: input.key,
                              },
                            !isEqual(
                              fetch_event.address_line_1,
                              input.address_line_1,
                            ) && {
                              address_line_1: input.address_line_1,
                            },
                            !isEqual(
                              fetch_event.address_line_2,
                              input.address_line_2,
                            ) && {
                              address_line_2: input.address_line_2,
                            },
                            !isEqual(fetch_event.tagline, input.tagline) &&
                              input.tagline !== '' && {
                                tagline: input.tagline,
                              },
                            !isEqual(fetch_event.city, input.city) && {
                              city: input.city,
                            },
                            !isEqual(fetch_event.state, input.state) && {
                              state: input.state,
                            },
                            !isEqual(fetch_event.country, input.country) && {
                              country: input.country,
                            },
                            !isEqual(
                              fetch_event.post_code,
                              input.post_code,
                            ) && {
                              post_code: input.post_code,
                            },
                            !isEqual(fetch_event.latitude, input.latitude) && {
                              latitude: input.latitude,
                            },
                            !isEqual(
                              fetch_event.longitude,
                              input.longitude,
                            ) && {
                              longitude: input.longitude,
                            },
                            !isEqual(fetch_event.location, input.location) && {
                              location: input.location,
                            },
                            !isEqual(
                              fetch_event.contact_event_organizer,
                              input.contact_event_organizer,
                            ) && {
                              contact_event_organizer:
                                input.contact_event_organizer,
                            },
                            !isEqual(
                              removeTypename(fetch_event.contact),
                              input.contact,
                            ) &&
                              input.contact.length !== 0 && {
                                contact: input.contact,
                              },
                            !isEqual(fetch_event.tag, input.tag) &&
                              input.tag.length !== 0 && {
                                tag: input.tag,
                              },
                            !isEqual(
                              fetch_event.description,
                              input.description,
                            ) &&
                              input.description !== '' && {
                                description: input.description,
                              },

                            !isEqual(
                              fetch_event.display_remaining_ticket,
                              input.display_remaining_ticket,
                            ) && {
                              display_remaining_ticket:
                                input.display_remaining_ticket,
                            },

                            !isEqual(
                              fetch_event.total_capacity,
                              input.total_capacity,
                            ) && {
                              total_capacity: input.total_capacity,
                            },

                            !isEqual(fetch_event.status, input.status) && {
                              status: input.status,
                            },
                            !isEqual(
                              removeTypename(fetch_event.event_type),
                              input.event_type,
                            ) &&
                              input.event_type.length !== 0 && {
                                event_type: input.event_type,
                              },

                            !isEqual(
                              removeTypename(fetch_event.event_category),
                              input.event_category,
                            ) &&
                              input.event_category.length !== 0 && {
                                event_category: input.event_category,
                              },
                            !isEqual(
                              removeTypename(fetch_event.feature),
                              input.feature,
                            ) &&
                              input.feature.length !== 0 && {
                                feature: input.feature,
                              },

                            !isEqual(
                              removeTypename(fetch_event.restriction),
                              input.restriction,
                            ) &&
                              input.restriction.length !== 0 && {
                                restriction: input.restriction,
                              },

                            !isEqual(
                              removeTypename(fetch_event.dress_code),
                              input.dress_code,
                            ) &&
                              input.dress_code.length !== 0 && {
                                dress_code: input.dress_code,
                              },
                            !isEqual(
                              removeTypename(fetch_event.social),
                              input.social,
                            ) &&
                              input.dress_code.length !== 0 && {
                                social: input.social,
                              },
                          );
                          update_event({
                            variables: { input: final },
                          });
                        }}
                      />
                    </React.Fragment>
                  )}
                </Mutation>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default UpdateEvent;
