import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { omit, isNull } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import tzLookup from 'tz-lookup';

import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import DealForm from './DealForm';
import PlaceSelection from '../../../components/PlaceSelection';

const createDealMutation = gql`
  mutation createDeal($input: PlaceDealInput) {
    create_place_deal(input: $input) {
      deal_id
      place_id
      error {
        description
      }
    }
  }
`;

// const createImageMutation = gql`
//   mutation createImage($input: ImageInput) {
//     create_image(input: $input) {
//       image_id
//       url
//       error {
//         description
//       }
//     }
//   }
// `;

// const parseImage = images => {
//   const imageValues = [];
//   forEach(images, item => {
//     if (item.url !== '') {
//       imageValues.push({
//         image_id: item.id,
//         url: item.url,
//         link: item.link,
//         tags: item.tags,
//         tooltip: item.tooltip,
//       });
//     }
//   });
//   return imageValues;
// };

const AddPlaceDeal = ({ history }) => {
  const [place, setPlace] = useState();
  const [placeSelection, setPlaceSelection] = useState(false);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  const setTimeZone = value => {
    const lat = parseFloat(value.latitude).toFixed(5);
    const long = parseFloat(value.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  };

  return (
    <Layout>
      {placeSelection && place ? (
        <View
          place={place}
          history={history}
          currentTimeZone={currentTimeZone}
        />
      ) : (
        <PlaceSelection
          heading="Select Place to add a Deals"
          claimStatus="NONE"
          handleSelect={value => {
            setPlace(value);
            setTimeZone(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

const View = ({ place, history, currentTimeZone }) => {
  const [images, setImages] = useState({});
  const { userId } = useStoreState(state => state.auth);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={createDealMutation}
      onCompleted={({ create_place_deal }) => {
        if (create_place_deal.error) {
          create_place_deal.error.map(item => toast.error(item.description));
        } else {
          swal(
            'Great!',
            'Place Deal had been created successfully!',
            'success',
          ).then(() => {
            history.push('/event-deals');
          });
        }
      }}
    >
      {(
        create_place_deal,
        { loading: createDealLoading, error: createDealError },
      ) => (
        <DealForm
          isAddDealForm
          error={createDealError}
          loading={createDealLoading}
          place={place}
          onSubmit={value => {
            setImages(value.images);
            const input = omit(value, [
              'image',
              'startDate',
              'endDate',
              'startTime',
              'endTime',
            ]);

            // const applies_to_input = value.applies_to_event.concat(
            //   value.applies_to_tickets,
            // );

            const customStartDate = moment(value.startDate).format(
              'YYYY-MM-DD',
            );
            const startTimeWhenValueIsNull = moment(value.startDate).isSame()
              ? moment().format('HH:mm:ss')
              : '00:00:00';

            const customStartTime = !isNull(value.startTime)
              ? moment(value.startTime).format('HH:mm:ss')
              : startTimeWhenValueIsNull;

            const customEndDate = moment(value.endDate).format('YYYY-MM-DD');
            const customEndTime = !isNull(value.endTime)
              ? moment(value.endTime).format('HH:mm:ss')
              : '23:59:00';

            const startDate = moment
              .tz(`${customStartDate} ${customStartTime}`, currentTimeZone)
              .toISOString();

            const endDate = moment
              .tz(`${customEndDate} ${customEndTime}`, currentTimeZone)
              .toISOString();

            const inputs = {
              user_id: userId,
              ...input,
              start: startDate,
              end: endDate,
            };
            create_place_deal({ variables: { input: inputs } });
          }}
        />
      )}
    </Mutation>
  );
};

export default AddPlaceDeal;
