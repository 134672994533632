import React from 'react';

const PlaceList = ({ place, handleSelect }) => (
  <React.Fragment>
    <td>
      <a onClick={() => handleSelect(place)}>{place.name}</a>
    </td>
    <td>{place.city}</td>
    <td>
      {place.state}, {place.post_code}
    </td>
    <td>{`${place.status}`}</td>
  </React.Fragment>
);

export default PlaceList;
