import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  setLocation,
  status,
  setPlaceNameFilter,
  history,
  cityInput,
  setCityInput,
}) => {
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Submitted"
              name="Type"
              value={status === 'SUBMITTED'}
              onChange={() => {
                history.push(
                  `/place-claims${qs.stringify({ status: 'SUBMITTED' }, true)}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Approved"
              name="Type"
              value={status === 'APPROVED'}
              onChange={() => {
                history.push(
                  `/place-claims${qs.stringify({ status: 'APPROVED' }, true)}`,
                );
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Rejected"
              name="Type"
              value={status === 'REJECTED'}
              onChange={() => {
                history.push(
                  `/place-claims${qs.stringify({ status: 'REJECTED' }, true)}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              placeholder="Start entering place name..."
              onChange={event => setPlaceNameFilter(event.target.value)}
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              placeholder="Start entering company name..."
            />
          </div>
          {/* <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div> */}

          <div className="column is-4">
            <Input
              label="City / Suburb"
              type="text"
              value={cityInput}
              onChange={city => setCityInput(city.target.value)}
              placeholder="Start entering city name..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(Search);
