import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { capitalize, range } from 'lodash';
import { Column } from 'rbx';
import moment from 'moment';

import { toast } from 'react-toastify';
import { Input, Select, TextArea, Radio } from '../../../components/elements';
import EventTimeDatePicker from './EventTimeDatePicker';
import TicketAttendeeForm from './TicketAttendeeForm';

const types = [
  { value: 'free', label: 'Free' },
  { value: 'paid', label: 'Paid' },
];

const saleMethods = [
  { value: 'all', label: 'All' },
  { value: 'online', label: 'Online' },
  { value: 'offline', label: 'offline (Ticket Agent)' },
];

const Container = styled.div`
  &&& {
    .label {
      font-size: 0.68rem;
    }
  }
`;

const RadioWrapper = styled.div`
  padding-right: 1.5rem;
`;

const TableBox = styled.div`
  border: 1px solid #adacac;
  padding: 1rem;
  width: 88%;
  &&& {
    table {
      width: 100%;
      padding: 1rem;
    }
    th,
    td {
      width: 2rem;
    }
  }
`;

const TicketForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
  // eventStartDate,
  eventEndDate,
  // eventStartTime,
  eventEndTime,
  absorbFeeAndPassOnFee,
  totalCapacity,
  haveAnyBookingInThisEvent,
}) => {
  // for End Date
  const [disableHoursArray, setDisableHoursArray] = useState([]);
  const [disableMinutesArray, setDisableMinutesArray] = useState([]);
  const [disableMinutesHelper, setDisableMinutesHelper] = useState(0);

  // for Start Date with Event Date

  const [disableHoursArrayE, setDisableHoursArrayE] = useState([]);
  const [disableMinutesArrayE, setDisableMinutesArrayE] = useState([]);
  const [disableMinutesHelperE, setDisableMinutesHelperE] = useState(0);

  const passOnFee = method => {
    if (method === 'all') {
      return [
        absorbFeeAndPassOnFee.passOnOnline,

        absorbFeeAndPassOnFee.passOnOffline,
      ].filter(item => item !== null);
    }
    if (method === 'offline') {
      return [absorbFeeAndPassOnFee.passOnOffline].filter(
        item => item !== null,
      );
    }
    if (method === 'online') {
      return [absorbFeeAndPassOnFee.passOnOnline].filter(item => item !== null);
    }
    return [];
  };
  const absorbFee = method => {
    if (method === 'all') {
      return [
        absorbFeeAndPassOnFee.absorbOnline,
        absorbFeeAndPassOnFee.absorbOffline,
      ].filter(item => item !== null);
    }
    if (method === 'offline') {
      return [absorbFeeAndPassOnFee.absorbOffline].filter(
        item => item !== null,
      );
    }
    if (method === 'online') {
      return [absorbFeeAndPassOnFee.absorbOnline].filter(item => item !== null);
    }
    return [];
  };

  useEffect(() => {
    if (!values.ticket) {
      if (values.type === 'paid') {
        setFieldValue('fee', absorbFee('all'));
      }
    }
  }, []);

  // const handleEventEndTimeDisableHours = endDate => {
  //   if (moment(eventEndDate).format('L') === moment(endDate).format('L')) {
  //     const hours = moment(eventEndTime).hours();
  //     const min = moment(eventEndTime).minutes();
  //     setDisableMinutesHelper(hours);
  //     setDisableHoursArray(range(hours, 23));
  //     setDisableMinutesArray(range(min, 60));
  //     return [];
  //   }
  //   return [];
  // };

  const disabledHours = (startDate, endDate, startTime) => {
    const ticketStartDate = moment(startDate).format('YYYY-MM-DD');
    const ticketEndDate = moment(endDate).format('YYYY-MM-DD');
    if (moment(ticketStartDate).isSame(ticketEndDate)) {
      const hours = moment(startTime).hours();
      const min = moment(startTime).minutes();
      setDisableMinutesHelper(hours);
      setDisableHoursArray(range(1, hours));
      setDisableMinutesArray(range(min, 10));
      return [];
    }
    setDisableMinutesHelper(0);
    setDisableHoursArray([]);
    setDisableMinutesArray([]);
    return [];
  };

  const disabledHoursE = saleStartDate => {
    const ticketStartDate = moment(saleStartDate).format('YYYY-MM-DD');
    const eventEndDateE = moment(eventEndDate).format('YYYY-MM-DD');
    if (moment(ticketStartDate).isSame(eventEndDateE)) {
      const hours = moment(eventEndTime).hours();
      const min = moment(eventEndTime).minutes();
      setDisableMinutesHelperE(hours);
      setDisableHoursArrayE(range(hours, 23));
      setDisableMinutesArrayE(range(min, 60));
      return [];
    }
    setDisableMinutesHelperE(0);
    setDisableHoursArrayE([]);
    setDisableMinutesArrayE([]);
    return [];
  };

  const disabledMinutesE = h => {
    if (h === disableMinutesHelperE) {
      return disableMinutesArrayE;
    }
    return [];
  };

  const disabledMinutes = h => {
    if (h === disableMinutesHelper) {
      return disableMinutesArray.concat(disableMinutesArrayE);
    }
    return [];
  };

  return (
    <Container>
      <Column.Group gapSize={8}>
        <Column size="half">
          <Column.Group multiline vcentered>
            <Column size="full">
              <Select
                label="Type"
                value={[
                  {
                    value: values.type,
                    label: capitalize(values.type),
                  },
                ]}
                options={types}
                onChange={value => setFieldValue('type', value.value)}
                disabled
                required
              />
            </Column>
            <Column size="full">
              <Input
                label="Ticket Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.name}
                touched={touched.name}
                required
              />
            </Column>
            <Column size="half">
              <Input
                label="Quantity"
                type="number"
                name="quantity"
                value={values.quantity !== 0 ? values.quantity : ''}
                // onChange={handleChange}
                onChange={event =>
                  setFieldValue(
                    'quantity',
                    event.target.value !== '' ? event.target.value : 0,
                  )
                }
                onBlur={handleBlur}
                errors={errors.quantity}
                touched={touched.quantity}
                min="1"
                max={`${totalCapacity}`}
                required
              />
            </Column>
            <Column size="half">
              <Select
                label="Sale Methods"
                value={
                  values.sales_method.length > 1
                    ? [
                        {
                          value: 'all',
                          label: 'All',
                        },
                      ]
                    : [
                        {
                          value: values.sales_method,
                          label: capitalize(values.sales_method),
                        },
                      ]
                }
                options={saleMethods}
                onChange={async value => {
                  if (value.value === 'all') {
                    await setFieldValue('sales_method', ['online', 'offline']);
                    await setFieldValue('salesMethods', 'all');
                    setFieldValue(
                      'fee',
                      values.feeType === 'passOnFee'
                        ? passOnFee('all')
                        : absorbFee('all'),
                    );
                  } else {
                    await setFieldValue('sales_method', [value.value]);
                    await setFieldValue('salesMethods', value.value);
                    setFieldValue(
                      'fee',
                      values.feeType === 'passOnFee'
                        ? passOnFee(value.value)
                        : absorbFee(value.value),
                    );
                  }
                }}
              />
            </Column>
            <Column size="full">
              <div className="label">Total Ticket Per Order</div>
            </Column>
            <Column size="half">
              <Input
                label="Minimum"
                type="number"
                name="minimum"
                value={values.minimum}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.minimum}
                touched={touched.minimum}
                min="1"
                required
              />
            </Column>
            <Column size="half">
              <Input
                label="Maximum"
                type="number"
                name="maximum"
                value={values.maximum}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.maximum}
                touched={touched.maximum}
                min={values.minimum}
                max={values.quantity}
                required
              />
            </Column>
            {values.type === 'paid' && (
              <React.Fragment>
                <Column size="half">
                  <Input
                    label="Price"
                    type="number"
                    name="price"
                    value={values.price !== 0 ? values.price : ''}
                    onChange={event =>
                      setFieldValue(
                        'price',
                        event.target.value !== '' ? event.target.value : 0,
                      )
                    }
                    errors={errors.price}
                    touched={touched.price}
                    min="1.00"
                    required
                  />
                </Column>
                <Column size="half">
                  <Input
                    label="Special Price"
                    type="number"
                    name="special_price"
                    value={
                      values.special_price !== 0 ? values.special_price : ''
                    }
                    onChange={event =>
                      setFieldValue(
                        'special_price',
                        event.target.value !== '' ? event.target.value : 0,
                      )
                    }
                    onBlur={handleBlur}
                    errors={errors.special_price}
                    touched={touched.special_price}
                    min="1.00"
                    max={`${values.price}`}
                  />
                </Column>
                <Column size="full">
                  <div className="field">
                    <label className="label">Ticket Fee </label>
                    {haveAnyBookingInThisEvent && (
                      <p className="is-size-5 has-text-warning">
                        ( Few Bookings are done in this ticket, so you are not
                        able to edit ticket fee )
                      </p>
                    )}
                    <br />
                    <div className="control">
                      <div className="is-flex">
                        <RadioWrapper>
                          <Radio
                            label="Absorbed Fee"
                            value={values.feeType === 'absorbedFee'}
                            onChange={value => {
                              if (value) {
                                setFieldValue(
                                  'fee',
                                  absorbFee(values.salesMethods),
                                );
                                setFieldValue('feeType', 'absorbedFee');
                              }
                            }}
                            disabled={haveAnyBookingInThisEvent}
                          />
                        </RadioWrapper>
                        <RadioWrapper>
                          <Radio
                            label="Pass On Fee"
                            value={values.feeType === 'passOnFee'}
                            onChange={value => {
                              if (value) {
                                setFieldValue(
                                  'fee',
                                  passOnFee(values.salesMethods),
                                );
                                setFieldValue('feeType', 'passOnFee');
                              }
                            }}
                            disabled={haveAnyBookingInThisEvent}
                          />
                        </RadioWrapper>
                      </div>
                    </div>
                  </div>
                </Column>
                <Column size="full">
                  <div>
                    {values.fee.length !== 0 ? (
                      <TableBox>
                        <table className="is-size-3">
                          <tr>
                            <th />
                            <th>Ticket Price</th>
                            <th>Fee</th>
                          </tr>

                          {values.fee.map(item => (
                            <tr>
                              <td>
                                {item.sales_method === 'online'
                                  ? 'Online'
                                  : 'Ticket Agent'}
                              </td>
                              <td>${parseFloat(values.price).toFixed(2)}</td>
                              <td> ${item.value || 0}</td>
                            </tr>
                          ))}
                        </table>
                      </TableBox>
                    ) : (
                      `No Fees Apply For ${values.sales_method}`
                    )}
                  </div>
                </Column>
              </React.Fragment>
            )}
          </Column.Group>
        </Column>
        <Column size="half">
          <Column.Group multiline vcentered>
            <Column size="full">
              <div className="field">
                <label className="label">Ticket Status</label>
                <div className="control">
                  <div className="is-flex">
                    <RadioWrapper>
                      <Radio
                        label="Available"
                        value={values.status === 'AVAILABLE'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('status', 'AVAILABLE');
                          }
                        }}
                      />
                    </RadioWrapper>
                    <RadioWrapper>
                      <Radio
                        label="Hold"
                        value={values.status === 'HOLD'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('status', 'HOLD');
                          }
                        }}
                      />
                    </RadioWrapper>
                    <RadioWrapper>
                      <Radio
                        label="Hidden"
                        value={values.status === 'HIDDEN'}
                        onChange={value => {
                          if (value) {
                            setFieldValue('status', 'HIDDEN');
                          }
                        }}
                      />
                    </RadioWrapper>
                  </div>
                </div>
              </div>
            </Column>
            <Column size="full">
              <EventTimeDatePicker
                label="Sale start"
                maxDate={new Date(eventEndDate)}
                date={values.startDate}
                time={values.startTime}
                onDateChange={value => {
                  if (value == null) {
                    setFieldValue('startDate', null);
                    setFieldValue('startTime', null);
                    setFieldValue('endDate', null);
                    setFieldValue('endTime', null);
                  } else if (
                    moment(values.startDate).isBefore(values.endDate)
                  ) {
                    toast.info(
                      'Sale end date has been cleared please re-enter new one',
                    );
                    setFieldValue('endDate', null);
                    setFieldValue('endTime', null);
                  } else {
                    setFieldValue('startDate', value);
                    setFieldValue('startTime', null);
                    disabledHours(value, values.endDate, value);
                    disabledHoursE(value);
                  }
                }}
                onTimeChange={value => {
                  setFieldValue('startTime', value);
                  setFieldValue('endTime', null);
                  disabledHours(values.startDate, values.endDate, value);

                  // handleEventEndTimeDisableHours(values.endDate);
                }}
                minDate={new Date()}
                errors={errors.startDate}
                touched={touched.startDate}
                required
                timeProps={{
                  disabledHours: () => disableHoursArrayE,
                  disabledMinutes: disabledMinutesE,
                }}
              />
            </Column>
            <Column size="full">
              <EventTimeDatePicker
                label="Sale end"
                date={values.endDate}
                time={values.endTime}
                onDateChange={value => {
                  setFieldValue('endDate', value);
                  disabledHours(
                    values.startDate,
                    value,
                    values.startTime,
                    values.endTime,
                  );
                  // handleEventEndTimeDisableHours(value);
                }}
                onTimeChange={value => {
                  setFieldValue('endTime', value);
                }}
                minDate={new Date(values.startDate)}
                maxDate={new Date(eventEndDate)}
                errors={errors.endTime}
                touched={touched.endTime}
                disabled={values.startDate === null}
                timeProps={{
                  disabledHours: () =>
                    disableHoursArray.concat(disableHoursArray),
                  disabledMinutes,
                }}
              />
            </Column>
            <Column size="full">
              <TextArea
                label="Description"
                name="description"
                rows="2"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Column>
            <Column size="full">
              <TicketAttendeeForm
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                setValues={setValues}
              />
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
    </Container>
  );
};

export default TicketForm;
