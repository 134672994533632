import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, first } from 'lodash';
import { toast } from 'react-toastify';
// import Footer from './Footer';
// import Header from './Header';
import { useStoreState } from 'easy-peasy';
import Layout from '../../../components/global/Layout';
import ItemApprovalForm from './ItemApprovalForm';
import { Loading, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const fetchImageReviewQuery = gql`
  query fetchImageReview($input: NavigateImageReviewInput) {
    fetch_image_review(input: $input) {
      image_review_id
      image_id
      object_id
      object_type
      parent_id
      parent_type
      upload_type
      status
      tags
      url
      link
      note {
        type
        date
        value
      }
      approval_status
      approved_by
      approved_at
      rejected_by
      rejected_at
      audit {
        created_at
        created_by
      }

      approved_by
      approved_at
      rejected_by
      rejected_at
      error {
        code
        description
      }
    }
  }
`;

const fetchPlaceQuery = gql`
  query fetchPlace($input: NavigatePlaceInput) {
    fetch_place(input: $input) {
      place_id
      name
      city
      post_code
      country
      status
      claim_status
    }
  }
`;

const fetchItem = gql`
  query fetchItem($input: NavigateItemInput) {
    fetch_item(input: $input) {
      name
      status
    }
  }
`;

const updateImageReviewMutation = gql`
  mutation update_image_review($input: ImageReviewInput) {
    update_image_review(input: $input) {
      image_review_id
      error {
        description
      }
    }
  }
`;

const PhotoApproval = ({ match, history }) => {
  const { imageReviewId } = match.params;
  const [action, setAction] = useState('');

  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Query
        client={client.clientPrivate}
        query={fetchImageReviewQuery}
        variables={{
          input: {
            image_review_id: imageReviewId,
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return <Message>{error.message}</Message>;
          }

          const review = data.fetch_image_review;

          if (!review) {
            return <Loading />;
          }

          return (
            <Query
              query={fetchPlaceQuery}
              variables={{ input: { place_id: review.parent_id } }}
              fetchPolicy="network-only"
            >
              {({ data: data1, loading: loading1, error: placeError }) => {
                if (loading1) {
                  return <Loading />;
                }
                if (error) {
                  return <Message>{placeError.message}</Message>;
                }
                const place = data1.fetch_place;

                return (
                  <Query
                    query={fetchItem}
                    variables={{ input: { item_id: review.object_id } }}
                    fetchPolicy="network-only"
                  >
                    {({
                      data: itemData,
                      loading: itemLoading,
                      error: itemError,
                    }) => {
                      if (itemLoading) {
                        return <Loading />;
                      }
                      if (error) {
                        return <Message>{itemError.message}</Message>;
                      }
                      const item = itemData.fetch_item;
                      const items = first(item);

                      return (
                        <Mutation
                          client={client.clientPrivate}
                          mutation={updateImageReviewMutation}
                          onCompleted={({ update_image_review }) => {
                            if (!isNull(update_image_review.error)) {
                              update_image_review.error.map(item =>
                                toast.error(item.description),
                              );
                            } else {
                              swal(
                                'Hurray!',
                                `Item photo ${
                                  action === 'APPROVED'
                                    ? 'Approved'
                                    : 'Rejected'
                                } successfully!`,
                                'success',
                              ).then(() => {
                                history.push('/item-photo-approvals');
                              });
                            }
                          }}
                        >
                          {(
                            update_image_review,
                            { loading: updateLoading },
                          ) => (
                            <ItemApprovalForm
                              images={review.url}
                              review={review}
                              place={place}
                              items={items}
                              loading={updateLoading}
                              action={action}
                              handleAction={setAction}
                              onSubmit={value => {
                                setAction(value);
                                const input = {
                                  user_id: userId,
                                  image_review_id: review.image_review_id,
                                  approval_status: value,
                                };
                                update_image_review({ variables: { input } });
                              }}
                            />
                          )}
                        </Mutation>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};
export default PhotoApproval;
