import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { useStoreState } from 'easy-peasy';
import { omit, forEach, concat, isNull } from 'lodash';
import swal from 'sweetalert';

import { toast } from 'react-toastify';
import Layout from '../../../components/global/Layout';
import AddCompanyForm from './AddCompanyForm';
import client from '../../../utils/apolloClient';

const createCompanyMutation = gql`
  mutation createCompany($input: CompanyInput) {
    create_company(input: $input) {
      company_id
      name
      error {
        description
      }
    }
  }
`;

const AddCompany = ({ history }) => {
  const { userId } = useStoreState(state => state.auth);

  return (
    <Layout>
      <Mutation
        client={client.clientPrivate}
        mutation={createCompanyMutation}
        onCompleted={({ create_company }) => {
          if (!isNull(create_company.error)) {
            create_company.error.map(item => toast.error(item.description));
          } else {
            swal(
              'Great!',
              'Company has been created successfully!',
              'success',
            ).then(() => {
              history.push('/companies');
            });
          }
        }}
      >
        {(create_company, { loading, error }) => (
          <AddCompanyForm
            isClaimed={false}
            userId={userId}
            loading={loading}
            error={error}
            onSubmit={async data => {
              const inputs = await omit(data, [
                'primaryEmail',
                'displayEmail',
                'primaryMobile',
                'displayMobile',
                'businessSite',
                'displayBusiness',
                'contact',
              ]);

              const primaryContacts = await [
                {
                  type: 'email',
                  value: data.primaryEmail,
                  display: data.displayEmail,
                  is_primary: true,
                  display_order: '1',
                },
                {
                  type: 'mobile',
                  value: data.primaryMobile,
                  display: data.displayMobile,
                  is_primary: true,
                  display_order: '1',
                },
                {
                  type: 'website',
                  value: data.businessSite,
                  display: data.displayBusiness,
                  is_primary: true,
                  display_order: '1',
                },
              ];

              const formContact = () => {
                const final = [];
                forEach(data.contact, item => {
                  const custom = omit(item, ['id']);
                  final.push(custom);
                });
                return final;
              };

              const contact = await concat(primaryContacts, formContact());

              const input = await {
                ...inputs,
                contact,
              };

              if (input) {
                const final = {};

                Object.assign(
                  final,
                  { user_id: userId },
                  { place: '6ba8d10c-007e-4d8b-a6e3-aa0ea4f4a3d1' },
                  { company_id: input.company_id },
                  { name: input.name },
                  { status: input.status },
                  { address_line_1: input.address_line_1 },
                  { address_line_2: input.address_line_2 },
                  { city: input.city },
                  { state: input.state },
                  { country: input.country },
                  { post_code: input.post_code },
                  { contact: input.contact },
                  { social: input.social },
                  { tax: input.tax },
                );
                console.log('final', final);
                create_company({ variables: { input: final } });
              }
            }}
          />
        )}
      </Mutation>
    </Layout>
  );
};

export default AddCompany;
