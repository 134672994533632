import React, { useState } from 'react';
// import { capitalize, forEach } from 'lodash';
import styled from 'styled-components';
import { Column } from 'rbx';
import AddAMenuForm from './AddAMenuForm';
import {
  Select,
  Input,
  TextArea,
  CheckBox,
  TagInputs,
} from '../../../components/elements';

const CheckBoxWrapper = styled.div``;

const Wrapper = styled.div`
  margin-left: 2rem;
`;

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;
const menu = [
  { value: 'MENU1', label: 'MENU1' },
  { value: 'MENU2', label: 'MENU2' },
];

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const serviceType = [
  { value: 'SELECT SERVICE TYPE', label: 'SELECT SERVICE TYPE' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'REJECTED', label: 'REJECTED' },
];
const approvalStatus = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'REJECTED', label: 'REJECTED' },
];

{
  /* const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
}; 
const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item, label: item });
  });
  return final;
};
*/
}

const DefineMenus = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  // const [isManual, setManual] = useState(false);
  <Container>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={4}>
        <Select
          label="Menu Name"
          options={menu}
          optionalText="(Max 26 Characters )"
          value={[{ value: values.menu, label: values.menu }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          placeholder="Select or Create a New Menu Type"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={4}>
        <Input
          label="Menu Internal Name"
          options={serviceType}
          optionalText="Max 26 Characters"
          placeholder="Enter Menu Internal Name"
        />
      </Column>
      <Column size={4}>
        <Select
          label="Menu Status"
          options={status}
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={4}>
        <Input label="Menu URL" />
      </Column>
      <Column size={4}>
        <Input label="Menu Type" />
      </Column>
      <Column size={4}>
        <CheckBoxWrapper>
          <CheckBox label="Private Menu" />
        </CheckBoxWrapper>
      </Column>
      <Column size={4}>
        <TagInputs label="Menu Type Keywords/ Tags" />
      </Column>

      <Wrapper>
        <fieldset className="box">
          <legend className="label">Menu Type Details</legend>
          <Column.Group vcentered multiline>
            <Column size={4}>
              <Select
                label="Menu Name"
                options={menu}
                optionalText="(Max 26 Characters )"
                value={[{ value: values.menu, label: values.menu }]}
                onChange={({ value }) =>
                  setFieldValue('status', value.toUpperCase())
                }
                placeholder="Select or Create a New Menu Type"
                errors={errors.name}
                touched={touched.name}
                required
              />
            </Column>

            <Column size={4}>
              <Select
                label="Service Type"
                options={serviceType}
                value={[
                  { value: values.serviceType, label: values.serviceType },
                ]}
                onChange={({ value }) =>
                  setFieldValue('status', value.toUpperCase())
                }
                placeholder="Select or Create a New Menu Type"
                errors={errors.name}
                touched={touched.name}
              />
            </Column>
            <Column size={4}>
              <CheckBoxWrapper>
                <CheckBox label="Private Menu" />
              </CheckBoxWrapper>
            </Column>
            <Column size={4}>
              <Input label="Menu URL" />
            </Column>
            <Column size="full">
              <TextArea
                label="Menu Type Description"
                optionalText="(Min 5 and Max 288 Characters)"
                placeHolder="Specify what is included in this Menu Type"
              />
            </Column>
            <Column size="ful">
              <TagInputs label="Menu Type Keywords/ Tags" />
            </Column>
          </Column.Group>
        </fieldset>
      </Wrapper>
      <br />
      <Column size="full">
        <TextArea
          label="Menu Type Description"
          optionalText="(Min 5 and Max 288 Characters)"
          placeHolder="Specify what is included in this Menu Type"
        />
      </Column>
    </Column.Group>

    {/* <AddAMenuForm /> */}
  </Container>
);
export default DefineMenus;
