import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, sortBy, reverse } from 'lodash';
import moment from 'moment';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  Message,
  // CustomInput,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_place_deal(input: $input) {
      total_pages
      total_size
      place_deal_listing {
        place_id
        deal_id
        name
        voucher_code
        start
        end
        place {
          place_id
          listing_status
          claim_status
          status
          approval_status
          name
          city
          state
          country
          post_code
        }
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const PlaceClaims = () => {
  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [voucherNameSort, setVoucherNameSort] = useState('AES');
  const [voucherSort, setVoucherSort] = useState('AES');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [menuNameSort, setMenuNameSort] = useState('AES');
  const [startDateSort, setStartDateSort] = useState('AES');
  const [endDateSort, setEndDateSort] = useState('AES');
  const [createdBySort, setCreatedBySort] = useState('AES');
  const [createdAtSort, setCreatedAtSort] = useState('AES');

  const [status, setStatus] = useState('current');
  const [dealStatus, setDealStatus] = useState('ACTIVE');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [voucherNameSearch, setVoucherNameSearch] = useState('');
  const [menuNameSearch, setMenuNameSearch] = useState('');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [location, setLocation] = useState('');

  const [startDateRangeFilter, setStartDateRangeFilter] = useState({
    start_date: moment()
      .subtract(6, 'months')
      .toISOString(),
    end_date: moment().toISOString(),
  });
  const [endDateRangeFilter, setEndDateRangeFilter] = useState({
    start_date: moment().toISOString(),
    end_date: moment()
      .add(6, 'months')
      .toISOString(),
  });

  const input = {};
  const place_deal_filter = {};

  Object.assign(
    place_deal_filter,
    { status: dealStatus },
    voucherNameSearch !== '' && { name: voucherNameSearch },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },

    startDateRangeFilter && {
      start_date_range: startDateRangeFilter,
    },
    endDateRangeFilter && {
      end_date_range: endDateRangeFilter,
    },
  );

  Object.assign(
    input,
    { from: pageFrom, size: listSize },
    // searchQuery !== '' && { search_criteria: searchQuery },
    location !== '' && { location },
    { sort },

    {
      filter: {
        place_deal_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <Search
          location={location}
          setLocation={setLocation}
          status={status}
          setStatus={setStatus}
          dealStatus={dealStatus}
          setDealStatus={setDealStatus}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          voucherNameSearch={voucherNameSearch}
          handleVoucherNameSearch={setVoucherNameSearch}
          menuNameSearch={menuNameSearch}
          handleMenuNameSearch={setMenuNameSearch}
          setStartDateRangeFilter={setStartDateRangeFilter}
          setEndDateRangeFilter={setEndDateRangeFilter}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setVoucherNameSort(
                          voucherNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          voucherNameSort === 'AES'
                            ? 'DEAL_NAME_DESC'
                            : 'DEAL_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          voucherNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Voucher Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setVoucherSort(voucherSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          voucherSort === 'AES'
                            ? 'VOUCHER_CODE_ASC'
                            : 'VOUCHER_CODE_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          voucherSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Voucher Code
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_ASC'
                            : 'PLACE_NAME_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_ASC'
                            : 'PLACE_CITY_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        const menu =
                          menuNameSort === 'AES'
                            ? sortBy(placeData, 'menu_name')
                            : reverse(placeData);
                        setPlaceData(menu);
                        setMenuNameSort(menuNameSort === 'AES' ? 'DES' : 'AES');
                      }}
                    >
                      <i
                        className={
                          menuNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Menu Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStartDateSort(
                          startDateSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          startDateSort === 'AES'
                            ? 'START_DATE_ASC'
                            : 'START_DATE_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          startDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Start Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEndDateSort(endDateSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          endDateSort === 'AES'
                            ? 'END_DATE_ASC'
                            : 'END_DATE_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          endDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    End Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCreatedBySort(
                          createdBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          createdBySort === 'AES'
                            ? 'UPDATED_ASC'
                            : 'UPDAETD_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          createdBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCreatedAtSort(
                          createdAtSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          createdAtSort === 'AES'
                            ? 'UPDATED_ASC'
                            : 'UPDAETD_DESC',
                        );
                      }}
                    >
                      <i
                        className={
                          createdAtSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Updated At
                  </span>
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return <Message>{error.message}</Message>;
              }
              console.log('data', data);
              const { search_place_deal } = data;
              if (isNull(search_place_deal)) {
                return <tbody>No Data Found</tbody>;
              }
              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_place_deal={search_place_deal}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_place_deal,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setPageCount(search_place_deal.total_pages);
    setTotalElements(search_place_deal.total_size);
    if (search_place_deal.place_deal_listing) {
      setPlaceData(search_place_deal.place_deal_listing);

      const userFilter = search_place_deal.place_deal_listing.map(
        item => item.audit.updated_by,
      );

      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: userFilter } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_place_deal.total_pages]);
  return (
    <tbody>
      {placeData.map(deal => (
        <ListItem deal={deal} users={users} />
      ))}
    </tbody>
  );
};

export default PlaceClaims;
