import React from 'react';
import { Column, Level, Title } from 'rbx';
import { capitalize } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Input, TextArea } from '../../../components/elements';

const ListingTypeView = ({ values, location }) => (
  <React.Fragment>
    {location.pathname !== '/add-event' && (
      <div>
        <Title size={3}>Default Estimates</Title>
        <Column.Group>
          <Column size={4}>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <TextArea label="Prep Estimation in Min" rows={1} />
                </Level.Item>
              </Level.Item>
            </Level>
          </Column>
          <Column size={4}>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <TextArea label="Delivery Estimation in Min" rows={1} />
                </Level.Item>
              </Level.Item>
            </Level>
          </Column>
          <Column size={4}>
            <Level>
              <Level.Item align="left">
                <Level.Item>
                  <TextArea label="Total Estimate" rows={1} />
                </Level.Item>
              </Level.Item>
            </Level>
          </Column>
        </Column.Group>
      </div>
    )}
  </React.Fragment>
);

export default withRouter(ListingTypeView);
