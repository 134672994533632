import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { omit, forEach, isEqual } from 'lodash';
import { Query, Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';

import { toast } from 'react-toastify';
import Layout from '../../../components/global/Layout';
import Form from './Form';
import PlaceSelection from '../../../components/PlaceSelection';
import { Loading, Message } from '../../../components/elements';
import { getGeneralFee } from '../../../utils/fetchStatic';
import client from '../../../utils/apolloClient';

const placeQuery = gql`
  query fetch_place($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      fee {
        name
        label
        # value_type
        value
        service_type
        applies_to
        sales_method
        place_managed
        can_override
        exempt_fee
        fee_value_type
      }
    }
  }
`;

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      error {
        code
        description
      }
    }
  }
`;

const EventFeeOverride = () => {
  const [place, setPlace] = useState({});
  const [placeSelection, setPlaceSelection] = useState(false);
  const [generalFee, setGeneralFee] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const fees = await getGeneralFee();
      setGeneralFee(fees);
    };
    getData();
  }, []);

  const { userId } = useStoreState(state => state.auth);
  return (
    <Layout>
      {placeSelection ? (
        <Query
          query={placeQuery}
          variables={{ placeId: place.place_id }}
          fetchPolicy="network-only"
        >
          {({ data: data1, loading, error }) => {
            if (loading) {
              return <Loading />;
            }
            if (error) {
              return <Message>error</Message>;
            }

            const { fetch_place } = data1;

            return (
              <Mutation
                client={client.clientPrivate}
                mutation={updatePlaceMutation}
                onCompleted={() => {
                  swal(
                    'Nice!',
                    'Place fee overridden successfully!',
                    'success',
                  ).then(() => {
                    setPlaceSelection(false);
                  });
                }}
              >
                {(update_place, { loading: loading3, error: error3 }) => (
                  <Form
                    generalFee={generalFee}
                    place={fetch_place}
                    loading={loading3}
                    back={() => setPlaceSelection(false)}
                    error={error3}
                    onSubmit={input => {
                      const allFee = input.eventTicketFee.concat(
                        input.eventFee,
                        input.placeManagedFee,
                      );
                      const fee = allFee.filter(
                        item => item.isOverride === true,
                      );
                      const inputFee = fee.map(item => {
                        const custom = omit(item, [
                          'id',
                          'isOverride',
                          '__typename',
                        ]);
                        return custom;
                      });

                      const removeTypename = parseValue => {
                        const final = [];
                        forEach(parseValue, item => {
                          final.push(omit(item, ['__typename']));
                        });
                        return final;
                      };

                      const isDirty = !isEqual(
                        removeTypename(fetch_place.fee),
                        inputFee,
                      );

                      if (isDirty) {
                        update_place({
                          variables: {
                            input: {
                              user_id: userId,
                              place_id: place.place_id,
                              fee: inputFee,
                            },
                          },
                        });
                      } else {
                        toast.info(
                          'Update Something then you are able to submit!',
                        );
                      }
                    }}
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
      ) : (
        <PlaceSelection
          heading="Select Place to override an event fee"
          handleSelect={value => {
            setPlace(value);
            setPlaceSelection(true);
          }}
        />
      )}
    </Layout>
  );
};

export default EventFeeOverride;
