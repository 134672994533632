import React from 'react';
import { Link } from 'react-router-dom';

const PlaceList = ({ place }) => (
  <React.Fragment>
    <td>
      <Link to={`/update-menu/${place.place_id}`}>{place.name}</Link>
    </td>
    <td>{place.city}</td>

    <td>
      <Link>
        <i className="fas fa-trash has-text-grey" />
      </Link>
    </td>
  </React.Fragment>
);

export default PlaceList;
