import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { isNull } from 'lodash';
import InputErrorMessage from './InputErrorMessage';

const customStyles = {
  container: provided => ({
    ...provided,
  }),
  control: provided => ({
    ...provided,
    borderRadius: '3px',
    minHeight: '1px',
    // height: '29px',
    fontSize: '0.8rem',
  }),
  input: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    minHeight: '1px',
    paddingTop: '0',
    paddingBottom: '0',
    color: '#757575',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    minHeight: '1px',
    height: '11px',
  }),
  clearIndicator: provided => ({
    ...provided,
    minHeight: '1px',
  }),
  valueContainer: provided => ({
    ...provided,
    minHeight: '1px',
    // height: '27px',
    paddingTop: '0',
    paddingBottom: '0',
  }),
  singleValue: provided => ({
    ...provided,
    minHeight: '1px',
    paddingBottom: '2px',
  }),
  option: provided => ({
    ...provided,
    fontSize: '0.8rem',
  }),
};

const CreatableSelectInputs = ({
  label,
  value,
  onChange,
  suggestions,
  errors,
  touched,
  disabled,
  required,
  ...props
}) => (
  <div className="field">
    <label className="label">
      {label} {required && <span className="has-text-danger">*</span>}
    </label>
    <div className="control">
      <CreatableSelect
        styles={customStyles}
        defaultValue={!isNull(value) ? value : []}
        closeMenuOnSelect={false}
        isMulti
        className="basic-multi-select"
        options={suggestions}
        onChange={onChange}
        isDisabled={disabled}
        {...props}
      />
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </div>
);

export default CreatableSelectInputs;
