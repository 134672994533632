import React, { useState } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { isNull, first, omit, forEach } from 'lodash';
import * as yup from 'yup';

import { Box, Tabs } from '../../../components/elements';
import Header from './Header';
import Footer from './Footer';

import Details from './Details';
import PlaceDetails from './PlaceDetails';
import Images from './Images';
import { placeBusinessHoursHandler } from '../../../utils/helpers';

const tabs = [
  {
    key: 0,
    name: 'Listing Details',
  },
  {
    key: 1,
    name: 'Listing Place Details',
  },
  {
    key: 3,
    name: 'Images',
  },
];

const Wrapper = styled.div`
  padding: 1rem;
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, '__typename'));
  });
  //  console.log('final', final);
  return final;
};

const checkOpen24Hours = option => {
  if (option.length === 1) {
    const time = first(option);
    if (time.start === '00:00 AM' && time.end === '11:59 PM') {
      return true;
    }
  }
  return false;
};

const removeBusinessTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    const hours = omit(item, ['option', '__typename']);
    const option = [];
    forEach(item.option, g => {
      const customOption = omit(g, ['__typename']);
      option.push(customOption);
    });
    const open24Hours = checkOpen24Hours(option);
    final.push({ ...hours, option, open24Hours });
  });
  return placeBusinessHoursHandler(final);
};

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const {
    values,
    handleSubmit,
    approvalLoading,
    placeUpdateLoading,
    setFieldValue,
    errors,
    place,
    dirty,
  } = props;

  const handleApprove = async () => {
    await setFieldValue('action', 'approve-listing');
    handleSubmit();
  };
  const handleUpdate = async () => {
    await setFieldValue('action', 'update-place');
    handleSubmit();
  };

  const prev = () =>
    activeTab === 0 ? setActiveTab(1) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 1 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  // useEffect(() => {
  //   toast.error(errors);
  // });

  return (
    <React.Fragment>
      <Header
        heading="Place approval"
        // eslint-disable-next-line max-len
        placeName={`${values.name} | ${values.city}, ${values.post_code} | ${values.country} (${place.status} / ${values.claim_status})`}
        placeUpdateLoading={placeUpdateLoading}
        approvalLoading={approvalLoading}
        handleApprove={handleApprove}
        handleUpdate={handleUpdate}
        approvalStatus={values.listing_status}
        activeTab={activeTab}
        prev={prev}
        next={next}
        dirty={dirty}
      />
      <Box>
        <Tabs>
          {tabs.map(item => (
            <Tab
              active={item.key === activeTab}
              onClick={() => setActiveTab(item.key)}
            >
              {item.name}
            </Tab>
          ))}
        </Tabs>
        <Wrapper>
          {activeTab === 0 && <Details {...props} />}{' '}
          {activeTab === 1 && <PlaceDetails {...props} />}
          {activeTab === 3 && <Images {...props} />}
        </Wrapper>
      </Box>
      <hr />
      <Footer
        placeUpdateLoading={placeUpdateLoading}
        approvalLoading={approvalLoading}
        handleApprove={handleApprove}
        handleUpdate={handleUpdate}
        approvalStatus={values.listing_status}
        prev={prev}
        next={next}
        dirty={dirty}
      />
    </React.Fragment>
  );
};

const PlaceApprovalForm = withFormik({
  mapPropsToValues: ({ place, images }) => {
    const primaryEmail =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'email_primary',
          )
        : [];
    const primaryMobile =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'phone_primary',
          )
        : [];

    const businessSite =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];
    const facebookPage =
      place && !isNull(place.social)
        ? place.social.filter(item => item.type === 'facebook')
        : [];

    return {
      action: '', // Mutation Depend upon action
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      facebookPage: facebookPage.length !== 0 ? first(facebookPage).value : '',
      allImages: images.length !== 0 ? images : [],
      rejectImages: [],
      relationShip:
        place &&
        !isNull(place.listing_audit) &&
        !isNull(place.listing_audit.listing_contact_role)
          ? place.listing_audit.listing_contact_role
          : 'Admin',
      place_id: place.place_id,
      name: place.name,
      listing_status: place.listing_status,
      claim_status: place.claim_status,
      description: !isNull(place.description) ? place.description : '',
      address_line_1: place.address_line_1,
      address_line_2: place.address_line_2,
      city: place.city,
      state: place.state,
      country: place.country,
      post_code: place.post_code,
      timezone: !isNull(place.timezone) ? place.timezone : '',
      latitude: place.latitude,
      longitude: place.longitude,
      business_hour: removeBusinessTypename(place.business_hour),
      business_type: removeTypename(place.business_type),
      service_category: removeTypename(place.service_category),
      feature: removeTypename(place.feature),
      cuisine_style: removeTypename(place.cuisine_style),
      good_for: removeTypename(place.good_for),
      dining_style: removeTypename(place.dining_style),
      listing_audit: !isNull(place.listing_audit)
        ? place.listing_audit
        : {
            listing_contact_user_id: '',
            listing_contact_name: '',
            listing_contact_phone: '',
            listing_contact_email: '',
            listing_contact_role: '',
          },
    };
  },

  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 75 Characters!')
      .required('Place Name is required!'),
    business_type: yup.array().required('Business Type is required'),
    service_category: yup.array().required('Service Category is required'),
    description: yup
      .string()
      .min(10, 'At least 10 Characters are Required!')
      .max(8000, 'Description cannot be greater than 8000 characters!'),
    address_line_1: yup.string().required('Street Address is required'),
    businessSite: yup.string().url('Should be a URL'),
    // facebookPage:yup.string().url('Should be a URL'),
    city: yup.string().required('Suburb / City is required'),
    post_code: yup.string().required('Post Code is required'),
    timezone: yup.string().required('Timezone is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
    latitude: yup.string().required('Latitude is required'),
    longitude: yup.string().required('Longitude is required'),
    business_hour: yup.array().of(
      yup.object().shape({
        option: yup.array().of(
          yup.object().shape(
            {
              start: yup.string().when(['type', 'end'], {
                is: (type, end) =>
                  (type !== undefined && type !== '' && type !== 'Closed') ||
                  (end !== undefined && end !== ''),
                then: yup.string().required('Start date is Required'),
              }),
              end: yup.string().when(['type'], {
                is: type =>
                  type !== undefined && type !== '' && type !== 'Closed',
                then: yup.string().required('End date is Required'),
              }),
              // type: yup.string().when(['start', 'end'], {
              //   is: (start, end) => start !== '' || end !== '',
              //   then: yup
              //     .string()
              //     .min(3, 'At least 3 Character Required!')
              //     .max(20, 'Not greater than 20 Character!')
              //     .required('Label is Required'),
              // }),
            },
            ['type', 'end'],
          ),
        ),
      }),
    ),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    props.onSubmit(values);
    setSubmitting(false);
  },
  displayName: 'PlaceApprovalForm',
})(Form);

export default PlaceApprovalForm;
