import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  loading,
  prev,
  next,
  status,
  handleApprovedAction,
  handleRejectAction,
  history,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Level.Item>
            <a onClick={() => history.goBack()} className="button is-light">
              <span>Cancel</span>
            </a>
          </Level.Item>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            className={`button is-danger ${loading && 'is-loading'}`}
            onClick={handleRejectAction}
            disabled={status === 'REJECTED'}
          >
            <span>Reject</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleApprovedAction}
            disabled={status === 'APPROVED'}
          >
            <span>Approve and Publish</span>
          </button>
        </Level.Item>
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next Tab</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
