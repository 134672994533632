import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { first, replace, forEach, isUndefined } from 'lodash';
import ListingAudit from './ListingAudit';
import ClaimAudit from './ClaimAudit';
import ClaimedUserLoginDetails from './ClaimedUserLoginDetails';
import {
  Select,
  Input,
  MobileInput,
  // CreatableSelectInputs,
  TextArea,
  CheckBox,
  TagInputs,
} from '../../../components/elements';

const Container = styled.div`
  fieldset {
    margin-right: 6rem;
  }
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const ClaimPlace = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  errors,
  claim,
}) => {
  console.log('values', values);
  return (
    <Container>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Column.Group gapSize={1}>
            <Column size="half">
              <Select
                label="Claimed Status"
                value={[
                  { value: values.claim_status, label: values.claim_status },
                ]}
                options={[
                  { value: 'CLAIMED', label: 'CLAIMED' },
                  { value: 'UNCLAIMED', label: 'UNCLAIMED' },
                ]}
                onChange={value => {
                  setFieldValue('claim.status', value.value);
                }}
                disabled
              />
            </Column>
            <Column size="half">
              <Input
                label="Claimed User Role"
                name="claim.contact_role"
                value={values.claim.contact_role}
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors.contact_role}
                touched={touched.contact_role}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Full Name"
            name="claim.contact_full_name"
            value={values.claim.contact_full_name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Contact Email"
            name="claim.contact_email"
            value={values.claim.contact_email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Claimed User Contact Phone"
            name="claim.contact_phone"
            value={values.claim.contact_phone}
            onChange={value =>
              setFieldValue('claim.contact_phone', replace(value, ' ', ''))
            }
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Business URL"
            name="claim.contact_business_url"
            value={values.claim.contact_business_url}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Linkedin URL"
            name="claim.contact_linked_in_url"
            value={values.claim.contact_linked_in_url}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Column>

        <Column size="half">
          <TagInputs
            label="Interested Services"
            // onChange={handleChange}
            // onBlur={handleBlur}
            disabled
            name="claim.service_type"
            suggestions={parseData(values.claim.service_type)}
            value={parseData(values.claim.service_type)}
            onChange={value => setFieldValue('service_type', parseInput(value))}
          />
        </Column>

        <Column size="half">
          <Select
            label="Vendor Verification Mode"
            value={[
              {
                value: values.claim.verification_mode,
                label: values.claim.verification_mode,
              },
            ]}
            options={[
              { value: 'SMS', label: 'SMS' },
              { value: 'FACEBOOK', label: 'FACEBOOK' },
              { value: 'PHONE', label: 'PHONE' },
              { value: 'MANUAL', label: 'MANUAL' },
              { value: 'CREDIT CARD', label: 'CREDIT CARD' },
            ]}
            disabled
          />
          <CheckBox
            label=" Authority Term Of Service & Claim Acknowledged"
            value={values.claim.agree_terms_and_conditions}
            disabled
          />
        </Column>
        <Column size="full">
          <TextArea
            label="Verification Comments"
            name="claim.contact_last_name"
            value={
              values.claim.verification_note.length !== 0
                ? first(values.claim.verification_note).value
                : ''
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
        </Column>
      </Column.Group>
      {claim.audit === null || isUndefined(claim) ? (
        <fieldset className="box">
          <legend className="label">Claimed User Login Details</legend>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size="half">
              <Input label="User Email" disabled />
            </Column>
            <Column size="half">
              <Input label="User Name" disabled />
            </Column>
            <Column size="half">
              <Input label="Claim Date & Time" disabled />
            </Column>
            <Column size="half">
              <Input label="User Phone" disabled />
            </Column>
          </Column.Group>
        </fieldset>
      ) : (
        <ClaimedUserLoginDetails
          values={values}
          claimedBy={claim.audit.created_by}
          claimedAt={claim.audit.created_at}
        />
      )}

      <ListingAudit
        values={values}
        approvedBy={values.listing_audit.listing_approved_by}
        approvedAt={values.listing_audit.listing_approved_at}
      />

      <ClaimAudit
        values={values}
        approvedBy={values.claim.place_claimant_audit.claim_approved_by}
        approvedAt={values.claim.place_claimant_audit.claim_approved_at}
      />
    </Container>
  );
};

export default ClaimPlace;
