import React from 'react';
import { Column } from 'rbx';
import { isNull } from 'lodash';
import { getEncodedImage } from '../../../utils/s3';
import ImageItem from '../../../components/ImageItem';

const PhotoReviews = ({ values, setFieldValue }) => (
  <Column.Group vcentered multiline gapSize={8}>
    <React.Fragment>
      {values.allImages.map(image => {
        const checkImage = values.rejectImages.filter(
          item => item.image_id === image.image_id,
        );

        return (
          <Column size={3} key={image.image_id}>
            <ImageItem
              isRejected={checkImage.length !== 0}
              imageSrc={getEncodedImage(image.url, 300, 300)}
              imageReviewId={image.image_review_id}
              imageSrc={image.url}
              status={image.status}
              tags={
                !isNull(image.tags) && image.tags !== 0
                  ? image.tags.map(item => ({
                      label: item,
                      value: item,
                    }))
                  : []
              }
              handleRejection={() => {
                setFieldValue(
                  'rejectImages',
                  values.rejectImages.concat(image),
                );
              }}
            />
          </Column>
        );
      })}
    </React.Fragment>
  </Column.Group>
);

export default PhotoReviews;
