import React from 'react';
import { withRouter } from 'react-router-dom';
import { Navbar } from 'rbx';
import { useStoreActions } from 'easy-peasy';

const Header = ({ history, onClickMenu }) => {
  const logout = useStoreActions(state => state.auth.logout);
  return (
    <Navbar color="primary" fixed="top">
      <Navbar.Brand>
        <Navbar.Item onClick={onClickMenu}>
          <i className=" heading is-size-3 has-text-weight-bold fas fa-bars has-text-white" />
        </Navbar.Item>
        <Navbar.Item href="#">
          <p className="heading is-size-3 has-text-weight-bold">Kravein</p>
        </Navbar.Item>
        <Navbar.Burger />
      </Navbar.Brand>
      <Navbar.Menu>
        {/* <Navbar.Segment align="start">
        <Navbar.Item>Home</Navbar.Item>
        <Navbar.Item>Documentation</Navbar.Item>

        <Navbar.Item dropdown>
          <Navbar.Link>More</Navbar.Link>
          <Navbar.Dropdown>
            <Navbar.Item>About</Navbar.Item>
            <Navbar.Item>Jobs</Navbar.Item>
            <Navbar.Item>Contact</Navbar.Item>
            <Navbar.Divider />
            <Navbar.Item>Report an issue</Navbar.Item>
          </Navbar.Dropdown>
        </Navbar.Item>
      </Navbar.Segment> */}

        <Navbar.Segment align="end">
          <Navbar.Item>Welcome</Navbar.Item>
          <Navbar.Item>Dashboard</Navbar.Item>
          <Navbar.Item>My Profile</Navbar.Item>
          <Navbar.Item>Change Password</Navbar.Item>
          <Navbar.Item onClick={() => logout(history)}>Sign out</Navbar.Item>
        </Navbar.Segment>
      </Navbar.Menu>
    </Navbar>
  );
};

export default withRouter(Header);
