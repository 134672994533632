import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import EventList from './EventList';
import SearchBox from './SearchBox';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const EventsQuery = gql`
  query searchEvents($input: SearchInput) {
    search_events(input: $input) {
      total_pages
      total_size
      event_listing {
        place_id
        event_id
        status
        display_order
        featured
        default_url
        start
        end
        place_name
        venue_name
        name
        description
        tagline
        slug
        listing_type
        voucher_count
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        error {
          description
        }
      }
    }
  }
`;

const Events = () => {
  const [status, setStatus] = useState('current');
  const [eventStatus, setEventStatus] = useState('ACTIVE');
  const [searchQuery, setSearchQuery] = useState('');
  const [eventNameSearchQuery, setEventNameSearchQuery] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [searchCity, setSearchCity] = useState('');

  const [eventData, setEventData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [eventNameSort, setEventNameSort] = useState('AES');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [listingTypeSort, setListingTypeSort] = useState('AES');
  const [startDateSort, setStartDateSort] = useState('AES');
  const [statusSort, setStatusSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [location, setLocation] = useState('');

  const [startDateRangeFilter, setStartDateRangeFilter] = useState({
    end_date: moment().toISOString(),
  });
  const [endDateRangeFilter, setEndDateRangeFilter] = useState({
    start_date: moment(`${moment().format('L')} 11:59 PM`).toISOString(),
  });

  const input = {};
  const event_filter = {};
  Object.assign(input, searchCity !== '' && { location: searchCity });
  Object.assign(
    event_filter,
    eventNameSearchQuery !== '' && { event_name: eventNameSearchQuery },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    startDateRangeFilter && {
      start_date_range: startDateRangeFilter,
    },
    endDateRangeFilter && {
      end_date_range: endDateRangeFilter,
    },
    { draft: status === 'draft' },
    { status: eventStatus },
  );
  Object.assign(
    input,
    location !== '' && { location },
    searchCity !== '' && { location: searchQuery },
    { sort },
    searchQuery !== '' && { search_criteria: searchQuery },
    { from: pageFrom, size: listSize },
    event_filter !== {} && {
      filter: {
        event_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <SearchBox
          location={location}
          setLocation={setLocation}
          searchQuery={searchQuery}
          eventNameSearchQuery={eventNameSearchQuery}
          setEventNameSearchQuery={setEventNameSearchQuery}
          status={status}
          setStatus={setStatus}
          eventStatus={eventStatus}
          setEventStatus={setEventStatus}
          handleSearchTag={setSearchQuery}
          handleLocation={setSearchCity}
          setStartDateRangeFilter={setStartDateRangeFilter}
          setEndDateRangeFilter={setEndDateRangeFilter}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEventNameSort(
                          eventNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          eventNameSort === 'AES'
                            ? 'EVENT_NAME_DESC'
                            : 'EVENT_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          eventNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Event Name
                  </span>
                  {/* <br />
            <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
            <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
            <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setListingTypeSort(
                          listingTypeSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          listingTypeSort === 'AES'
                            ? 'LISTING_TYPE_DESC'
                            : 'LISTING_TYPE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          listingTypeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Listing Type
                  </span>
                  {/* <br />
            <CustomSelect
              options={[
                { value: '', label: 'Select..' },
                { value: 'APPROVED', label: 'APPROVED' },
                { value: 'UNAPPROVED', label: 'UNAPPROVED' },
              ]}
            /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStartDateSort(
                          startDateSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          startDateSort === 'AES' ? 'START_DESC' : 'START_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          startDateSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Start Date
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setStatusSort(statusSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          statusSort === 'AES' ? 'STATUS_DESC' : 'STATUS_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          statusSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Status
                  </span>
                  <br />
                  {/* <CustomSelect
              options={[
                { value: '', label: 'Select..' },
                { value: 'APPROVED', label: 'APPROVED' },
                { value: 'UNAPPROVED', label: 'UNAPPROVED' },
              ]}
            /> */}
                </div>
              </th>
              <th>Action</th>
            </tr>
          </thead>
          <Query
            query={EventsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }
              const { search_events } = data;
              // console.log('search_events', search_events);
              const events = search_events.event_listing.filter(
                event => event.listing_type && event.listing_type.length > 0,
              );

              if (events.length === 0) {
                return <div>No Data Found</div>;
              }
              return (
                <React.Fragment>
                  <View
                    eventData={eventData}
                    setEventData={setEventData}
                    search_events={search_events}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                    queryInput={input}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default Events;

const View = ({
  search_events,
  setPageCount,
  setTotalElements,
  setEventData,
  eventData,
  queryInput,
}) => {
  useEffect(() => {
    setPageCount(search_events.total_pages);
    setTotalElements(search_events.total_size);
    if (search_events.event_listing) {
      setEventData(search_events.event_listing);
    }
  }, [search_events.total_pages]);
  return (
    <tbody>
      {eventData.map(event => (
        <tr key={event.event_id}>
          <EventList event={event} queryInput={queryInput} />
        </tr>
      ))}
    </tbody>
  );
};
