import React, { useState } from 'react';
//import { capitalize, forEach } from 'lodash';
//import styled from 'styled-components';
import { Title } from 'rbx';

import { Column } from 'rbx';
import {
 
  Input,
  
  CheckBox,
  TagInputs,
} from '../../../components/elements';


const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const ItemDetails = () => {
  //const [isManual, setManual] = useState(false);
  return (
    <div>
      <p>Item Image</p>
      <br />
      <Title>Item Information</Title>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={6}>
          <TagInputs label="Cuisine Style" />
        </Column>
        <Column size={6}>
          <TagInputs label="Item Types" />
        </Column>
        <Column size={6}>
          <TagInputs label="Dietary Information" />
        </Column>
        <Column size={3}>
          <Title>Item Review Information</Title>
          <CheckBox label="Allow Item Review" />
        </Column>
        <Column size={3}>
          <Title>Alcohol Information</Title>
          <CheckBox label="This Item contains Alcohol" />
        </Column>
      </Column.Group>

      <Title>Catering Information</Title>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={3}>
          <Input
            label="Order Minimum"
            optionalText="(Minimum Qty for the item)"
          />
        </Column>
        <Column size={3}>
          <Input
            label="Serving Size"
            optionalText="(How many people will this item serve)"
          />
        </Column>

        <Column size={3}>
          <Input label="Calories" />
        </Column>
        <Column size={3}>
          <Input label="Kilojoules" />
        </Column>
      </Column.Group>

      <Column.Group>
        <Column>
          <TagInputs label="Cuisine Style" />
        </Column>
      </Column.Group>
      <Title optionalText="(Add Additional details as labels to you menu items)">
        Additional Information
      </Title>
    </div>
  );
};

export default ItemDetails;
