import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { isEqual, isNull, omit } from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';
import tzLookup from 'tz-lookup';

import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import DealForm from '../PlaceDealAdd/DealForm';
import { Loading, Message } from '../../../components/elements';

const allNeededQuery = gql`
  query fetch_place($placeId: String, $dealId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      latitude
      longitude
    }
    fetch_place_deal(input: { deal_id: $dealId }) {
      deal_id
      place_id
      deal_type
      name
      voucher_code
      description
      tagline
      start
      end
      status
      menu_id
      menu_item
      minimum_purchase_amount
      maximum_discount_amount
      maximum_use_per_user
      maximum_usage
      # price
      # percentage
      can_be_clubbed
      is_published
      service_type {
        name
        description
        display_order
      }
      applies_to {
        name
        description
        display_order
      }
      applicable_city
      valid_for_new_user
      free_delivery
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
      error {
        code
        description
      }
    }
  }
`;

const updateDealMutation = gql`
  mutation updateDeal($input: PlaceDealInput) {
    update_place_deal(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const PlaceClaimUpdate = ({ match, history }) => (
  <Layout>
    <Query
      query={allNeededQuery}
      variables={{
        placeId: match.params.placeId,
        dealId: match.params.dealId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <Message>error</Message>;
        }

        const { fetch_place: place, fetch_place_deal } = data;
        return <View history={history} place={place} deal={fetch_place_deal} />;
      }}
    </Query>
  </Layout>
);

const View = ({ place, deal, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  useEffect(() => {
    const lat = parseFloat(place.latitude).toFixed(5);
    const long = parseFloat(place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={updateDealMutation}
      onCompleted={({ update_place_deal }) => {
        if (update_place_deal.error) {
          update_place_deal.error.map(item => toast.error(item.description));
        } else {
          swal(
            'Great!',
            'Place Deal had been updated successfully!',
            'success',
          ).then(() => {
            history.push('/place-deals');
          });
        }
      }}
    >
      {(update_place_deal, { loading: dealLoading, error: dealError }) => (
        <DealForm
          loading={dealLoading}
          error={dealError}
          place={place}
          deal={deal}
          onSubmit={value => {
            const input = omit(value, [
              'startDate',
              'endDate',
              'startTime',
              'endTime',
            ]);
            const customStartDate = moment(value.startDate).format(
              'YYYY-MM-DD',
            );
            const customStartTime = !isNull(value.startTime)
              ? moment(value.startTime).format('HH:mm:ss')
              : moment().format('HH:mm:ss');
            const customEndDate = moment(value.endDate).format('YYYY-MM-DD');
            const customEndTime = !isNull(value.endTime)
              ? moment(value.endTime).format('HH:mm:ss')
              : '23:59:00';

            const startDate = moment
              .tz(`${customStartDate} ${customStartTime}`, currentTimeZone)
              .toISOString();

            const endDate = moment
              .tz(`${customEndDate} ${customEndTime}`, currentTimeZone)
              .toISOString();
            const final = {};
            Object.assign(
              final,
              {
                user_id: userId,
              },
              { place_id: place.place_id },
              { deal_id: deal.deal_id },
              !isEqual(deal.name, input.name) && {
                name: input.name,
              },
              !isEqual(deal.status, input.status) && {
                status: input.status,
              },
              !isEqual(deal.tagline, input.tagline) && {
                tagline: input.tagline,
              },
              !isEqual(deal.voucher_code, input.voucher_code) && {
                voucher_code: input.voucher_code,
              },
              !isEqual(deal.description, input.description) && {
                description: input.description,
              },
              !isEqual(deal.deal_type, input.deal_type) && {
                deal_type: input.deal_type,
              },
              !isEqual(deal.start, startDate) && {
                start: startDate,
              },
              !isEqual(deal.end, endDate) && { end: endDate },
            );
            update_place_deal({ variables: { input: final } });
          }}
        />
      )}
    </Mutation>
  );
};

export default PlaceClaimUpdate;
