import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { forEach, first } from 'lodash';
import SocialCounts from './SocialCounts';

import {
  Input,
  TextArea,
  TagInputs,
  CheckBox,
} from '../../../components/elements';
import { getCuisines, getItemTypes } from '../../../utils/fetchStatic';
import CreatedUserDetails from './CreatedUserDetails';
import ApprovalUserDetails from './ApprovalUserDetails';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const parseInput = parseValue => {
  const final = [];
  forEach(parseValue, (item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Details = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  review,
  place,
  images,
  setFieldValue,
}) => {
  const [cuisines, setCuisines] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const cuisinesData = await getCuisines();
      const itemTypesData = await getItemTypes();
      setCuisines(cuisinesData);
      setItemTypes(itemTypesData);
    };
    getData();
  }, []);

  return (
    <Container>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={6}>
          <Input
            label="Item Name"
            name="item_name"
            value={values.item_name}
            onChange={handleChange}
          />
        </Column>
        <Column size={3}>
          <Input
            label=" Status"
            value={values.status}
            placeholder="Submitted"
            disabled
          />
        </Column>
        {/* <Column size={3}>
          <Input label="User Added Place via Item Review" disabled />
        </Column> */}
      </Column.Group>
      <div>
        <SocialCounts review={review} values={values} />
      </div>

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={3}>
          <Input label="Flavour" placeholder="4" disabled />
        </Column>
        <Column size={3}>
          <Input label="Value" placeholder="4" disabled />
        </Column>
        <Column size={3}>
          <Input label="Presentation" placeholder="4" disabled />
        </Column>
        <Column size={3}>
          <Input label="Review Rating" value={values.rating} disabled />
        </Column>
      </Column.Group>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={9}>
          <TagInputs
            label="Cuisines"
            value={parseData(values.cuisine_style)}
            suggestions={parseData(cuisines)}
            onChange={value =>
              setFieldValue('cuisine_style', parseInput(value))
            }
          />
        </Column>
        <Column size={3}>
          <Input label="Photos" value={images.length} disabled />
        </Column>
      </Column.Group>

      <Column.Group vcentered multiline gapSize={8}>
        <Column size={9}>
          <TagInputs
            label="Item Types"
            value={parseData(values.item_type)}
            suggestions={parseData(itemTypes)}
            onChange={value => setFieldValue('item_type', parseInput(value))}
          />
        </Column>

        <CheckBox
          label="User Added Place via Item Review"
          disabled
          name="item_review_place"
          value={place.item_review_place}
          onChange={value => setFieldValue('item_review_place', value)}
        />
      </Column.Group>
      {/* <LocationForm {...props} />  */}
      <Column.Group vcentered multiline gapSize={8}>
        <Column size="full">
          <TextArea
            label="Review Content"
            name="content"
            value={values.content}
            rows={5}
            onChange={handleChange}
          />
        </Column>
      </Column.Group>
      <CreatedUserDetails
        review={review}
        createdBy={review.audit.created_by}
        createdTime={review.audit.created_at}
      />

      <Column.Group>
        <Column>
          <Column.Group multiline vcentered gapSize={8}>
            <Column size={7}>
              <Input
                label="Custom Questions"
                placeholder="Is this restaurant family friendly?"
                disabled
              />
            </Column>
            <Column size={4}>
              <Input label="Response" placeholder="Yes" disabled />
            </Column>
            <Column size={7}>
              <Input
                placeholder="Is this restaurant good for dinner?"
                disabled
              />
            </Column>
            <Column size={4}>
              <Input placeholder="Yes" disabled />
            </Column>
            <Column size={7}>
              <Input
                placeholder="Can a vegetarian person get a good meal at this restaurant"
                disabled
              />
            </Column>

            <Column size={4}>
              <Input placeholder="Yes" disabled />
            </Column>
          </Column.Group>
        </Column>
      </Column.Group>
      {review.approval_status === 'SUBMITTED' ||
      review.item_review_audit === null ? (
        ''
      ) : (
        <Column>
          <ApprovalUserDetails
            review={review}
            createdBy={review.item_review_audit.item_review_approved_by}
            createdTime={review.item_review_audit.item_review_approved_at}
            rejectedBy={review.item_review_audit.item_review_rejected_by}
            rejectedAt={review.item_review_audit.item_review_rejected_at}
          />
        </Column>
      )}

      <Column.Group>
        <Column size="full">
          <TextArea
            label="Note"
            name="note"
            value={first(review.note)}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors.note}
            touched={touched.note}
            rows={2}
            required
          />
        </Column>
      </Column.Group>

      <Column.Group multiline vcentered gapSize={8} size="half">
        <Column size="half">
          <Input label="Review Shared on" placeholder="Facebook" disabled />
        </Column>

        <Column size="half">
          <Input label="Shared Status" placeholder="Success" disabled />
        </Column>

        <Column size="half">
          <Input label="Review Shared on" placeholder="Twitter" disabled />
        </Column>

        <Column size="half">
          <Input label="Shared Status" placeholder="Fail" disabled />
        </Column>
      </Column.Group>
    </Container>
  );
};

export default Details;
