import React from 'react';
import { FieldArray } from 'formik';
import { useStoreState } from 'easy-peasy';
import Dropzone from '../../../components/elements/Dropzone';
import ImageForm from '../../../components/ImageForm';
import config from '../../../utils/config';

const EventImages = ({ values, setFieldValue, setValues }) => {
  const handleRemove = url => {
    setValues({
      ...values,
      image: values.image.filter(item => item.url !== url),
    });
  };
  console.log('values', values);
  const { userId } = useStoreState(state => state.auth);
  return (
    <div>
      <div className="label">Place Deal Image</div>
      <div className="columns is-multiline">
        <FieldArray
          name="image"
          // render={helpers => (
          //   <React.Fragment>
          //     {helpers.form.values.image.map((item, index) => (
          //       <div className="column is-3">
          //         <ImageForm
          //           item={item}
          //           index={index}
          //           url={`https://${config.apiPrefix}.images.kravein.com.au/${item.url}`}
          //           handleChange={helpers.form.handleChange}
          //           handleBlur={helpers.form.handleBlur}
          //           setFieldValue={helpers.form.setFieldValue}
          //           onDelete={() => handleRemove(item.url)}
          //         />
          //       </div>
          //     ))}
          //   </React.Fragment>
          // )}
        />
        {/* {values.image.length === 0 && ( */}
        <div className="column is-3">
          <Dropzone
            s3UploadPath={`place-deal/${values.deal_id}/`}
            metaData={{
              deal_id: values.deal_id,
              user_id: userId,
            }}
            onChange={image => {
              const imageValues = values.image;
              const order = imageValues.length + 1;
              imageValues.push({
                url: `place-deal/${values.deal_id}/${image.path}`,
                tooltip: `image${order}`,
                link: `https://${config.apiPrefix}images.kravein.com.au/`,
                display_order: order.toString(8),
                tags: [],
              });
              setFieldValue('image', imageValues);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EventImages;
