import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab, Title } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
//import uuidv4 from 'uuid/v4';
import MenuSection from './MenuSection';
import AssignItems from './AssignItems';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 0,
    name: 'Menu Section',
  },
  {
    key: 1,
    name: 'Assign Items',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, place, loading, isAddComponent } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(1) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 1 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  return (
    <React.Fragment>
      <Header />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>

        <Container>
          {activeTab === 0 && <MenuSection {...props} />}
          {activeTab === 1 && <AssignItems />}
        </Container>
      </Box>
      <hr />
      <Footer prev={prev} next={next} />
    </React.Fragment>
  );
};

const PlaceActivity = withFormik({
  mapPropsToValues: () => ({}),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PlaceActivity',
})(Form);

export default PlaceActivity;
