import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';

const CheckInList = ({ review, users }) => {
  const userArray = users.filter(
    item => item.user_id === review.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <tr>
      <td>
        <Link to={`/item-review/${review.item_id}/${review.item_review_id}`}>
          {review.item_name}
        </Link>
      </td>
      <td>{review.place.name}</td>
      <td>{review.place.city}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user && user.full_name}>{user && user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user && user.email} </p>
                <p>objectId: {user && user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          review.audit.updated_by
        )}
      </td>
      <td>{moment(review.audit.updated_at).format('lll')}</td>
      {/* <td>{review.status}</td> */}
    </tr>
  );
};

export default CheckInList;
