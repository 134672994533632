import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import MenuSearch from './MenuSearch';
import CreateMenuList from './CreateMenuList';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const placesQuery = gql`
  query searchPlaces($input: SearchInput) {
    search_places(input: $input) {
      total_pages
      total_size
      place_listing {
        vendor_id
        place_id
        display_order
        is_open
        listing_status
        claim_status
        featured
        event_business
        item_review_place
        status
        approval_status
        voucher_count
        default_url
        price_range
        name
        trading_name
        tagline
        description
        slug
        stars
        review_count
        like_percentage
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        audit {
          updated_by
          updated_at
        }
      }
    }
  }
`;

const Places = () => {
  const [placeData, setPlaceData] = useState([]);
  const [sort, setSort] = useState('CREATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(10);

  const [vendorNameSearchInput, setVendorNameSearchInput] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [listingApproved, setListingApproved] = useState('APPROVED');

  const [eventBusiness, setEventBusiness] = useState('');
  const [itemReviewPlace, setItemReviewPlace] = useState('');

  const input = {};
  const place_filter = {};
  Object.assign(
    place_filter,
    vendorNameSearchInput !== '' && { vendor_name: vendorNameSearchInput },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    listingApproved !== '' && { listing_status: listingApproved },
    eventBusiness && { event_business: eventBusiness },
    itemReviewPlace && { item_review_place: itemReviewPlace },
  );

  Object.assign(
    input,
    location !== '' && { location },
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        place_filter,
      },
    },
  );

  return (
    <Layout>
      <Header />
      <Box>
        <MenuSearch
          vendorNameSearchInput={vendorNameSearchInput}
          setVendorNameSearchInput={setVendorNameSearchInput}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
          listingApproved={listingApproved}
          setListingApproved={setListingApproved}
          eventBusiness={eventBusiness}
          setEventBusiness={setEventBusiness}
          itemReviewPlace={itemReviewPlace}
          setItemReviewPlace={setItemReviewPlace}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>Actions</th>
            </tr>
          </thead>
          <Query
            query={placesQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_places } = data;

              return (
                <React.Fragment>
                  <View
                    placeData={placeData}
                    setPlaceData={setPlaceData}
                    search_places={search_places}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_places,
  setPageCount,
  setTotalElements,
  setPlaceData,
  placeData,
}) => {
  useEffect(() => {
    setPageCount(search_places.total_pages);
    setTotalElements(search_places.total_size);
    if (search_places.place_listing) {
      setPlaceData(search_places.place_listing);
    }
  }, [search_places.total_pages]);

  return (
    <tbody>
      {placeData.map(place => (
        <tr key={place.place_id}>
          <CreateMenuList place={place} />
        </tr>
      ))}
    </tbody>
  );
};

export default Places;
