import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import { PageHeader } from '../../../components/elements';

const Header = ({
  activeTab,
  loading,
  handleSubmit,
  location,
  companyName,
  status,
  next,
  prev,
}) => (
  <PageHeader
    title={
      location.pathname === '/add-company'
        ? 'Add a Company'
        : `Edit - ${companyName}`
    }
    // otherText={location.pathname === '/add-company' ? '' : `${companyName} `}
  >
    <Level.Item align="right">
      <Level.Item>
        <Link to="/companies" className="button is-light">
          <span>Cancel</span>
        </Link>
      </Level.Item>
      {activeTab !== 0 && (
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      )}

      <Level.Item>
        <button
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
        >
          <span>Submit</span>
        </button>
      </Level.Item>
      {activeTab !== 2 && (
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next</span>
          </button>
        </Level.Item>
      )}
    </Level.Item>
  </PageHeader>
);

export default withRouter(Header);
