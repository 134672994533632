import React from 'react';
import moment from 'moment';

const PlaceFollowedList = ({ social }) => (
  <tr>
    <td>{social.user.display_name}</td>
    <td>{social.user.email}</td>
    <td>{moment(social.audit.created_at).format('lll')}</td>
  </tr>
);
export default PlaceFollowedList;
