import React from 'react';
import { Link } from 'react-router-dom';
import { isNull, first } from 'lodash';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

const DealList = ({ deal, users }) => {
  const userArray = users.filter(
    item => item.user_id === deal.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;
  return (
    <tr>
      <td>
        <Link to={`/update-place-deal/${deal.place_id}/${deal.deal_id}`}>
          {deal.name}
        </Link>
      </td>
      <td>{deal.voucher_code}</td>
      <td>{deal.place.name}</td>
      <td>{deal.city}</td>
      <td> - </td>
      <td>{isNull(deal.start) ? 'N.A' : moment(deal.start).format('ll')}</td>
      <td>{isNull(deal.end) ? 'N.A' : moment(deal.end).format('ll')}</td>
      <td>
        {user ? (
          <React.Fragment>
            <p data-tip={user.full_name}>{user.full_name}</p>
            <ReactTooltip place="top" type="dark" effect="float">
              <span>
                <p>email: {user.email}</p>
                <p>objectId: {user.user_id}</p>
              </span>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          deal.audit.created_by
        )}
      </td>
      <td>{moment(deal.audit.updated_at).format('lll')}</td>{' '}
      <td>
        <Link>
          <i className="fas fa-trash has-text-grey" />
        </Link>
      </td>
    </tr>
  );
};

export default DealList;
