import React, { useState } from 'react';
// import { capitalize, forEach } from 'lodash';
import styled from 'styled-components';
import { Column } from 'rbx';
import AddAMenuForm from './AddAMenuForm';
import {
  Select,
  Input,
  TextArea,
  CheckBox,
  TagInputs,
} from '../../../components/elements';

const CheckBoxWrapper = styled.div``;

const menu = [
  { value: 'MENU1', label: 'MENU1' },
  { value: 'MENU2', label: 'MENU2' },
];

const status = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
];

const serviceType = [
  { value: 'SELECT SERVICE TYPE', label: 'SELECT SERVICE TYPE' },
  { value: 'SUBMITTED', label: 'SUBMITTED' },
  { value: 'REJECTED', label: 'REJECTED' },
];
const approvalStatus = [
  { value: 'APPROVED', label: 'APPROVED' },
  { value: 'REJECTED', label: 'REJECTED' },
];

{
  /* const placeTagInput = tagsValue => {
  const tags = [];
  tagsValue.map(item => {
    tags.push(item.value);
    return null;
  });
  return tags;
}; 
const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item, label: item });
  });
  return final;
};
*/
}

const DefineMenus = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => (
  // const [isManual, setManual] = useState(false);
  <div>
    <Column.Group vcentered multiline gapSize={8}>
      <Column size={4}>
        <Select
          label="Menu Type Name"
          options={menu}
          optionalText="(Max 26 Characters )"
          value={[{ value: values.menu, label: values.menu }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          placeholder="Select or Create a New Menu Type"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={4}>
        <Select
          label="Service Type "
          options={serviceType}
          optionalText="Select the applicable service or shopping cart to enable this Menu Type"
          value={[{ value: values.serviceType, label: values.serviceType }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          placeholder="Select Service Type"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      <Column size={4}>
        <Select
          label="Menu Type Status"
          options={status}
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>
      {/* <Column size={4}>
          <CheckBoxWrapper>
            <CheckBox label="Private Menu" />
          </CheckBoxWrapper>
        </Column>
        <Column size={4}>
          <Input label="Menu URL" />
        </Column>
        <Column size={4}>
          <Select
            label="Approval Status"
            options={approvalStatus}
            value={[
              {
                value: values.approval_status,
                label: values.approval_status,
              },
            ]}
            onChange={({ value }) =>
              setFieldValue('approval_status', value.toUpperCase())
            }
            required
          />
        </Column> */}
      <Column size="full">
        <TextArea
          label="Menu Type Description"
          optionalText="(Min 5 and Max 288 Characters)"
          placeHolder="Specify what is included in this Menu Type"
        />
      </Column>
      {/* <Column size="full">
        <TagInputs label="Menu Type Keywords/ Tags" />
      </Column> */}
    </Column.Group>
    {/* <div className="content is-large">
        Menu Categories{' '}
        <div className="content is-medium">
          (Add your Menus and Home Hours){' '}
          <p className="content is-small">
            If you have different Menu's for a Menu Type or different service
            hours per Menu, create multiple Menu's such as 'All Day Menu',
            'Dinner Menu'. You can also create multiple Menu's if you have
            multiple outlets in the same Place.
          </p>
        </div>
      </div> */}
    {/* <AddAMenuForm /> */}
  </div>
);
export default DefineMenus;
