import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const CheckInList = ({ review, setPageCount, totalPages }) => {
  useEffect(() => {
    setPageCount();
  }, [totalPages]);
  return (
    <tr>
      <td>
        <Link to="/manage-menu-item">Chicken Wings</Link>
      </td>
      <td>Las Tapas Diner</td>
      <td>St Kilda</td>
      <td>Delivery Menu - All Day Menu: Entree</td>
      <td>$20.00</td>
      <td>Y</td>
      <td>--</td>
      <td>4</td>
      <td>Super Admin </td>
      <td>24/06/2019</td>
      <td>Approved</td>
    </tr>
  );
};

export default CheckInList;
