import React, { useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Tab } from 'rbx';
import uuidv4 from 'uuid/v4';
import { isNull, first, uniqBy } from 'lodash';
import voucher_codes from 'voucher-code-generator';
import moment from 'moment';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import VoucherDetails from './VoucherDetails';
import DiscountDetails from './DiscountDetails';
import VoucherImages from './VoucherImages';

const Container = styled.div`
  padding: 1rem;
`;

const tabs = [
  {
    key: 'voucher-details',
    name: 'Voucher Details',
  },
  {
    key: 'discount-details',
    name: 'Discount Details',
  },
  {
    key: 'voucher-images',
    name: 'Voucher Images',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState('voucher-details');
  const { handleSubmit, place, values, loading, isAddDealForm } = props;
  console.log('values', values);
  return (
    <React.Fragment>
      <Header
        heading={`${
          isAddDealForm ? 'Add an Event deal' : 'Edit an Event deal'
        }`}
        placeName={`${place.name} | ${place.city}, ${place.post_code} | ${place.country}`}
        dealName={values.name}
        handleSubmit={handleSubmit}
        loading={loading}
      />

      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.key}
              key={tab.key}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Container>
          {activeTab === 'voucher-details' && (
            <VoucherDetails {...props} placeName={place.name} />
          )}
          {activeTab === 'discount-details' && <DiscountDetails {...props} />}
          {activeTab === 'voucher-images' && <VoucherImages {...props} />}
        </Container>
      </Box>
      <hr />
      <Footer loading={loading} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
};

const DealForm = withFormik({
  mapPropsToValues: ({ place, deal }) => {
    const defaultCouponCode = voucher_codes.generate({
      length: 10,
      count: 1,
      charset: voucher_codes.charset('alphabetic'),
    });

    const applies_to_event = deal ? deal.applies_to : [];

    const applies_to_tickets = deal ? deal.applies_to : [];

    const startDate = deal && !isNull(deal.start) ? moment(deal.start) : null;
    const endDate = deal && !isNull(deal.end) ? moment(deal.end) : null;

    return {
      eventsSelection: '',
      ticketsSelection: '',
      place_id: deal && !isNull(deal.place_id) ? deal.place_id : place.place_id,
      deal_id: deal && !isNull(deal.deal_id) ? deal.deal_id : uuidv4(),
      applies_to_event: uniqBy(applies_to_event, 'event_id'),
      applies_to_tickets,
      name: deal && !isNull(deal.name) ? deal.name : '',
      status: deal && !isNull(deal.status) ? deal.status : 'ACTIVE',
      approval_status:
        deal && !isNull(deal.approval_status)
          ? deal.approval_status
          : 'APPROVED',
      voucher_code:
        deal && !isNull(deal.voucher_code)
          ? deal.voucher_code
          : first(defaultCouponCode).toUpperCase(),
      description: deal && !isNull(deal.description) ? deal.description : '',
      tagline: deal && !isNull(deal.tagline) ? deal.tagline : '',
      startDate: deal ? moment(startDate).toISOString() : null,
      startTime: deal ? moment(startDate).toISOString() : null,
      endDate: deal ? moment(endDate).toISOString() : null,
      endTime: deal ? moment(endDate).toISOString() : null,
      discount_type:
        deal && !isNull(deal.discount_type) ? deal.discount_type : 'Amount',
      value:
        deal && !isNull(deal.value)
          ? parseFloat(deal.value).toFixed(2)
          : parseFloat(0).toFixed(2),
      maximum_discount_amount:
        deal && !isNull(deal.maximum_discount_amount)
          ? parseFloat(deal.maximum_discount_amount).toFixed(2)
          : '',
      minimum_purchase_amount:
        deal && !isNull(deal.minimum_purchase_amount)
          ? parseFloat(deal.minimum_purchase_amount).toFixed(2)
          : '',
      image: [],
      valid_for_new_user:
        deal && !isNull(deal.valid_for_new_user)
          ? deal.valid_for_new_user
          : false,
      maximum_usage:
        deal && !isNull(deal.maximum_usage) ? deal.maximum_usage : 999999,
      maximum_use_per_user:
        deal && !isNull(deal.maximum_use_per_user)
          ? deal.maximum_use_per_user
          : 999999,
      can_be_clubbed:
        deal && !isNull(deal.can_be_clubbed) ? deal.can_be_clubbed : false,
      is_published:
        deal && !isNull(deal.is_published) ? deal.is_published : true,
    };
  },

  validate: values => {
    const errors = {};
    if (values.discount_type !== 'Percentage') {
      if (
        parseFloat(values.minimum_purchase_amount) < parseFloat(values.value)
      ) {
        errors.minimum_purchase_amount =
          'Minimum purchase amount should be greater than or equal to discount amount';
      }
    }

    if (parseFloat(values.maximum_discount_amount) < parseFloat(values.value)) {
      errors.maximum_discount_amount =
        'Maximum discount amount should be greater than or equal to discount amount';
    }
    if (values.discount_type === 'Percentage') {
      if (values.value > 100) {
        errors.value = 'Discount percentage should be less than 100';
      }
    }
    return errors;
  },

  validationSchema: () =>
    yup.object().shape({
      name: yup
        .string()
        .min(4, 'At least 4 Characters Required!')
        .max(50, 'Not greater than 50 Characters!')
        .required('Deal Name is required!'),
      voucher_code: yup
        .string()
        .min(5, 'At least 5 Characters Required!')
        .max(10, 'Not greater than 10 Characters!')
        .required('Voucher code is required!'),
      startDate: yup
        .string()
        .transform(v => (v === null ? '' : v))
        .required('Start date is required'),
      endDate: yup
        .string()
        .transform(v => (v === null ? '' : v))
        .required('End date is required'),
      tagline: yup
        .string()
        .min(10, 'At least 10 Characters Required!')
        .max(50, 'Not greater than 50 Characters!')
        .required('Event Voucher Tagline is required!'),
      description: yup
        .string()
        .min(100, 'At least 100 Characters Required!')
        .max(500, 'Not greater than 500 Characters!'),
      minimum_purchase_amount: yup.number(),
      discount_type: yup.string(),
      maximum_usage: yup
        .number()
        .lessThan(0, 'Negative number is not accepted')
        .max(999999, 'Only accept 6 digit number'),
      maximum_use_per_user: yup
        .number()
        .lessThan(0, 'Negative number is not accepted')
        .max(999999, 'Only accept 6 digit number'),
    }),

  handleSubmit: (values, { props, setSubmitting }) => {
    console.log(values);
    props.onSubmit(values);
    setSubmitting(false);
  },

  displayName: 'BasicForm',
})(Form);

export default DealForm;
