import React from 'react';
import { Column, Level } from 'rbx';
import styled from 'styled-components';
import moment from 'moment';
import TimeDatePicker from '../../../components/global/TimeDatePicker';
import {
  Select,
  TextArea,
  Input,
  CheckBox,
  ReactDateTimePicker,
  CreatableSelectInputs,
  TagInputs,
} from '../../../components/elements';

const DeliveryZone = () => {
  return (
    <Column.Group multiline gapSize={8}>
      <Column size={4}>
        <Input
          label="Zone 1"
          name="name"
          // value={values.name}
          //onChange={handleChange}
          required
        />
      </Column>
      <Column size={4}>
        <Input label="Delivery Fee" placeholder="$2.00" />
      </Column>
      <Column size={4}>
        <Select
          label="Approval Status"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          //  value={[{ value: values.status, label: values.status }]}
          required
        />
      </Column>
      <Column size={6}>
        <TagInputs />
      </Column>
    </Column.Group>
  );
};
export default DeliveryZone;
