import React from 'react';
import styled from 'styled-components';
import { Column, Title } from 'rbx';
// import { Query } from 'react-apollo';
// import { isNull } from 'lodash';
// import gql from 'graphql-tag';
import moment from 'moment';
import Header from './Header';

import { Input, TextArea } from '../../../components/elements';

// const placesQuery = gql`
//   query searchPlaces($input: SearchInput) {
//     search_place_claim(input: $input) {
//       place_id
//       claimant_id
//       name
//       city
//       status
//       claim_status
//     }
//   }
// `;

const Container = styled.div`
  padding: 0rem 1rem;
`;

const EmailInvitation = ({ event }) => {
  console.log('event', event);
  return (
    <Container>
      <br />
      <Header event={event} subHeading3={moment(event.start).format('LLLL')} />

      <hr />
      <Title size={1}>Compose New Message</Title>
      <Column.Group vcentered multiline gapSize={8}>
        <Column size={4}>
          <Input label="Sender Name" />
        </Column>

        <Column size={4}>
          <Input label="Reply To" />
        </Column>

        <Column size="full">
          <Input label="Subject" />
        </Column>
      </Column.Group>
      <br />
      <Title size={1}>Add Invitees</Title>
      <Column.Group>
        <Column size="full" rows={4}>
          <TextArea
            label="Email Address(Use comma(,) sign for multiple email address)"
            required
            placeholder="Email Address"
            required
          />
        </Column>
      </Column.Group>
      <Column.Group>
        <Column size="full" rows={4}>
          <TextArea
            label="Body Text"
            required
            placeholder="We invite you to Rock and Roll Party"
            required
          />
        </Column>
      </Column.Group>
    </Container>
  );
};

export default EmailInvitation;
