import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import moment from 'moment';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';
import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const flaggedReviewsQuery = gql`
  query searchFlaggedReview($input: SearchInput) {
    search_flags(input: $input) {
      total_pages
      total_size
      flag_listing {
        place {
          place_id
          name
          city
        }
        flag_id
        flag_type
        object_type
        object_id
        parent_id
        flag_content
        status
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;
const FlaggedReviews = props => {
  const routeState = qs.parse(props.location.search);
  const { status } = routeState;

  const [flagData, setFlagData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [reviewTypeSort, setReviewTypeSort] = useState('AES');
  const [flagTypeSort, setFlagTypeSort] = useState('AES');
  const [flaggedBySort, setFlaggedBySort] = useState('AES');
  const [flaggedOnSort, setFlaggedOnSort] = useState('AES');

  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [flagType, setFlagType] = useState('');
  const [reviewType, setReviewType] = useState('');
  const [reviewOn, setReviewOn] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();
  const input = {};
  const flag_filter = {};
  Object.assign(
    flag_filter,
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    { status },
    flagType !== '' && { flag_type: flagType },
    reviewType !== '' && { object_type: reviewType },
    reviewOn !== null && {
      date_range: {
        start_date: moment(reviewOn).toISOString(),
        end_date: moment(reviewOn)
          .add(1, 'day')
          .toISOString(),
      },
    },
  );

  Object.assign(
    input,
    { from: pageFrom, size: listSize },
    { sort },
    {
      filter: {
        flag_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          flagType={flagType}
          setFlagType={setFlagType}
          reviewType={reviewType}
          reviewOn={reviewOn}
          setReviewType={setReviewType}
          setReviewOn={setReviewOn}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC'
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setReviewTypeSort(
                          reviewTypeSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          reviewTypeSort === 'AES'
                            ? 'OBJECT_TYPE_DESC'
                            : 'OBJECT_TYPE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          reviewTypeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Review Type
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFlagTypeSort(flagTypeSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          flagTypeSort === 'AES'
                            ? 'FLAG_TYPE_DESC'
                            : 'FLAG_TYPE_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          flagTypeSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    Flag Type
                  </span>
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFlaggedBySort(
                          flaggedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          flaggedBySort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          flaggedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFlaggedOnSort(
                          flaggedOnSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          flaggedOnSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          flaggedOnSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated On
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={flaggedReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_flags } = data;

              return (
                <React.Fragment>
                  <View
                    flagData={flagData}
                    setFlagData={setFlagData}
                    search_flags={search_flags}
                    setPageCount={setPageCount}
                    setTotalElements={setTotalElements}
                  />
                </React.Fragment>
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_flags,
  setPageCount,
  setTotalElements,
  setFlagData,
  flagData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_flags.total_pages);
    setTotalElements(search_flags.total_size);
    if (search_flags.flag_listing) {
      setFlagData(search_flags.flag_listing);

      const userFilter = search_flags.flag_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: userFilter } } },
          },
        })
        .then(({ data: { search_users } }) => {
          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_flags.total_pages]);
  return (
    <tbody>
      {flagData.map(flag => (
        <ListItem flag={flag} users={users} />
      ))}
    </tbody>
  );
};

export default FlaggedReviews;
