import React from 'react';
import styled from 'styled-components';
import { FieldArray, Field, getIn } from 'formik';
import { Table } from 'rbx';
import {
  Input,
  Table as CustomTable,
  CheckBox,
} from '../../../components/elements';

const Container = styled.div`
  .table td,
  .table th {
    border: 0;
  }
`;

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? (
        <div className="has-text-danger	is-size-5">{error}</div>
      ) : null;
    }}
  />
);

const EventFee = ({ values, handleChange, handleBlur, setFieldValue }) => (
  <Container>
    <legend className="label">Event Fees</legend>
    <CustomTable>
      <Table.Head>
        <Table.Row>
          <Table.Heading>Fee Name</Table.Heading>
          <Table.Heading>Fee Display Label</Table.Heading>
          <Table.Heading>Sale Method</Table.Heading>
          <Table.Heading>Override</Table.Heading>
          <Table.Heading>Fee Type</Table.Heading>
          <Table.Heading>Fee</Table.Heading>
          <Table.Heading>Applies To</Table.Heading>
          <Table.Heading>Exempt Fee</Table.Heading>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <FieldArray
          name="eventFee"
          render={() => (
            <React.Fragment>
              {values.eventFee.map((item, index) => (
                <Table.Row>
                  <Table.Cell>
                    <Input value={item.name} disabled />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={item.label}
                      name={`eventFee.${index}.label`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={item.exempt_fee || !item.isOverride}
                    />
                    <ErrorMessage name={`eventFee.${index}.label`} />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={item.sales_method}
                      name={`eventFee.${index}.sales_method`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    {/* <ErrorMessage name={`eventFee.${index}.sales_method`} /> */}
                  </Table.Cell>
                  <Table.Cell>
                    {!item.can_override ? (
                      <div className="is-size-1 has-text-danger">
                        <i className="fas fa-times-circle" />
                      </div>
                    ) : (
                      <CheckBox
                        label=""
                        value={item.isOverride}
                        onChange={value =>
                          setFieldValue(`eventFee.${index}.isOverride`, value)
                        }
                        disabled={item.exempt_fee}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={item.fee_value_type}
                      name={`eventFee.${index}.fee_value_type`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    <ErrorMessage name={`eventFee.${index}.fee_value_type`} />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={item.value}
                      type="number"
                      name={`eventFee.${index}.value`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={item.exempt_fee || !item.isOverride}
                    />
                    <ErrorMessage name={`eventFee.${index}.value`} />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      value={item.applies_to}
                      name={`eventFee.${index}.applies_to`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    <ErrorMessage name={`eventFee.${index}.applies_to`} />
                  </Table.Cell>
                  <Table.Cell>
                    <CheckBox
                      label=""
                      value={item.exempt_fee}
                      onChange={value => {
                        setFieldValue(`eventFee.${index}.exempt_fee`, value);
                        setFieldValue(`eventFee.${index}.isOverride`, value);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </React.Fragment>
          )}
        />
      </Table.Body>
    </CustomTable>
  </Container>
);

export default EventFee;
