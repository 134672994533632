import React from 'react';
import { Column } from 'rbx';
import { isNull } from 'lodash';
import { getEncodedImage } from '../../../utils/s3';
import ImageItem from '../../../components/ImageItem';

const PhotoReviews = ({ values, setFieldValue, review }) => (
  <React.Fragment>
    <Column.Group vcentered multiline gapSize={8}>
      <React.Fragment>
        <Column size={3}>
          <ImageItem
            isRejected={values.rejectImages.length !== 0}
            imageReviewId={review.image_review_id}
            imageSrc={getEncodedImage(review.url, 300, 300)}
            status={review.status}
            tags={
              !isNull(review.tags) && review.tags !== 0
                ? review.tags.map(item => ({
                    label: item,
                    value: item,
                  }))
                : []
            }
            handleRejection={() => {
              setFieldValue(
                'rejectImages',
                values.rejectImages.concat(values.images),
              );
            }}
          />
        </Column>
      </React.Fragment>
    </Column.Group>
  </React.Fragment>
);

export default PhotoReviews;
