import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { isUndefined } from 'lodash';
import GlobalFooter from './GlobalFooter';

import EventDashboard from './EventDashboard';
import TicketList from './TicketList';
import BookingSummary from './BookingSummary';
import Attendees from './Attendees';

import { Box, Tabs } from '../../../components/elements';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const TabsStyle = styled(Tabs)`
  &&& {
    margin: 0;
  }
`;
const tabs = [
  {
    key: 0,
    name: 'Event Dashboard',
  },
  {
    key: 1,
    name: 'Booking Summary',
  },
  {
    key: 2,
    name: 'Attendees',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { event, eventOccurrence, bookingSummary } = props;

  return (
    <Box>
      <TabsStyle className="tabs is-medium">
        {tabs.map(tab => (
          <Tab
            active={activeTab === tab.key}
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.name}
          </Tab>
        ))}
      </TabsStyle>
      {activeTab === 0 && (
        <>
          <EventDashboard
            event={event}
            eventOccurrence={eventOccurrence}
            bookingSummary={bookingSummary}
          />
          {isUndefined(eventOccurrence) ? (
            ''
          ) : (
            <TicketList
              event={event}
              eventOccurrenceId={eventOccurrence.event_occurrence_id}
            />
          )}
          <br />
          <GlobalFooter />
        </>
      )}
      <Container>
        {activeTab === 1 && <BookingSummary event={event} />}
        {activeTab === 2 && <Attendees event={event} activeTab={activeTab} />}
      </Container>
    </Box>
  );
};

const ManageEvent = withFormik({
  mapPropsToValues: () => ({}),

  validationSchema: yup.object().shape({}),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'ManageEvent',
})(Form);

export default ManageEvent;
