/* eslint-disable no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isNull, first, capitalize } from 'lodash';
import Header from './Header';

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  padding-bottom: 1rem;
  display: flex;
`;

const BoldText = styled.p.attrs({
  className: 'has-text-weight-bold is-size-4',
})``;

const Text = styled.p`
  padding-left: 0.5rem;
`;

const BookingInfo = ({ booking }) => {
  const bookingFeeArray =
    booking.fee && !isNull(booking.fee)
      ? booking.fee.filter(item => item.name === 'booking_fee')
      : [];

  const transactionFeeArray =
    booking.fee && !isNull(booking.fee)
      ? booking.fee.filter(item => item.name === 'transaction_fee')
      : [];

  const paymentArray =
    booking.payment && !isNull(booking.payment)
      ? booking.payment.filter(
          item =>
            item.type === 'credit-card' ||
            item.type === 'debit-card' ||
            item.type === 'paypal',
        )
      : [];

  const bookingFee =
    bookingFeeArray.length !== 0 ? first(bookingFeeArray).value : 0;

  const payment = paymentArray.length !== 0 ? first(paymentArray).type : 'N.A';

  console.log('booking', booking);
  const transactionFee =
    transactionFeeArray.length !== 0 ? first(transactionFeeArray).value : 0;

  return (
    <Flex>
      <div>
        <TextWrapper>
          <BoldText>Booked by:</BoldText>{' '}
          <Text>{booking.booking.booked_by}</Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Attendees: </BoldText>
          <Text>{booking.total_attendee_count}</Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Total Price ($):</BoldText>
          <Text>
            {parseFloat(booking.total_price + booking.discount).toFixed(2)}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Booking Fee ($):</BoldText>
          <Text>{bookingFee}</Text>
        </TextWrapper>
      </div>
      <div>
        <TextWrapper>
          <BoldText>Purchased by:</BoldText>
          <Text>
            {isNull(
              `${booking.purchaser.first_name} ${booking.purchaser.last_name}`,
            )
              ? 'N.A'
              : `${booking.purchaser.first_name} ${booking.purchaser.last_name}`}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Payment Type: </BoldText>
          <Text>
            {payment === 'credit-card'
              ? 'CREDIT CARD'
              : payment === 'debit-card'
              ? 'DEBIT CARD'
              : payment === 'paypal'
              ? 'PAYPAL'
              : 'N.A'}
          </Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Discount Applied ($):</BoldText>
          <Text>{isNull(booking.discount) ? 0 : booking.discount}</Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>Ticket Fee ($):</BoldText>
          <Text>{parseFloat(booking.total_fee).toFixed(2)}</Text>
        </TextWrapper>
      </div>
      <div>
        <TextWrapper>
          <BoldText>Email ID: </BoldText>
          <Text>
            {isNull(booking.purchaser) ? 'N.A' : booking.purchaser.email}
          </Text>{' '}
        </TextWrapper>
        <TextWrapper>
          <BoldText>Date and Time: </BoldText>
          <Text>{moment(booking.timestamp).format('LLL')}</Text>{' '}
        </TextWrapper>
        <TextWrapper>
          <BoldText>Payment ($):</BoldText>
          <Text>{parseFloat(booking.total_price - booking.discount).toFixed(2)}</Text>
        </TextWrapper>
        <TextWrapper>
          <BoldText>CC Transaction Fee ($):</BoldText>
          <Text>{transactionFee}</Text>
        </TextWrapper>
      </div>
    </Flex>
  );
};
export default BookingInfo;
