import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { isNull, uniq } from 'lodash';
import moment from 'moment';
import qs from 'querystringify';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  // Pagination,
  Table,
  Box,
  // ReactDateTimePicker,
  Pagination,
  // CustomInput,
  // CustomSelect,
} from '../../../components/elements';

const searchItemReviewsQuery = gql`
  query searchItemReviews($input: SearchInput) {
    search_item_review(input: $input) {
      total_pages
      total_size
      item_review_listing {
        item_id
        item_review_id
        item_name
        status
        place {
          place_id
          name
          city
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
      }
    }
  }
`;

const usersQuery = gql`
  query searchUsers($input: SearchInput) {
    search_users(input: $input) {
      user_listing {
        user_id
        display_name
        first_name
        last_name
        full_name
        email
      }
    }
  }
`;

const ItemReviews = props => {
  const routeState = qs.parse(props.location.search);
  const { status } = routeState;

  const [reviewData, setReviewData] = useState([]);
  const [sort, setSort] = useState('UPDATED_DESC');
  const [itemNameSort, setItemNameSort] = useState('AES');
  const [placeNameSort, setPlaceNameSort] = useState('AES');
  const [citySort, setCitySort] = useState('AES');
  const [reviewedBySort, setReviewedBySort] = useState('AES');
  const [reviewedOnSort, setReviewedOnSort] = useState('AES');
  const [itemNameFilter, setItemNameFilter] = useState('');
  const [placeNameSearchInput, setPlaceNameSearchInput] = useState('');
  const [location, setLocation] = useState('');
  const [reviewOn, setReviewOn] = useState(null);

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const item_review_filter = {};
  const item_filter = {};
  const input = {};
  Object.assign(
    item_review_filter,
    { draft: false },
    { status },
    placeNameSearchInput !== '' && { place_name: placeNameSearchInput },
    itemNameFilter !== '' && { item_name: itemNameFilter },
    location !== '' && { city: location },
    reviewOn !== null && {
      date_range: {
        start_date: moment(reviewOn).format('YYYY-MM-DD'),
        end_date: moment(reviewOn)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
      },
    },
  );
  Object.assign(item_filter, { status: 'ACTIVE' });

  Object.assign(
    input,
    location !== '' && { location },
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        item_review_filter,
        item_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          itemNameFilter={itemNameFilter}
          setItemNameFilter={setItemNameFilter}
          placeNameSearchInput={placeNameSearchInput}
          setPlaceNameSearchInput={setPlaceNameSearchInput}
          location={location}
          setLocation={setLocation}
          reviewOn={reviewOn}
          setReviewOn={setReviewOn}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setItemNameSort(itemNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          itemNameSort === 'AES'
                            ? 'ITEM_NAME_DESC'
                            : 'ITEM_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          itemNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Item Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setPlaceNameSort(
                          placeNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          placeNameSort === 'AES'
                            ? 'PLACE_NAME_DESC '
                            : 'PLACE_NAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          placeNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Place Name
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setCitySort(citySort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          citySort === 'AES'
                            ? 'PLACE_CITY_DESC'
                            : 'PLACE_CITY_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          citySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up '
                        }
                      />
                    </a>{' '}
                    City / Suburb
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setReviewedBySort(
                          reviewedBySort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          reviewedBySort === 'AES'
                            ? 'CREATED_DESC'
                            : 'CREATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          reviewedBySort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated By
                  </span>
                  {/* <br />
                  <CustomInput /> */}
                </div>
              </th>

              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setReviewedOnSort(
                          reviewedOnSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          reviewedOnSort === 'AES'
                            ? 'UPDATED_DESC'
                            : 'UPDATED_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          reviewedOnSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Updated On
                  </span>
                </div>
              </th>

              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down " /> Status
                  </span>
                  <br />
                  <CustomSelect
                    options={[
                      { value: '', label: 'Select..' },
                      { value: 'Active ', label: 'Active' },
                      { value: 'Inactive', label: 'Inactive' },
                    ]}
                  />
                </div>
              </th> */}
            </tr>
          </thead>
          <Query
            client={client.clientPublic}
            query={searchItemReviewsQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_item_review } = data;

              if (isNull(search_item_review)) {
                return 'no data found';
              }

              return (
                <View
                  reviewData={reviewData}
                  setReviewData={setReviewData}
                  search_item_review={search_item_review}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

const View = ({
  search_item_review,
  setPageCount,
  setTotalElements,
  setReviewData,
  reviewData,
}) => {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    setPageCount(search_item_review.total_pages);
    setTotalElements(search_item_review.total_size);
    if (search_item_review.item_review_listing) {
      setReviewData(search_item_review.item_review_listing);

      const userFilter = search_item_review.item_review_listing.map(
        item => item.audit.updated_by,
      );
      client.clientPrivate
        .query({
          query: usersQuery,
          variables: {
            input: { filter: { user_filter: { user_id: uniq(userFilter) } } },
          },
        })
        .then(({ data: { search_users } }) => {
          console.log('result');

          if (search_users) {
            setUsers(search_users.user_listing);
          }
        });
    }
  }, [search_item_review.total_pages]);
  return (
    <tbody>
      {reviewData.map(review => (
        <ListItem review={review} users={users} />
      ))}
    </tbody>
  );
};

export default ItemReviews;
