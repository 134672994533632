import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({
  activeTab,
  disableSubmitButton,
  loading,
  handleSubmit,
  next,
  prev,
  location,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Link to="/events" className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
        <Level.Item>
          <button onClick={prev} className="button is-primary">
            <span>Prev Tab</span>
          </button>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
            disabled={disableSubmitButton}
          >
            <span>
              {location.pathname === '/add-event'
                ? 'Post Event'
                : 'Update Event'}
            </span>
          </button>
        </Level.Item>
        {activeTab !== 3 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
