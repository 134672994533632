import React from 'react';
import { Column } from 'rbx';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import { TextArea, Loading, Message } from '../../../components/elements';

const feedQuery = gql`
  query fetch_feed($input: NavigateFeedInput) {
    fetch_feed(input: $input) {
      feed_id
      title
      content
      error {
        description
      }
    }
  }
`;

const FeedReview = ({ flag }) => (
  <React.Fragment>
    <Query
      query={feedQuery}
      variables={{
        input: {
          feed_id: flag.object_id,
        },
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return <Message>{error.message}</Message>;
        }
        const { fetch_feed } = data;

        return (
          <React.Fragment>
            {fetch_feed.map(feed => (
              <div multiline vcentered gapSize={8} key={feed.feed_id}>
                <Column size={11}>
                  <TextArea
                    label="Feed Heading"
                    rows={2}
                    value={feed.title}
                    disabled
                  />
                </Column>
                <Column size={11}>
                  <TextArea
                    label="Feed Content"
                    value={feed.content}
                    disabled
                  />
                </Column>
                <br />
              </div>
            ))}
          </React.Fragment>
        );
      }}
    </Query>
  </React.Fragment>
);
export default FeedReview;
