import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import swal from 'sweetalert';
import { first, isNull } from 'lodash';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

const eventsQuery = gql`
  query searchEvents($input: SearchInput) {
    search_events(input: $input) {
      total_pages
      total_size
      event_listing {
        place_id
        event_id
        status
        display_order
        featured
        default_url
        start
        end
        place_name
        venue_name
        name
        description
        tagline
        slug
        listing_type
        voucher_count
        minimum_price
        maximum_price
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        latitude
        longitude
        location
        timezone
        error {
          description
        }
      }
    }
  }
`;

const deleteEventMutation = gql`
  mutation delete_event($input: NavigateEventInput) {
    delete_event(input: $input) {
      event_id
      error {
        description
      }
    }
  }
`;

const deleteEventOccurrenceMutation = gql`
  mutation delete_event_occurrence($input: NavigateEventOccurrenceInput) {
    delete_event_occurrence(input: $input) {
      event_id
      error {
        description
      }
    }
  }
`;

const deleteEventOccurrenceTicketMutation = gql`
  mutation delete_event_occurrence_ticket($input: DeleteTicketInput) {
    delete_event_occurrence_ticket(input: $input) {
      ticket_id
      error {
        description
      }
    }
  }
`;

const fetchEventOccurrenceQuery = gql`
  query fetch_event_occurrence($input: NavigateEventOccurrenceInput) {
    fetch_event_occurrence(input: $input) {
      event_id
      event_occurrence_id
    }
  }
`;

const fetchEventOccurrenceTicketsQuery = gql`
  query fetch_event_occurrence_ticket($input: NavigateTicketInput) {
    fetch_event_occurrence_ticket(input: $input) {
      ticket_id
      event_occurrence_id
    }
  }
`;

const DeleteEventAction = ({ eventId, queryInput }) => {
  const { userId } = useStoreState(state => state.auth);
  const [occurrences, setOccurrences] = useState([]);
  return (
    <Mutation
      client={client.clientPrivate}
      mutation={deleteEventMutation}
      variables={{
        input: {
          user_id: userId,
          event_id: eventId,
        },
      }}
      update={() => {
        const { clientPublic } = client;
        const { search_events } = clientPublic.readQuery({
          query: eventsQuery,
          variables: { input: queryInput },
        });

        console.log('search_events', search_events, queryInput);

        clientPublic.writeQuery({
          query: eventsQuery,
          variables: { input: queryInput },
          data: {
            search_events: {
              total_pages: search_events.total_pages,
              total_size: search_events.total_size,
              event_listing: search_events.event_listing.filter(
                item => item.event_id !== eventId,
              ),
              __typename: 'EventListingAggregation',
            },
          },
        });
      }}
      onCompleted={({ delete_event }) => {
        if (isNull(delete_event)) {
          swal(
            'This event is already deleted, please refresh to see the changes',
            {
              icon: 'warning',
            },
          );
        } else {
          swal('Poof! Your event has been deleted!', {
            icon: 'success',
          });
        }
      }}
    >
      {delete_event => (
        <Mutation
          client={client.clientPrivate}
          mutation={deleteEventOccurrenceMutation}
          onCompleted={() => {
            delete_event();
          }}
        >
          {delete_event_occurrence => (
            <Mutation
              client={client.clientPrivate}
              mutation={deleteEventOccurrenceTicketMutation}
              onCompleted={() => {
                delete_event_occurrence({
                  variables: {
                    input: {
                      user_id: userId,
                      event_id: eventId,
                      event_occurrence_id: first(occurrences),
                    },
                  },
                });
              }}
            >
              {delete_event_occurrence_ticket => (
                <a
                  onClick={() => {
                    swal('Are you sure you want to delete the event?', {
                      buttons: ['Cancel', true],
                    }).then(willDelete => {
                      if (willDelete) {
                        client.clientPublic
                          .query({
                            query: fetchEventOccurrenceQuery,
                            variables: { input: { event_id: eventId } },
                          })
                          .then(
                            ({ data: { fetch_event_occurrence, errors } }) => {
                              if (!isNull(errors)) {
                                delete_event();
                              } else if (fetch_event_occurrence.length === 0) {
                                delete_event();
                              } else {
                                setOccurrences(
                                  fetch_event_occurrence.map(
                                    item => item.event_occurrence_id,
                                  ),
                                );
                                client.clientPublic
                                  .query({
                                    query: fetchEventOccurrenceTicketsQuery,
                                    variables: { input: { event_id: eventId } },
                                  })
                                  .then(
                                    ({
                                      data: { fetch_event_occurrence_ticket },
                                    }) => {
                                      if (
                                        fetch_event_occurrence === null ||
                                        fetch_event_occurrence_ticket.length !==
                                          0
                                      ) {
                                        delete_event_occurrence_ticket({
                                          variables: {
                                            input: {
                                              user_id: userId,
                                              event_id: eventId,
                                              ticket_id: fetch_event_occurrence_ticket.map(
                                                item => item.ticket_id,
                                              ),
                                            },
                                          },
                                        });
                                      } else {
                                        delete_event_occurrence({
                                          variables: {
                                            input: {
                                              user_id: userId,
                                              event_id: eventId,
                                              event_occurrence_id: first(
                                                occurrences,
                                              ),
                                            },
                                          },
                                        });
                                      }
                                    },
                                  );
                              }
                            },
                          );
                      }
                    });
                  }}
                >
                  <span>
                    <i className="fas fa-trash has-text-grey" />
                  </span>
                </a>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Mutation>
  );
};

export default DeleteEventAction;
