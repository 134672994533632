import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  heading,
  itemName,
  loading,
  subHeading,
  next,
  prev,
  activeTab,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>{heading}</Title>
      </Level.Item>
      <Level.Item align="right">
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}

        <Level.Item>
          <Link to="/item-activities" className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
        {activeTab !== 3 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next Tab</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>

    <SubHeading className="is-size-2">{itemName}</SubHeading>
    <SubHeading className="is-size-2">{subHeading}</SubHeading>
  </Wrapper>
);

export default Header;
