import React from 'react';
import { withRouter } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ next, history }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <a onClick={() => history.goBack()} className="button is-light">
            <span>Cancel</span>
          </a>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button onClick={next} className="button is-primary">
            <span>Next Tab</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default withRouter(Footer);
