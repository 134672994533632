import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const ManageDealList = ({ review, setPageCount, totalPages }) => {
  useEffect(() => {
    setPageCount();
  }, [totalPages]);
  return (
    <tr>
      <td>
        <Link to="/manage-menu-deal">Check Point</Link>
      </td>
      <td>EVENT</td>
      <td>18/07/2019</td>
      <td>Las Tapas Diner</td>
      <td>St Kilda</td>
      <td>Delivery Menu - All Day Menu</td>
      <td>24/06/2019</td>
      <td>Approved</td>
    </tr>
  );
};

export default ManageDealList;
