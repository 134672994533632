import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import gql from 'graphql-tag';

import UploadImage from '../../../components/elements/UploadImage';
import UpdateUploadImage from '../../../components/elements/UpdateUploadImage';

import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 0rem 1rem;
`;

const deleteMutation = gql`
  mutation delete($userId: String, $imageId: String) {
    delete_image(input: { user_id: $userId, image_id: $imageId }) {
      image_id
      error {
        description
      }
    }
  }
`;

const PlaceImages = ({ setFieldValue, values, setValues }) => {
  const { userId } = useStoreState(state => state.auth);

  const handleRemoveUploadedImage = id => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        setValues({
          ...values,
          uploadedImages: values.uploadedImages.filter(item => item.id !== id),
        });
        client.clientPrivate
          .mutate({
            mutation: deleteMutation,
            variables: { userId, imageId: id },
          })
          .then(result => {
            console.log('result', result);
          })
          .catch(error => {
            console.log('result', error);
          });
      }
    });
  };

  const handleRemoveCurrentImage = id => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this image!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        setValues({
          ...values,
          image: values.image.filter(item => item.id !== id),
        });
      }
    });
  };

  return (
    <Container>
      <div className="columns is-multiline">
        <UpdateUploadImage
          s3UploadPath={`place/${values.place_id}/`}
          metaData={{
            place_id: values.place_id,
            user_id: userId,
          }}
          setFieldValue={setFieldValue}
          handleRemoveUploadedImage={handleRemoveUploadedImage}
          setDefaultImage={image =>
            setFieldValue('default_image_url', image.url)
          }
          defaultImage={values.default_image_url}
        />

        <UploadImage
          s3UploadPath={`place/${values.place_id}/`}
          metaData={{
            place_id: values.place_id,
            user_id: userId,
          }}
          setFieldValue={setFieldValue}
          handleRemoveCurrentImage={handleRemoveCurrentImage}
          setDefaultImage={image =>
            setFieldValue('default_image_url', image.url)
          }
          defaultImage={values.default_image_url}
        />
      </div>
    </Container>
  );
};
export default withRouter(PlaceImages);
