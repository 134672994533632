import React from 'react';
import { Link } from 'react-router-dom';
import { Level } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 0rem 1rem;
  padding-bottom: 1rem;
`;

const Footer = ({ loading, handleSubmit }) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <Link to="/users" className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            <span>Update</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Footer;
