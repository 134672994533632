import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isNull, first } from 'lodash';
import moment from 'moment';
import useFetchUser from '../../../utils/useFetchUser';

const CheckInList = ({ users }) => (
  <tr>
    <td>
      <Link to="/user-activity">{users.email}</Link>
    </td>
    <td>{users.first_name}</td>
    <td>{users.last_name}</td>
    <td>{users.display_name}</td>
    <td>
      {!isNull(users.contact) && users.contact.length !== 0
        ? first(users.contact.filter(item => item.type === 'mobile')).value
        : '-'}
    </td>
    {/* <td>-</td> */}
    {/* <td>
        {user ? (
          <React.Fragment>
            <p>{`${user.caption1} ${user.caption2}`}</p>
          </React.Fragment>
        ) : (
          userId
        )}
      </td>
      <td>{moment(users.audit.updated_at).format('lll')}</td> */}
  </tr>
);

export default CheckInList;
