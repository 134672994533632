import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import moment from 'moment';
import { Input, Radio } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  status,
  setStatus,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  voucherNameSearch,
  handleVoucherNameSearch,
  menuNameSearch,
  handleMenuNameSearch,
  setStartDateRangeFilter,
  setEndDateRangeFilter,
  setLocation,
  dealStatus,
  setDealStatus,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Current"
              value={status === 'current'}
              onChange={() => {
                setStatus('current');
                setStartDateRangeFilter({
                  end_date: moment().toISOString(),
                });
                setEndDateRangeFilter({
                  start_date: moment(
                    `${moment().format('L')} 11:59 PM`,
                  ).toISOString(),
                });
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Past"
              value={status === 'past'}
              onChange={() => {
                setStatus('past');
                setEndDateRangeFilter({
                  end_date: moment(`${moment().format('L')} 12:00 AM`)
                    .subtract(1, 'day')
                    .toISOString(),
                });
                setStartDateRangeFilter();
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Future"
              value={status === 'future'}
              onChange={() => {
                setStatus('future');
                setStartDateRangeFilter({
                  start_date: moment(`${moment().format('L')} 12:00 AM`)
                    .add(1, 'day')
                    .toISOString(),
                });
                setEndDateRangeFilter();
              }}
            />
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Place Deal Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Active"
              name="placeDeal_active_status"
              value={dealStatus === 'active'}
              onChange={() => {
                setDealStatus('active');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Inactive"
              name="placeDeal_inactive_status"
              value={dealStatus === 'inactive'}
              onChange={() => {
                setDealStatus('inactive');
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-3">
            <Input
              label="Voucher Name"
              type="text"
              value={voucherNameSearch}
              onChange={event => handleVoucherNameSearch(event.target.value)}
              placeholder="Start entering voucher name..."
            />
          </div>
          <div className="column is-3">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-3">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                  onHandleClear={length => {
                    if (length === 0) {
                      setLocation('');
                      setCity('');
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="column is-3">
            <Input
              label="Menu Name"
              type="text"
              value={menuNameSearch}
              onChange={event => handleMenuNameSearch(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
