import React from 'react';
import { Select, Title } from '../../../components/elements';
import { Column } from 'rbx';

const ItemModifiers = () => {
  return (
    <React.Fragment>
      <Column size={4}>
        <Select
          label="Add Modifiers"
          options={[{ value: '', label: 'Select..' }]}
        />
      </Column>
    </React.Fragment>
  );
};
export default ItemModifiers;
