import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import qs from 'querystringify';
import { withRouter } from 'react-router-dom';
import { Input, Radio, CheckBox } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const PlaceSearch = ({
  vendorNameSearchInput,
  setVendorNameSearchInput,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setLocation,
  listing_status,
  item_review_place,
  history,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Listing Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Level.Item>
              <Radio
                label="UNLISTED"
                name="Type"
                value={listing_status === 'UNLISTED'}
                onChange={() => {
                  history.push(
                    `/place-approval${qs.stringify(
                      {
                        listing_status: 'UNLISTED',
                        item_review_place,
                      },
                      true,
                    )}`,
                  );
                }}
              />
            </Level.Item>
            <Radio
              label="APPROVED"
              name="Type"
              value={listing_status === 'APPROVED'}
              onChange={() =>
                history.push(
                  `/place-approval${qs.stringify(
                    {
                      listing_status: 'APPROVED',
                      item_review_place,
                    },
                    true,
                  )}`,
                )
              }
            />
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Additional Criteria</Title>
            </TitleWrapper>
          </Level.Item>

          <Level.Item>
            <CheckBox
              label="User added place via item review"
              value={item_review_place === 'true'}
              name="item_review_place"
              onChange={value => {
                history.push(
                  `/place-approval${qs.stringify(
                    {
                      listing_status,
                      item_review_place: value,
                      // event_business: false,
                    },
                    true,
                  )}`,
                );
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          <div className="column is-4">
            <Input
              label="Company Name"
              type="text"
              value={vendorNameSearchInput}
              onChange={event => setVendorNameSearchInput(event.target.value)}
              placeholder="Start entering company name..."
            />
          </div>
          <div className="column is-4">
            <div className="field">
              <label className="label">City / Suburb</label>
              <div className="control">
                <LocationSuggestion
                  initialValue={city}
                  onSelect={data => {
                    setLocation(data.location);
                    setCity(data.name);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default withRouter(PlaceSearch);
