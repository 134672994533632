import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import { replace, first, isUndefined, forEach } from 'lodash';
import ListingAudit from './ListingAudit';
import ClaimAudit from './ClaimAudit';

import {
  Input,
  TextArea,
  Select,
  CheckBox,
  MobileInput,
  CreatableSelectInputs,
  InputErrorMessage,
} from '../../../components/elements';
import { getServicesTypes } from '../../../utils/fetchStatic';

const Container = styled.div`
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const parseInput = parseValue => {
  const final = [];
  parseValue.map((item, index) => {
    final.push({
      name: item.value,
      description: item.label,
      display_order: (index + 1).toString(),
    });
  });
  return final;
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

const Details = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  createForm,
}) => {
  const [data, setData] = useState([
    {
      servicesTypes: [],
    },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      const servicesTypes = await getServicesTypes();

      setData({
        servicesTypes,
      });
    };
    fetchData();
  }, []);

  return (
    <Container>
      <div className="label">Claim By Details</div>
      <Column.Group multiline vcentered gapSize={8}>
        <Column size="half">
          <Input
            label="Claimed User Contact Email"
            name="contact_email"
            value={values.contact_email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!createForm}
            errors={errors.contact_email}
            touched={touched.contact_email}
            required
          />
        </Column>
        <Column size="half">
          <MobileInput
            label="Claimed User Contact Phone"
            value={values.contact_phone}
            onChange={value =>
              setFieldValue('contact_phone', replace(value, ' ', ''))
            }
            disabled={!createForm}
            errors={errors.contact_phone}
            touched={touched.contact_phone}
            required
          />
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Business URL"
            name="contact_business_url"
            value={values.contact_business_url}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!createForm}
            errors={errors.contact_business_url}
            touched={touched.contact_business_url}
          />
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Linkedin Profile URL"
            name="contact_linked_in_url"
            value={values.contact_linked_in_url}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!createForm}
            errors={errors.contact_linked_in_url}
            touched={touched.contact_linked_in_url}
            required
          />
        </Column>
        {isUndefined(values.service_type) ? (
          <Column size="half">
            <CreatableSelectInputs
              label="Interested Services"
              suggestions={parseData(data.servicesTypes)}
              value=""
              onChange={value => {
                if (value) {
                  setFieldValue('service_type', parseInput(value));
                }
              }}
              disabled={!createForm}
              errors={errors.service_type}
              touched={touched.service_type}
            />
          </Column>
        ) : (
          <Column size="half">
            <CreatableSelectInputs
              label="Interested Services"
              suggestions={parseData(data.servicesTypes)}
              value={values.service_type.map(item => ({
                value: item.name,
                label: item.description,
              }))}
              onChange={value => {
                if (value) {
                  setFieldValue('service_type', parseInput(value));
                }
              }}
              disabled={!createForm}
              errors={errors.service_type}
              touched={touched.service_type}
            />
          </Column>
        )}
        <Column size="half">
          <CheckBox
            label=" Authority Term Of Service & Claim Acknowledged"
            value={values.agree_terms_and_conditions}
            onChange={value => {
              if (value) {
                setFieldValue('agree_terms_and_conditions', value);
              }
            }}
            disabled={!createForm}
            required
          />
          <InputErrorMessage
            errors={errors.agree_terms_and_conditions}
            touched={touched.agree_terms_and_conditions}
          />
        </Column>
        <Column size="half">
          <Column.Group gapSize={1}>
            <Column size="half">
              <Select
                label="Vender Verification"
                options={[
                  { value: 'SMS', label: 'SMS' },
                  { value: 'FACEBOOK', label: 'FACEBOOK' },
                  { value: 'PHONE', label: 'PHONE' },
                  { value: 'MANUAL', label: 'MANUAL' },
                  { value: 'CREDIT CARD', label: 'CREDIT CARD' },
                ]}
                value={[
                  {
                    value: first(values.verification_mode),
                    label: first(values.verification_mode),
                  },
                ]}
                onChange={value => {
                  setFieldValue('verification_mode', [value.value]);
                }}
                disabled={!createForm}
              />
            </Column>
            <Column size="half">
              <Input
                label="Claimed User Role"
                name="contact_role"
                value={values.contact_role}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!createForm}
                errors={errors.contact_role}
                touched={touched.contact_role}
                required
              />
            </Column>
          </Column.Group>
        </Column>
        <Column size="half">
          <Input
            label="Claimed User Full Name"
            name="contact_full_name"
            value={values.contact_full_name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!createForm}
            errors={errors.contact_full_name}
            touched={touched.contact_full_name}
            required
          />
        </Column>
        {isUndefined(values.verification_note) ? (
          <Column size="full">
            <TextArea
              label="Verification Comments"
              name="verification_note"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.verification_note}
              touched={touched.verification_note}
              required
            />
          </Column>
        ) : (
          <Column size="full">
            <TextArea
              label="Verification Comments"
              name="verification_note"
              value={
                values.verification_note.length !== 0
                  ? first(values.verification_note).value
                  : ''
              }
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors.verification_note}
              touched={touched.verification_note}
              required
            />
          </Column>
        )}
      </Column.Group>
      <fieldset className="box">
        <legend className="label">Claimed User Login Details</legend>
        <Column.Group multiline vcentered gapSize={8}>
          <Column size="half">
            <Input label="Claimed by FullName" disabled />
          </Column>
          <Column size="half">
            <Input
              label="Claimed by Email"
              value={values.contact_email}
              disabled
            />
          </Column>
          <Column size="half">
            <MobileInput label="Claimed by Phone" disabled />
          </Column>
          <Column size="half">
            <Input label="Claim Date & Time" disabled />
          </Column>
        </Column.Group>
      </fieldset>

      <ListingAudit
        values={values}
        approvedBy={values.listing_audit.listing_approved_by}
        approvedAt={values.listing_audit.listing_approved_at}
      />

      <ClaimAudit
        values={values}
        approvedBy={values.place_claimant_audit.claim_approved_by}
        approvedAt={values.place_claimant_audit.claim_approved_at}
      />
    </Container>
  );
};

export default Details;
