import React from 'react';
import { Link } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 1rem 1rem;
  /* border-bottom: 1px solid #f6f6f6; */
`;

const Header = () => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>Add / Edit Events</Title>
      </Level.Item>
      <Level.Item align="right">
        <Link to="/add-event" className="button is-primary">
          <span className="has-text-weight-semibold">Add an Event</span>
        </Link>
      </Level.Item>
    </Level>
  </Wrapper>
);

export default Header;
