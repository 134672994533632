import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const InputErrorMessage = ({ errors, touched }) => {
  useEffect(() => {
    if (errors && touched) {
      toast.error(errors);
    }
  }, [errors, touched]);

  return (
    <React.Fragment>
      {errors && touched && (
        <p className="help is-danger is-size-4">{errors}</p>
      )}
    </React.Fragment>
  );
};

export default InputErrorMessage;
