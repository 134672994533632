import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Column } from 'rbx';
import { first, forEach, isNull } from 'lodash';
import voucher_codes from 'voucher-code-generator';
import gql from 'graphql-tag';

import {
  Select,
  TextArea,
  Input,
  CheckBox,
  CreatableSelectInputs,
} from '../../../components/elements';

import EventSelection from './EventSelection';
import DealTimeDatePicker from './DealTimeDatePicker';
import TicketSelection from './TicketSelection';
import client from '../../../utils/apolloClient';

const CheckBoxWrapper = styled.div`
  /* position: absolute;
  right: 31%; */
`;
const eventsQuery = gql`
  query searchEvents($input: SearchInput) {
    search_events(input: $input) {
      event_listing {
        event_id
        name
      }
    }
  }
`;

const eventOccurrenceQuery = gql`
  query eventTicketsQuery($eventId: String) {
    fetch_event_occurrence(input: { event_id: $eventId }) {
      event_id
      event_occurrence_id
    }
  }
`;

const eventTicketsQuery = gql`
  query eventTicketsQuery($eventOccurrenceId: String) {
    fetch_event_occurrence_ticket(
      input: { event_occurrence_id: $eventOccurrenceId }
    ) {
      ticket_id
      name
      price
    }
  }
`;

const VoucherDetails = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  placeName,
}) => {
  const [autoGenerateRadioValue, setAutoGenerateRadioValue] = useState(true);
  const [events, setEvents] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketFetchLoading, setTicketFetchLoading] = useState(true);
  const [checkEventList, setCheckEventList] = useState();

  const status = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ];

  const finalTickets = [];

  useEffect(() => {
    client.clientPublic
      .query({
        query: eventsQuery,
        variables: {
          input: {
            filter: {
              event_filter: {
                place_name: placeName,
                status: 'ACTIVE',
                listing_type: ['public_tickets', 'private_tickets'],
                draft: false,
                end_date_range: {
                  start_date: moment().toISOString(),
                },
              },
            },
          },
        },
      })

      .then(results => {
        if (results.data.search_events.event_listing.length === 0) {
          setTicketFetchLoading(false);
          setCheckEventList(true);
        } else {
          const fetchEvents = results.data.search_events.event_listing;
          const applies_to = fetchEvents.map(item => ({
            event_id: item.event_id,
            event_occurrence_id: '',
            ticket_id: '',
            ticket_price: 0,
            event_name: item.name,
            ticket_name: '',
          }));
          setEvents(applies_to);
          setFieldValue('applies_to', applies_to);
          if (fetchEvents && !isNull(fetchEvents)) {
            forEach(fetchEvents, item1 => {
              client.clientPublic
                .query({
                  query: eventOccurrenceQuery,
                  variables: {
                    eventId: item1.event_id,
                  },
                })
                .then(async results2 => {
                  const fetchOccurrence = results2.data.fetch_event_occurrence;
                  if (fetchOccurrence && !isNull(fetchOccurrence)) {
                    await forEach(fetchOccurrence, async item2 => {
                      await client.clientPublic
                        .query({
                          query: eventTicketsQuery,
                          variables: {
                            eventOccurrenceId: item2.event_occurrence_id,
                          },
                        })
                        .then(async results3 => {
                          const fetchTickets = await results3.data
                            .fetch_event_occurrence_ticket;
                          const applies_to_tickets = await fetchTickets.map(
                            item3 => ({
                              event_id: item1.event_id,
                              event_occurrence_id: item2.event_occurrence_id,
                              ticket_id: item3.ticket_id,
                              ticket_price: item3.price,
                              event_name: item1.name,
                              ticket_name: item3.name,
                            }),
                          );
                          forEach(applies_to_tickets, item4 => {
                            finalTickets.push(item4);
                          });
                          setTickets(finalTickets);
                        });
                    });
                  }
                });
            });
          }
        }
      });
  }, []);

  return (
    <Column.Group multiline gapSize={8}>
      <Column size={4}>
        <Input
          label="Event Voucher Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          optionalText="(Min 4 Max 50 Characters )"
          errors={errors.name}
          touched={touched.name}
          required
        />
      </Column>

      <Column size={4}>
        <Input
          label="Event Voucher Code"
          name="voucher_code"
          value={values.voucher_code}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.voucher_code}
          touched={touched.voucher_code}
          disabled={autoGenerateRadioValue}
          required
        />
        <CheckBoxWrapper>
          <CheckBox
            label="Auto generate Voucher Code"
            value={autoGenerateRadioValue}
            onChange={value => {
              if (value) {
                const coupon = voucher_codes.generate({
                  length: 10,
                  count: 1,
                  charset: voucher_codes.charset('alphabetic'),
                });
                setFieldValue('voucher_code', first(coupon).toUpperCase());
                setAutoGenerateRadioValue(true);
              } else {
                setAutoGenerateRadioValue(false);
              }
            }}
          />
        </CheckBoxWrapper>
      </Column>
      <Column size={4}>
        <Select
          label="Event Voucher Status"
          options={status}
          value={[{ value: values.status, label: values.status }]}
          onChange={({ value }) => setFieldValue('status', value.toUpperCase())}
          required
        />
      </Column>
      <Column size={8}>
        <Input
          label="Event Voucher Tagline"
          name="tagline"
          value={values.tagline}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.tagline}
          touched={touched.tagline}
          optionalText="( Min 10 and Max 50)"
          required
        />
      </Column>
      <Column size="full">
        <TextArea
          label="Event Voucher Description"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.description}
          touched={touched.description}
        />
      </Column>
      <Column size={4}>
        <DealTimeDatePicker
          label="Start Date"
          timeLabel="Start Time"
          date={values.startDate}
          time={values.startTime}
          onDateChange={value => {
            setFieldValue('startDate', value);
            setFieldValue('endDate', value);
          }}
          onTimeChange={value => {
            setFieldValue('startTime', value);
            setFieldValue('endTime', null);
          }}
          minDate={new Date()}
          errors={errors.startDate}
          touched={touched.startDate}
          required
        />
      </Column>
      <Column size={4}>
        <DealTimeDatePicker
          label="End Date"
          timeLabel="End Time"
          date={values.endDate}
          time={values.endTime}
          onDateChange={value => {
            setFieldValue('endDate', value);
          }}
          onTimeChange={value => {
            setFieldValue('endTime', value);
          }}
          minDate={new Date(values.startDate)}
          errors={errors.startEnd}
          touched={touched.startEnd}
          disabled={values.startDate === null}
          required
        />
      </Column>
      <Column size={3}>
        <Input
          label="Voucher Timezone"
          name="voucher_code"
          value="AEST"
          onChange={handleChange}
          onBlur={handleBlur}
          disabled
        />
      </Column>
      <Column size={6}>
        {checkEventList ? (
          <div className="notification is-warning">
            This Place does not have any events
          </div>
        ) : (
          <EventSelection
            values={values}
            setFieldValue={setFieldValue}
            events={events}
            loading={ticketFetchLoading}
          />
        )}
      </Column>
      <Column size={3}>
        <CheckBoxWrapper>
          <CheckBox
            label="Newly Register user Only"
            value={values.valid_for_new_user}
            onChange={value => {
              if (value) {
                setFieldValue('valid_for_new_user', true);
              }
            }}
          />
        </CheckBoxWrapper>
      </Column>
      <Column size={6}>
        {!checkEventList && (
          <TicketSelection
            values={values}
            setFieldValue={setFieldValue}
            tickets={tickets}
          />
        )}
      </Column>
      <Column size="full">
        <CreatableSelectInputs label="Event Deal Tags" disabled />
      </Column>
    </Column.Group>
  );
};

export default VoucherDetails;
