import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { isNull, last, sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import client from '../../../utils/apolloClient';

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e0e0e0;
  padding: 0.5rem 0rem;
`;

const checkInAndOutMutation = gql`
  mutation checkInAndOut($input: TicketRecordCheckInInput) {
    update_ticket_record_checkin(input: $input) {
      ticket_record_id
      check_in {
        type
        timestamp
      }
      error {
        description
      }
    }
  }
`;

const SubHeader = ({ ticket }) => {
  const [checkInStatus, setCheckInStatus] = useState('CHECK_IN');

  const { userId } = useStoreState(state => state.auth);
  useEffect(() => {
    if (!ticket.check_in) {
      setCheckInStatus('CHECK_OUT');
      return null;
    }
    const checkInLatest = last(sortBy(ticket.check_in), 'timestamp');
    if (checkInLatest.type === 'CHECK_OUT') {
      setCheckInStatus('CHECK_OUT');
    }
    return null;
  }, []);

  return (
    <React.Fragment>
      <HeadingWrapper>
        <p className="is-size-3">Check In Attendee</p>
        <p className="is-size-3">
          Status :{' '}
          <span className="has-text-weight-bold">
            {checkInStatus !== 'CHECK_OUT' ? 'CheckIn' : 'CheckOut'}
          </span>
        </p>
        <Mutation
          client={client.clientPrivate}
          mutation={checkInAndOutMutation}
          variables={{
            input: {
              user_id: userId,
              ticket_record_id: ticket && ticket.ticket_record_id,
              check_in: {
                type: checkInStatus === 'CHECK_IN' ? 'CHECK_OUT' : 'CHECK_IN',
              },
            },
          }}
          onCompleted={({ update_ticket_record_checkin }) => {
            if (!isNull(update_ticket_record_checkin.error)) {
              update_ticket_record_checkin.error.map(item =>
                toast.error(item.description),
              );
            } else {
              setCheckInStatus(
                checkInStatus === 'CHECK_IN' ? 'CHECK_OUT' : 'CHECK_IN',
              );
            }
          }}
        >
          {(update_ticket_record_checkin, { loading }) => (
            <button
              className={`button is-primary ${loading && 'is-loading'}`}
              onClick={() => update_ticket_record_checkin()}
            >
              {checkInStatus === 'CHECK_OUT'
                ? 'Check In Attendee'
                : 'Check Out Attendee'}
            </button>
          )}
        </Mutation>
      </HeadingWrapper>
    </React.Fragment>
  );
};

export default SubHeader;
