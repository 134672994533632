import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';

const Wrapper = styled.div`
  &&& {
    a {
      width: fit-content;
    }
    .disabled a span {
      color: #a8a8a8 !important;
      cursor: not-allowed;
    }
  }
`;

const OptionalText = styled.p`
  &&& {
    color: #737373;
    padding-left: 4px;
  }
`;

const Radio = ({ label, value, optionalText, onChange, disabled }) => {
  const [enable, setEnable] = useState(value);
  useEffect(() => {
    setEnable(value);
  }, [value]);

  return (
    <Wrapper className="field">
      <div className={classNames('control', { disabled })}>
        <input type="checkbox" className="is-hidden" onChange={onChange} />
        <a
          onClick={
            disabled
              ? null
              : () => {
                  if (!enable) {
                    setEnable(!enable);
                    onChange(!enable);
                  }
                }
          }
          className="has-text-weight-semibold label is-flex"
        >
          <span className="has-text-grey">
            <i
              className={
                enable ? 'fas fa-dot-circle has-text-info' : 'far fa-circle'
              }
            />
          </span>
          &nbsp; &nbsp;
          <span className="has-text-grey has-text-weight-normal is-flex">
            {label}
            {optionalText && <OptionalText>{optionalText}</OptionalText>}{' '}
          </span>
        </a>
      </div>
    </Wrapper>
  );
};

Radio.defaultProps = {
  label: 'Stranger',
  value: false,
  onChange: () => {},
};

Radio.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Radio;
