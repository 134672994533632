import React from 'react';
import { capitalize, isNull, first } from 'lodash';

const BookingByTicketList = ({
  tickets,
  //  fee
}) => {
  const onlinePassOnFeeArray =
    tickets.fee && !isNull(tickets.fee)
      ? tickets.fee.filter(item => item.name === 'online_passon_fee')
      : [];

  const offlinePassOnFeeArray =
    tickets.fee && !isNull(tickets.fee)
      ? tickets.fee.filter(item => item.name === 'offline_passon_fee')
      : [];

  const onlineAbsorbFeeArray =
    tickets.fee && !isNull(tickets.fee)
      ? tickets.fee.filter(item => item.name === 'online_absorb_fee')
      : [];

  const offlineAbsorbFeeArray =
    tickets.fee && !isNull(tickets.fee)
      ? tickets.fee.filter(item => item.name === 'offline_absorb_fee')
      : [];

  const onlinePassFee =
    onlinePassOnFeeArray.length !== 0 ? first(onlinePassOnFeeArray).value : 0;

  const offlinePassOnFee =
    offlinePassOnFeeArray.length !== 0 ? first(offlinePassOnFeeArray).value : 0;

  const onlineAbsorbFee =
    onlineAbsorbFeeArray.length !== 0 ? first(onlineAbsorbFeeArray).value : 0;

  const offlineAbsorbFee =
    offlineAbsorbFeeArray.length !== 0 ? first(offlineAbsorbFeeArray).value : 0;
  return (
    <React.Fragment>
      <td>{tickets.ticket_name}</td>
      <td>1</td>
      <td>$ {parseFloat(tickets.price).toFixed(2)}</td>
      <td>$ {parseFloat(onlinePassFee).toFixed(2)}</td>
      <td>$ {parseFloat(onlineAbsorbFee).toFixed(2)}</td>
      <td>$ {parseFloat(offlinePassOnFee).toFixed(2)}</td>
      <td>$ {parseFloat(offlineAbsorbFee).toFixed(2)}</td>
      <td>{capitalize(tickets.ticket_type)}</td>
    </React.Fragment>
  );
};

export default BookingByTicketList;
