import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';

import { SubHeading } from '../../../components/elements';

const Wrapper = styled.div`
  padding: 1rem;
  .level:not(:last-child) {
    margin-bottom: 0;
  }
`;

const Header = ({
  activeTab,
  loading,
  handleSubmit,
  location,
  placeName,
  eventName,
  next,
  prev,
  submitDraft,
  disableSubmitButton,
  isDraftLoading,
  draft,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Title size={2}>
          {location.pathname === '/add-event'
            ? 'Add an event'
            : 'Edit an event'}
        </Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <Link to="/events" className="button is-light">
            <span>Cancel</span>
          </Link>
        </Level.Item>
        {activeTab !== 0 && (
          <Level.Item>
            <button onClick={prev} className="button is-primary">
              <span>Prev Tab</span>
            </button>
          </Level.Item>
        )}
        <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
            disabled={disableSubmitButton}
          >
            <span>
              {location.pathname === '/add-event'
                ? 'Post Event'
                : 'Update Event'}
            </span>
          </button>
        </Level.Item>

        {/* <ACLSConsumer>
          {({ apiActions, pageActions }) => (
            <Can
              action={`${
                location.pathname === '/add-event'
                  ? 'create_event'
                  : 'update_event'
              }`}
              apiActions={apiActions}
              pageActions={pageActions}
              yes={() => (
                <Level.Item>
                  <button
                    className={`button is-primary ${loading && 'is-loading'}`}
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                  >
                    <span>
                      {location.pathname === '/add-event'
                        ? 'Post Event'
                        : 'Update Event'}
                    </span>
                  </button>
                </Level.Item>
              )}
            />
          )}
        </ACLSConsumer> */}

        {/* <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
            disabled={disableSubmitButton}
          >
            <span>
              {location.pathname === '/add-event'
                ? 'Post Event'
                : 'Update Event'}
            </span>
          </button>
        </Level.Item> */}

        {(draft || location.pathname === '/add-event') && (
          <Level.Item>
            <button
              className={`button is-primary ${isDraftLoading && 'is-loading'}`}
              onClick={submitDraft}
              disabled={disableSubmitButton}
            >
              <span>Draft Event</span>
            </button>
          </Level.Item>
        )}

        {activeTab !== 3 && (
          <Level.Item>
            <button onClick={next} className="button is-primary">
              <span>Next</span>
            </button>
          </Level.Item>
        )}
      </Level.Item>
    </Level>
    <SubHeading className="is-size-2">{eventName}</SubHeading>
    <SubHeading className="is-size-2">{placeName}</SubHeading>
  </Wrapper>
);

export default withRouter(Header);
