import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import { isNull } from 'lodash';

import { Box, Tabs } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import Details from './Details';
import Likes from './Likes';
import ShareList from './ShareList';
import Flags from './Flags';
import SocialCounts from './SocialCounts';

const Container = styled.div`
  padding: 1rem;
`;

const tabsForAdd = [
  {
    key: 0,
    name: 'Posting Details',
  },
];
const tabsForUpdate = [
  {
    key: 0,
    name: 'Posting Details',
  },
  {
    key: 1,
    name: 'Posting Likes',
  },
  {
    key: 2,
    name: 'Posting Share',
  },
  {
    key: 3,
    name: 'Posting Flags',
  },
];

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { handleSubmit, place, loading, isAddComponent, dirty } = props;
  const prev = () =>
    activeTab === 0 ? setActiveTab(3) : setActiveTab(activeTab - 1);

  const next = () =>
    activeTab === 3 ? setActiveTab(0) : setActiveTab(activeTab + 1);
  console.log('place', place.status);
  return (
    <React.Fragment>
      <Header
        heading="Add a place posting"
        placeName={`${place.name} | ${place.city}, ${place.post_code} | ${place.country} (${place.status} / ${place.claim_status})`}
        handleSubmit={handleSubmit}
        loading={loading}
        prev={prev}
        next={next}
        dirty={dirty}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {isAddComponent
            ? tabsForAdd.map(tab => (
                <Tab
                  active={activeTab === tab.key}
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                >
                  {tab.name}
                </Tab>
              ))
            : tabsForUpdate.map(tab => (
                <Tab
                  active={activeTab === tab.key}
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                >
                  {tab.name}
                </Tab>
              ))}
        </Tabs>
        <Container>
          {!isAddComponent && <SocialCounts />}

          {activeTab === 0 && <Details {...props} />}
          {!isAddComponent && (
            <React.Fragment>
              {activeTab === 1 && <Likes />}
              {activeTab === 2 && <ShareList />}
              {activeTab === 3 && <Flags />}
            </React.Fragment>
          )}
        </Container>
      </Box>
      <hr />
      <Footer
        loading={loading}
        handleSubmit={handleSubmit}
        prev={prev}
        next={next}
      />
    </React.Fragment>
  );
};

const PostForm = withFormik({
  mapPropsToValues: ({ place, feed, userId }) => ({
    user_id: userId,
    feed_id: feed && !isNull(feed.feed_id) ? feed.feed_id : uuidv4(),
    object_id: place.place_id,
    object_type: 'place',
    title: feed && !isNull(feed.title) ? feed.title : '',
    content: feed && !isNull(feed.content) ? feed.content : '',
    status: feed && !isNull(feed.status) ? feed.status : 'ACTIVE',
    approval_status:
      feed && !isNull(feed.approval_status) ? feed.approval_status : 'APPROVED',
  }),

  validationSchema: yup.object().shape({
    title: yup
      .string()
      .min(3, 'Please enter at least 3 characters')
      .max(50, 'Please enter not greater than 50 characters')
      .required('Posting title is required!'),
    content: yup
      .string()
      .min(20, 'Please enter at least 20 characters')
      .max(800, 'Please enter not greater than 800 characters')
      .required('Posting Content is required!'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'PostForm',
})(Form);

export default PostForm;
