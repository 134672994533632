import React from 'react';
import styled from 'styled-components';
import { Level, Title, Column } from 'rbx';
import { Radio, CheckBox } from '../../../components/elements';
const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Selection = ({ statusFilter, setStatusFilter }) => {
  return (
    <React.Fragment>
      <Title>Is the Selection Optional or Required*</Title>
      <Level>
        <Column.Group>
          <Column>
            <Level.Item align="left">
              <Radio
                label="Required"
                value={statusFilter === 'REQUIRED'}
                onChange={value => {
                  if (value) {
                    setStatusFilter('REQUIRED');
                  }
                }}
              />
            </Level.Item>
          </Column>
          <Column>
            <Level.Item>
              <Radio
                label="Optional"
                value={statusFilter === 'OPTIONAL'}
                onChange={value => {
                  if (value) {
                    setStatusFilter('OPTIONAL');
                  }
                }}
              />
            </Level.Item>
          </Column>

          <Column size={4}>
            <CheckBox label="Set Modifier as default Add-On" />
          </Column>
        </Column.Group>
      </Level>
    </React.Fragment>
  );
};

export default Selection;
