import React, { useState } from 'react';
import { withFormik } from 'formik';
import styled from 'styled-components';
import uuidv4 from 'uuid/v4';
import * as yup from 'yup';
import { isNull, first, forEach, omit } from 'lodash';
import { Tab } from 'rbx';
import CompanyInformation from './CompanyInformation';
import CompanyDetails from './CompanyDetails';
import CompanyTaxDetails from './CompanyTaxDetails';
import Header from './Header';
import Footer from './Footer';
import { Box, Tabs } from '../../../components/elements';

const tabs = [
  {
    key: 'company-information',
    name: 'Company Information',
    index: 0,
  },
  {
    key: 'company-place-details',
    name: 'Company Place details',
    index: 1,
  },
  {
    key: 'company-tax-details',
    name: 'Company Tax Details',
    index: 2,
  },
];

const Wrapper = styled.div`
  padding: 1rem;
`;

const removeTypename = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push(omit(item, ['__typename']));
  });
  return final;
};

const Form = props => {
  const [activeTab, setActiveTab] = useState(0);
  const { values, handleSubmit, loading } = props;

  const prev = () =>
    activeTab === 0 ? setActiveTab(4) : setActiveTab(activeTab - 1);
  const next = () =>
    activeTab === 2 ? setActiveTab(0) : setActiveTab(activeTab + 1);

  return (
    <React.Fragment>
      <Header
        activeTab={activeTab}
        loading={loading}
        handleSubmit={handleSubmit}
        companyName={values.name}
        status={values.status}
        prev={prev}
        next={next}
      />
      <Box>
        <Tabs className="tabs is-medium">
          {tabs.map(tab => (
            <Tab
              active={activeTab === tab.index}
              key={tab.key}
              onClick={() => setActiveTab(tab.index)}
            >
              {tab.name}
            </Tab>
          ))}
        </Tabs>
        <Wrapper>
          {activeTab === 0 && (
            <CompanyInformation
              props={props}
              next={() => setActiveTab('company-information')}
            />
          )}
          {activeTab === 1 && (
            <CompanyDetails
              {...props}
              next={() => setActiveTab('company-place-details')}
            />
          )}
          {activeTab === 2 && (
            <CompanyTaxDetails
              {...props}
              next={() => setActiveTab('company-tax-details')}
            />
          )}
        </Wrapper>
      </Box>
      <hr />
      <Footer
        loading={loading}
        handleSubmit={handleSubmit}
        prev={prev}
        next={next}
      />
    </React.Fragment>
  );
};

const AddCompanyForm = withFormik({
  mapPropsToValues: ({ company, userId }) => {
    const contact =
      company && !isNull(company.contact)
        ? company.contact.filter(item => item.is_primary === false)
        : [];

    const primaryEmail =
      company && !isNull(company.contact)
        ? company.contact.filter(
            item => item.is_primary === true && item.type === 'email',
          )
        : [];
    const primaryMobile =
      company && !isNull(company.contact)
        ? company.contact.filter(
            item => item.is_primary === true && item.type === 'mobile',
          )
        : [];

    const businessSite =
      company && !isNull(company.contact)
        ? company.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];

    return {
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      displayEmail:
        primaryEmail.length !== 0 ? first(primaryEmail).display : false,
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      displayMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).display : false,
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      user_id: userId,
      company_id: company ? company.company_id : uuidv4(),
      name: company ? company.name : '',
      status: company ? company.status : 'ACTIVE',
      contact,
      tax: company && !isNull(company.tax) ? removeTypename(company.tax) : [],
      address_line_1: company ? company.address_line_1 : '',
      address_line_2: company ? company.address_line_2 : '',
      city: company ? company.city : '',
      state: company ? company.state : '',
      country: company ? company.country : '',
      post_code: company ? company.post_code : '',
      location: company ? company.location : '',
    };
  },
  validationSchema: yup.object().shape({
    name: yup
      .string()
      .min(3, 'At least 3 Characters Required!')
      .max(75, 'Not greater than 100 Characters!')
      .required('Company Name is required!'),
    status: yup.string().required('Status is required'),
    address_line_1: yup.string().required('Street Address is required'),
    city: yup.string().required('Suburb / City is required'),
    post_code: yup.string().required('Post Code is required'),
    state: yup.string().required('State is required'),
    country: yup.string().required('Country is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'AddCompanyForm',
})(Form);

export default AddCompanyForm;
