import React, { useEffect, useState } from 'react';
import { union, forEach, slice } from 'lodash';
import styled from 'styled-components';
import {
  Input,
  CreatableSelectInputs,
  TagInputs,
  TextArea,
  Select,
} from '../../../components/elements';
import {
  getAmbience,
  getNoiseLevels,
  getPublicTransports,
  getParkingDetails,
  getDressCodes,
  getGoodFor,
  getCuisines,
  getDiningStyle,
  getFeatures,
  getItemTypes,
  getServicesTypes,
  getServiceCategories,
  getPlaceTypes,
  getDietaryInformation,
} from '../../../utils/fetchStatic';

const options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

const PlaceDetails = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const [data, setData] = useState([
    {
      businessTypes: [],
      serviceCategories: [],
      servicesTypes: [],
      itemTypes: [],
      features: [],
      diningStyle: [],
      cuisines: [],
      goodFor: [],
      dressCodes: [],
      parkingDetails: [],
      publicTransports: [],
      ambience: [],
      noiseLevels: [],
      dietaryInformation: [],
    },
  ]);

  const parseInput = parseValue => {
    const final = [];
    forEach(parseValue, (item, index) => {
      final.push({
        name: item.value,
        description: item.label,
        display_order: (index + 1).toString(),
      });
    });
    return final;
  };

  const placeKeywordSuggestions = () => {
    const businessTypeFirstTwo = slice(values.business_type, 0, 2);
    const itemTypeFirstTwo = slice(values.item_type, 0, 2);
    const cuisineTypeFirstTwo = slice(values.cuisine_style, 0, 2);
    const featureFirstTwo = slice(values.feature, 0, 2);

    return union(
      businessTypeFirstTwo,
      itemTypeFirstTwo,
      cuisineTypeFirstTwo,
      featureFirstTwo,
    );
  };

  const placeTagInput = tagsValue => {
    const tags = [];
    tagsValue.map(item => {
      tags.push(item.value);
      return null;
    });
    return tags;
  };

  useEffect(() => {
    const getData = async () => {
      const serviceCategories = await getServiceCategories();
      const businessTypes = await getPlaceTypes();
      const servicesTypes = await getServicesTypes();
      const itemTypes = await getItemTypes();
      const features = await getFeatures();
      const diningStyle = await getDiningStyle();
      const cuisines = await getCuisines();
      const goodFor = await getGoodFor();
      const dressCodes = await getDressCodes();
      const parkingDetails = await getParkingDetails();
      const publicTransports = await getPublicTransports();
      const ambience = await getAmbience();
      const noiseLevels = await getNoiseLevels();
      const dietaryInformation = await getDietaryInformation();
      // console.log(parseInput(businessTypes[0]), parseInput(businessTypes[1]);
      // setFieldValue(
      //   'business_type'[
      //     (parseInput(businessTypes[0]), parseInput(businessTypes[1]))
      //   ],
      // );
      // setFieldValue('cuisine_style', [
      //   (parseInput(cuisines[0]), parseInput(cuisines[1])),
      // ]);
      // setFieldValue('item_type', [
      //   (parseInput(itemTypes[0]), parseInput(itemTypes[1])),
      // ]);
      // setFieldValue('feature', [
      //   (parseInput(features[0]), parseInput(features[1])),
      // ]);
      // setFieldValue('keywords');

      setData({
        serviceCategories,
        servicesTypes,
        businessTypes,
        itemTypes,
        features,
        diningStyle,
        cuisines,
        goodFor,
        dressCodes,
        parkingDetails,
        publicTransports,
        ambience,
        noiseLevels,
        dietaryInformation,
      });
    };
    getData();
  }, []);

  const parseData = parseValue => {
    const final = [];
    forEach(parseValue, item => {
      final.push({ value: item.name, label: item.description });
    });
    return final;
  };

  const parseKeywordsData = parseValue => {
    const final = [];
    forEach(parseValue, item => {
      final.push({ value: item.description, label: item.description });
    });
    return final;
  };

  return (
    <div>
      <div className="columns is-multiline">
        <div className="column is-half">
          <TagInputs
            label="Business Type"
            optionalText="(Upto 2 Business Types)"
            value={parseData(values.business_type)}
            suggestions={
              values.business_type.length === 2
                ? []
                : parseData(data.businessTypes)
            }
            onChange={value =>
              setFieldValue('business_type', parseInput(value))
            }
            errors={errors.business_type}
            touched={touched.business_type}
            required
          />
        </div>
        {/* <div className="column is-half">
          <TagInputs
            label="Service Type"
            value={parseData(values.service_type)}
            suggestions={parseData(data.servicesTypes)}
            onChange={value => setFieldValue('service_type', parseInput(value))}
          />
        </div> */}
        <div className="column is-half">
          <TagInputs
            label="Service Category"
            suggestions={parseData(data.serviceCategories)}
            value={parseData(values.service_category)}
            onChange={value =>
              setFieldValue('service_category', parseInput(value))
            }
            errors={errors.service_category}
            touched={touched.service_category}
            required
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Place Features"
            suggestions={parseData(data.features)}
            value={parseData(values.feature)}
            onChange={value => setFieldValue('feature', parseInput(value))}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Item Types"
            suggestions={parseData(data.itemTypes)}
            value={parseData(values.item_type)}
            onChange={value => setFieldValue('item_type', parseInput(value))}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Cuisine Style"
            suggestions={parseData(data.cuisines)}
            value={parseData(values.cuisine_style)}
            onChange={value =>
              setFieldValue('cuisine_style', parseInput(value))
            }
            required
            errors={errors.cuisine_style}
            touched={touched.cuisine_style}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Dining Style"
            suggestions={parseData(data.diningStyle)}
            value={parseData(values.dining_style)}
            onChange={value => setFieldValue('dining_style', parseInput(value))}
            errors={errors.dining_style}
            touched={touched.dining_style}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Dietary information"
            suggestions={parseData(data.dietaryInformation)}
            value={parseData(values.dietary_information)}
            onChange={value =>
              setFieldValue('dietary_information', parseInput(value))
            }
            errors={errors.dietary_information}
            touched={touched.dietary_information}
          />
        </div>

        <div className="column is-half">
          <TagInputs
            label="Good For (Known For)"
            suggestions={parseData(data.goodFor)}
            value={parseData(values.good_for)}
            onChange={value => setFieldValue('good_for', parseInput(value))}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Parking Details"
            suggestions={parseData(data.parkingDetails)}
            value={parseData(values.parking_detail)}
            onChange={value =>
              setFieldValue('parking_detail', parseInput(value))
            }
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Public Transport"
            suggestions={parseData(data.publicTransports)}
            value={parseData(values.public_transport)}
            onChange={value =>
              setFieldValue('public_transport', parseInput(value))
            }
          />
        </div>
        <div className="column is-half">
          <Select
            label="Price Range"
            options={options}
            value={[{ value: values.price_range, label: values.price_range }]}
            onChange={value =>
              setFieldValue('price_range', parseInt(value.value, 10))
            }
            optionalText="(Choose between 1 and 5)"
          />
        </div>

        <div className="column is-half">
          <CreatableSelectInputs
            label="Keywords"
            suggestions={parseKeywordsData(placeKeywordSuggestions())}
            value={values.keywords.map(item => ({ value: item, label: item }))}
            onChange={value => {
              if (value) {
                setFieldValue('keywords', placeTagInput(value));
              }
            }}
            errors={errors.keywords}
            touched={touched.keywords}
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Ambience"
            suggestions={parseData(data.ambience)}
            value={parseData(values.ambience)}
            onChange={value => setFieldValue('ambience', parseInput(value))}
          />
        </div>

        <div className="column is-half">
          <Select
            label="Noise level"
            options={parseData(data.noiseLevels)}
            value={parseData(values.noise_level)}
            onChange={value =>
              setFieldValue('noise_level', [
                {
                  name: value.value,
                  description: value.label,
                  display_order: '1',
                },
              ])
            }
          />
        </div>
        <div className="column is-half">
          <TagInputs
            label="Dress Code"
            suggestions={parseData(data.dressCodes)}
            value={parseData(values.dress_code)}
            onChange={value => setFieldValue('dress_code', parseInput(value))}
          />
        </div>

        <div className="column is-half">
          <Input
            label="Place Tag Line"
            name="tagline"
            value={values.tagline}
            onChange={handleChange}
            onBlur={handleBlur}
            optionalText="(Min 10 and Max 80 Characters)"
            errors={errors.tagline}
            touched={touched.tagline}
          />
        </div>
        <div className="column is-full">
          <CreatableSelectInputs
            label="Tag"
            placeholder="eg, nice place, awesome food here, ...."
            suggestions={[]}
            value={values.tag.map(item => ({ value: item, label: item }))}
            onChange={value => {
              if (value) {
                setFieldValue('tag', placeTagInput(value));
              }
            }}
          />
        </div>

        <div className="column is-full">
          <TextArea
            label="Place Description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            optionalText="(Min 10 and Max 8000 Characters)"
            errors={errors.description}
            touched={touched.description}
          />
          {/* <Text>{`Characters left ${8000 - values.description.length}`}</Text> */}
          {values.description.length > 8000 || values.description.length < 10
            ? ''
            : `Characters left ${8000 - values.description.length}`}
        </div>
      </div>
    </div>
  );
};

export default PlaceDetails;
