import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { Radio } from '../../../components/elements';
const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const ItemStock = ({ statusFilter, setStatusFilter }) => {
  return (
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <TitleWrapper>
            <Title className="label">Item Stock</Title>
          </TitleWrapper>
        </Level.Item>
        <Level.Item>
          <Radio
            label="Available"
            value={statusFilter === 'AVAILABLE'}
            onChange={value => {
              if (value) {
                setStatusFilter('AVAILABLE');
              }
            }}
          />
        </Level.Item>
        <Level.Item>
          <Radio
            label="Unavailable Today"
            value={statusFilter === 'UNAVAILABLE TODAY'}
            onChange={value => {
              if (value) {
                setStatusFilter('UNAVAILABLE TODAY');
              }
            }}
          />
        </Level.Item>

        <Level.Item>
          <Radio
            label="Unavailable permanently or indefinitely"
            value={statusFilter === 'UNAVAILABLE PERMANENTLY OR INDEFINITELY'}
            onChange={value => {
              if (value) {
                setStatusFilter('UNAVAILABLE PERMANENTLY OR INDEFINITELY');
              }
            }}
          />
        </Level.Item>
      </Level.Item>
    </Level>
  );
};

export default ItemStock;
