import React from 'react';
import styled from 'styled-components';
import { Column, Field, Label, Control } from 'rbx';
import moment from 'moment';
import { replace, first } from 'lodash';

import Contacts from '../../../components/Contacts';
// import Socials from '../../../components/Socials';
import {
  Radio,
  Input,
  CheckBox,
  TextArea,
  Select,
  MobileInput,
  ReactDateTimePicker,
  Dropzone,
} from '../../../components/elements';
import config from '../../../utils/config';

const dummyImage =
  'https://images.unsplash.com/photo-1524593689594-aae2f26b75ab?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2800&q=80';
const Wrapper = styled.div`
  padding-top: 1.7rem;
`;
const RadioWrapper = styled.div`
  padding-right: 1rem;
`;

const Fieldset = styled.fieldset`
  margin-right: 6rem;
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const UserDetails = ({
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  setValues,
}) => {
  const imageUrl = '';
  return (
    <Column.Group vcentered multiline gapSize={8}>
      <Column size="half">
        <Input label="Login" value={values.email} required disabled />
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={8}>
          <Column>
            <Select
              label="Status"
              value={[{ value: values.status, label: values.status }]}
              options={[
                { value: 'ACTIVE', label: 'ACTIVE' },
                { value: 'INACTIVE', label: 'INACTIVE' },
              ]}
              onChange={value => setFieldValue('status', value.value)}
              required
            />
          </Column>
          <Column>
            <Wrapper>
              <CheckBox
                label="Admin User"
                value={values.admin}
                onChange={value => setFieldValue('admin', value)}
                secondary
              />
            </Wrapper>
          </Column>
        </Column.Group>
      </Column>
      <Column size="half">
        <Input
          label="First Name"
          name="first_name"
          value={values.first_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
      </Column>
      <Column size="half">
        <Input
          label="Last Name"
          name="last_name"
          value={values.last_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
      </Column>
      <Column size="half">
        <Input
          label="Display Name"
          name="display_name"
          value={values.display_name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
      </Column>
      <Column size="half">
        {/* <MobileInput
          label="Primary Contact Phone"
          value={values.primaryContact}
          onChange={value =>
            setFieldValue('primaryContact', replace(value, ' ', ''))
          }
          required
        /> */}
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={2}>
          <Column size="full">
            <Input
              label="Primary Email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </Column>
          <Column size="full">
            <Input
              label="User Handle"
              name="handle"
              value={values.user_handle}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Column>
          <Column size="full">
            <Column.Group vcentered multiline gapSize={2}>
              <Column size={8}>
                <Input label="State" required disabled />
              </Column>
              <Column size={4}>
                <Input label="Timezone" secondary disabled />
              </Column>
            </Column.Group>
          </Column>
        </Column.Group>
      </Column>
      <Column size="half">
        <Dropzone
          s3UploadPath={`user/${values.user_id}/`}
          metaData={{ user_id: values.user_id }}
          onChange={image => {
            const imageValues = [];
            imageValues.push({
              url: `user/${values.user_id}/${image.path}`,
              tooltip: `image`,
              link: `https://${config.apiPrefix}images.kravein.com.au/`,
              display_order: '1',
              tags: [],
            });
            setFieldValue('profile_image', imageValues);
          }}
        >
          <figure className="image is-128x128">
            <img src={imageUrl} alt={values.first_name} />
          </figure>
        </Dropzone>
        <br />
      </Column>
      <Column size="half">
        <Select
          label="Country"
          options={[
            { value: 'ACTIVE', label: 'ACTIVE' },
            { value: 'INACTIVE', label: 'INACTIVE' },
          ]}
          disabled
          required
        />
      </Column>
      <Column size="half">
        <Column.Group vcentered multiline gapSize={4}>
          <Column size="half">
            {/* <ReactDateTimePicker
              label="Date of Birth"
              value={values.date_of_birth}
              onChange={date => {
                setFieldValue('date_of_birth', moment(date).toISOString());
              }}
              required
            /> */}
          </Column>
          <Column size="half">
            <Field>
              <Label>Gender</Label>
              <Control>
                <div className="is-flex">
                  <RadioWrapper>
                    <Radio
                      label="Male"
                      value={values.gender === 'MALE'}
                      onChange={value => {
                        if (value) {
                          setFieldValue('gender', 'MALE');
                        }
                      }}
                    />
                  </RadioWrapper>
                  <RadioWrapper>
                    <Radio
                      label="Female"
                      value={values.gender === 'FEMALE'}
                      onChange={value => {
                        if (value) {
                          setFieldValue('gender', 'FEMALE');
                        }
                      }}
                    />
                  </RadioWrapper>
                  <RadioWrapper>
                    <Radio
                      label="Other"
                      value={values.gender === 'OTHER'}
                      onChange={value => {
                        if (value) {
                          setFieldValue('gender', 'OTHER');
                        }
                      }}
                    />
                  </RadioWrapper>
                </div>
              </Control>
            </Field>
          </Column>
        </Column.Group>
      </Column>
      <Column size="full">
        <Contacts
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          setValues={setValues}
          onlyAdditionalContact
        />
      </Column>
      <Column size="full">
        <Fieldset className="box">
          <legend className="label"> Social Details</legend>
          {/* <Socials
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setValues={setValues}
            onlyAdditionalContact
          /> */}
        </Fieldset>
      </Column>
      <Column size={11}>
        <TextArea
          label="About User"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Column>
    </Column.Group>
  );
};

export default UserDetails;
