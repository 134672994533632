import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Layout from '../../../components/global/Layout';
import Header from './Header';
import Search from './Search';
import ListItem from './ListItem';
import {
  Loading,
  Pagination,
  Table,
  Box,
  // CustomInput,
} from '../../../components/elements';
import client from '../../../utils/apolloClient';

const usersQuery = gql`
  query users($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        user_id
        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        admin
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

const Users = () => {
  const [userData, setUserData] = useState([]);
  const [sort, setSort] = useState('FIRSTNAME_DESC');

  const [firstNameSort, setFirstNameSort] = useState('AES');
  const [lastNameSort, setLastNameSort] = useState('AES');
  const [emailSort, setEmailSort] = useState('AES');
  const [displayNameSort, setDisplayNameSort] = useState('AES');

  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [listSize] = useState(10);
  const [totalElements, setTotalElements] = useState();

  const [status, setStatus] = useState('active');

  const [loginSearchInput, setLoginSearchInput] = useState('');
  const [firstNameSearchInput, setFirstNameSearchInput] = useState('');
  const [lastNameSearchInput, setLastNameSearchInput] = useState('');
  const [displayNameSearchInput, setDisplayNameSearchInput] = useState('');

  const input = {};
  const user_filter = { status: 'ACTIVE' };
  Object.assign(
    user_filter,
    loginSearchInput !== '' && { email: loginSearchInput },
    firstNameSearchInput !== '' && { full_name: firstNameSearchInput },
    lastNameSearchInput !== '' && { full_name: lastNameSearchInput },
    displayNameSearchInput !== '' && { display_name: displayNameSearchInput },
  );
  Object.assign(
    input,

    { from: pageFrom, size: listSize },
    { sort },
    {
      filter: {
        user_filter,
      },
    },
  );
  return (
    <Layout>
      <Header />
      <Box>
        <Search
          status={status}
          setStatus={setStatus}
          loginSearchInput={loginSearchInput}
          setLoginSearchInput={setLoginSearchInput}
          firstNameSearchInput={firstNameSearchInput}
          setFirstNameSearchInput={setFirstNameSearchInput}
          lastNameSearchInput={lastNameSearchInput}
          setLastNameSearchInput={setLastNameSearchInput}
          displayNameSearchInput={displayNameSearchInput}
          setDisplayNameSearchInput={setDisplayNameSearchInput}
        />
        <br />
        {totalElements > 0 && (
          <Pagination
            pageFrom={pageFrom}
            pageCount={pageCount}
            listSize={listSize}
            currentPage={currentPage}
            totalElements={totalElements}
            handlePageClick={value => {
              setPageFrom(value.selected * listSize);
              setCurrentPage(value.selected + 1);
            }}
          />
        )}

        <Table>
          <thead>
            <tr>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setEmailSort(emailSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          emailSort === 'AES' ? 'EMAIL_DESC' : 'EMAIL_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          emailSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Login
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setFirstNameSort(
                          firstNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          firstNameSort === 'AES'
                            ? 'FIRSTNAME_DESC'
                            : 'FIRSTNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          firstNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    First Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setLastNameSort(lastNameSort === 'AES' ? 'DES' : 'AES');
                        setSort(
                          lastNameSort === 'AES'
                            ? 'LASTNAME_DESC'
                            : 'LASTNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          lastNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Last Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <a
                      onClick={() => {
                        setDisplayNameSort(
                          displayNameSort === 'AES' ? 'DES' : 'AES',
                        );
                        setSort(
                          displayNameSort === 'AES'
                            ? 'DISPLAYNAME_DESC'
                            : 'DISPLAYNAME_ASC',
                        );
                      }}
                    >
                      <i
                        className={
                          displayNameSort === 'AES'
                            ? 'fas fa-sort-amount-down'
                            : 'fas fa-sort-amount-up'
                        }
                      />
                    </a>{' '}
                    Display Name
                  </span>
                  {/* <br />
                <CustomInput /> */}
                </div>
              </th>
              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Image
                    Status
                  </span>
                  <br />
                  <CustomInput />
                </div>
              </th> */}
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Last
                    Logged In
                  </span>
                </div>
              </th>
              {/* <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Updated
                    By
                  </span>
                </div>
              </th>
              <th>
                <div>
                  <span>
                    <i className="fas fa-sort-amount-down is-size-7" /> Updated
                    At
                  </span>
                </div>
              </th>  */}
              <th>Actions</th>
            </tr>
          </thead>
          <Query
            client={client.clientPrivate}
            query={usersQuery}
            variables={{ input }}
            fetchPolicy="network-only"
          >
            {({ data, loading, error }) => {
              if (loading) {
                return <Loading />;
              }
              if (error) {
                return 'error';
              }

              const { search_users } = data;
              return (
                <View
                  userData={userData}
                  setUserData={setUserData}
                  search_users={search_users}
                  setPageCount={setPageCount}
                  setTotalElements={setTotalElements}
                />
              );
            }}
          </Query>
        </Table>
      </Box>
    </Layout>
  );
};

export default Users;

const View = ({
  search_users,
  setPageCount,
  setTotalElements,
  userData,
  setUserData,
}) => {
  useEffect(() => {
    setPageCount(search_users.total_pages);
    setTotalElements(search_users.total_size);
    if (search_users.user_listing) {
      setUserData(search_users.user_listing);
    }
  }, [search_users.total_pages]);
  return (
    <tbody>
      {userData.map(user => (
        <ListItem user={user} />
      ))}
    </tbody>
  );
};
