import React, { useState } from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import moment from 'moment';
import { Input, Radio, CheckBox } from '../../../components/elements';
import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const Search = ({
  status,
  setStatus,
  voucherNameSearch,
  setVoucherNameSearch,
  eventStatus,
  setEventStatus,
  // handleSuburbNameSearch,
  // eventNameSearch,
  // suburbNameSearch,
  setLocation,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  setStartDateRangeFilter,
  setEndDateRangeFilter,
  voucherCodeSearch,
  setVoucherCodeSearch,
}) => {
  const [city, setCity] = useState('');
  return (
    <Wrapper>
      <Level>
        <Level.Item align="left">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Current"
              value={status === 'current'}
              onChange={() => {
                setStatus('current');
                setStartDateRangeFilter({
                  end_date: moment().toISOString(),
                });
                setEndDateRangeFilter({
                  start_date: moment(
                    `${moment().format('L')} 11:59 PM`,
                  ).toISOString(),
                });
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Past"
              value={status === 'past'}
              onChange={() => {
                setStatus('past');
                setEndDateRangeFilter({
                  end_date: moment(`${moment().format('L')} 12:00 AM`)
                    .subtract(1, 'day')
                    .toISOString(),
                });
                setStartDateRangeFilter();
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Future"
              value={status === 'future'}
              onChange={() => {
                setStatus('future');
                setStartDateRangeFilter({
                  start_date: moment(`${moment().format('L')} 12:00 AM`)
                    .add(1, 'day')
                    .toISOString(),
                });
                setEndDateRangeFilter();
              }}
            />
          </Level.Item>
        </Level.Item>
        <Level.Item align="right">
          <Level.Item>
            <TitleWrapper>
              <Title className="label">Event Deal Status</Title>
            </TitleWrapper>
          </Level.Item>
          <Level.Item>
            <Radio
              label="Active"
              value={eventStatus === 'ACTIVE'}
              onChange={() => {
                setEventStatus('ACTIVE');
              }}
            />
          </Level.Item>
          <Level.Item>
            <Radio
              label="Inactive"
              value={eventStatus === 'INACTIVE'}
              onChange={() => {
                setEventStatus('INACTIVE');
              }}
            />
          </Level.Item>
        </Level.Item>
      </Level>
      <BoxWrapper className="box">
        <div className="columns">
          <div className="column is-4">
            <Input
              label="Voucher Name"
              type="text"
              value={voucherNameSearch}
              onChange={event => setVoucherNameSearch(event.target.value)}
              placeholder="Start entering voucher name..."
            />
          </div>

          <div className="column is-4">
            <Input
              label="Voucher Code"
              type="text"
              value={voucherCodeSearch}
              onChange={event => setVoucherCodeSearch(event.target.value)}
              placeholder="Start entering voucher code..."
            />
          </div>

          <div className="column is-one-quarter">
            <Input
              label="Place Name"
              type="text"
              value={placeNameSearchInput}
              onChange={event => setPlaceNameSearchInput(event.target.value)}
              placeholder="Start entering place name..."
            />
          </div>
          {/* <div className="column is-4">
            <Input
              label="Event Name"
              type="text"
              value={eventNameSearch}
              onChange={event => handleEventNameSearch(event.target.value)}
              placeholder="Start entering event name..."
            />
          </div> */}
        </div>
      </BoxWrapper>
    </Wrapper>
  );
};

export default Search;
