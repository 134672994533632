import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withFormik } from 'formik';
import gql from 'graphql-tag';
import moment from 'moment';
import uuid from 'uuid';
import * as yup from 'yup';
import { omit, isNull } from 'lodash';
import tzLookup from 'tz-lookup';
import swal from 'sweetalert';

import { toast } from 'react-toastify';
import TicketForm from './TicketForm';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  &&& {
    .modal-card-head,
    .modal-card-foot {
      justify-content: space-between;
    }
  }
`;

const createEventOccurrenceTicketsMutation = gql`
  mutation createEventOccurrenceTickets(
    $input: [TicketInput]
    $occurrenceInput: EventOccurrenceInput
  ) {
    create_event_occurrence_ticket(input: $input) {
      event_occurrence_id
      ticket_id
    }
    update_event_occurrence(input: $occurrenceInput) {
      event_id
      event_occurrence_id
      error {
        description
      }
    }
  }
`;

const eventTicketBookingsQuery = gql`
  query eventTicketBookings($ticketId: String) {
    search_event_ticket_records(
      input: {
        filter: { event_ticket_record_filter: { ticket_id: $ticketId } }
      }
    ) {
      total_size
    }
  }
`;

const TicketFormModal = ({
  isActive,
  onClose,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  handleSubmit,
  setValues,
  eventStartDate,
  eventEndDate,
  eventStartTime,
  eventEndTime,
  ticketFee,
  totalTicketCapacity,
  totalTickets,
  ticketSubmissionLoading,
  ticket,
  dirty,
}) => {
  const [haveAnyBookingInThisEvent, setHaveAnyBookingInThisEvent] = useState(
    false,
  );

  useEffect(() => {
    if (ticket) {
      client.clientPrivate
        .query({
          query: eventTicketBookingsQuery,
          variables: {
            ticketId: ticket.ticket_id,
          },
        })
        .then(({ data }) => {
          if (data) {
            setHaveAnyBookingInThisEvent(
              data.search_event_ticket_records.total_size !== 0,
            );
          }
        });
    }
  }, []);
  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Ticket</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={() => {
              if (dirty) {
                swal('Do you want to discard the changes?', {
                  buttons: ['Cancel', true],
                }).then(willDelete => {
                  if (willDelete) {
                    onClose(values.quantity);
                  }
                });
              } else {
                onClose(values.quantity);
              }
            }}
          />
        </header>
        <section className="modal-card-body">
          <TicketForm
            values={values}
            touched={touched}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            setValues={setValues}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            absorbFeeAndPassOnFee={ticketFee}
            totalCapacity={totalTicketCapacity - totalTickets}
            haveAnyBookingInThisEvent={haveAnyBookingInThisEvent}
          />
        </section>

        <footer className="modal-card-foot">
          <button
            type="button"
            className="button is-light"
            onClick={() => {
              if (dirty) {
                swal('Do you want to discard the changes?', {
                  buttons: ['Cancel', true],
                }).then(willDelete => {
                  if (willDelete) {
                    onClose(values.quantity);
                  }
                });
              } else {
                onClose(values.quantity);
              }
            }}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`button is-primary ${ticketSubmissionLoading &&
              'is-loading'}`}
            onClick={() => !ticketSubmissionLoading && handleSubmit()}
          >
            Submit
          </button>
        </footer>
      </div>
    </Container>
  );
};

const MyEnhancedForm = withFormik({
  mapPropsToValues: ({ ticket, userId, event_occurrence_id, ticketType }) => {
    const checkFeeType = ticket
      ? ticket.fee.filter(
          item =>
            item.name === 'online_passon_fee' ||
            item.name === 'offline_passon_fee',
        )
      : [];

    const feeType = checkFeeType.length !== 0 ? 'passOnFee' : 'absorbedFee';

    return {
      ticket: !!ticket,
      salesMethods: 'all',
      feeType,
      user_id: ticket ? ticket.user_id : userId,
      event_occurrence_id,
      ticket_id: ticket ? ticket.ticket_id : uuid(),
      status: ticket ? ticket.status : 'AVAILABLE',
      sales_method: ticket ? ticket.sales_method : ['online', 'offline'],
      name: ticket ? ticket.name : '',
      type: ticket ? ticket.type : ticketType,
      description: ticket ? ticket.description : '',
      quantity: ticket ? ticket.quantity : 0,
      // sale_start: ticket ? ticket.sale_start : moment().toISOString(),
      // sale_end: ticket ? ticket.sale_end : null,
      startDate: ticket ? ticket.sale_start : moment().toISOString(),
      startTime: ticket ? ticket.sale_start : null,
      endDate: ticket ? ticket.sale_end : null,
      endTime: ticket ? ticket.sale_end : null,

      minimum: ticket ? ticket.minimum : 1,
      maximum: ticket ? ticket.maximum : 0,
      price: ticket ? parseFloat(ticket.price).toFixed(2) : 0,
      attribute: ticket ? ticket.attribute : [],
      special_price: ticket ? parseFloat(ticket.special_price).toFixed(2) : 0,
      fee: ticket && ticketType === 'paid' ? ticket.fee : [],
    };
  },

  validate: values => {
    const errors = {};

    if (values.maximum < values.minimum) {
      errors.maximum = 'Maximum should be greater than or equal to minimum';
    }

    if (values.maximum < values.minimum) {
      errors.maximum = 'Maximum should be greater than or equal to minimum';
    }

    if (values.special_price > values.price) {
      errors.special_price = 'Special Price should be smaller than price';
    }

    if (values.type === 'paid') {
      if (!values.price || values.price <= 0) {
        errors.price = 'Enter the price';
      }

      values.fee.map(item => {
        if (item.value > parseFloat(values.price)) {
          errors.price = 'Ticket price should be greater than the ticket fee';
        }
        // TODO: Check Online and Offline Price
        return null;
      });
    }

    if (values.maximum > values.quantity) {
      errors.maximum = 'maximum should be less than quantity';
    }
    // const { absorb } = values.value;
    // console.log('fee', absorb);
    return errors;
  },

  validationSchema: ({ totalTicketCapacity, availableQuantity }) =>
    yup.object().shape({
      name: yup
        .string()
        .min(3, 'Please enter at least 3 characters')
        .max(50, 'Please enter less than 50 characters')
        .required('Ticket Name is required!'),
      startDate: yup
        .string()
        .transform(v => (v === null ? '' : v))
        .required('Sale start is required!'),
      endDate: yup.string().transform(v => (v === null ? '' : v)),

      type: yup.string().required('Type is required!'),
      sales_method: yup.array().required('sales_method is required'),
      minimum: yup
        .number()
        .min(1, 'Please enter at-least one ')
        // .max(9999, 'Minimum cannot exceed 9999')
        .integer('Minimum cannot be in decimals')
        .required('Minimum is required'),
      maximum: yup
        .number()
        .min(1, 'Please enter at-least one ')
        // .max(9999, 'Maximum cannot exceed 9999')
        .integer('Maximum cannot be in decimals')
        .required('Maximum is required')
        .moreThan(0, 'Maximum number is required')
        .lessThan(51, 'Should Not exceed 50 Tickets'),

      price: yup.number().required('Price is required'),
      quantity: yup
        .number()
        // .max(5, 'Quantity cannot exceed 9999')
        .integer('Quantity cannot be in decimals')
        .moreThan(0, 'Quantity is required')
        // .lessThan(
        //   totalTicketCapacity + 1 - totalTickets,
        //   `Total quantity available exceeds the total capacity.
        //    Review the total quantity (between 0 to ${totalTicketCapacity -
        //      totalTickets})`,
        // )
        .lessThan(
          totalTicketCapacity + 1,
          `Total quantity should not exceed the total Ticket Capacity.`,
        )
        .lessThan(
          availableQuantity + 1,
          'Ticket cannot be more than the available quantity',
        )
        .required('Quantity is required'),
    }),

  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const input = omit(values, [
      'feeType',
      'salesMethods',
      'fee',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'ticket',
    ]);
    const {
      latitude,
      longitude,
      totalTickets,
      eventEndDate,
      eventEndTime,
      eventId,
      setTicketSubmissionLoading,
      totalTicketCapacity,
    } = props;
    setTicketSubmissionLoading(true);

    const lat = parseFloat(latitude).toFixed(5);
    const long = parseFloat(longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);

    const eventEndTimeCustom = !isNull(eventEndTime)
      ? moment(eventEndTime).format('HH:mm:ss')
      : '23:59:00';

    const customStartDate = moment(values.startDate).format('YYYY-MM-DD');
    const customStartTime = !isNull(values.startTime)
      ? moment(values.startTime).format('HH:mm:ss')
      : '00:00:00';
    const customEndDate = !isNull(values.endDate)
      ? moment(values.endDate).format('YYYY-MM-DD')
      : moment(eventEndDate).format('YYYY-MM-DD');
    const customEndTime = !isNull(values.endTime)
      ? moment(values.endTime).format('HH:mm:ss')
      : eventEndTimeCustom;

    const saleStartDate = moment
      .tz(`${customStartDate} ${customStartTime}`, timeZone)
      .toISOString();

    const saleEndDate = moment
      .tz(`${customEndDate} ${customEndTime}`, timeZone)
      .toISOString();

    const fee = await values.fee.map(item => {
      const customFee = omit(item, ['id', '__typename']);
      return customFee;
    });

    await setTimeout(() => {
      if (props.pathName === '/add-event') {
        if (props.ticket) {
          props.handleUpdate({
            ...input,
            fee,
            sale_start: saleStartDate,
            sale_end: !isNull(values.endDate) ? saleEndDate : null,
          });
          resetForm();
        } else {
          props.isSubmit({
            ...input,
            fee,
            sale_start: saleStartDate,
            sale_end: !isNull(values.endDate) ? saleEndDate : null,
          });
          resetForm();
        }
      } else if (props.ticket) {
        props.handleUpdate({
          ...input,
          fee,
          sale_start: saleStartDate,
          sale_end: !isNull(values.endDate) ? saleEndDate : null,
        });
        resetForm();
      } else {
        client.clientPrivate
          .mutate({
            mutation: createEventOccurrenceTicketsMutation,
            variables: {
              occurrenceInput: {
                user_id: input.event_occurrence_id,
                event_id: eventId,
                event_occurrence_id: input.event_occurrence_id,
                total_tickets:
                  parseInt(totalTickets, 10) + parseInt(input.quantity, 10),
                total_capacity: totalTicketCapacity,
              },
              input: [
                {
                  ...input,
                  fee,
                  sale_start: saleStartDate,
                  sale_end: !isNull(values.endDate) ? saleEndDate : null,
                },
              ],
            },
          })
          .then(() => {
            props.isSubmit({
              ...input,
              fee,
              sale_start: saleStartDate,
              sale_end: saleEndDate,
            });
            resetForm();
            toast.success('a new ticket created successfully in this event');
          })
          .catch(error => {
            console.log('error', error);
          });
      }
      setSubmitting(false);
    }, 300);
  },

  displayName: 'TicketFormModalForm',
})(TicketFormModal);

export default MyEnhancedForm;
