import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Level, Title } from 'rbx';
import styled from 'styled-components';
import { PageHeader } from '../../../components/elements';

const Container = styled.div`
  padding: 1rem 0.5rem;
`;

const Header = ({ loading, handleSubmit, location, placeName, next, prev }) => (
  <Container>
    <Level>
      <Level.Item align="left">
        <Title>Admin Users</Title>
      </Level.Item>
      <Level.Item align="right">
        <Level.Item>
          <Link to="/places" className="button is-light">
            <span>Back</span>
          </Link>
        </Level.Item>

        <Level.Item>
          <button
            className={`button is-primary ${loading && 'is-loading'}`}
            onClick={handleSubmit}
          >
            <span>Submit</span>
          </button>
        </Level.Item>
      </Level.Item>
    </Level>
  </Container>
);

export default withRouter(Header);
