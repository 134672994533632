import React from 'react';
import { Button, Icon } from 'rbx';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { capitalize } from 'lodash';
import { useStoreState } from 'easy-peasy';
import swal from 'sweetalert';
import { withRouter } from 'react-router-dom';

import { Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const deleteEventOccurrenceTicketMutation = gql`
  mutation delete_event_occurrence_ticket($input: NavigateTicketInput) {
    delete_event_occurrence_ticket(input: $input) {
      ticket_id
      error {
        description
      }
    }
  }
`;

const EditDeleteButton = ({ edit, remove, loading }) => (
  <div className="is-flex">
    <Button state={loading && 'loading'} onClick={remove}>
      {!loading && (
        <Icon size="small">
          <i className="fas fa-trash has-text-grey" />
        </Icon>
      )}
    </Button>
    <Button onClick={edit}>
      <Icon size="small">
        <i className="fas fa-edit has-text-grey" />
      </Icon>
    </Button>
  </div>
);

const getTicketStatus = ticket => {
  if (ticket.quantity === ticket.sold) {
    return 'SOLD OUT';
  }
  return ticket.status;
};

const TicketList = ({ ticket, removeTickets, onHandleUpdate, location }) => {
  const { userId } = useStoreState(state => state.auth);

  console.log('removeTickets', ticket);

  return (
    <React.Fragment>
      <tr>
        <td>
          <Input value={capitalize(ticket.type)} disabled />
        </td>
        <td>
          <Input value={capitalize(ticket.name)} disabled />
        </td>
        <td>
          <Input value={ticket.quantity} disabled />
        </td>
        <td>
          <Input value={parseFloat(ticket.price).toFixed(2)} disabled />
        </td>
        <td>
          <Input value={getTicketStatus(ticket)} disabled />
        </td>
        <td>
          <Input
            value={ticket.sales_method.map(item => `${capitalize(item)} `)}
            disabled
          />
        </td>
        <td>
          <Mutation
            client={client.clientPrivate}
            mutation={deleteEventOccurrenceTicketMutation}
            variables={{
              input: {
                user_id: userId,
                ticket_id: ticket.ticket_id,
              },
            }}
            onCompleted={() => {
              removeTickets();
            }}
          >
            {(delete_event_occurrence_ticket, { loading }) => (
              <EditDeleteButton
                edit={onHandleUpdate}
                remove={() => {
                  swal({
                    title: 'Are you sure?',
                    text:
                      'Once deleted, you will not be able to recover this ticket!',
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  }).then(willDelete => {
                    if (willDelete) {
                      if (location.pathname === '/add-event') {
                        removeTickets();
                      } else {
                        delete_event_occurrence_ticket();
                      }
                    }
                  });
                }}
                loading={loading}
              />
            )}
          </Mutation>
        </td>
      </tr>
      {/* <UpdateTicketModal
      ticket={ticket}
      index={index}
      isActive={isActive}
      onClose={() => setActive(false)}
      {...form}
    /> */}
    </React.Fragment>
  );
};

export default withRouter(TicketList);
