import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { omit, first, isEqual, isNull } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import tzLookup from 'tz-lookup';

import Layout from '../../../components/global/Layout';
import DealForm from '../EventDealAdd/DealForm';
import { Loading } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const allNeededQuery = gql`
  query fetch_place($placeId: String, $dealId: String!) {
    fetch_place_event_voucher(input: { place_id: $placeId, deal_id: $dealId }) {
      place_id
      deal_id
      name
      voucher_code
      description
      tagline
      start
      end
      applies_to {
        event_id
        event_occurrence_id
        ticket_id
        event_name
        ticket_name
      }
      discount_type
      value
      maximum_discount_amount
      minimum_purchase_amount
      valid_for_new_user
      maximum_usage
      maximum_use_per_user
      can_be_clubbed
      status
      approval_status
      is_published
      used_count
    }
    fetch_place(input: { place_id: $placeId }) {
      place_id
      name
      city
      post_code
      country
      latitude
      longitude
      error {
        description
      }
    }
  }
`;

const updateDealMutation = gql`
  mutation updateDeal($input: PlaceEventVoucherInput) {
    update_place_event_voucher(input: $input) {
      deal_id
      error {
        description
      }
    }
  }
`;

const EventDealUpdate = ({ history, match }) => (
  <Layout>
    <Query
      query={allNeededQuery}
      variables={{
        placeId: match.params.placeId,
        dealId: match.params.dealId,
      }}
      fetchPolicy="network-only"
    >
      {({ data, loading, error }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return 'error';
        }
        const { fetch_place, fetch_place_event_voucher: eventVoucher } = data;

        return (
          <View
            history={history}
            fetch_place={fetch_place}
            eventVoucher={eventVoucher}
          />
        );
      }}
    </Query>
  </Layout>
);

const View = ({ fetch_place, eventVoucher, history }) => {
  const { userId } = useStoreState(state => state.auth);
  const [currentTimeZone, setCurrentTimeZone] = useState('');

  useEffect(() => {
    const lat = parseFloat(fetch_place.latitude).toFixed(5);
    const long = parseFloat(fetch_place.longitude).toFixed(5);
    const timeZone = tzLookup(lat, long);
    setCurrentTimeZone(timeZone);
    moment.tz.setDefault(timeZone);
  }, []);

  return (
    <Mutation
      client={client.clientPrivate}
      mutation={updateDealMutation}
      onCompleted={({ update_place_event_voucher }) => {
        if (update_place_event_voucher.error) {
          update_place_event_voucher.error.map(item =>
            toast.error(item.description),
          );
        } else {
          swal(
            'Great!',
            'Event Deal had been updated successfully!',
            'success',
          ).then(() => {
            history.push('/event-deals');
          });
        }
      }}
    >
      {(
        update_place_event_voucher,
        { loading: updateDealLoading, error: updateDealError },
      ) => {
        const deal = first(eventVoucher);

        if (!deal) {
          return <Loading />;
        }
        return (
          <DealForm
            error={updateDealError}
            loading={updateDealLoading}
            place={fetch_place}
            deal={deal}
            onSubmit={value => {
              const input = omit(value, [
                'eventsSelection',
                'ticketsSelection',
                'applies_to_event',
                'applies_to_tickets',
                'startDate',
                'endDate',
                'startTime',
                'endTime',
              ]);
              const applies_to_input = value.applies_to_tickets;

              const applies_to = applies_to_input.map(item => ({
                event_id: item.event_id,
                event_occurrence_id: item.event_occurrence_id,
                ticket_id: item.ticket_id,
                ticket_price: item.ticket_price,
              }));

              const inputs = { ...input, applies_to };
              const customStartDate = moment(value.startDate).format(
                'YYYY-MM-DD',
              );
              const customStartTime = !isNull(value.startTime)
                ? moment(value.startTime).format('HH:mm:ss')
                : moment().format('HH:mm:ss');
              const customEndDate = moment(value.endDate).format('YYYY-MM-DD');
              const customEndTime = !isNull(value.endTime)
                ? moment(value.endTime).format('HH:mm:ss')
                : '23:59:00';

              const startDate = moment
                .tz(`${customStartDate} ${customStartTime}`, currentTimeZone)
                .toISOString();

              const endDate = moment
                .tz(`${customEndDate} ${customEndTime}`, currentTimeZone)
                .toISOString();

              const final = {};
              Object.assign(
                final,
                { user_id: userId },
                { place_id: deal.place_id },
                { deal_id: deal.deal_id },
                !isEqual(deal.name, inputs.name) && {
                  name: inputs.name,
                },
                !isEqual(deal.voucher_code, inputs.voucher_code) && {
                  voucher_code: inputs.voucher_code,
                },
                !isEqual(deal.status, inputs.status) && {
                  status: inputs.status,
                },
                !isEqual(deal.applies_to, inputs.applies_to) && {
                  applies_to: inputs.applies_to,
                },
                !isEqual(deal.tagline, inputs.tagline) && {
                  tagline: inputs.tagline,
                },
                !isEqual(deal.description, inputs.description) && {
                  description: inputs.description,
                },
                !moment(deal.start).isSame(startDate) && {
                  start: startDate,
                },
                !moment(deal.end).isSame(endDate) && {
                  end: endDate,
                },
                !isEqual(
                  deal.minimum_purchase_amount,
                  parseFloat(inputs.minimum_purchase_amount),
                ) && {
                  minimum_purchase_amount: parseFloat(
                    inputs.minimum_purchase_amount,
                  ),
                },
                !isEqual(
                  deal.maximum_discount_amount,
                  parseFloat(inputs.maximum_discount_amount),
                ) && {
                  maximum_discount_amount: parseFloat(
                    inputs.maximum_discount_amount,
                  ),
                },
                !isEqual(deal.value, parseFloat(inputs.value)) && {
                  value: parseFloat(inputs.value),
                },
                !isEqual(deal.can_be_clubbed, inputs.can_be_clubbed) && {
                  can_be_clubbed: inputs.can_be_clubbed,
                },
                !isEqual(deal.is_published, inputs.is_published) && {
                  is_published: inputs.is_published,
                },
                !isEqual(
                  deal.maximum_use_per_user,
                  parseInt(inputs.maximum_use_per_user, 10),
                ) && {
                  maximum_use_per_user: parseInt(
                    inputs.maximum_use_per_user,
                    10,
                  ),
                },
                !isEqual(
                  deal.maximum_usage,
                  parseInt(inputs.maximum_usage, 10),
                ) && {
                  maximum_usage: parseInt(inputs.maximum_usage, 10),
                },
                !isEqual(deal.discount_type, inputs.discount_type) && {
                  discount_type: inputs.discount_type,
                },

                !isEqual(
                  deal.valid_for_new_user,
                  inputs.valid_for_new_user,
                ) && { valid_for_new_user: inputs.valid_for_new_user },
              );
              update_place_event_voucher({
                variables: { input: final },
              });
            }}
          />
        );
      }}
    </Mutation>
  );
};

export default EventDealUpdate;
