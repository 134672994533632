import React, { useEffect } from 'react';
import { isUndefined } from 'lodash';
import { Link } from 'react-router-dom';

const DeliveryZoneList = ({
  review,
  setPageCount,
  totalPages,
  listSize,
  setListSize,
}) => {
  useEffect(
    () => {
      setPageCount();
    },
    [totalPages],
    [listSize],
  );
  // useEffect(() => {
  //   setListSize(
  //     !isUndefined(review.search_item_review) &&
  //       review.search_item_review.total_pages,
  //   );
  // }, [review.search_item_review]);
  return (
    <tr>
      <td>
        <Link to="/holiday-hour">Zone 1</Link>
      </td>
      <td>Las Tapas Diner</td>
      <td>St Kilda</td>
      <td>$2.00</td>
      <td>Super Admin</td>
      <td>24/06/2019</td>
      <td>Approved</td>
    </tr>
  );
};

export default DeliveryZoneList;
