import React from 'react';
import styled from 'styled-components';
import { Tab } from 'rbx';
import { withFormik } from 'formik';
import { isNull, first } from 'lodash';
import uuid from 'uuid';
import * as yup from 'yup';
import uuidv4 from 'uuid/v4';
import moment from 'moment';

import { Tabs, Box } from '../../../components/elements';

import Header from './Header';
import Footer from './Footer';
import PlaceDetails from './PlaceDetails';
import ClaimDetails from './ClaimDetails';
import Partner from './Partner';

const Container = styled.div`
  padding: 1rem;
  legend {
    background-color: #ffffff;
    padding: 0px 6px;
  }
`;

const Form = props => {
  const {
    values,
    handleSubmit,
    createForm,
    action,
    setAction,
    loading,
    claim,
  } = props;

  const handleClaimApproveAndSubmit = async () => {
    await setAction('submit-approve-claim');
    handleSubmit();
  };
  const handleClaimSubmit = async () => {
    await setAction('submit-claim');
    handleSubmit();
  };

  const handleClaimApprove = async () => {
    console.log('approve-claim');
    await setAction('approve-claim');
    handleSubmit();
  };
  const handleClaimReject = async () => {
    console.log('reject-claim');
    await setAction('reject-claim');
    handleSubmit();
  };

  const handleClaimUpdate = async () => {
    await setAction('update-claim');
    handleSubmit();
  };

  const hideRejectButton = claim && claim.status === 'REJECTED';
  const hideApproveButton = claim && claim.status === 'APPROVED';

  return (
    <React.Fragment>
      <Header
        heading="Add a claim"
        placeName={`${values.name} | ${values.city}, ${values.post_code} | ${values.country} (${values.claim_status})`}
        action={action}
        loading={loading}
        createForm={createForm}
        handleClaimApproveAndSubmit={handleClaimApproveAndSubmit}
        handleClaimSubmit={handleClaimSubmit}
        handleClaimApprove={handleClaimApprove}
        handleClaimReject={handleClaimReject}
        handleClaimUpdate={handleClaimUpdate}
        hideRejectButton={hideRejectButton}
        hideApproveButton={hideApproveButton}
      />
      <Box>
        <Tabs>
          <Tab active>Claim Place</Tab>
        </Tabs>
        <Container>
          <PlaceDetails {...props} />
          <hr />
          <ClaimDetails {...props} createForm={createForm} />
          <br />
          <Partner {...props} />
        </Container>
      </Box>
      <hr />
      <Footer
        action={action}
        loading={loading}
        createForm={createForm}
        handleClaimApproveAndSubmit={handleClaimApproveAndSubmit}
        handleClaimSubmit={handleClaimSubmit}
        handleClaimApprove={handleClaimApprove}
        handleClaimReject={handleClaimReject}
        handleClaimUpdate={handleClaimUpdate}
        hideRejectButton={hideRejectButton}
        hideApproveButton={hideApproveButton}
      />
    </React.Fragment>
  );
};

const ClaimPlace = withFormik({
  mapPropsToValues: ({ place, claim }) => {
    const primaryEmail =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'email_primary',
          )
        : [];

    const primaryMobile =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'phone_primary',
          )
        : [];

    const businessSite =
      place && !isNull(place.contact)
        ? place.contact.filter(
            item => item.is_primary === true && item.type === 'website',
          )
        : [];
    const facebookPage =
      place && !isNull(place.social)
        ? place.contact.filter(item => item.type === 'facebook')
        : [];

    return {
      action: '', // Mutation depend on action
      user_id: place ? place.user_id : uuid(),
      primaryEmail: primaryEmail.length !== 0 ? first(primaryEmail).value : '',
      primaryMobile:
        primaryMobile.length !== 0 ? first(primaryMobile).value : '',
      businessSite: businessSite.length !== 0 ? first(businessSite).value : '',
      facebookPage: facebookPage.length !== 0 ? first(facebookPage).value : '',
      place_id: place.place_id,
      name: place.name,
      listing_status: place.listing_status,
      claim_status: place.claim_status,
      status: claim ? claim.status : 'SUBMITTED',
      approval_status: place.approval_status,
      description: place.description,
      address_line_1: place.address_line_1,
      city: place.city,
      state: place.state,
      country: place.country,
      post_code: place.post_code,
      timezone: place.timezone,
      latitude: place.latitude,
      longitude: place.longitude,
      claimant_id:
        claim && !isNull(claim.claimant_id) ? claim.claimant_id : uuidv4(),
      object_id:
        claim && !isNull(claim.object_id) ? claim.object_id : place.place_id,
      object_type:
        claim && !isNull(claim.object_type) ? claim.object_type : 'place',
      contact_user_id: '4b6d9b3a-8d4a-4a88-be57-fcd225daab52',
      contact_full_name:
        claim && !isNull(claim.contact_full_name)
          ? claim.contact_full_name
          : '',
      contact_phone:
        claim && !isNull(claim.contact_phone) ? claim.contact_phone : '',
      contact_email:
        claim && !isNull(claim.contact_email) ? claim.contact_email : '',
      contact_role:
        claim && !isNull(claim.contact_role) ? claim.contact_role : '',
      contact_business_url:
        claim && !isNull(claim.contact_business_url)
          ? claim.contact_business_url
          : '',
      contact_linked_in_url:
        claim && !isNull(claim.contact_linked_in_url)
          ? claim.contact_linked_in_url
          : '',
      contact_facebook_url:
        claim && !isNull(claim.contact_facebook_url)
          ? claim.contact_facebook_url
          : '',
      service_type:
        claim && !isNull(claim.service_type) ? claim.service_type : [],
      agree_terms_and_conditions:
        claim && !isNull(claim.agree_terms_and_conditions)
          ? claim.agree_terms_and_conditions
          : false,
      verification_mode:
        claim && !isNull(claim.verification_mode)
          ? claim.verification_mode
          : ['SMS'],
      verification_note:
        claim && !isNull(claim.verification_note)
          ? claim.verification_note
          : '',
      listing_audit:
        place && !isNull(place.listing_audit)
          ? {
              listing_approved_by: place.listing_audit
                ? place.listing_audit.listing_approved_by
                : '',
              listing_approved_at: place.listing_audit
                ? place.listing_audit.listing_approved_at
                : '',
              listing_contact_user_id: place.listing_audit
                ? place.listing_audit.listing_contact_user_id
                : '',
              listing_contact_name: place.listing_audit
                ? place.listing_audit.listing_contact_name
                : '',
              listing_contact_phone: place.listing_audit
                ? place.listing_audit.listing_contact_phone
                : '',
              listing_contact_email: place.listing_audit
                ? place.listing_audit.listing_contact_email
                : '',
              listing_contact_role: place.listing_audit
                ? place.listing_audit.listing_contact_role
                : '',
            }
          : {
              listing_approved_by:
                place && !isNull(place.listing_audit.listing_approved_by)
                  ? place.listing_audit.listing_approved_by
                  : '',
              listing_approved_at:
                place.listing_audit &&
                !isNull(place.listing_audit.listing_approved_at)
                  ? moment(place.listing_audit.listing_approved_at).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )
                  : '',
              listing_contact_user_id:
                place && !isNull(place.listing_audit.listing_contact_user_id)
                  ? place.listing_audit.listing_contact_user_id
                  : '',
              listing_contact_name:
                place && !isNull(place.listing_audit.listing_contact_name)
                  ? place.listing_audit.listing_contact_name
                  : '',
              listing_contact_phone:
                place && !isNull(place.listing_audit.listing_contact_phone)
                  ? place.listing_audit.listing_contact_phone
                  : '',
              listing_contact_email:
                place && !isNull(place.listing_audit.listing_contact_email)
                  ? place.listing_audit.listing_contact_email
                  : '',
              listing_contact_role:
                place && !isNull(place.listing_audit.listing_contact_role)
                  ? place.listing_audit.listing_contact_role
                  : '',
            },
      place_claimant_audit:
        claim && !isNull(claim.place_claimant_audit)
          ? {
              claim_approved_at:
                claim.place_claimant_audit &&
                !isNull(claim.place_claimant_audit.claim_approved_at)
                  ? moment(claim.place_claimant_audit.claim_approved_at).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )
                  : '',
              claim_approved_by:
                claim.place_claimant_audit &&
                !isNull(claim.place_claimant_audit.claim_approved_by)
                  ? claim.place_claimant_audit.claim_approved_by
                  : '',
            }
          : { claim_approved_at: '', claim_approved_by: '' },
    };
  },
  validate: values => {
    const errors = {};
    if (!values.contact_phone && !values.contact_email) {
      errors.contact_phone = 'Contact phone is required';
      errors.contact_email = 'Contact email is required';
    }
    if (!values.agree_terms_and_conditions) {
      errors.agree_terms_and_conditions = 'Agree to terms and conditions';
    }
    if (!values.contact_role && !values.contact_role) {
      errors.contact_role = 'Claimed User Role is required';
    }

    return errors;
  },

  validationSchema: yup.object().shape({
    contact_full_name: yup.string().required('Contact full name is required'),
    verification_note: yup.string().required('Verification Note is required'),
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    setTimeout(() => {
      props.onSubmit(values);
      setSubmitting(false);
    }, 300);
  },

  displayName: 'ClaimPlaceForm',
})(Form);

export default ClaimPlace;
