import React from 'react';
import styled from 'styled-components';
import { Field, Label, Control } from 'rbx';
import ReactSelectInput from './ReactSelectInput';

const OptionalText = styled.p`
  &&& {
    font-size: 0.8rem;
    padding-top: 2px;
    padding-left: 4px;
    color: #737373;
  }
`;

const MySelect = ({
  label,
  value,
  options,
  optionalText,
  required,
  onChange,
  ...props
}) => (
  <Field>
    <Label className="is-flex">
      {label}
      {required && <span className="has-text-danger">*</span>}
      {optionalText && <OptionalText>{optionalText}</OptionalText>}
    </Label>
    <Control>
      <ReactSelectInput
        defaultValue={value}
        value={value}
        options={options}
        onChange={onChange}
        isDisabled={props.disabled}
        {...props}
      />
    </Control>
  </Field>
);

export default MySelect;
