import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { Input, Box } from '../../../components/elements';
import Header from './Header';
import EventSocial from './EventSocial';

const Container = styled.div`
  padding: 1rem;
`;
const Wrapper = styled.div`
  fieldset {
    margin-right: 6rem;
    legend {
      background-color: #ffffff;
      padding: 0px 6px;
    }
  }
`;

const Dashboard = ({ event, eventOccurrence, bookingSummary }) => (
  <Container>
    <Box>
      <Header
        event={event}
        subHeading3={moment(event.start.start).format('LLLL')}
      />

      <hr />
      <Wrapper>
        <fieldset className="box">
          <legend className="label">Booking</legend>
          <Column.Group vcentered multiline gapSize={8}>
            <Column size={4}>
              <Input
                label="Total Bookings / Tickets Sold"
                value={`${bookingSummary.total_bookings} / ${bookingSummary.tickets_sold}`}
                disabled
              />
            </Column>

            <Column size={4}>
              <Input
                label="Online Bookings / Tickets Sold"
                value={`${bookingSummary.online_bookings} / ${bookingSummary.online_tickets_sold}`}
                disabled
              />
            </Column>

            <Column size={4}>
              <Input
                label="Agent Bookings / Tickets Sold"
                value={`${bookingSummary.agent_bookings} / ${bookingSummary.agent_tickets_sold}`}
                disabled
              />
            </Column>
          </Column.Group>
        </fieldset>
        <br />
        <fieldset className="box">
          <legend className="label">Tickets</legend>
          <Column.Group vcentered multiline>
            {isUndefined(eventOccurrence) ? (
              <Column size={3}>
                <Input label="Total Tickets" value="0" disabled />
              </Column>
            ) : (
              <Column size={3}>
                <Input
                  label="Total Tickets"
                  value={eventOccurrence.total_tickets}
                  disabled
                />
              </Column>
            )}

            <Column size={3}>
              <Input
                label="Total Tickets Sold"
                value={`${bookingSummary.tickets_sold}`}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Online Tickets Sold"
                value={`${bookingSummary.online_tickets_sold}`}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Agent Tickets Sold"
                value={`${bookingSummary.agent_tickets_sold}`}
                disabled
              />
            </Column>

            {isUndefined(eventOccurrence) ? (
              <Column size={3}>
                <Input label="Tickets Available" value="0" disabled />
              </Column>
            ) : (
              <Column size={3}>
                <Input
                  label="Tickets Available"
                  value={
                    eventOccurrence.total_tickets - bookingSummary.tickets_sold
                  }
                  disabled
                />
              </Column>
            )}
          </Column.Group>
        </fieldset>

        <fieldset className="box">
          <legend className="label">Fee</legend>
          <Column.Group vcentered multiline>
            <Column size={3}>
              <Input
                label="Total Fee ($)"
                value={parseFloat(bookingSummary.total_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Total Booking Fee ($)"
                value={parseFloat(bookingSummary.total_booking_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Total Absorb Fee ($)"
                value={parseFloat(bookingSummary.total_absorb_fee).toFixed(2)}
                placeholder="$0.00"
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Total Pass On Fee ($)"
                value={parseFloat(bookingSummary.total_passon_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Online Absorb Fee ($)"
                value={parseFloat(bookingSummary.online_absorb_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Online Pass On Fee ($)"
                value={parseFloat(bookingSummary.online_passon_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Agent Absorb Fee ($)"
                value={parseFloat(bookingSummary.agent_absorb_fee).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Agent Pass On Fee ($)"
                value={parseFloat(bookingSummary.agent_passon_fee).toFixed(2)}
                disabled
              />
            </Column>
          </Column.Group>
        </fieldset>

        <fieldset className="box">
          <legend className="label">Sales</legend>
          <Column.Group vcentered multiline>
            <Column size={3}>
              <Input
                label="Total Sales ($)"
                value={parseFloat(bookingSummary.total_sales).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Online Sales ($)"
                value={parseFloat(bookingSummary.online_sales).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Agent Sales ($)"
                value={parseFloat(bookingSummary.agent_sales).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Payout Value"
                value={parseFloat(
                  bookingSummary.total_sales -
                    bookingSummary.total_fee -
                    bookingSummary.total_discount,
                ).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input
                label="Discount Value ($)"
                value={parseFloat(bookingSummary.total_discount).toFixed(2)}
                disabled
              />
            </Column>

            <Column size={3}>
              <Input label="Refunds" value={bookingSummary.refunds} disabled />
            </Column>

            <Column size={3}>
              <Input
                label="Credit Card Transaction Fee"
                placeholder="$0.00"
                disabled
              />
            </Column>
          </Column.Group>
        </fieldset>
        <EventSocial />
      </Wrapper>

      {/* payout value = "total sales" - "total fees" - "total discounts" */}

      <hr />
    </Box>
  </Container>
);

export default Dashboard;
