import React from 'react';
import { Column } from 'rbx';
import { isNull } from 'lodash';

import ImageItem from '../../../components/ImageItem';
import { getEncodedImage } from '../../../utils/s3';

const PhotoReviews = ({ values, setFieldValue }) => (
  <React.Fragment>
    <Column.Group vcentered multiline gapSize={8}>
      <React.Fragment>
        {values.allImages.map(image => {
          const checkImage = values.rejectImages.filter(
            item => item.image_id === image.image_id,
          );

          return (
            <Column size={3} key={image.image_id}>
              <ImageItem
                isRejected={checkImage.length !== 0}
                imageReviewId={image.image_review_id}
                imageSrc={getEncodedImage(image.url, 300, 300)}
                status={image.status}
                tags={
                  !isNull(image.tags) && image.tags !== 0
                    ? image.tags.map(item => ({
                        label: item,
                        value: item,
                      }))
                    : []
                }
                handleRejection={() => {
                  setFieldValue(
                    'rejectImages',
                    values.rejectImages.concat(image),
                  );
                }}
              />
            </Column>
          );
        })}
      </React.Fragment>
    </Column.Group>
  </React.Fragment>
);

export default PhotoReviews;
