import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { sortBy, last } from 'lodash';
import client from '../../../utils/apolloClient';

import Layout from '../../../components/global/Layout';
import { Loading, Table, Box, Pagination } from '../../../components/elements';
import Header from './Header';
import ListItem from './ListItem';
import Footer from './Footer';

const eventQuery = gql`
  query event($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      place_name
      start
      end
      place_name
      name
      fee {
        name
        value
        sales_method
      }
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
  }
`;

const ticketsQuery = gql`
  query tickets($input: SearchInput) {
    search_event_ticket_records(input: $input) {
      total_pages
      total_size
      ticket_record_listing {
        ticket_record_id
        booking_id
        ticket_name
        ticket_id
        booking_number
        ticket_status
        sales_method
        ticket_type
        check_in {
          type
          timestamp
        }
        attendee {
          attendee_name
          attendee_email
        }
      }
    }
  }
`;

const View = ({
  ticketData,
  setTicketData,
  search_event_ticket_records,
  setPageCount,
  setTotalElements,
  noOfCheckingAttendees,
  setTotalAttendeesCount,
  setNoOfCheckingAttendees,
}) => {
  useEffect(() => {
    setPageCount(search_event_ticket_records.total_pages);
    setTotalElements(search_event_ticket_records.total_size);
    setTotalAttendeesCount(search_event_ticket_records.total_size);
    setNoOfCheckingAttendees(0);
    if (search_event_ticket_records.ticket_record_listing) {
      setTicketData(search_event_ticket_records.ticket_record_listing);
      const checkingAttendees = search_event_ticket_records.ticket_record_listing.map(
        item => {
          if (item.check_in === null) {
            return '';
          }
          const checkInLatest = last(sortBy(item.check_in), 'timestamp');
          if (checkInLatest.type === 'CHECK_IN') {
            return checkInLatest;
          }
          return '';
        },
      );
      const final = checkingAttendees.filter(item => item !== '');
      setNoOfCheckingAttendees(final.length);
    }
  }, [search_event_ticket_records.total_pages]);

  return (
    <tbody>
      {ticketData.length === 0 && 'No Ticket Record Found'}
      {ticketData.map(ticket => (
        <ListItem
          ticket={ticket}
          noOfCheckingAttendees={noOfCheckingAttendees}
          setNoOfCheckingAttendees={setNoOfCheckingAttendees}
        />
      ))}
    </tbody>
  );
};

const CheckingAttendees = ({ match }) => {
  const [sort, setSort] = useState('CREATED_DESC');
  const [attendeeSort, setAttendeeSort] = useState('AES');
  const [attendeeEmailSort, setAttendeeEmailSort] = useState('AES');
  const [ticketNameSort, setTicketNameSort] = useState('AES');
  const [bookingNumberSort, setBookingNumberSort] = useState('AES');
  const [ticketNumberSort, setTicketNumberSort] = useState('AES');
  const { eventId } = match.params;
  const [ticketData, setTicketData] = useState([]);
  const [totalAttendeesCount, setTotalAttendeesCount] = useState(0);
  const [noOfCheckingAttendees, setNoOfCheckingAttendees] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageFrom, setPageFrom] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [listSize] = useState(15);

  const input = {};
  Object.assign(
    input,
    { sort },
    { from: pageFrom, size: listSize },
    {
      filter: {
        event_ticket_record_filter: { event_id: eventId },
      },
    },
  );

  return (
    <Layout>
      <Query
        query={eventQuery}
        variables={{ eventId }}
        fetchPolicy="network-only"
      >
        {({ data, loading, error }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { fetch_event } = data;
          return (
            <>
              <Header
                event={fetch_event}
                totalAttendeesCount={totalAttendeesCount}
                noOfCheckingAttendees={noOfCheckingAttendees}
              />
              {totalElements > 0 && (
                <Pagination
                  pageFrom={pageFrom}
                  pageCount={pageCount}
                  listSize={listSize}
                  totalElements={totalElements}
                  currentPage={currentPage}
                  handlePageClick={value => {
                    setPageFrom(value.selected * listSize);
                    setCurrentPage(value.selected + 1);
                  }}
                />
              )}
              <Query
                client={client.clientPrivate}
                query={ticketsQuery}
                variables={{
                  input,
                }}
                fetchPolicy="network-only"
              >
                {({ data: data2, loading: loading2, error: error2 }) => {
                  if (loading2) {
                    return <Loading />;
                  }
                  if (error2) {
                    return 'error';
                  }

                  const { search_event_ticket_records } = data2;

                  return (
                    <Box>
                      <Table>
                        <thead>
                          <tr>
                            <th>
                              <div>
                                <span>
                                  <a
                                    onClick={() => {
                                      setAttendeeSort(
                                        attendeeSort === 'AES' ? 'DES' : 'AES',
                                      );
                                      setSort(
                                        attendeeSort === 'AES'
                                          ? 'ATTENDEE_NAME_DESC'
                                          : 'ATTENDEE_NAME_ASC',
                                      );
                                    }}
                                  >
                                    <i
                                      className={
                                        attendeeSort === 'AES'
                                          ? 'fas fa-sort-amount-down'
                                          : 'fas fa-sort-amount-up'
                                      }
                                    />
                                  </a>{' '}
                                  Attendee
                                </span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>
                                  <a
                                    onClick={() => {
                                      setAttendeeEmailSort(
                                        attendeeEmailSort === 'AES'
                                          ? 'DES'
                                          : 'AES',
                                      );
                                      setSort(
                                        attendeeEmailSort === 'AES'
                                          ? 'ATTENDEE_NAME_DESC'
                                          : 'ATTENDEE_NAME_ASC',
                                      );
                                    }}
                                  >
                                    <i
                                      className={
                                        attendeeEmailSort === 'AES'
                                          ? 'fas fa-sort-amount-down'
                                          : 'fas fa-sort-amount-up'
                                      }
                                    />
                                  </a>{' '}
                                  Email
                                </span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>
                                  <a
                                    onClick={() => {
                                      setTicketNameSort(
                                        ticketNameSort === 'AES'
                                          ? 'DES'
                                          : 'AES',
                                      );
                                      setSort(
                                        ticketNameSort === 'AES'
                                          ? 'TICKET_NAME_DESC'
                                          : 'TICKET_NAME_ASC',
                                      );
                                    }}
                                  >
                                    <i
                                      className={
                                        ticketNameSort === 'AES'
                                          ? 'fas fa-sort-amount-down'
                                          : 'fas fa-sort-amount-up'
                                      }
                                    />
                                  </a>{' '}
                                  Ticket Category
                                </span>
                                <br />
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>
                                  <a
                                    onClick={() => {
                                      setBookingNumberSort(
                                        bookingNumberSort === 'AES'
                                          ? 'DES'
                                          : 'AES',
                                      );
                                      setSort(
                                        bookingNumberSort === 'AES'
                                          ? 'TICKET_NAME_DESC'
                                          : 'TICKET_NAME_ASC',
                                      );
                                    }}
                                  >
                                    <i
                                      className={
                                        bookingNumberSort === 'AES'
                                          ? 'fas fa-sort-amount-down'
                                          : 'fas fa-sort-amount-up'
                                      }
                                    />
                                  </a>{' '}
                                  Booking Number
                                </span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>
                                  <a
                                    onClick={() => {
                                      setTicketNumberSort(
                                        ticketNumberSort === 'AES'
                                          ? 'DES'
                                          : 'AES',
                                      );
                                      setSort(
                                        ticketNumberSort === 'AES'
                                          ? 'TICKET_NAME_DESC'
                                          : 'TICKET_NAME_ASC',
                                      );
                                    }}
                                  >
                                    <i
                                      className={
                                        ticketNumberSort === 'AES'
                                          ? 'fas fa-sort-amount-down'
                                          : 'fas fa-sort-amount-up'
                                      }
                                    />
                                  </a>{' '}
                                  Ticket Number
                                </span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>Status</span>
                              </div>
                            </th>
                            <th>
                              <div>
                                <span>Action</span>
                              </div>
                            </th>
                            <th>
                              <span>Attn Dtls/Chk Acti.</span>
                            </th>
                          </tr>
                        </thead>
                        <View
                          search_event_ticket_records={
                            search_event_ticket_records
                          }
                          ticketData={ticketData}
                          setPageCount={setPageCount}
                          setTotalElements={setTotalElements}
                          setTicketData={setTicketData}
                          noOfCheckingAttendees={noOfCheckingAttendees}
                          setTotalAttendeesCount={setTotalAttendeesCount}
                          setNoOfCheckingAttendees={setNoOfCheckingAttendees}
                        />
                      </Table>
                      <Footer />
                    </Box>
                  );
                }}
              </Query>
              <br />
            </>
          );
        }}
      </Query>
    </Layout>
  );
};

export default CheckingAttendees;
