import React from 'react';
import moment from 'moment';

const PlaceFollowedList = ({ social }) => {
  console.log('jfdkf', social);
  return (
    <tr>
      <td>{social.place.name}</td>
      <td>{social.place.city}</td>
      <td>{moment(social.audit.created_at).format('lll')}</td>
    </tr>
  );
};
export default PlaceFollowedList;
