import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';

import {
  CreatableSelectInputs,
  Select,
  SubHeading,
  Radio,
} from '../../../components/elements';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  padding: 0.5rem 1rem;
`;

const Image = () => (
  <Container>
    <Select label="Status" />
    <figure className="image is-1by1">
      <img src="https://bulma.io/images/placeholders/128x128.png" alt="image" />
    </figure>
    <CreatableSelectInputs
      label=""
      components={{ DropdownIndicator: null }}
      suggestions={[]}
      isClearable={false}
    />
  </Container>
);

const Images = () => (
  <React.Fragment>
    <Column.Group>
      <Column size={4}>
        <SubHeading className="is-size-2 has-text-weight-semibold">
          Spicy Dragon
        </SubHeading>
        <SubHeading className="is-size-2">St Kilda, 3001</SubHeading>
        <SubHeading className="is-size-2">
          Australia (Active / Claimed)
        </SubHeading>
      </Column>
      <Column size={8}>
        <div className="is-flex">
          <Wrapper>
            <div className="label">Status</div>
          </Wrapper>
          <Wrapper>
            <Radio label="Add a Review" />
          </Wrapper>
          <Wrapper>
            <Radio label="Upload Place Photo" />
          </Wrapper>
        </div>
        <div className="is-flex">
          <Wrapper>
            <div className="label">Updated At</div>
          </Wrapper>
          <Wrapper>
            <div>05/06/2018 12:29:21 AM</div>
          </Wrapper>
        </div>
      </Column>
    </Column.Group>
    <Column.Group vcentered multiline gapSize={4}>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
      <Column size={2}>
        <Image />
      </Column>
    </Column.Group>
  </React.Fragment>
);

export default Images;
