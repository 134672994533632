import React from 'react';
import styled from 'styled-components';
import { Field, Label, Control } from 'rbx';
import InputErrorMessage from './InputErrorMessage';

const OptionalText = styled.p`
  &&& {
    padding-left: 4px;
    color: #737373;
  }
`;

const TextArea = ({
  label,
  required,
  optionalText,
  errors,
  touched,
  ...props
}) => (
  <Field>
    <Label className="is-flex">
      {label}
      {required && <span className="has-text-danger">*</span>}
      {optionalText && <OptionalText>{optionalText}</OptionalText>}
    </Label>
    <Control>
      <textarea className="textarea" {...props} />
    </Control>
    <InputErrorMessage errors={errors} touched={touched} />
  </Field>
);

export default TextArea;
