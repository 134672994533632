import React from 'react';
import styled from 'styled-components';
import { Level, Title } from 'rbx';
import { withRouter } from 'react-router-dom';
import qs from 'querystringify';
import {
  Input,
  Radio,
  Select,
  ReactDateTimePicker,
} from '../../../components/elements';
// import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

const TitleWrapper = styled.nav`
  padding-right: 1rem;
`;

const object_types = [
  { value: '', label: 'Clear' },
  { value: 'PLACE_FEED', label: 'PLACE_FEED' },
  { value: 'PLACE_REVIEW', label: 'PLACE_REVIEW' },
  { value: 'PLACE_REVIEW_COMMENT', label: 'PLACE_REVIEW_COMMENT' },
  { value: 'ITEM_REVIEW', label: 'ITEM_REVIEW' },
  { value: 'ITEM_REVIEW_COMMENT', label: 'ITEM_REVIEW_COMMENT' },
  { value: 'IMAGE', label: 'IMAGE' },
];

const Search = ({
  status,
  placeNameSearchInput,
  setPlaceNameSearchInput,
  flagType,
  setFlagType,
  reviewType,
  reviewOn,
  setReviewType,
  setReviewOn,
  history,
}) => (
  <Wrapper>
    <Level>
      <Level.Item align="left">
        <Level.Item>
          <TitleWrapper>
            <Title className="label">Status</Title>
          </TitleWrapper>
        </Level.Item>
        <Level.Item>
          <Radio
            label="Submitted"
            name="Type"
            value={status === 'SUBMITTED'}
            onChange={() => {
              history.push(
                `/flagged-reviews${qs.stringify(
                  { status: 'SUBMITTED' },
                  true,
                )}`,
              );
            }}
          />
        </Level.Item>
        <Level.Item>
          <Radio
            label="Approved"
            name="Type"
            value={status === 'APPROVED'}
            onChange={() => {
              history.push(
                `/flagged-reviews${qs.stringify({ status: 'APPROVED' }, true)}`,
              );
            }}
          />
        </Level.Item>
        <Level.Item>
          <Radio
            label="Rejected"
            name="Type"
            value={status === 'REJECTED'}
            onChange={() => {
              history.push(
                `/flagged-reviews${qs.stringify({ status: 'REJECTED' }, true)}`,
              );
            }}
          />
        </Level.Item>
      </Level.Item>
    </Level>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-3">
          <Input
            label="Place Name"
            type="text"
            value={placeNameSearchInput}
            onChange={flag => setPlaceNameSearchInput(flag.target.value)}
            placeholder="Start entering place name..."
          />
        </div>
        <div className="column is-3">
          <Select
            label="Review Type"
            options={object_types}
            value={[{ value: object_types.value, label: object_types.value }]}
            onChange={({ value }) => setReviewType(value)}
          />
        </div>

        <div className="column is-3">
          <Input
            label="Flag Type"
            type="text"
            value={flagType}
            onChange={flag => setFlagType(flag.target.value)}
            placeholder="Start entering flag type..."
          />
        </div>

        <div className="column is-3">
          <ReactDateTimePicker
            label="Flag Date"
            value={reviewOn}
            onChange={value => setReviewOn(value)}
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);

export default withRouter(Search);
