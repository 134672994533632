import React from 'react';

const AttendeeList = ({ ticket, goToStepTwo }) => (
  <React.Fragment>
    <td>{ticket.attendee.attendee_name || '-'}</td>
    <td>{ticket.attendee.attendee_email || '-'}</td>
    <td>{ticket.attendee.attendee_phone || '-'}</td>
    <td>{ticket.attendee.attendee_age || '-'}</td>
    <td>{ticket.ticket_name}</td>
    <td>{`${ticket.booking_number}-${ticket.ticket_number}`}</td>
    <td>{ticket.ticket_status}</td>
    <td>
      <a onClick={goToStepTwo}>
        <span className="icon">
          <i className="fas fa-pencil-alt" />
        </span>
      </a>
    </td>
  </React.Fragment>
);
export default AttendeeList;
