import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
// import swal from 'sweetalert';
import { isNull, first } from 'lodash';
import AdminUserActivity from './AdminUserActivity';

import Layout from '../../../components/global/Layout';
import { Loading } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const PlaceQuery = gql`
  query searchPlace($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        name
        status
      }
    }
  }
`;
const placeActivityQuery = gql`
  query placeActivity($input: SearchInput) {
    search_place_activity(input: $input) {
      deals {
        deals_total
        deals_active
        deals_past
        deals_future
      }
      feeds {
        feeds_total
        feeds_flagged
      }
      followers
    }
  }
`;

const Event = ({ match }) => {
  const { placeId } = match.params;
  return (
    <Layout>
      <Query
        query={PlaceQuery}
        variables={{
          input: {
            place_id: placeId,
          },
        }}
        fetchPolicy="network-only"
      >
        {({ data: placeData, loading: placeLoading, error }) => {
          if (placeLoading) {
            return <Loading />;
          }
          if (error) {
            return 'error';
          }
          const { search_places } = placeData;
          console.log('search_places', search_places);

          return (
            <Query
              client={client.clientPrivate}
              query={placeActivityQuery}
              variables={{
                input: {
                  filter: {
                    social_filter: {
                      object_id: search_places.place_listing.place_id,
                      object_type: 'PLACE',
                    },
                  },
                },
              }}
              fetchPolicy="network-only"
            >
              {({ data: activityData, loading }) => {
                if (loading) {
                  return <Loading />;
                }

                const { search_place_activity } = activityData;
                console.log('search_place_activity', search_place_activity);

                return <AdminUserActivity activity={search_place_activity} />;
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default Event;
