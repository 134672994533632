import React, { useState, useEffect } from 'react';
import { Column, Label } from 'rbx';
import gql from 'graphql-tag';
import moment from 'moment';
import styled from 'styled-components';

import { Input } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const userQuery = gql`
  query user($userId: String) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      first_name
      last_name
      display_name
      preferred_username
      email
      status
      rating
      audit {
        created_at
        created_by
        updated_at
        updated_by
      }
    }
  }
`;

const CommentUserDetails = ({ createdBy, createdTime }) => {
  const [user, setUser] = useState();

  useEffect(() => {
    client.clientPrivate
      .query({
        query: userQuery,
        variables: { userId: createdBy },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (data) {
          setUser(data.fetch_user);
        }
      });
  }, []);

  return (
    <Column>
      <Column.Group multiline vcentered gapSize={6}>
        <Column size={5}>
          <Input label="Commenter Email" value={user && user.email} disabled />
        </Column>
        <Column size={5}>
          <Input
            label="Commented By"
            value={`${user && user.first_name} ${user && user.last_name}`}
            disabled
          />
        </Column>
        <Column size={5}>
          <Input
            label="Commenter Display Name"
            value={user && user.display_name}
            disabled
          />
        </Column>
        <Column size={5}>
          <Input
            label="Commented On"
            value={moment(createdTime).format('lll')}
            disabled
          />
        </Column>
      </Column.Group>
    </Column>
  );
};

export default CommentUserDetails;
