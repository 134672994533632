import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { first } from 'lodash';

const PlaceList = ({ place, users }) => {
  const userArray = users.filter(
    item => item.user_id === place.audit.updated_by,
  );

  const user = userArray.length !== 0 ? first(userArray) : undefined;

  return (
    <>
      <tr key={place.place_id}>
        <td>
          <Link to={`/place-approval-update/${place.place_id}`}>
            {place.name}
          </Link>
        </td>
        <td>{place.city}</td>
        <td>{`${place.status}`}</td>
        {/* <td> {`${place.listing_status}`}</td> */}
        <td>{place.claim_status}</td>
        <td>
          {user ? (
            <React.Fragment>
              <p data-tip={user.full_name}>{user.full_name}</p>
              <ReactTooltip place="top" type="dark" effect="float">
                <span>
                  <p>email: {user.email} </p>
                  <p>objectId: {user.user_id}</p>
                </span>
              </ReactTooltip>
            </React.Fragment>
          ) : (
            place.audit.updated_by
          )}
        </td>
        <td>{moment(place.audit.updated_at).format('lll')}</td>
        <td>
          <Link>
            <i className="fas fa-trash has-text-grey" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default PlaceList;
