import React, { useState } from 'react';
import styled from 'styled-components';
import { Column, Title } from 'rbx';
import { Query } from 'react-apollo';
import { isNull, first } from 'lodash';
import gql from 'graphql-tag';
import Header from './Header';
// import ListItem from './ListItem';
import Layout from '../../../components/global/Layout';
import BookingByTicketTable from './BookingByTicketTable';
import BookingByAttendeeTable from './BookingByAttendeeTable';
import BookingInfo from './BookingInfo';
import { Loading, Message } from '../../../components/elements';
import client from '../../../utils/apolloClient';

const Container = styled.div`
  padding: 1rem;
`;

const eventBookingQuery = gql`
  query event($eventId: String, $bookingId: String) {
    search_event_bookings(
      input: {
        filter: {
          event_booking_filter: { booking_id: $bookingId, event_id: $eventId }
        }
      }
    ) {
      total_pages
      total_size
      event_booking_listing {
        place_id
        event_id
        booking_id
        booking_number
        total_attendee_count
        total_price
        discount
        timestamp
        total_fee
        url
        sales_method
        fee {
          name
          value
          service_type
        }
        payment {
          type
          amount
        }
        booking {
          purchased_by
          purchased_at
          booked_by
          booked_at
        }
        purchaser {
          first_name
          last_name
          email
        }
      }
    }
    search_event_ticket_records(
      input: {
        filter: {
          event_ticket_record_filter: {
            booking_id: $bookingId
            event_id: $eventId
          }
        }
      }
    ) {
      total_pages
      total_size
      ticket_record_listing {
        event_id
        ticket_name
        ticket_id
        ticket_number
        ticket_status
        ticket_type
        price
        fee {
          name
          value
        }
        attendee {
          attendee_name
          attendee_email
          attendee_age
          attendee_gender
          attendee_phone
        }
      }
    }
  }
`;

const eventQuery = gql`
  query event($eventId: String) {
    fetch_event(input: { event_id: $eventId }) {
      place_id
      event_id
      status
      place_name
      listing_type
      start
      place_name
      name
      address_line_1
      address_line_2
      city
      state
      country
      post_code
    }
  }
`;

const BookingDetails = ({ match }) => {
  const [selectedAttendee, setSelectedAttendee] = useState();
  console.log('selectedAttendee', selectedAttendee);
  const { params } = match;

  const [sort, setSort] = useState('TICKET_NAME_DESC');

  const [pageFrom, setPageFrom] = useState(0);
  const [listSize] = useState(10);
  return (
    <Layout>
      <Query
        query={eventQuery}
        variables={{
          eventId: params.eventId,
        }}
      >
        {({ data: eventData, loading: eventLoading, error: eventError }) => {
          if (eventLoading) {
            return <Loading />;
          }
          if (eventError) {
            return <Message>{eventError.message}</Message>;
          }
          const { fetch_event } = eventData;
          return (
            <Query
              client={client.clientPrivate}
              query={eventBookingQuery}
              variables={{
                eventId: params.eventId,
                bookingId: params.bookingId,
                from: pageFrom,
                size: listSize,
                sort,
              }}
              fetchPolicy="network-only"
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return <Message>{error.message}</Message>;
                }
                const {
                  search_event_bookings,
                  search_event_ticket_records,
                } = data;

                return (
                  <div className="container">
                    <Container>
                      <Header
                        event={fetch_event}
                        booking={first(
                          search_event_bookings.event_booking_listing,
                        )}
                        tickets={
                          search_event_ticket_records.ticket_record_listing
                        }
                      />
                      <hr />

                      <BookingInfo
                        booking={first(
                          search_event_bookings.event_booking_listing,
                        )}
                        tickets={
                          search_event_ticket_records.ticket_record_listing
                        }
                      />
                    </Container>
                    <BookingByTicketTable
                      setSort={setSort}
                      tickets={search_event_ticket_records}
                    />
                    <br />
                    <br />

                    <BookingByAttendeeTable
                      setSort={setSort}
                      tickets={search_event_ticket_records}
                      booking={first(
                        search_event_bookings.event_booking_listing,
                      )}
                    />
                  </div>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default BookingDetails;
