import React from 'react';
import { Label } from 'rbx';
import styled from 'styled-components';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InputErrorMessage from './InputErrorMessage';

const Span = styled.span`
  padding-left: 1rem;
`;

const MobileInput = ({
  label,
  required,
  children,
  errors,
  touched,
  ...props
}) => (
  <div className="field">
    {label && (
      <Label className="is-flex">
        {label}
        {required && <span className="has-text-danger">*</span>}
        <Span>{children}</Span>
      </Label>
    )}
    <div className="control">
      <ReactPhoneInput
        country="au"
        inputClass="input is-primary"
        countryCodeEditable={false}
        {...props}
      />
    </div>
    <InputErrorMessage errors={errors} touched={touched} />
  </div>
);

export default MobileInput;
